import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, AbsoluteBox, RelativeFlex } from '../../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const DispatchingGroup = (props: Object) => {
  const { visibility, onClickHandler, showCloseIcon, setValueToStore, dispatchingGroup } = props;

  const name = R.path(['name'], dispatchingGroup);
  const guid = G.getGuidFromObject(dispatchingGroup);

  if (dispatchingGroup) {
    return (
      <RelativeFlex
        p='5px'
        mt={10}
        width={400}
        cursor='pointer'
        border='1px solid'
        borderRadius='5px'
        bg={G.getTheme('colors.white')}
        visibility={R.or(visibility, 'visible')}
        borderColor={G.getTheme('colors.dark.blue')}
        onClick={() => onClickHandler({ dispatchingGroupGuid: guid })}
      >
        {I.groupIcon()}
        <TextComponent
          ml={10}
          title={name}
          display='block'
          maxWidth='180px'
          withEllipsis={true}
          color={darkBlueColor}
        >
          {name}
        </TextComponent>
        { showCloseIcon &&
          <AbsoluteBox
            right='5px'
            width='30px'
            height='35px'
            cursor='pointer'
            title={G.getWindowLocale('titles:close', 'Close')}
            onClick={(event: Event) => {
              G.stopPropagation(event);

              setValueToStore({ value: null, path: `loadsByDispatchGroup.${guid}` });
            }}
          >
            {I.closeIcon(darkBlueColor)}
          </AbsoluteBox>
        }
        { R.not(showCloseIcon) &&
          <AbsoluteBox
            right='5px'
            width='30px'
            height='35px'
            cursor='pointer'
            title={G.getWindowLocale('titles:open', 'Open')}
          >
            {I.arrowDownSimple(darkBlueColor)}
          </AbsoluteBox>
        }
      </RelativeFlex>
    );
  }

  return (
    <Box mt={10} visibility={R.or(visibility, 'visible')} color={darkBlueColor}>
      {G.getWindowLocale('titles:no-group', 'No Group')}
    </Box>
  );
};

export default DispatchingGroup;
