import * as R from 'ramda';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
// components
import { TextComponent } from '../../../../components';
import { Document, AddDocument2 } from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hooks
import { useDownloadAndPreviewDocument } from '../../../../hooks';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// ui
import { Box, MainActionButton, scrollableContainerCss4px } from '../../../../ui';
// feature fleet-profile
import { prepareAndSetDocuments } from './expense-ai-upload';
import { DocumentPreview, ExpenseDocument } from './expense-documents';
//////////////////////////////////////////////////

const ExpenseDocumentsUpdate = (props: Object) => {
  const {
    values,
    setValues,
    aiEnabled,
    documents,
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    setDocuments,
    aiRecognizeRequest,
    handleRemovePrevFile,
    documentsDefaultDocumentType,
  } = props;

  const { fileUri, fileName, createdBy, createdDate, deleteFile } = values;

  const document = {
    fileUri,
    createdBy,
    createdDate,
    documentFilename: fileName,
  };

  const isEditMode = G.isNotNilAndNotEmpty(G.getGuidFromObject(values));

  const { documentRes, documentURL, handleDownloadOrPreviewDocument } = useDownloadAndPreviewDocument();

  const [expenseDocumentURL, setExpenseDocumentURL] = useState(documentURL);

  const handleRemoveDocument = useCallback((index: number) => {
    const newFiles = documents.filter((_: any, i: number) => i !== index);

    setDocuments(newFiles);
  }, [documents, setDocuments]);

  const handleDocuments = useCallback(async (method: string) => {
    openLoader();

    if (R.equals(method, 'delete')) handleRemovePrevFile();

    closeLoader();
  }, [handleRemovePrevFile]);

  useEffect(() => {
    setExpenseDocumentURL(documentURL);
  }, [documentURL]);

  useEffect(() => {
    if (G.isNilOrEmpty(documentRes)) return;

    const file = G.getFileInstanceFromArrayBufferResponse(documentRes);

    const callback = (expense: Object) => {
      let newValues = G.mergeWithoutNullUndefinedOrEmptyString(values, expense);

      const date = G.getPropFromObject(GC.FIELD_DATE, newValues);

      if (R.and(G.isNotNilAndNotEmpty(date), G.isValidMoment(date))) {
        newValues = R.assoc(
          GC.FIELD_DATE,
          G.convertInstanceToDefaultDateTimeFormat(date),
          newValues,
        );
      }

      setValues(newValues);
    };

    aiRecognizeRequest(file, callback);
  }, [documentRes]);

  if (G.isNotNilAndNotEmpty(expenseDocumentURL)) {
    return (
      <DocumentPreview expenseDocumentURL={expenseDocumentURL} setExpenseDocumentURL={setExpenseDocumentURL} />
    );
  }

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        height='calc(100% - 200px)'
        css={scrollableContainerCss4px}
      >
        {
          isEditMode && R.and(R.not(deleteFile), G.isNotNilAndNotEmpty(fileName)) &&
          <Fragment>
            <Box mb={10}>
              <TextComponent fontSize={12}>
                {G.getWindowLocale('titles:current-file', 'Current File')}
              </TextComponent>
              {
                aiEnabled && (
                  <MainActionButton
                    ml={10}
                    height={26}
                    type='button'
                    onClick={() => handleDownloadOrPreviewDocument({
                      params: { fileUri },
                      returnObjectURL: true,
                      documentFilename: fileName,
                      endpoint: endpointsMap.driverExpenseDocumentDownload,
                    })}
                  >
                    {G.getWindowLocale('titles:parse-with-open-ai', 'Parse with Open AI')}
                  </MainActionButton>
                )
              }
            </Box>
            <Document
              document={document}
              openModal={openModal}
              closeModal={closeModal}
              handleDocuments={handleDocuments}
              handleDownloadOrPreviewDocument={(data: Object) => {
                const { fileUri, actionType, documentFilename } = data;

                handleDownloadOrPreviewDocument({
                  actionType,
                  documentFilename,
                  params: { fileUri },
                  returnObjectURL: true,
                  endpoint: endpointsMap.driverExpenseDocumentDownload,
                });
              }}
            />
          </Fragment>
        }
        {
          G.isNotNilAndNotEmpty(documents) &&
          <TextComponent fontSize={12}>
            {G.getWindowLocale('titles:new-files-to-submit', 'New Files To Submit')}
          </TextComponent>
        }
        {
          R.or(documents, []).map((document: Object, index: number) => (
            <ExpenseDocument
              key={index}
              index={index}
              document={document}
              openModal={openModal}
              closeModal={closeModal}
              handleRemoveDocument={handleRemoveDocument}
              setExpenseDocumentURL={setExpenseDocumentURL}
            />
          ))
        }
      </Box>
      <AddDocument2
        onDropHandler={(files: Array) => {
          prepareAndSetDocuments({
            files,
            documents,
            setDocuments,
            defaultDocumentType: documentsDefaultDocumentType,
          });
        }}
      />
    </Fragment>
  );
};

export default ExpenseDocumentsUpdate;
