import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import { setExpandedContainerOptions } from '../../../expanded-container/actions';
import { ClaimFormForCloDetails } from '../../../claim-management/components/claim-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch-details-new
import { removeClaimRequest, createOrUpdateClaimRequest } from '../actions';
//////////////////////////////////////////////////

const withAddClaim = compose(
  withHandlers({
    handleAddClaim: (props: Object) => () => {
      const { load, openModal, createOrUpdateClaimRequest } = props;

      const initialValues = {
        [GC.FIELD_CLO_GUID]: G.getGuidFromObject(load),
        [GC.BRANCH_GUID]: G.getBranchGuidFromObject(load),
      };

      const component = (
        <ClaimFormForCloDetails
          isCloDisabled={true}
          initialValues={initialValues}
          branchGuid={R.prop(GC.BRANCH_GUID, load)}
          submitAction={createOrUpdateClaimRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getAddTitle('titles:claim', 'Claim'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const withClaimTableGroupRowActions = compose(
  connect(
    null,
    {
      removeClaimRequest,
      createOrUpdateClaimRequest,
      setExpandedContainerOptions,
    },
  ),
  withHandlers({
    handleEditClaim: ({ entity, setExpandedContainerOptions }: Object) => () => setExpandedContainerOptions({
      opened: true,
      visitPageGuid: G.getGuidFromObject(entity),
      componentType: GC.PAGE_TYPE_CLAIM_DETAILS,
      options: { [GC.FIELD_GUID]: R.prop(GC.FIELD_CLO_GUID, entity) },
    }),
    handleRemoveClaim: ({ entity, openModal, removeClaimRequest }: Object) => () => {
      const { guid } = entity;

      const textLocale = G.getWindowLocale(
        'messages:confirm-delete-entity',
        'Are you sure you want to delete this entity?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeClaimRequest(guid),
              name: G.getWindowLocale('actions:remove', 'Remove'),
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

export {
  withAddClaim,
  withClaimTableGroupRowActions,
};
