import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../components/text';
import EditableBox from '../../../components/editable-box';
import { pickerColorGrey } from '../../../components/color-picker';
import PopperColorPicker from '../../../components/color-picker/popper-color-picker';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withHoveredZIndex } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  RelativeFlex,
} from '../../../ui';
// features drivers-card
import { makeSelectZoom } from '../selectors';
import { getCardWidth, getCardLeftPosition } from './card';
import { withPendingActivity } from '../hocs/with-pending-activity';
import { getMainCardWidth, getCardActionColor, getCardDayMultiplier } from '../helpers';
import { PICKER_COLOR_TO_CARD_RGB_MAP, PICKER_COLOR_TO_TEXT_COLOR_MAP } from '../constants';
//////////////////////////////////////////////////

const blackColor = G.getTheme('colors.black');
const subtextColor = G.getTheme('colors.#7D828C');

const getHeaderText = (status: Object, driverName: string) => {
  const value = G.getDisplayedValueFromObject(status);

  const title = `${driverName}. ${G.getWindowLocale('titles:activity', 'Activity')}`;

  if (G.isNilOrEmpty(value)) return title;

  return `${title}: ${value}`;
};

const getSubheaderText = (item: Object) => {
  const { endDate, location, startDate } = item;

  const subheaderText = G.concatLocationFields(location);

  // TODO: with state and country mapping
  const subheaderTitle = G.createStringFromArray([subheaderText, startDate, endDate], ' - ');

  return { subheaderText, subheaderTitle };
};

const PendingActivity = (props: Object) => {
  const {
    zoom,
    width,
    activity,
    cardIndex,
    cardHeight,
    driverName,
    filtersStore,
    hoveredZIndex,
    show24HoursView,
    handleZIndexOnHover,
    handleZIndexOnUnHover,
    patchUpdatePendingActivity,
    handleUpdatePendingActivity,
    handleRemovePendingActivity,
  } = props;

  const { note, status, endDate, startDate, driverCardColor } = activity;

  const [colorValue, setColorValue] = useState(driverCardColor);

  const colorValueToUse = R.or(colorValue, pickerColorGrey);

  useEffect(() => {
    setColorValue(driverCardColor);
  }, [driverCardColor]);

  const changeColorCallback = (color: string) => {
    patchUpdatePendingActivity(R.assoc(GC.FIELD_DRIVER_CARD_COLOR, color, activity), () => setColorValue(color));
  };

  const changeNoteCallback = (note: string) => {
    patchUpdatePendingActivity(R.assoc(GC.FIELD_NOTE, note, activity));
  };

  const mainCardWidth = getMainCardWidth(show24HoursView, zoom);
  const cardDayMultiplier = getCardDayMultiplier(show24HoursView, zoom);

  const widthToUse = getCardWidth(
    width,
    startDate,
    endDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  );

  if (G.isNilOrEmpty(widthToUse)) return null;

  const zIndex = R.subtract(500, cardIndex);

  const left = getCardLeftPosition(
    true,
    filtersStore,
    startDate,
    mainCardWidth,
    cardDayMultiplier,
  );

  const headerText = getHeaderText(status, driverName);

  const headerTextColor = R.path([colorValueToUse], PICKER_COLOR_TO_TEXT_COLOR_MAP);

  const { subheaderText, subheaderTitle } = getSubheaderText(activity);

  const cardActionColor = getCardActionColor(colorValueToUse);

  const cardBg = R.path([colorValueToUse], PICKER_COLOR_TO_CARD_RGB_MAP);

  return (
    <Flex
      left={left}
      alignItems='start'
      position='absolute'
      height={cardHeight}
      flexDirection='column'
      justifyContent='center'
      width={R.or(widthToUse, 'auto')}
      zIndex={R.or(hoveredZIndex, zIndex)}
      onMouseEnter={() => handleZIndexOnHover()}
      onMouseLeave={() => handleZIndexOnUnHover()}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
    >
      <Box width='100%' height='100%' justifyContent='space-between'>
        <RelativeFlex
          px='5px'
          height={24}
          width='100%'
          borderRadius='2px'
          bg={colorValueToUse}
          borderBottomLeftRadius='0px'
          borderBottomRightRadius='0px'
          justifyContent='space-between'
        >
          <AbsoluteBox
            top='-7px'
            right='15px'
            width='30px'
            height='7px'
            borderRadius='2px'
            bg={colorValueToUse}
          />
          <Box color={headerTextColor}>
            <TextComponent
              fontSize={14}
              display='block'
              title={headerText}
              withEllipsis={true}
              maxWidth={R.subtract(widthToUse, 100)}
            >
              {headerText}
            </TextComponent>
          </Box>
          <Flex>
            <PopperColorPicker
              arrowColor={cardActionColor}
              activeBoxProps={{ height: 17 }}
              activeBorder={`1px solid ${cardActionColor}`}
              pickerProps={{
                changeColorCallback,
                value: colorValueToUse,
                wrapperStyles: { width: 150, justifyContent: 'space-between' },
                itemStyles: { width: 20, height: 20, opacity: 0.9, borderRadius: '4px' },
              }}
            />
            <Flex
              pr='5px'
              cursor='pointer'
              onClick={() => handleRemovePendingActivity(activity)}
            >
              {I.trash(cardActionColor)}
            </Flex>
            <Flex
              cursor='pointer'
              onClick={() => handleUpdatePendingActivity(
                R.assoc('status', G.getDropdownOptionGuidFromObject(status), activity),
              )}
            >
              {I.pencil(cardActionColor)}
            </Flex>
          </Flex>
        </RelativeFlex>
        <Flex
          p='5px'
          bg={cardBg}
          alignItems='start'
          color={blackColor}
          borderRadius='2px'
          flexDirection='column'
          borderTopLeftRadius='0px'
          borderTopRightRadius='0px'
          height={R.subtract(cardHeight, 24)}
        >
          <TextComponent
            mb='5px'
            fontSize={12}
            display='block'
            maxWidth='100%'
            withEllipsis={true}
            color={subtextColor}
            title={subheaderTitle}
          >
            {subheaderText}
          </TextComponent>
          {
            G.isNotNilAndNotEmpty(note) &&
            <Box
              fontSize={12}
              color={subtextColor}
            >
              {G.getWindowLocale('titles:notes', 'Notes')}:
            </Box>
          }
          <EditableBox
            value={note}
            bgColor={cardBg}
            onBlurCallback={changeNoteCallback}
            wrapperStyles={{
              pt: '5px',
              width: '100%',
              height: R.subtract(cardHeight, 70),
              color: G.getTheme('colors.dark.mainDark'),
            }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
});

export default connect(mapStateToProps, {})(
  withHoveredZIndex({ zIndex: 1501 })(withPendingActivity(PendingActivity)),
);
