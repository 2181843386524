import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { Tabs2 } from '../../../components/tabs-mui';
import { TitlePanel } from '../../../components/title-panel';
import { ConfirmComponent } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { PageWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature geo-fencing-zone
import { getPageTabs } from '../settings';
import GeoFencingZoneForm from '../components/geo-fencing-zone-form';
import { makeSelectItemList, makeSelectIndexedList } from './selectors';
import { commonTableSettings, geoFencingZoneDetailsTableSettings } from '../settings/table-settings';
import {
  toggleGeoFencingZoneDetails,
  createGeoFencingZoneRequest,
  deleteGeoFencingZoneRequest,
  createGeoFencingZoneExportRequest,
} from './actions';
import {
  report,
  columnSettings,
  ratingGeoFencingZoneDetailsReport,
  geoFencingZoneDetailsColumnSettings,
} from '../settings/column-settings';
//////////////////////////////////////////////////

export const withGeoFencingZone = compose(
  withHandlers({
    handleCreateGeoFencingZone: (props: Object) => () => {
      const { addTitle, openModal, createGeoFencingZoneRequest } = props;

      const component = <GeoFencingZoneForm submitAction={createGeoFencingZoneRequest} />;

      const modal = {
        component,
        p: '15px 10px 15px 15px',
        options: {
          width: 985,
          height: 'auto',
          title: R.or(addTitle, G.getWindowLocale('titles:add-geo-fencing-zone', 'Add Geo Fencing Zone')),
        },
      };

      openModal(modal);
    },
    handleEditGeoFencingZone: (props: Object) => (_: any, initialValues: Object) => {
      const {
        type,
        openModal,
        editTitle,
        createGeoFencingZoneRequest,
      } = props;

      const component = (
        <GeoFencingZoneForm
          type={type}
          initialValues={initialValues}
          submitAction={createGeoFencingZoneRequest}
        />
      );

      const modal = {
        component,
        p: '15px 10px 15px 15px',
        options: {
          width: 985,
          height: 'auto',
          title: R.or(editTitle, G.getWindowLocale('titles:edit-geo-fencing-zone', 'Edit Geo Fencing Zone')),
        },
      };

      openModal(modal);
    },
    handleDeleteGeoFencingZone: (props: Object) => (_: any, { name, guid }: Object) => {
      const { openModal, deleteGeoFencingZoneRequest } = props;

      const textLocale = G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this');
      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => deleteGeoFencingZoneRequest(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleClickTab: () => (i: number) => {
      if (G.isZero(i)) return;

      G.goToRoute(routesMap.reportGeoFencingZoneList);
    },
  }),
  pure,
);

const renderGeoFencingZoneDetails = ({ parentProps }: Object) => {
  const data = {
    loading: false,
    hasSelectable: false,
    allowSelectItems: false,
    report: ratingGeoFencingZoneDetailsReport,
    tableSettings: geoFencingZoneDetailsTableSettings,
    columnSettings: geoFencingZoneDetailsColumnSettings,
    itemList: parentProps[GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS],
  };

  return <Table {...data} />;
};

const ratingTableSettings = {
  ...commonTableSettings,
  expandedDetailsComponent: (props: Object) => renderGeoFencingZoneDetails(props),
};

const actionButtons = ({ handleEditGeoFencingZone, handleDeleteGeoFencingZone }: Object) => [
  {
    iconName: 'pencil',
    action: handleEditGeoFencingZone,
    text: G.getWindowLocale('titles:edit', 'Edit'),
  },
  {
    iconName: 'trash',
    action: handleDeleteGeoFencingZone,
    text: G.getWindowLocale('titles:delete', 'Delete'),
  },
];

export const GeoFencingZoneTable = ({
  itemList,
  handleEditGeoFencingZone,
  handleDeleteGeoFencingZone,
  toggleGeoFencingZoneDetails,
  additionalTableSettings = {},
}: Object) => (
  <Table
    report={report}
    loading={false}
    itemList={itemList}
    columnSettings={columnSettings}
    toggle={toggleGeoFencingZoneDetails}
    tableSettings={R.mergeRight(ratingTableSettings, additionalTableSettings)}
    actionButtons={actionButtons({ handleEditGeoFencingZone, handleDeleteGeoFencingZone })}
  />
);

const GeoFencingZoneList = (props: Object) => {
  const {
    itemList,
    branchGuid,
    handleClickTab,
    handleEditGeoFencingZone,
    handleDeleteGeoFencingZone,
    handleCreateGeoFencingZone,
    toggleGeoFencingZoneDetails,
    createGeoFencingZoneExportRequest,
  } = props;

  const customTitleComponent = (
    <Tabs2
      activeTab={0}
      tabs={getPageTabs(0)}
      count={R.length(itemList)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          withCount={true}
          filterProps={[]}
          noEditReport={true}
          noExportable={false}
          hideFilterInfo={true}
          popperWithCount={true}
          noSelectDropdown={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={false}
          totalCount={R.length(itemList)}
          customTitleComponent={customTitleComponent}
          exportReportDataRequest={createGeoFencingZoneExportRequest}
          title={G.getWindowLocale('titles:geo-fencing-zones', 'Geo Fencing Zones')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <GeoFencingZoneTable
          itemList={itemList}
          branchGuid={branchGuid}
          handleEditGeoFencingZone={handleEditGeoFencingZone}
          handleDeleteGeoFencingZone={handleDeleteGeoFencingZone}
          toggleGeoFencingZoneDetails={toggleGeoFencingZoneDetails}
        />
      </ZOrderWrapper>
      <PageActions
        shadowColor={G.getTheme('createButton.shadowColor')}
        mainAction={{
          action: handleCreateGeoFencingZone,
          text: G.getWindowLocale('titles:create-geo-fencing-zone', 'Create Geo Fencing Zone'),
        }}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  indexedList: makeSelectIndexedList(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  deleteGeoFencingZoneRequest,
  createGeoFencingZoneRequest,
  toggleGeoFencingZoneDetails,
  createGeoFencingZoneExportRequest,
})(withGeoFencingZone(GeoFencingZoneList));
