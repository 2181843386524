import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { Label } from '../../../components/label';
import { InfoPair } from '../../../components/info-pair';
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StyledLink, BoxHovered } from '../../../ui';
// features drivers-card
import { CARD_HEIGHT_170 } from '../constants';
//////////////////////////////////////////////////

const unknownLocale = G.getWindowLocale('titles:unknown', 'Unknown');

const borderColor = G.getTheme('colors.#E4E4E4');
const darkGreyColor = G.getTheme('colors.darkGrey');
const lightBlueColor = G.getTheme('colors.light.blue');
const lightGreyColor = G.getTheme('colors.light.grey');
const darkMainColor = G.getTheme('colors.dark.mainDark');
const lightMainColor = G.getTheme('colors.light.mainLight');

const infoPairCommonStyles = {
  fontSize: 13,
  lineHeight: 1,
  fontWeight: 'normal',
  textColor: darkGreyColor,
  actionTextColor: darkMainColor,
};

const Avatar = () => {
  const content = I.truck();

  return (
    <Flex
      mr={10}
      width={30}
      height={30}
      border='1px solid'
      borderRadius='50%'
      alignItems='center'
      justifyContent='center'
      borderColor={lightGreyColor}
      background='rgba(255, 255, 255, 0.5)'
    >
      {content}
    </Flex>
  );
};

const DriversInfo = (props: Object) => {
  const {
    guid,
    driver,
    groupGuid,
    dispatcherGuid,
    secondaryDriver,
    openDriverProfile,
    assignedToDivisionName,
    primaryDriverPendingActivities,
    primaryDriverUnavailablePeriods,
    secondaryDriverPendingActivities,
    secondaryDriverUnavailablePeriods,
  } = props;

  const { fullText, firstLastNames } = G.getUserInfo(driver);

  const { fullText: secondaryDriverTitle, firstLastNames: secondaryDriverName } = G.getUserInfo(secondaryDriver);

  return (
    <Fragment>
      <Flex justifyContent='space-between'>
        <BoxHovered
          cursor='pointer'
          alignSelf='flex-start'
          textDecoration='underline'
          hoverColor={lightBlueColor}
        >
          <TextComponent
            maxWidth={330}
            display='block'
            title={fullText}
            withEllipsis={true}
            fontSize={assignedToDivisionName ? 11 : 12}
            onClick={() => G.callFunctionWithArgs(
              openDriverProfile,
              {
                groupGuid,
                dispatcherGuid,
                truckGuid: guid,
                driverProfileGuid: G.getGuidFromObject(driver),
                pendingActivities: primaryDriverPendingActivities,
                unavailablePeriods: primaryDriverUnavailablePeriods,
              },
            )}
          >
            {firstLastNames}
          </TextComponent>
        </BoxHovered>
      </Flex>
      {
        G.isNotNilAndNotEmpty(secondaryDriver) &&
        <BoxHovered
          cursor='pointer'
          alignSelf='flex-start'
          textDecoration='underline'
          hoverColor={lightBlueColor}
        >
          <TextComponent
            maxWidth={330}
            display='block'
            withEllipsis={true}
            title={secondaryDriverTitle}
            fontSize={assignedToDivisionName ? 11 : 12}
            onClick={() => G.callFunctionWithArgs(
              openDriverProfile,
              {
                groupGuid,
                dispatcherGuid,
                truckGuid: guid,
                pendingActivities: secondaryDriverPendingActivities,
                unavailablePeriods: secondaryDriverUnavailablePeriods,
                driverProfileGuid: G.getGuidFromObject(secondaryDriver),
              },
            )}
          >
            {secondaryDriverName}
          </TextComponent>
        </BoxHovered>
      }
    </Fragment>
  );
};

const FleetInfo = (props: Object) => {
  const { guid, unitId, trailers, openTruckDetails, assignedToDivisionName } = props;

  const trailersText = G.createStringFromArrayWithProp(R.or(trailers, []), ', ', GC.FIELD_UNIT_ID);

  return (
    <Flex
      height='100%'
      alignItems='start'
      flexDirection='column'
      justifyContent='center'
    >
      <Box width={330}>
        <InfoPair
          {...infoPairCommonStyles}
          py='0px'
          text={unitId}
          textMaxWidth={270}
          fontSize={assignedToDivisionName ? 12 : 14}
          clickHandler={() => openTruckDetails(guid)}
          label={`${G.getWindowLocale('titles:truck', 'Truck')}#`}
        />
        <InfoPair
          {...infoPairCommonStyles}
          py='0px'
          textMaxWidth={270}
          text={trailersText}
          fontSize={assignedToDivisionName ? 11 : 12}
          label={`${G.getWindowLocale('titles:trailer', 'Trailer')}#`}
        />
        <InfoPair
          {...infoPairCommonStyles}
          py='0px'
          textMaxWidth={270}
          text={assignedToDivisionName}
          fontSize={assignedToDivisionName ? 11 : 12}
          label={G.getWindowLocale('titles:division', 'Division')}
        />
      </Box>
      <DriversInfo {...props} />
    </Flex>
  );
};

const Truck = (props: Object) => {
  const {
    guid,
    unitId,
    trailers,
    groupGuid,
    cardHeight,
    primaryDriver,
    dispatcherGuid,
    secondaryDriver,
    openTruckDetails,
    lastKnownLocation,
    openDriverProfile,
    assignedToDivisionName,
    primaryDriverPendingActivities,
    primaryDriverUnavailablePeriods,
    secondaryDriverPendingActivities,
    secondaryDriverUnavailablePeriods,
  } = props;

  const fullHeight = R.equals(cardHeight, CARD_HEIGHT_170);

  const lnl = G.concatLocationFields(lastKnownLocation);

  const phoneNumber = R.prop('phoneNumber', primaryDriver);

  return (
    <Flex
      py='3px'
      width={400}
      borderRadius={10}
      alignItems='start'
      bg={lightMainColor}
      height={cardHeight}
      flexDirection='column'
      justifyContent='space-around'
      border={`1px solid ${borderColor}`}
    >
      <Flex
        px={10}
        height={60}
        width='100%'
      >
        <Avatar
          groupGuid={groupGuid}
          driver={primaryDriver}
          secondaryDriver={secondaryDriver}
          openDriverProfile={openDriverProfile}
        />
        <FleetInfo
          guid={guid}
          unitId={unitId}
          trailers={trailers}
          groupGuid={groupGuid}
          driver={primaryDriver}
          fullHeight={fullHeight}
          dispatcherGuid={dispatcherGuid}
          secondaryDriver={secondaryDriver}
          openTruckDetails={openTruckDetails}
          openDriverProfile={openDriverProfile}
          assignedToDivisionName={assignedToDivisionName}
          primaryDriverPendingActivities={primaryDriverPendingActivities}
          primaryDriverUnavailablePeriods={primaryDriverUnavailablePeriods}
          secondaryDriverPendingActivities={secondaryDriverPendingActivities}
          secondaryDriverUnavailablePeriods={secondaryDriverUnavailablePeriods}
        />
      </Flex>
      <Flex
        px={10}
        width='100%'
        alignItems='start'
        borderTop={`1px solid ${borderColor}`}
        py={G.ifElse(fullHeight, '10px', '3px')}
        flexDirection={G.ifElse(fullHeight, 'column', 'row')}
      >
        <Label
          cursor='initial'
          frontIcon={I.phone2()}
          mb={G.ifElse(fullHeight, '5px', 0)}
        >
          <TextComponent
            display='block'
            withEllipsis={true}
            title={phoneNumber}
            maxWidth={G.ifElse(fullHeight, 350, 120)}
          >
            <StyledLink
              href={`tel:${phoneNumber}`}
            >
              {R.or(phoneNumber, unknownLocale)}
            </StyledLink>
          </TextComponent>
        </Label>
        <Label frontIcon={I.locationPin()}>
          <TextComponent
            title={lnl}
            display='block'
            withEllipsis={true}
            color={darkMainColor}
            maxWidth={G.ifElse(fullHeight, 350, 210)}
          >
            {R.or(lnl, unknownLocale)}
          </TextComponent>
        </Label>
      </Flex>
    </Flex>
  );
};

export default Truck;
