import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const selectDriverProfileSimpleStore = (state: Object) => state.driverProfileSimple;

const makeSelectOpenedDriverProfile = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ openedDriverProfile }: Object) => openedDriverProfile,
);

const makeSelectDriverProfileGuid = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverProfileGuid }: Object) => driverProfileGuid,
);

const makeSelectDriverGroupGuid = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ groupGuid }: Object) => groupGuid,
);

const makeSelectDriverTruckGuid = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ truckGuid }: Object) => truckGuid,
);

const makeSelectDriverDispatcherGuid = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ dispatcherGuid }: Object) => dispatcherGuid,
);

const makeSelectDriverPendingActivities = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverPendingActivities }: Object) => driverPendingActivities,
);

const makeSelectDriverUnavailablePeriods = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverUnavailablePeriods }: Object) => driverUnavailablePeriods,
);

const makeSelectDriverProfile = () => createSelector(
  selectDriverProfileSimpleStore,
  (state: Object) => {
    const { driverProfiles, driverProfileGuid } = state;

    const driverProfile = R.path([driverProfileGuid], driverProfiles);

    return driverProfile;
  },
);

const makeSelectDriverPrimaryDriver = () => createSelector(
  selectDriverProfileSimpleStore,
  (state: Object) => {
    const { driverProfiles, driverProfileGuid } = state;

    const driverProfile = R.path([driverProfileGuid], driverProfiles);

    return R.not(G.getPropFromObject('teamDriver', driverProfile));
  },
);

const makeSelectDriverAvailabilityStatus = () => createSelector(
  selectDriverProfileSimpleStore,
  ({ driverAvailabilityStatus }: Object) => driverAvailabilityStatus,
);

export {
  makeSelectDriverProfile,
  makeSelectDriverGroupGuid,
  makeSelectDriverTruckGuid,
  makeSelectDriverProfileGuid,
  makeSelectDriverPrimaryDriver,
  makeSelectOpenedDriverProfile,
  makeSelectDriverDispatcherGuid,
  makeSelectDriverPendingActivities,
  makeSelectDriverUnavailablePeriods,
  makeSelectDriverAvailabilityStatus,
};
