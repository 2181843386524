import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
import { ActionBox } from '../../../components/action-box';
import { withOpenTelRateRequestList } from '../../../components/tel-rate-request-list';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// forms
import { Toggle, ToggleRed } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  RelativeFlex,
  HideIfUserTypeCarrierBox,
  HideIfExpandedContainerOpenedBox,
} from '../../../ui';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');
const blueColor = G.getTheme('colors.dark.blue');
const redColor = G.getTheme('colors.light.mainRed');
const textColor = G.getTheme('colors.greyMatterhorn');

const CarriersHistory = withOpenTelRateRequestList(({ handleOpenTelRateRequestList }: Object) => (
  <AuthWrapper mr={15} has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
    <Box
      cursor='pointer'
      onClick={handleOpenTelRateRequestList}
      title={G.getWindowLocale('titles:carriers-history', 'Carriers History')}
    >
      {I.gearSolid(blueColor)}
    </Box>
  </AuthWrapper>
));

const getSecondLineTitleAndValue = (load: Object, isLoadTypeClo: boolean) => {
  if (isLoadTypeClo) {
    const isNotOnTel = G.isNilOrEmpty(R.prop(GC.SYSTEM_LIST_TELS, load));

    return {
      editableSecondLine: isNotOnTel,
      branchInfoSecondTitle: G.ifElse(
        R.or(isNotOnTel, R.pathEq(1, [GC.SYSTEM_LIST_TELS, 'length'], load)),
        G.getWindowLocale('titles:division', 'Division'),
        G.getWindowLocale('titles:divisions', 'Divisions'),
      ),
      branchInfoSecondValue: G.ifElse(
        isNotOnTel,
        R.path([GC.FIELD_BRANCH_NAME], G.getBranchFromWindowByGuid(R.pathOr('', [GC.FIELD_LOAD_DIVISION_GUID], load))),
        R.compose(
          R.join(', '),
          R.map((tel: Object) => R.prop(GC.FIELD_BRANCH_NAME, tel)),
          R.pathOr([], [GC.SYSTEM_LIST_TELS]),
        )(load),
      ),
    };
  }

  return {
    editableSecondLine: false,
    branchInfoSecondTitle: G.ifElse(
      R.pathEq(1, [GC.SYSTEM_LIST_CLOS, 'length'], load),
      G.getWindowLocale('titles:customer', 'Customer'),
      G.getWindowLocale('titles:customers', 'Customers'),
    ),
    branchInfoSecondValue: R.compose(
      R.join(', '),
      R.map((clo: Object) => R.prop(GC.FIELD_BRANCH_NAME, clo)),
      R.prop(GC.SYSTEM_LIST_CLOS),
    )(load),
  };
};

const getHotOrdersTitle = (load: Object) => {
  if (G.isLoadTypeClo(load)) return '';

  return R.compose(
    R.join(', '),
    R.map(R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE])),
    R.filter(({ hot }: Object) => hot),
    R.pathOr([], [GC.SYSTEM_LIST_CLOS]),
  )(load);
};

const ClaimItem = ({ status, subStatus, claimNumber, handleOpenDetails }: Object) => (
  <Flex mt={15}>
    <Flex color='greyMatterhorn' fontWeight='bold'>
      {G.getWindowLocale('titles:claim', 'Claim')}:
      <ActionBox
        text={claimNumber}
        action={handleOpenDetails}
        boxStyles={{ ml: 10, maxWidth: 250, fontWeight: 'bold' }}
      />
    </Flex>
    <Flex ml={16} color='greyMatterhorn'>
      {G.getWindowLocale('titles:claim-status', 'Claim Status')}:
      <TextComponent
        ml={10}
        maxWidth={200}
        title={status}
        display='block'
        fontWeight='bold'
        withEllipsis={true}
      >
        {status}
      </TextComponent>
    </Flex>
    {
      G.isNotNilAndNotEmpty(subStatus) &&
      <Flex ml={16} color='greyMatterhorn'>
        {G.getWindowLocale('titles:claim-sub-status', 'Claim Sub Status')}:
        <TextComponent
          ml={10}
          maxWidth={200}
          title={status}
          display='block'
          fontWeight='bold'
          withEllipsis={true}
        >
          {subStatus}
        </TextComponent>
      </Flex>
    }
  </Flex>
);

const MultipleClaims = (props: Object) => {
  const {
    openFixedPopup,
    handleOpenDetails,
    claimGeneralDetails,
  } = props;

  const handleOpen = (event: Object) => openFixedPopup({
    version: 2,
    position: 'left',
    wrapperPadding: '0',
    el: event.currentTarget,
    boxStyles: { zIndex: 3 },
    content: (
      <Box p='0 15px 15px 15px'>
        {claimGeneralDetails.map(({ guid, status, subStatus, claimNumber }: Object) => (
          <ClaimItem
            key={guid}
            claimNumber={claimNumber}
            status={G.getDisplayedValueFromObject(status)}
            subStatus={G.getDisplayedValueFromObject(subStatus)}
            handleOpenDetails={() => handleOpenDetails(guid, true)}
          />
        ))}
      </Box>
    ),
  });

  return (
    <ActionBox
      action={handleOpen}
      text={G.getWindowLocale('titles:multiple-claims', 'Multiple Claims')}
      boxStyles={{
        mt: 15,
        color: redColor,
        fontWeight: 'bold',
        hoverColor: redColor,
        width: 'max-content',
      }}
    />
  );
};

const ClaimGeneralDetails = (props: Object) => {
  const {
    loadGuid,
    closeFixedPopup,
    claimGeneralDetails,
    handleRefreshClaimDetails,
    setExpandedContainerOptions,
  } = props;

  if (R.includes('claim_details', G.getWindowLocationPathname())) return null;

  const handleOpenDetails = (guid: string, shouldClosePopup: boolean) => {
    if (shouldClosePopup) closeFixedPopup();

    setExpandedContainerOptions({
      opened: true,
      visitPageGuid: guid,
      componentType: GC.PAGE_TYPE_CLAIM_DETAILS,
      options: { [GC.FIELD_GUID]: loadGuid, callback: handleRefreshClaimDetails },
    });
  };

  if (G.isArray(claimGeneralDetails)) {
    return <MultipleClaims {...props} handleOpenDetails={handleOpenDetails} />;
  }

  const { guid, status, subStatus, claimNumber } = claimGeneralDetails;

  return (
    <ClaimItem
      claimNumber={claimNumber}
      status={G.getDisplayedValueFromObject(status)}
      handleOpenDetails={() => handleOpenDetails(guid)}
      subStatus={G.getDisplayedValueFromObject(subStatus)}
    />
  );
};

const PageHeader = (props: Object) => {
  const {
    load,
    my = 20,
    showFast,
    activeTab,
    notification,
    setActiveTab,
    openFixedPopup,
    closeFixedPopup,
    handleDownloadXML,
    handleChangeBranch,
    claimGeneralDetails,
    handleOpenAuditList,
    handleChangeDivision,
    primaryReferenceValue,
    handleRefreshClaimDetails,
    setExpandedContainerOptions,
    toggleMarkAsHotLoadByLoadTypeRequest,
    toggleMarkAsFastLoadByLoadTypeRequest,
  } = props;

  const { hot, guid, loadType, fastLoad } = load;

  const handleToggleHotLoad = () =>
    toggleMarkAsHotLoadByLoadTypeRequest({ guid, loadType, hot: R.not(hot), fromPage: 'details' });
  const handleToggleFastLoad = () =>
    toggleMarkAsFastLoadByLoadTypeRequest({ guid, loadType, fastLoad: R.not(fastLoad), fromPage: 'details' });
  const hotColor = G.ifElse(hot, G.getTheme('colors.light.mainRed'), G.getTheme('colors.light.darkGrey'));
  const getActiveTabIconColor = (currentTab: string) => G.getThemeByCond(
    R.equals(activeTab, currentTab),
    'colors.dark.blue',
    'colors.dark.grey',
  );
  const generalTabIconColor = getActiveTabIconColor('general');
  const documentsTabIconColor = getActiveTabIconColor('documents');
  const isLoadTypeClo = G.isLoadTypeClo(load);
  const customerTitle = G.getWindowLocale('titles:customer', 'Customer');
  const divisionTitle = G.getWindowLocale('titles:division', 'Division');
  const branchInfoFirstTitle = G.ifElse(isLoadTypeClo, customerTitle, divisionTitle);

  const {
    editableSecondLine,
    branchInfoSecondTitle,
    branchInfoSecondValue,
  } = getSecondLineTitleAndValue(load, isLoadTypeClo);

  const getMarkAsHotPermissions = G.ifElse(isLoadTypeClo, R.of(Array, PC.CLO_WRITE), R.of(Array, PC.TEL_WRITE));

  const changeBranchPermissions = G.ifElse(
    isLoadTypeClo,
    R.of(Array, PC.CLO_ENTERPRISE_WRITE),
    R.of(Array, PC.TEL_ENTERPRISE_WRITE),
  );

  const getXmlPermissions = G.ifElse(
    isLoadTypeClo,
    R.of(Array, PC.CLO_WRITE),
    R.of(Array, PC.TEL_WRITE),
  );

  const getAuditPermissions = G.ifElse(
    isLoadTypeClo,
    R.of(Array, PC.CLO_ADMINISTRATION_EXECUTE),
    R.of(Array, PC.TEL_ADMINISTRATION_EXECUTE),
  );

  const getDocumentsTabPermissions = G.ifElse(
    isLoadTypeClo,
    [PC.CLO_DOCUMENT_READ, PC.CLO_DOCUMENT_WRITE, PC.CLO_CHAT_MESSAGE_READ, PC.CLO_CHAT_MESSAGE_WRITE],
    [PC.TEL_DOCUMENT_READ, PC.TEL_DOCUMENT_WRITE, PC.TEL_CHAT_MESSAGE_READ, PC.TEL_CHAT_MESSAGE_WRITE],
  );

  return (
    <Box
      my={my}
      p='15px 25px'
      overflowX='auto'
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
      boxShadow='0 0 5px 0 rgba(0, 0, 0, 0.2)'
    >
      <Flex justifyContent='space-between'>
        <Flex>
          <Flex fontSize={24} color={blueColor} fontWeight='bold'>
            {G.ifElse(
              G.isLoadTypeClo(load),
              G.getWindowLocale('titles:clo', 'CLO'),
              G.getWindowLocale('titles:tel', 'TEL'),
            )}:
            <TextComponent
              ml={10}
              maxWidth={300}
              display='block'
              withEllipsis={true}
              title={primaryReferenceValue}
            >
              {primaryReferenceValue}
            </TextComponent>
          </Flex>
          <HideIfUserTypeCarrierBox ml={16} color={textColor}>
            <Flex mb='6px'>
              {branchInfoFirstTitle}:
              <TextComponent
                mx={10}
                maxWidth={250}
                display='block'
                fontWeight='bold'
                withEllipsis={true}
              >
                {R.path([GC.FIELD_BRANCH, GC.BRANCH_NAME], load)}
              </TextComponent>
              <AuthWrapper has={changeBranchPermissions}>
                <Box cursor='pointer' onClick={handleChangeBranch}>
                  {I.pencil(iconColor)}
                </Box>
              </AuthWrapper>
            </Flex>
            <Flex>
              {branchInfoSecondTitle}:
              <TextComponent
                mx={10}
                maxWidth={250}
                display='block'
                fontWeight='bold'
                withEllipsis={true}
                title={branchInfoSecondValue}
              >
                {branchInfoSecondValue}
              </TextComponent>
              {
                editableSecondLine &&
                <AuthWrapper has={changeBranchPermissions}>
                  <Box cursor='pointer' onClick={handleChangeDivision}>
                    {I.pencil(iconColor)}
                  </Box>
                </AuthWrapper>
              }
            </Flex>
          </HideIfUserTypeCarrierBox>
          <AuthWrapper has={getMarkAsHotPermissions}>
            <Flex ml={16} title={getHotOrdersTitle(load)}>
              <ToggleRed
                checked={hot}
                icons={false}
                onChange={handleToggleHotLoad}
              />
              <Box
                ml={10}
                color={hotColor}
                cursor='pointer'
                textTransform='uppercase'
                onClick={handleToggleHotLoad}
              >
                {G.ifElse(
                  G.isLoadTypeClo(load),
                  G.getWindowLocale('titles:hot-order', 'Hot Order'),
                  G.getWindowLocale('titles:hot-trip', 'Hot Trip'),
                )}
              </Box>
            </Flex>
          </AuthWrapper>
          {
            showFast &&
            <Flex ml={16}>
              <Toggle
                icons={false}
                checked={fastLoad}
                onChange={handleToggleFastLoad}
              />
              <Box
                ml={10}
                color={hotColor}
                cursor='pointer'
                textTransform='uppercase'
                onClick={handleToggleFastLoad}
              >
                {G.getWindowLocale('titles:fast', 'Fast')}
              </Box>
            </Flex>
          }
        </Flex>
        <Flex ml={10}>
          {
            G.isFalse(notification.empty) &&
            <RelativeFlex
              mr={15}
              ml='auto'
              cursor='pointer'
              onClick={props.handleShowNotifications}
            >
              {I.bellIcon(blueColor, 20, 20)}
              <AbsoluteBox
                width={18}
                left='8px'
                height={18}
                fontSize={9}
                bottom='12px'
                bg={blueColor}
                borderRadius='50%'
                justifyContent='center'
                color={G.getTheme('colors.white')}
              >
                {notification.unreadCount}
              </AbsoluteBox>
            </RelativeFlex>
          }
          <Box
            mr={15}
            cursor='pointer'
            onClick={() => setActiveTab('general')}
            title={G.getWindowLocale('titles:general-tab', 'General Tab')}
          >
            {I.renderDetailInfoIcon(generalTabIconColor)}
          </Box>
          <AuthWrapper has={getDocumentsTabPermissions}>
            <HideIfUserTypeCarrierBox
              mr={15}
              cursor='pointer'
              onClick={() => setActiveTab('documents')}
              title={G.getWindowLocale('titles:documents-tab', 'Documents Tab')}
            >
              {I.documents(documentsTabIconColor)}
            </HideIfUserTypeCarrierBox>
          </AuthWrapper>
          {
            isLoadTypeClo &&
            <AuthWrapper has={[PC.CLO_RATE_WRITE, PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_OVERWRITE_EXECUTE]}>
              <HideIfUserTypeCarrierBox
                mr={15}
                fontSize={20}
                cursor='pointer'
                onClick={() => setActiveTab('invoicing')}
                color={getActiveTabIconColor('invoicing')}
                title={G.getWindowLocale('titles:invoicing-tab', 'Invoicing Tab')}
              >
                $
              </HideIfUserTypeCarrierBox>
            </AuthWrapper>
          }
          <AuthWrapper has={getAuditPermissions}>
            <Box
              mr={15}
              cursor='pointer'
              onClick={handleOpenAuditList}
              title={G.getWindowLocale('titles:audit', 'Audit')}
            >
              {I.renderAuditHistoryIcon(blueColor)}
            </Box>
          </AuthWrapper>
          {
            G.isLoadTypeTel(loadType) && <CarriersHistory telGuid={guid} />
          }
          <AuthWrapper has={getXmlPermissions}>
            <Box
              mr={15}
              cursor='pointer'
              onClick={handleDownloadXML}
              title={G.getWindowLocale('titles:get-xml', 'Get XML')}
            >
              {I.downloadButton()}
            </Box>
          </AuthWrapper>
          <HideIfExpandedContainerOpenedBox
            cursor='pointer'
            onClick={() => window.location.reload()}
            title={G.getWindowLocale('titles:refresh-page', 'Refresh Page')}
          >
            {I.refresh()}
          </HideIfExpandedContainerOpenedBox>
        </Flex>
      </Flex>
      {
        G.isNotNilAndNotEmpty(claimGeneralDetails) &&
        <ClaimGeneralDetails
          loadGuid={guid}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          claimGeneralDetails={claimGeneralDetails}
          handleRefreshClaimDetails={handleRefreshClaimDetails}
          setExpandedContainerOptions={setExpandedContainerOptions}
        />
      }
    </Box>
  );
};

export default PageHeader;
