import * as R from 'ramda';
import React, { memo } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedFlex, scrollableContainerCss6px } from '../../../ui';
// feature drivers-card
import Truck from './truck';
import Filters from './filters';
import DispatchingGroup from './dispatching-group';
import { DriverTelsCards } from './driver-items-lists';
import CalendarLine, { CalendarLine2 } from './calendar-line';
import { getGroupedAssignedLoadsToUse } from './grouped-by-cards';
import TruckPendingActivitiesAndUnavailablePeriods from './truck-pending-activities-and-unavailable-periods';
//////////////////////////////////////////////////

export default memo((props: Object) => {
  const {
    cardHeight,
    whiteColor,
    searchDate,
    filtersStore,
    teamFilterValue,
    setValueToStore,
    driverCardsInfo,
    openTruckDetails,
    searchRadiusValue,
    openDriverProfile,
    minEmptyHoursValue,
    loadsByDispatchGroup,
    driverLoadsByDispatcher,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
    getDriverLoadsByDispatchGroupRequest,
  } = props;

  const dispatchingGroups = R.pathOr([], ['dispatchingGroups'], driverCardsInfo);

  const groupedAssignedLoadsToUse = getGroupedAssignedLoadsToUse({
    searchDate,
    filtersStore,
    dispatchers: [],
    teamFilterValue,
    searchRadiusValue,
    dispatchingGroups,
    minEmptyHoursValue,
    loadsByDispatchGroup,
    showDispatchers: false,
    driverLoadsByDispatcher,
    showDispatchingGroups: true,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
  });

  return (
    <Flex
      height='100%'
      overflow='auto'
      bg={whiteColor}
      flexDirection='row'
      alignItems='flex-start'
      css={scrollableContainerCss6px}
    >
      {
        <StickedFlex
          left='0'
          pr={15}
          zIndex={2100}
          minHeight='100%'
          flexDirection='column'
          bg={G.getTheme('colors.bgGrey')}
        >
          <Filters {...props} />
          {
            groupedAssignedLoadsToUse.map((group: Object, index: number) => (
              <Flex key={index} flexDirection='column'>
                <DispatchingGroup
                  dispatchingGroup={group}
                  setValueToStore={setValueToStore}
                  onClickHandler={getDriverLoadsByDispatchGroupRequest}
                  showCloseIcon={
                    G.isNotNilAndNotEmpty(R.path([G.getGuidFromObject(group)], loadsByDispatchGroup))
                  }
                />
                {
                  group.driverLoads.map((entity: Object, driverIndex: number) => (
                    <Box mt={10} key={`${index}${driverIndex}`}>
                      <Truck
                        {...entity}
                        cardHeight={cardHeight}
                        openTruckDetails={openTruckDetails}
                        openDriverProfile={openDriverProfile}
                        groupGuid={G.getGuidFromObject(group)}
                      />
                    </Box>
                  ))
                }
              </Flex>
            ))
          }
        </StickedFlex>
      }
      {
        <Flex
          bg={whiteColor}
          flexDirection='column'
          alignItems='flex-start'
          width='calc(100vw - 350px)'
        >
          <CalendarLine {...props} />
          <CalendarLine2 {...props} />
          {
            groupedAssignedLoadsToUse.map((group: Object, index: number) => (
              <Flex key={index} ml={10} position='relative' flexDirection='column'>
                <DispatchingGroup
                  visibility='hidden'
                  dispatchingGroup={R.or(group.dispatchingGroup, group)}
                />
                {
                  group.driverLoads.map((truck: Object) => (
                    <Flex
                      mt={10}
                      alignItems='start'
                      height={cardHeight}
                      key={R.prop(GC.FIELD_GUID, truck)}
                    >
                      {
                        G.isNotNilAndNotEmpty(R.path(['tels'], truck)) &&
                        <DriverTelsCards
                          {...props}
                          tels={R.path(['tels'], truck)}
                          truckGuid={G.getGuidFromObject(truck)}
                          groupGuid={G.getGuidFromObject(group)}
                        />
                      }
                      <TruckPendingActivitiesAndUnavailablePeriods
                        {...props}
                        group={group}
                        truck={truck}
                      />
                    </Flex>
                  ))
                }
              </Flex>
            ))
          }
        </Flex>
      }
    </Flex>
  );
});
