import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const store = R.path(['fleetProfile', 'truck']);

const selectTruckGeneralDetails = createSelector(
  R.path(['fleetProfile', 'truck', 'generalDetails']),
  (generalDetails: Object) => {
    if (R.isNil(generalDetails)) return;

    const {
      truckType,
      outOfServiceReason,
      unavailabilityReason,
      truckAdditionalEquipment,
    } = generalDetails;

    const mappedTruckAdditionalEquipment = R.map(
      G.getDropdownOptionGuidFromObject,
      R.or(truckAdditionalEquipment, []),
    );

    return {
      ...generalDetails,
      [GC.FIELD_TRUCK_TYPE]: G.getDropdownOptionGuidFromObject(truckType),
      [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT]: mappedTruckAdditionalEquipment,
      [GC.FIELD_OUT_OF_SERVICE_REASON]: R.propOr('', GC.FIELD_DROPDOWN_OPTION_GUID, outOfServiceReason),
      [GC.FIELD_UNAVAILABILITY_REASON]: R.propOr('', GC.FIELD_DROPDOWN_OPTION_GUID, unavailabilityReason),
    };
  },
);

const makeSelectTruckGeneralTabEntities = () => createSelector(
  store,
  R.pick([
    'references',
    'insurances',
    'integrationList',
    'lastKnownLocation',
    'truckIntegrationList',
    'teamDriverIntegrationList',
  ]),
);

const makeSelectTruckMaintenanceTabEntities = () => createSelector(
  store,
  R.pick(['equipmentComponent']),
);

const makeSelectTruckSpecificationsTabEntities = () => createSelector(
  store,
  R.pick(['locations', 'ownership', 'specification']),
);

const makeSelectTruckDocumentsTabEntities = () => createSelector(
  store,
  (state: Object) => {
    const { documents } = state;

    const documentsFilter = R.path(['formGroupFilters', 'documentsFilter'], state);

    const filteredDocuments = R.compose(
      R.filter((item: Object) => R.or(
        G.isNilOrEmpty(documentsFilter),
        R.pathEq(documentsFilter, [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item),
      )),
      R.pathOr([], ['documents']),
    )(state);

    return {
      documents: G.ifElse(G.isNotNil(documents), filteredDocuments, documents),
    };
  },
);

const makeSelectDailyLogTabEntities = () => createSelector(
  store,
  R.pick(['dailyLog']),
);

const makeSelectDailyLog = () => createSelector(
  store,
  R.prop('dailyLog'),
);

export {
  // common
  selectTruckGeneralDetails,
  // tabs
  makeSelectDailyLogTabEntities,
  makeSelectTruckGeneralTabEntities,
  makeSelectTruckDocumentsTabEntities,
  makeSelectTruckMaintenanceTabEntities,
  makeSelectTruckSpecificationsTabEntities,
  // daily log
  makeSelectDailyLog,
};
