import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  lifecycle,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import Zoom from '../../components/zoom';
import { makeSelectLoader } from '../../components/loader/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { enhanceRateCharges } from '../rate/hocs';
import { messageType } from '../sockets-v2/constants';
import { socketPostMessage } from '../sockets-v2/actions';
import DriverProfileSimple from '../driver-profile-simple';
import { addTelToRouteBuilderByGuid } from '../dispatch-planner/actions';
import { setExpandedContainerOptions } from '../expanded-container/actions';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { addTelToLoadPlannerByGuid } from '../dispatch-planner-events/actions';
import { makeSelectExpandedContainerOptions } from '../expanded-container/selectors';
import {
  openDriverProfile,
  setInitialState as setDriverProfileSimpleInitialState,
} from '../driver-profile-simple/actions';
// hocs
import { withFixedPopover, withAsyncConfigs, withComponentDidUpdatePropCallback } from '../../hocs';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box, Flex, PageWrapper, CancelButton, RelativeFlex } from '../../ui';
// feature drivers-card
import Cards from './components/cards';
import { getRequestFilters } from './helpers';
import CardActions from './components/card-actions';
import Header, { SubHeader } from './components/header';
import UnassignedLoadsList from './components/unassigned-loads-list';
import {
  enhanceEvents,
  enhanceDetails,
  enhanceChatMessages,
  enhanceNotifications,
  enhanceCardsDateRange,
} from './hocs';
import {
  makeSelectZoom,
  makeSelectFilters,
  makeSelectGroupBy,
  makeSelectCardView,
  makeSelectCardHeight,
  makeSelectSearchDate,
  makeSelectOpenedGroups,
  makeSelectDriversCount,
  makeSelectDriverCardsInfo,
  makeSelectUnassignedLoads,
  makeSelectDispatchByTruck,
  makeSelectTelNotifications,
  makeSelectSearchRadiusValue,
  makeSelectInitialFilterDays,
  makeSelectGlobalFilterValue,
  makeSelectLoadRefFilterValue,
  makeSelectMinEmptyHoursValue,
  makeSelectShowUnassignedLoads,
  makeSelectLoadsByDispatchGroup,
  makeSelectNotGroupedAssignedLoads,
  makeSelectDriverLoadsByDispatcher,
  makeSelectSearchPlaceAutocompleteValue,
  makeSelectSearchPlaceAutocompleteResult,
  makeSelectFilteredByPlaceAutocompleteLoads,
  makeSelectNotGroupedAssignedLoadsPagination,
  makeSelectLoadsByDispatchGroupAndDispatchers,
} from './selectors';
import {
  onZoomSwitched,
  setValueToStore,
  setInitialState,
  setFilterToStore,
  setFiltersToStore,
  onGroupBySwitched,
  setDateRangeFilters,
  setGlobalFilterValue,
  setSearchRadiusValue,
  setSearchDateToStore,
  toggleUnassignedLoads,
  toggleDispatchByTruck,
  setLoadRefFilterValue,
  setMinEmptyHoursValue,
  filterByPlaceAutocomplete,
  getUnassignedLoadsRequest,
  hideTelNotificationsRequest,
  refreshAssignedLoadsRequest,
  getMoreNotGroupedAssignedLoads,
  getGroupedAssignedLoadsRequest,
  setSearchPlaceAutocompleteValue,
  getNotGroupedAssignedLoadsRequest,
  getDriverLoadsByDispatcherRequest,
  getDriverLoadsByDispatchGroupRequest,
  getLoadsByDispatchGroupAndDispatcherRequest,
  dropLoadsByDispatchGroupAndDispatchersGroup,
} from './actions';
//////////////////////////////////////////////////

const GLOBAL_FILTER_TYPE = 'GLOBAL_FILTER_TYPE';
const LOAD_REF_FILTER_TYPE = 'LOAD_REF_FILTER_TYPE';

const getRequestActionOnChangeFilter = (type: string, filterValue: string, props: Object) => {
  const {
    groupBy,
    filtersStore,
    globalFilterValue,
    loadRefFilterValue,
    getGroupedAssignedLoadsRequest,
    getNotGroupedAssignedLoadsRequest,
  } = props;

  return getRequestFilters(
    groupBy,
    filtersStore,
    G.ifElse(R.equals(type, LOAD_REF_FILTER_TYPE), filterValue, loadRefFilterValue),
    G.ifElse(R.equals(type, GLOBAL_FILTER_TYPE), filterValue, globalFilterValue),
    getGroupedAssignedLoadsRequest,
    getNotGroupedAssignedLoadsRequest,
  );
};

const enhanceBody = compose(
  withProps({
    configsNamesArray: [GC.UI_DRIVER_CARDS_24H_VIEW, GC.UI_DRIVER_CARDS_SHOW_TEAM_DRIVER],
  }),
  withAsyncConfigs,
  withHandlers({
    handleChangeAsyncConfigs: (props: Object) => () => {
      const { asyncConfigs, setShow24HoursView, setTeamFilterValue } = props;

      const hView = G.getConfigValueFromStore(GC.UI_DRIVER_CARDS_24H_VIEW, asyncConfigs);
      const hViewToUse = G.ifElse(G.isBoolean(hView), hView, false);

      const showTeam = G.getConfigValueFromStore(GC.UI_DRIVER_CARDS_SHOW_TEAM_DRIVER, asyncConfigs);
      const showTeamToUse = G.ifElse(G.isBoolean(showTeam), showTeam, true);

      setShow24HoursView(hViewToUse);

      setTeamFilterValue(showTeamToUse);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'asyncConfigs',
    callbackName: 'handleChangeAsyncConfigs',
  }),
  pure,
);

const Body = enhanceBody((props: Object) => (
  <RelativeFlex
    zIndex={0}
    width='100%'
    p='0px 0px 15px 15px'
    alignItems='flex-start'
    height={`calc(100vh - ${G.ifElse(props.expandedContainer, '195px', '120px')})`}
  >
    <Cards {...props} />
    {
      props.showUnassignedLoads &&
      <Box
        {...props}
        height='100%'
        zIndex={1101}
        bg={G.getTheme('colors.white')}
        boxShadow='0 2px 40px 0 rgba(0, 0, 0, 0.2)'
      >
        <UnassignedLoadsList {...props} />
      </Box>
    }
  </RelativeFlex>
));

const DriversCardComponent = (props: Object) => {
  const {
    zoom,
    onZoomSwitched,
    expandedContainer,
    loadRefFilterValue,
    closeExpandedContainer,
    handleChangeLoadRefFilter,
  } = props;

  const [colorPickerFilterValue, setColorPickerFilterValue] = useState();

  const [teamFilterValue, setTeamFilterValue] = useState(false);

  const cancelStyles = {
    height: 32,
    width: 150,
    p: '4px 8px',
    fontSize: 14,
    bgColor: 'none',
    background: 'none',
    border: '1px solid',
    borderRadius: '5px',
    textTransform: 'uppercase',
    textColor: G.getTheme('colors.greyMatterhorn'),
    borderColor: G.getTheme('colors.greyMatterhorn'),
  };

  return (
    <PageWrapper
      zi={1}
      pb='0px'
      position='relative'
      bgColor={G.getTheme('colors.bgGrey')}
      pl={G.ifElse(G.isTrue(expandedContainer), '0px', 60)}
    >
      <Zoom zoom={zoom} onZoomSwitched={onZoomSwitched} />
      <Box height='100vh'>
        <Header {...props} />
        <SubHeader
          {...props}
          teamFilterValue={teamFilterValue}
          loadRefFilterValue={loadRefFilterValue}
          setTeamFilterValue={setTeamFilterValue}
          colorPickerFilterValue={colorPickerFilterValue}
          handleChangeLoadRefFilter={handleChangeLoadRefFilter}
          setColorPickerFilterValue={setColorPickerFilterValue}
        />
        <Body
          {...props}
          teamFilterValue={teamFilterValue}
          setTeamFilterValue={setTeamFilterValue}
          colorPickerFilterValue={colorPickerFilterValue}
          branchGuid={G.getAmousCurrentBranchGuidFromWindow()}
        />
      </Box>
      <DriverProfileSimple />
      {
        G.isTrue(expandedContainer) &&
        <Flex
          p='14px 20px'
          boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
          background={G.getTheme('colors.white')}
        >
          <CancelButton {...cancelStyles} onClick={closeExpandedContainer}>
            {G.getWindowLocale('actions:close', 'Close')}
          </CancelButton>
        </Flex>
      }
    </PageWrapper>
  );
};

const enhance = compose(
  enhanceEvents,
  enhanceDetails,
  enhanceChatMessages,
  enhanceNotifications,
  enhanceCardsDateRange,
  withComponentDidUpdatePropCallback({
    propName: 'initialFilterDays',
    callbackName: 'handleSetFilterQuickDaysFromStore',
  }),
  withState('show24HoursView', 'setShow24HoursView', false),
  enhanceRateCharges('driversCard'),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, data: Object, useAllActions: any) => (
      props.openFixedPopup({
        version: 2,
        position: 'right',
        el: e.currentTarget,
        content: (
          <CardActions {...props} entity={data} useAllActions={useAllActions} />
        ),
      })
    ),
    toggleShow24HoursView: (props: Object) => (s: boolean) => {
      const { openLoader, closeLoader, setShow24HoursView } = props;

      openLoader();

      setTimeout(() => setShow24HoursView(
        s,
        () => {
          closeLoader();

          G.scrollIntoElementView({ elementId: 'driver-card-scroll-to-id' });
        },
      ), 10);
    },
    handleChangeGlobalFilter: (props: Object) => (filterString: string) => {
      const { setGlobalFilterValue } = props;

      setGlobalFilterValue(filterString);

      const { action, options } = getRequestActionOnChangeFilter(GLOBAL_FILTER_TYPE, filterString, props);

      action(options);
    },
    handleChangeLoadRefFilter: (props: Object) => (filterString: string) => {
      const { setLoadRefFilterValue } = props;

      setLoadRefFilterValue(filterString);

      const { action, options } = getRequestActionOnChangeFilter(LOAD_REF_FILTER_TYPE, filterString, props);

      action(options);
    },
    handleOpenCreateNewDO: (props: Object) => () => {
      const { setExpandedContainerOptions } = props;

      setExpandedContainerOptions({ opened: true, componentType: GC.PAGE_NEW_DO });
    },
    openTruckDetails: (props: Object) => (truckGuid: string) => {
      const { setExpandedContainerOptions } = props;

      setExpandedContainerOptions({
        opened: true,
        visitPageGuid: truckGuid,
        componentType: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
      });
    },
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      const {
        setInitialState,
        socketPostMessage,
        setDriverProfileSimpleInitialState,
        // socketDriverCardsTelDisconnectRequest,
      } = this.props;

      setInitialState();

      setDriverProfileSimpleInitialState();

      socketPostMessage({
        action: messageType.unsubscribe,
        destination: '/driverCards/tel',
      });
      // socketDriverCardsTelDisconnectRequest('deactivate');
    },
    componentDidMount() {
      // const { socketDriverCardsTelConnectRequest } = this.props;

      // socketDriverCardsTelConnectRequest();

      setTimeout(() => G.scrollIntoElementView({ elementId: 'driver-card-scroll-to-id' }), 3000);
    },
  }),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
  loader: makeSelectLoader(state),
  groupBy: makeSelectGroupBy(state),
  cardView: makeSelectCardView(state),
  filtersStore: makeSelectFilters(state),
  cardHeight: makeSelectCardHeight(state),
  searchDate: makeSelectSearchDate(state),
  driversCount: makeSelectDriversCount(state),
  openedGroups: makeSelectOpenedGroups(state),
  dispatchByTruck: makeSelectDispatchByTruck(state),
  driverCardsInfo: makeSelectDriverCardsInfo(state),
  unassignedLoads: makeSelectUnassignedLoads(state),
  telNotifications: makeSelectTelNotifications(state),
  searchRadiusValue: makeSelectSearchRadiusValue(state),
  initialFilterDays: makeSelectInitialFilterDays(state),
  globalFilterValue: makeSelectGlobalFilterValue(state),
  loadRefFilterValue: makeSelectLoadRefFilterValue(state),
  minEmptyHoursValue: makeSelectMinEmptyHoursValue(state),
  showUnassignedLoads: makeSelectShowUnassignedLoads(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  loadsByDispatchGroup: makeSelectLoadsByDispatchGroup(state),
  notGroupedAssignedLoads: makeSelectNotGroupedAssignedLoads(state),
  driverLoadsByDispatcher: makeSelectDriverLoadsByDispatcher(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
  searchPlaceAutocompleteValue: makeSelectSearchPlaceAutocompleteValue(state),
  searchPlaceAutocompleteResult: makeSelectSearchPlaceAutocompleteResult(state),
  filteredByPlaceAutocompleteLoads: makeSelectFilteredByPlaceAutocompleteLoads(state),
  notGroupedAssignedLoadsPagination: makeSelectNotGroupedAssignedLoadsPagination(state),
  loadsByDispatchGroupAndDispatchers: makeSelectLoadsByDispatchGroupAndDispatchers(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  onZoomSwitched,
  setValueToStore,
  setInitialState,
  setFilterToStore,
  onGroupBySwitched,
  openDriverProfile,
  setFiltersToStore,
  socketPostMessage,
  setDateRangeFilters,
  setGlobalFilterValue,
  setSearchRadiusValue,
  setSearchDateToStore,
  toggleUnassignedLoads,
  toggleDispatchByTruck,
  setLoadRefFilterValue,
  setMinEmptyHoursValue,
  filterByPlaceAutocomplete,
  getUnassignedLoadsRequest,
  addTelToLoadPlannerByGuid,
  addTelToRouteBuilderByGuid,
  hideTelNotificationsRequest,
  refreshAssignedLoadsRequest,
  setExpandedContainerOptions,
  getMoreNotGroupedAssignedLoads,
  getGroupedAssignedLoadsRequest,
  setSearchPlaceAutocompleteValue,
  getNotGroupedAssignedLoadsRequest,
  getDriverLoadsByDispatcherRequest,
  setDriverProfileSimpleInitialState,
  getDriverLoadsByDispatchGroupRequest,
  getLoadsByDispatchGroupAndDispatcherRequest,
  dropLoadsByDispatchGroupAndDispatchersGroup,
  //
  // socketDriverCardsTelConnectRequest,
  // socketDriverCardsTelDisconnectRequest,
})(withFixedPopover(enhance(DriversCardComponent), true));
