import * as R from 'ramda';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
// components
import { FleetMarkerWithInfo } from '../../components/map/components/marker-with-info';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { setExpandedContainerOptions } from '../expanded-container/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import { truckOnMap, fleetsOnMap, driverOnMap, trailerOnMap } from '../../svgs';
// ui
import { PageWrapper, RelativeWrapper } from '../../ui';
// feature fleet-map
import FleetsMapComponent from './components/map';
import FiltersPanel from './components/filters-panel';
import InfoLengthContainer from './components/info-length-container';
import { FleetsContent, MultiFleetContent } from './components/fleets-content';
import {
  makeSelectFleets,
  makeSelectConfigs,
  makeSelectFilteredFleets,
  makeSelectGeoFencingLocation,
  makeSelectTrailerLoadedFilter,
  makeSelectTrailerUnitIdFilter,
} from './selectors';
import {
  setIdleTimeFilter,
  setEnterpriseFilter,
  setTrailerLoadedFilter,
  setTrailerUnitIdFilter,
  setGeoFencingLocationTypeFilter,
} from './actions';
import {
  mapFleets,
  groupByLatLng,
  fleetTypeObjectMap,
  getLatLngFromFleets,
  getDivisionFilterOptions,
} from './helpers';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');
const greenColor = G.getTheme('colors.light.green');

const fleetMapIcons = {
  all: fleetsOnMap,
  trucks: truckOnMap,
  drivers: driverOnMap,
  trailers: trailerOnMap,
};

const mapFleetsToContent = (
  entities: Array,
  fleetType: string,
  setExpandedContainerOptions: Function,
) => entities.map((entity: Array) => {
  const item = R.reduce((acc: Object, item: Object) => {
    const itemWithFleets = R.assoc('fleets', R.append(item.fleetObj, acc.fleets), item);

    return R.omit('fleetObj', itemWithFleets);
  }, { shortid: G.genShortId(), fleets: [] }, entity);

  const fleets = R.prop('fleets', item);
  const length = R.length(fleets);

  const hasTelPrimaryRef = R.any(R.compose(
    G.isNotNilAndNotEmpty,
    R.prop('telPrimaryReference'),
  ), fleets);

  const markerIconColor = G.ifElse(hasTelPrimaryRef, greenColor, blueColor);

  return {
    guid: item.shortid,
    latLng: item.latLng,
    count: R.length(item.fleets),
    infoContent: () => (
      <FleetsContent
        lnl={item.lnl}
        fleets={item.fleets}
        fleetType={fleetType}
        setExpandedContainerOptions={setExpandedContainerOptions}
      />
    ),
    markerContent: (
      <RelativeWrapper>
        {fleetMapIcons[fleetType](markerIconColor)}
        <InfoLengthContainer length={length} />
      </RelativeWrapper>
    ),
  };
});

const mapMultiFleetsToContent = (entities: Array, setExpandedContainerOptions: Function) =>
  entities.map((entity: Object) => {
    const { trucks, drivers, trailers } = entity;

    const trucksLength = R.length(trucks);
    const driversLength = R.length(drivers);
    const trailersLength = R.length(trailers);

    let latLng = null;

    if (R.gt(driversLength, 0)) {
      latLng = getLatLngFromFleets(drivers);
    } else if (R.gt(trucksLength, 0)) {
      latLng = getLatLngFromFleets(trucks);
    } else if (R.gt(trailersLength, 0)) {
      latLng = getLatLngFromFleets(trailers);
    }

    const length = R.sum([driversLength, trucksLength, trailersLength]);

    const hasTelPrimaryRef = R.any(R.complement(R.pathEq(null, ['fleetObj', 'telPrimaryReference'])), trailers);

    const markerIconColor = G.ifElse(hasTelPrimaryRef, greenColor, blueColor);

    return {
      latLng,
      guid: G.genShortId(),
      count: R.sum([trucksLength, driversLength, trailersLength]),
      infoContent: () => (
        <MultiFleetContent
          trucks={trucks}
          drivers={drivers}
          trailers={trailers}
          setExpandedContainerOptions={setExpandedContainerOptions}
        />
      ),
      markerContent: (
        <RelativeWrapper>
          {fleetMapIcons.all(markerIconColor)}
          <InfoLengthContainer length={length} />
        </RelativeWrapper>
      ),
    };
  },
);

const groupFleets = (name: string, fleets: Array, groupedObj: Object = {}) => {
  const grouped = R.clone(groupedObj);

  fleets.forEach((item: Object) => {
    const empty = {
      trucks: [],
      drivers: [],
      trailers: [],
    };

    const lat = R.path(['latLng', 'lat'], item);
    const lng = R.path(['latLng', 'lng'], item);
    const latLng = `${lat}${lng}`;

    if (R.has(latLng, grouped)) {
      grouped[latLng][name].push(item);
    } else {
      grouped[latLng] = R.clone(empty);
      grouped[latLng][name].push(item);
    }
  });

  return grouped;
};

const getLocations = (props: Object, setExpandedContainerOptions: Function) => {
  const { trucks, fleets, drivers, trailers, fleetType } = props;

  if (R.and(R.equals(fleetType, 'all'), R.isNil(fleets))) {
    const grouped = groupFleets(
      'trailers',
      R.flatten(trailers),
      groupFleets(
        'trucks',
        R.flatten(trucks),
        groupFleets(
          'drivers',
          R.flatten(drivers),
        ),
      ),
    );

    return mapMultiFleetsToContent(R.values(grouped), setExpandedContainerOptions);
  }

  return mapFleetsToContent(fleets, fleetType, setExpandedContainerOptions);
};

const defaultFleetTypes = {
  [GC.FLEET_TRUCK_REPORT]: 'trucks',
  [GC.FLEET_TRAILER_REPORT]: 'trailers',
};

const MarkersContainerForGM = memo(({ locations }: Object) => (
  <FleetMarkerWithInfo
    locations={locations}
    infoBorderColor={G.getTheme('map.infoBoxBorderColor')}
  />
));

const FleetMap = ({
  fleets,
  configs,
  reportType,
  filteredFleets,
  geoFencingLocation,
  trailerLoadedFilter,
  trailerUnitIdFilter,
}: Object) => {
  const {
    trucks,
    drivers,
    trailers,
    fleetsLoaded,
  } = filteredFleets;

  const dispatch = useDispatch();

  const fromFleetList = G.isNotNilAndNotEmpty(reportType);
  const defaultFleetType = R.propOr('all', reportType, defaultFleetTypes);

  const [activeFleetType, setActiveFleetType] = useState(defaultFleetType);
  const [withoutClustering, setWithoutClustering] = useState(false);
  const [withoutGeoFencing, setWithoutGeoFencing] = useState(true);
  const [shouldChangeClustering, setShouldChangeClustering] = useState(false);

  const trailersActive = R.equals(activeFleetType, 'trailers');

  const handleSetActiveFleetType = useCallback((activeFleetType: any) => {
    if (R.not(withoutClustering)) {
      setWithoutClustering(true);
      setShouldChangeClustering(true);
    }

    setActiveFleetType(activeFleetType);

    dispatch(setIdleTimeFilter(null));
    dispatch(setTrailerUnitIdFilter(null));
    dispatch(setTrailerLoadedFilter(false));
  }, []);

  useEffect(() => {
    if (shouldChangeClustering) {
      setShouldChangeClustering(false);
      setWithoutClustering(false);
    }
  }, [shouldChangeClustering]);

  const handleSetExpandedContainerOptions = useCallback((payload: Object) => {
    dispatch(setExpandedContainerOptions(payload));
  }, []);

  const handleSetWithoutClustering = useCallback(
    () => setWithoutClustering(R.not(withoutClustering)),
    [withoutClustering],
  );

  const handleSetWithoutGeoFencing = useCallback(
    () => setWithoutGeoFencing(R.not(withoutGeoFencing)),
    [withoutGeoFencing],
  );

  const handleSetEnterpriseFilter = useCallback(
    (enterpriseGuidList: any) => dispatch(setEnterpriseFilter(enterpriseGuidList)),
    [],
  );

  const handleSetIdleTimeFilter = useCallback(
    (idleTime: any) => dispatch(setIdleTimeFilter(idleTime)),
    [],
  );

  const handleSetGeoFencingLocationTypeFilter = useCallback(
    (optionGuid: any) => dispatch(setGeoFencingLocationTypeFilter(optionGuid)),
    [],
  );

  const handleSetTrailerLoadedFilter = useCallback(
    () => dispatch(setTrailerLoadedFilter(R.not(trailerLoadedFilter))),
    [trailerLoadedFilter],
  );

  const handleSetTrailerUnitIdFilter = useCallback(
    (trailerUnitIdFilter: any) => dispatch(setTrailerUnitIdFilter(trailerUnitIdFilter)),
    [],
  );

  const trucksToUse = useMemo(() => R.values(mapFleets(trucks)), [trucks]);
  const trailersToUse = useMemo(() => R.values(mapFleets(trailers)), [trailers]);
  const driversToUse = useMemo(() => R.values(mapFleets(drivers)), [drivers]);

  const fleetsToUse = useMemo(
    () => groupByLatLng(filteredFleets[fleetTypeObjectMap[activeFleetType]]),
    [trucks, drivers, trailers, activeFleetType],
  );

  const markerIcon = useMemo(() => fleetMapIcons[activeFleetType], [activeFleetType]);

  const locations = useMemo(
    () => getLocations({
      fleets: fleetsToUse,
      trucks: trucksToUse,
      drivers: driversToUse,
      trailers: trailersToUse,
      fleetType: activeFleetType,
    }, handleSetExpandedContainerOptions),
    [fleetsToUse, trucksToUse, driversToUse, trailersToUse, activeFleetType, handleSetExpandedContainerOptions]);

  const loaded = useMemo(() => R.all(R.equals(true), R.values(fleetsLoaded)), [fleetsLoaded]);

  const wrapperHeight = useMemo(() => G.ifElse(fromFleetList, 'calc(100% - 48px)', '100%'), [fromFleetList]);

  const divisionFilterOptions = useMemo(() => {
    if (R.not(loaded)) return [];

    return getDivisionFilterOptions(activeFleetType, fleets);
  }, [fleets, loaded, activeFleetType]);

  const markersContainerForGM = <MarkersContainerForGM locations={locations} />;

  return (
    <PageWrapper
      pb='0'
      pl='0'
      minWidth='1710px'
      height={wrapperHeight}
      bgColor={G.getTheme('pages.layOutBgColor')}
    >
      <FiltersPanel
        loaded={loaded}
        configs={configs}
        reportType={reportType}
        trucksToUse={trucksToUse}
        driversToUse={driversToUse}
        fromFleetList={fromFleetList}
        trailersToUse={trailersToUse}
        trailersActive={trailersActive}
        defaultFleetType={defaultFleetType}
        withoutClustering={withoutClustering}
        withoutGeoFencing={withoutGeoFencing}
        geoFencingLocation={geoFencingLocation}
        trailerLoadedFilter={trailerLoadedFilter}
        trailerUnitIdFilter={trailerUnitIdFilter}
        divisionFilterOptions={divisionFilterOptions}
        handleSetIdleTimeFilter={handleSetIdleTimeFilter}
        handleSetActiveFleetType={handleSetActiveFleetType}
        handleSetEnterpriseFilter={handleSetEnterpriseFilter}
        handleSetWithoutClustering={handleSetWithoutClustering}
        handleSetWithoutGeoFencing={handleSetWithoutGeoFencing}
        handleSetTrailerLoadedFilter={handleSetTrailerLoadedFilter}
        handleSetTrailerUnitIdFilter={handleSetTrailerUnitIdFilter}
        handleSetGeoFencingLocationTypeFilter={handleSetGeoFencingLocationTypeFilter}
      />
      { loaded && (
        <FleetsMapComponent
          loaded={loaded}
          locations={locations}
          markerIcon={markerIcon}
          trailersActive={trailersActive}
          withoutClustering={withoutClustering}
          withoutGeoFencing={withoutGeoFencing}
          geoFencingLocation={geoFencingLocation}
          markersContainerForGM={markersContainerForGM}
        />
      )}
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  fleets: makeSelectFleets(state),
  configs: makeSelectConfigs(state),
  filteredFleets: makeSelectFilteredFleets(state),
  geoFencingLocation: makeSelectGeoFencingLocation(state),
  trailerLoadedFilter: makeSelectTrailerLoadedFilter(state),
  trailerUnitIdFilter: makeSelectTrailerUnitIdFilter(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps)(FleetMap);
