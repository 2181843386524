import React from 'react';
// helpers/constants
import * as GC from '../../../constants';
// feature edi-integration
import AcceptDeclineLoadFromEDIComponent from '../components/accept-decline';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_EDI_ACCEPT_DECLINE]: {
    width: 160,
    notResizable: true,
    name: 'titles:accept-decline',
    customComponent: (entity: Object) =>
      <AcceptDeclineLoadFromEDIComponent showDownload={true} entity={entity} />,
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    sortable: false,
    name: 'titles:status',
  },
  [GC.FIELD_EDI_PROCESSED]: {
    width: 150,
    type: 'boolean',
    name: 'titles:processed',
  },
  [GC.FIELD_EDI_SENDER_ID]: {
    width: 200,
    name: 'titles:sender-id',
  },
  [GC.FIELD_EDI_EXTERNAL_ID]: {
    width: 200,
    name: 'titles:external-id',
  },
  [GC.FIELD_EDI_ORIGINAL_FILE_NAME]: {
    width: 250,
    name: 'titles:file-name',
  },
  [GC.FIELD_EDI_RECEIVED_DATE]: {
    type: 'date',
    name: 'titles:received-date',
  },
  [GC.FIELD_EDI_TRANSACTION_TYPE]: {
    width: 200,
    type: 'enum',
    sortable: false,
    name: 'titles:transaction-type',
  },
  [GC.FIELD_EDI_TRANSACTION_DATE]: {
    type: 'date',
    name: 'titles:transaction-date',
  },
  [GC.GRC.EDI_CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: 'titles:clo',
  },
  [GC.GRC.EDI_CLO_TRANSACTION_ACTION]: {
    width: 200,
    sortable: false,
    name: 'titles:transaction-action',
  },
  [GC.GRC.EDI_CREATED_CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 200,
    name: 'titles:created-order',
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    guidPropName: GC.GRC.EDI_CREATED_CLO_GUID,
  },
  [GC.GRC.EDI_CREATED_CLO_STATUS]: {
    width: 200,
    type: 'enum',
    sortable: false,
    name: ['titles:created-order', 'titles:status'],
  },
  [GC.GRC.CLO_EXPIRATION_DATE]: {
    width: 200,
    type: 'date',
    name: ['titles:clo', 'titles:expiration-date'],
  },
  [GC.GRC.CLO_BILL_TO_LOCATION_NAME]: {
    width: 200,
    name: 'titles:bill-to',
  },
  [GC.GRC.CLO_BILL_TO_LOCATION_TYPE_DISPLAYED]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:type'],
  },
  [GC.GRC.CLO_BILL_TO_COUNTRY]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:country'],
  },
  [GC.GRC.CLO_BILL_TO_STATE]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:state'],
  },
  [GC.GRC.CLO_BILL_TO_CITY]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:city'],
  },
  [GC.GRC.CLO_BILL_TO_ADDRESS1]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:address1'],
  },
  [GC.GRC.CLO_BILL_TO_ZIP]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:zip'],
  },
  [GC.GRC.CLO_BILL_TO_COMMENTS]: {
    width: 200,
    name: ['titles:bill-to', 'titles:comments'],
  },
  [GC.GRC.CLO_BILL_TO_PAYMENT_TERM]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:payment-terms'],
  },
  [GC.GRC.CLO_BILL_TO_CONTACT_NAME]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:contact-name'],
  },
  [GC.GRC.CLO_BILL_TO_PHONE]: {
    width: 200,
    name: ['titles:bill-to', 'titles:phone'],
  },
  [GC.GRC.CLO_BILL_TO_COMMENTS]: {
    width: 200,
    sortable: false,
    name: ['titles:bill-to', 'titles:comments'],
  },
  [GC.FIELD_DIRECTION]: {
    width: 150,
    type: 'enum',
    sortable: false,
    name: 'titles:direction',
  },
  [GC.GRC.CLO_FIRST_EVENT_EVENT_EARLY_DATE]: {
    width: 200,
    type: 'date',
    sortable: false,
    name: ['titles:clo-first-event', 'titles:early-date'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_NAME]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-first-event', 'titles:location-name'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-first-event', 'titles:address1'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-first-event', 'titles:city'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-first-event', 'titles:state'],
  },
  [GC.GRC.CLO_LAST_EVENT_EVENT_EARLY_DATE]: {
    width: 200,
    type: 'date',
    sortable: false,
    name: ['titles:clo-last-event', 'titles:early-date'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_NAME]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-last-event', 'titles:location-name'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-last-event', 'titles:address1'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_CITY]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-last-event', 'titles:city'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_STATE]: {
    width: 200,
    sortable: false,
    name: ['titles:clo-last-event', 'titles:state'],
  },
};
