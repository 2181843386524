import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import React, { memo, useState, useCallback } from 'react';
// components
import DimmerComponent from '../../../components/loader/dimmer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hooks
import { useModalAndLoaderActions } from '../../../hooks';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import GeneralDetails from '../forms/general-details';
import TruckTabsGroups from './components/tabs-group';
import { generalDetailsSettings } from './settings/general-details';
import FleetProfileWrapper from '../components/fleet-profile-wrapper';
import { useFleetProfileTabsView } from '../hooks/use-fleet-profile-tabs-view';
import { setInitialState, updateTruckGeneralDetailsRequest } from '../actions';
import { useFleetProfileWrapperActions } from '../hooks/use-fleet-profile-wrapper-actions';
import {
  makeSelectConfigGroup,
  makeSelectCollapsedGroup,
  selectTruckGeneralDetails,
  makeSelectGeneralUsersAndDispatchingGroup,
} from '../selectors';
//////////////////////////////////////////////////

const TruckGeneralDetails = memo((props: Object) => {
  const { configGroup, initialValues: { unitId } } = props;

  const { generalUserList, dispatchingGroup } = useSelector(makeSelectGeneralUsersAndDispatchingGroup());

  const dispatchingGroupOptions = G.addEmptyOptionToDropDown(
    G.mapNameGuidObjectPropsToLabelValueObject(R.or(dispatchingGroup, [])),
  );

  const usersGeneral = G.addEmptyOptionToDropDown(R.map((item: Object) => ({
    [GC.FIELD_VALUE]: G.getGuidFromObject(item),
    [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
  }), R.or(generalUserList, [])));

  const title = `${G.getWindowLocale('titles:fleet-truck', 'Fleet Truck')} ${unitId}`;

  const additionalEquipmentTypeOptions = R.drop(
    1,
    R.pathOr([], ['dropdownOptions', GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE], configGroup),
  );

  const optionsForSelect = R.compose(
    R.assoc('usersGeneral', usersGeneral),
    R.assoc('dispatchingGroupOptions', dispatchingGroupOptions),
    R.assoc(GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE, additionalEquipmentTypeOptions),
    R.pick(
      [
        GC.TRUCK_TRUCK_TYPE,
        GC.TRUCK_OUT_SERVICE_REASON,
        GC.TRUCK_UNAVAILABILITY_REASON,
        GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE,
      ],
    ),
    R.propOr({}, 'dropdownOptions'),
  )(configGroup);

  const truckProps = {
    ...generalDetailsSettings,
    title,
    active: true,
    type: 'truck',
    hasPhoto: true,
    optionsForSelect,
    showStatus: true,
    hasAssignment: true,
  };

  return <GeneralDetails {...props} {...truckProps} />;
});

const FleetProfileTruck = ({ handleGoBack, expandedContainer, closeExpandedContainer }: Object) => {
  const [saveAndClose, setSaveAndClose] = useState(false);

  const configGroup = useSelector(makeSelectConfigGroup());
  const generalDetails = useSelector(selectTruckGeneralDetails);
  const collapsedGroup = useSelector(makeSelectCollapsedGroup());

  const dispatch = useDispatch();

  const commonActions = useModalAndLoaderActions();

  const { closeModal } = commonActions;

  const handleClose = useCallback(() => {
    dispatch(setInitialState());

    if (R.and(G.isFunction(handleGoBack), G.isArray(saveAndClose))) return handleGoBack();

    if (G.isFunction(closeExpandedContainer)) return closeExpandedContainer();

    G.goToRoute(routesMap.fleetTruckList);
  }, [saveAndClose]);

  const {
    handleToggleFormGroupTable,
    handleUpdateGeneralDetails,
    handleGoToProfileByProfileType,
  } = useFleetProfileWrapperActions({
    dispatch,
    closeModal,
    handleClose,
    saveAndClose,
    generalDetails,
    setSaveAndClose,
    expandedContainer,
    updateGeneralDetailsRequest: updateTruckGeneralDetailsRequest,
  });

  const { setTabsView, tabsCollapsedView } = useFleetProfileTabsView();

  const defaultProps = {
    ...commonActions,
    configGroup,
    tabsCollapsedView,
    fleetType: 'truck',
  };

  const { guid, unitId } = R.or(generalDetails, {});

  if (G.isNilOrEmpty(guid)) return <DimmerComponent tableCount={3} rectangleCount={4} />;

  return (
    <FleetProfileWrapper
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      setSaveAndClose={setSaveAndClose}
      expandedContainer={expandedContainer}
    >
      <TruckGeneralDetails
        {...defaultProps}
        hasFormSectionTitles={true}
        saveAndClose={saveAndClose}
        initialValues={generalDetails}
        setSaveAndClose={setSaveAndClose}
        submitAction={handleUpdateGeneralDetails}
        handleGoToProfileByProfileType={handleGoToProfileByProfileType}
        driverAssignmentGuid={R.prop(GC.FIELD_DRIVER_GUID, generalDetails)}
      />
      <TruckTabsGroups
        {...defaultProps}
        primaryObjectGuid={guid}
        setTabsView={setTabsView}
        primaryObjectUnitId={unitId}
        collapsedGroup={collapsedGroup}
        handleToggleFormGroupTable={handleToggleFormGroupTable}
        handleUpdateGeneralDetails={handleUpdateGeneralDetails}
        handleGoToProfileByProfileType={handleGoToProfileByProfileType}
        driverAssignmentGuid={R.prop(GC.FIELD_DRIVER_GUID, generalDetails)}
        primaryObjectBranchGuid={G.getBranchGuidFromObject(generalDetails)}
      />
    </FleetProfileWrapper>
  );
};

export default FleetProfileTruck;
