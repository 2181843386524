import * as R from 'ramda';
import React from 'react';
// features
import { AuthWrapper } from '../../../../permission';
import PC from '../../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withSetExpandedContainerOptions } from '../../../../../hocs';
// ui
import { Box, RelativeBox } from '../../../../../ui';
// feature dispatch-detail-new
import { InfoPair, EmailsInfoPair, ContactInfoPair } from '../../info-pair';
import AcceptDeclineRate, { AcceptDeclineRateWithoutConnect } from './accept-decline-rate';
//////////////////////////////////////////////////

const borderColor = G.getTheme('colors.bgGrey');

const CarrierSection = withSetExpandedContainerOptions((props: Object) => {
  const {
    loadGuid,
    assignment,
    approvedBy,
    approvedDate,
    declineReasonCode,
    expandedContainerOptions,
    handleSetExpandedContainerOptions,
  } = props;

  const {
    bolNumber,
    quoteNumber,
    pickupNumber,
    trackingNumber,
    carrierSnapshot,
    originCarrierName,
    destinationCarrierName,
    transportingCarrierName,
    integrationAccountNumber,
  } = assignment;

  const carrierName = R.prop(GC.FIELD_NAME, carrierSnapshot);
  const carrierScac = R.prop(GC.FIELD_CARRIER_SCAC, carrierSnapshot);

  const approvedOrDeclineLabel = G.ifElse(
    G.isNilOrEmpty(declineReasonCode),
    G.getWindowLocale('titles:approved-by', 'Approved By'),
    G.getWindowLocale('titles:decline-by', 'Decline By'),
  );

  const approvedDateOrDeclineDateLabel = G.ifElse(
    G.isNilOrEmpty(declineReasonCode),
    G.getWindowLocale('titles:approved-date-time', 'Approved Date/Time'),
    G.getWindowLocale('titles:decline-date-time', 'Decline Date/Time'),
  );
  const clickHandler = () => handleSetExpandedContainerOptions({
    parentPageGuid: loadGuid,
    componentType: GC.PAGE_CARRIER_PROFILE,
    visitPageGuid: G.getGuidFromObject(carrierSnapshot),
  });

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <AuthWrapper has={[PC.CARRIER_WRITE]}>
        <InfoPair
          flexWrap='wrap'
          text={carrierName}
          label={G.getWindowLocale('titles:carrier-name', 'Carrier Name')}
          clickHandler={G.ifElse(
            G.isTrue(G.getPropFromObject('opened', expandedContainerOptions)),
            null,
            clickHandler,
          )}
        />
      </AuthWrapper>
      <AuthWrapper notHas={[PC.CARRIER_WRITE]}>
        <InfoPair
          flexWrap='wrap'
          text={carrierName}
          label={G.getWindowLocale('titles:carrier-name', 'Carrier Name')}
        />
      </AuthWrapper>
      <InfoPair
        text={carrierScac}
        label={G.getWindowLocale('titles:scac', 'SCAC')}
      />
      <InfoPair
        text={transportingCarrierName}
        label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
      />
      <InfoPair
        text={originCarrierName}
        textMaxWidth='calc(100% - 120px)'
        label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
      />
      <InfoPair
        text={destinationCarrierName}
        textMaxWidth='calc(100% - 130px)'
        label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
      />
      <InfoPair
        flexWrap='wrap'
        text={bolNumber}
        label={G.getWindowLocale('titles:bol-number', 'BOL Number')}
      />
      <InfoPair
        flexWrap='wrap'
        text={trackingNumber}
        label={G.getWindowLocale('titles:tracking-number', 'Tracking Number')}
      />
      <InfoPair
        flexWrap='wrap'
        text={pickupNumber}
        label={G.getWindowLocale('titles:pickup-number', 'Pickup Number')}
      />
      <InfoPair
        flexWrap='wrap'
        text={quoteNumber}
        label={G.getWindowLocale('titles:quote-number', 'Quote Number')}
      />
      <AuthWrapper has={[PC.CARRIER_INTEGRATION_ACCOUNT_NUMBER_READ]}>
        <InfoPair
          flexWrap='wrap'
          text={integrationAccountNumber}
          label={G.getWindowLocale('titles:integration-account-number', 'Integration Account Number')}
        />
      </AuthWrapper>
      <InfoPair
        text={approvedBy}
        label={approvedOrDeclineLabel}
      />
      {
        G.isNotNil(approvedDate) &&
        <InfoPair
          label={approvedDateOrDeclineDateLabel}
          text={G.convertDateTimeToConfigFormat(approvedDate)}
        />
      }
      {
        G.isNotNil(declineReasonCode) &&
        <InfoPair
          text={G.getDisplayedValueFromObject(declineReasonCode)}
          label={G.getWindowLocale('titles:decline-reason', 'Decline Reason')}
        />
      }
    </Box>
  );
});

const DispatchedBySection = ({ dispatchedBy, dispatchedDate }: Object) => (
  <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
    <InfoPair
      text={dispatchedBy}
      label={G.getWindowLocale('titles:dispatched-by', 'Dispatched By')}
    />
    <InfoPair
      text={G.convertDateTimeToConfigFormat(dispatchedDate)}
      label={G.getWindowLocale('titles:dispatched-date', 'Dispatched Date')}
    />
  </Box>
);

const DispatchInfoSection = ({ autodialApp, dispatchInfo }: Object) => {
  if (R.all(G.isNilOrEmpty, R.values(dispatchInfo))) return null;

  const { comments, dispatchName, dispatchPhone, dispatchEmails } = dispatchInfo;

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        flexWrap='wrap'
        text={dispatchName}
        label={G.getWindowLocale('titles:dispatcher-name', 'Dispatcher Name')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        text={dispatchPhone}
        autodialApp={autodialApp}
        label={G.getWindowLocale('titles:dispatcher-phone', 'Dispatcher Phone')}
      />
      <EmailsInfoPair
        emails={dispatchEmails}
        label={G.getWindowLocale('titles:dispatcher-emails', 'Dispatcher Emails')}
      />
      <InfoPair
        text={comments}
        label={G.getWindowLocale('titles:comments', 'Comments')}
      />
    </Box>
  );
};

const PrimaryDriverSection = ({ assignment, autodialApp }: Object) => {
  const noPrimaryDriverInfo = R.and(
    G.isNilOrEmpty(R.prop(GC.FIELD_PRIMARY_DRIVER, assignment)),
    G.isNilOrEmpty(R.prop(GC.FIELD_PRIMARY_DRIVER_PHONE, assignment)),
  );

  if (noPrimaryDriverInfo) return null;

  const { primaryDriver, primaryDriverPhone } = assignment;

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        flexWrap='wrap'
        text={R.or(primaryDriver, '-')}
        label={G.getWindowLocale('titles:primary-driver', 'Primary Driver')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        autodialApp={autodialApp}
        text={primaryDriverPhone}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
    </Box>
  );
};

const TeamDriverSection = ({ assignment, autodialApp }: Object) => {
  const noSecondaryDriverInfo = R.and(
    G.isNilOrEmpty(R.prop(GC.FIELD_SECONDARY_DRIVER, assignment)),
    G.isNilOrEmpty(R.prop(GC.FIELD_SECONDARY_DRIVER_PHONE, assignment)),
  );

  if (noSecondaryDriverInfo) return null;

  const { secondaryDriver, secondaryDriverPhone } = assignment;

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        flexWrap='wrap'
        text={R.or(secondaryDriver, '-')}
        label={G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}
      />
      <ContactInfoPair
        phone={true}
        iconMargin='3px'
        autodialApp={autodialApp}
        text={secondaryDriverPhone}
        label={G.getWindowLocale('titles:phone-number', 'Phone Number')}
      />
    </Box>
  );
};

const TruckTrailerSection = ({ assignment }: Object) => {
  const { truck, trailer, fleetTrailerUnitId } = assignment;

  if (G.isAllNilOrEmpty([truck, trailer, fleetTrailerUnitId])) return null;

  return (
    <Box p='8px' borderBottom='1px solid' borderColor={borderColor}>
      <InfoPair
        text={truck}
        flexWrap='wrap'
        label={G.getWindowLocale('titles:truck', 'Truck')}
      />
      <InfoPair
        flexWrap='wrap'
        text={trailer}
        label={G.getWindowLocale('titles:trailers', 'Trailers')}
      />
      <InfoPair
        flexWrap='wrap'
        text={fleetTrailerUnitId}
        label={G.getWindowLocale('titles:fleet-trailer', 'Fleet Trailer')}
      />
    </Box>
  );
};

const AcceptDeclineComponent = ({ guid, fromDispatchBoard, declineReasonOptions, acceptDeclineHandlers }: Object) => {
  if (fromDispatchBoard) {
    return (
      <AcceptDeclineRateWithoutConnect
        {...acceptDeclineHandlers}
        guid={guid}
        isCarrierRate={true}
        declineReasonOptions={declineReasonOptions}
      />
    );
  }

  return (
    <AcceptDeclineRate
      guid={guid}
      isCarrierRate={true}
      declineReasonOptions={declineReasonOptions}
    />
  );
};

const Carrier = (props: Object) => {
  const {
    guid,
    loadGuid,
    approvedBy,
    autodialApp,
    approvedDate,
    carrierAssignment,
    declineReasonCode,
    fromDispatchBoard,
    declineReasonOptions,
    acceptDeclineHandlers,
    showAcceptDeclineSection,
  } = props;

  const dispatchedBy = R.path([GC.FIELD_DISPATCHED_OR_CANCEL_BY], props);

  return (
    <RelativeBox
      height={400}
      overflowY={G.ifElse(showAcceptDeclineSection, 'hidden', 'auto')}
    >
      {
        showAcceptDeclineSection &&
        <AcceptDeclineComponent
          guid={guid}
          fromDispatchBoard={fromDispatchBoard}
          declineReasonOptions={declineReasonOptions}
          acceptDeclineHandlers={acceptDeclineHandlers}
        />
      }
      <CarrierSection
        loadGuid={loadGuid}
        approvedBy={approvedBy}
        approvedDate={approvedDate}
        assignment={carrierAssignment}
        declineReasonCode={declineReasonCode}
      />
      {
        G.isNotNilAndNotEmpty(dispatchedBy) &&
        <DispatchedBySection
          dispatchedBy={dispatchedBy}
          dispatchedDate={R.path([GC.FIELD_DISPATCH_OR_CANCEL_DATE], props)}
        />
      }
      <DispatchInfoSection
        autodialApp={autodialApp}
        dispatchInfo={R.pick(
          [
            GC.FIELD_COMMENTS,
            GC.FIELD_DISPATCH_NAME,
            GC.FIELD_DISPATCH_PHONE,
            GC.FIELD_DISPATCH_EMAILS,
          ],
          props,
        )}
      />
      <PrimaryDriverSection autodialApp={autodialApp} assignment={carrierAssignment} />
      <TeamDriverSection autodialApp={autodialApp} assignment={carrierAssignment} />
      <TruckTrailerSection assignment={carrierAssignment} />
    </RelativeBox>
  );
};

export default Carrier;
