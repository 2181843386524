import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature claim-management
import * as A from '../actions';
import {
  makeSelectClaimGuid,
} from '../selectors';
//////////////////////////////////////////////////

function* getClaimGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.claimDetails(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getClaimGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getClaimGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getClaimGeneralDetailsRequest exception');
  }
}

// documents
function* getDocumentListRequest() {
  try {
    yield put(openLoader());

    const primaryObjectGuid = yield select(makeSelectClaimGuid());

    const options = {
      params: { primaryObjectGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimDocumentList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDocumentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDocumentListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDocumentListRequest exception');
  }
}

function* createOrUpdateDocumentRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const isUpdate = G.isNotNilAndNotEmpty(version);
    const endpoint = R.prop(G.ifElse(isUpdate, 'claimDocumentUpdate', 'claimDocument'), endpointsMap);
    const options = { data: G.ifElse(isUpdate, G.makeDataForDocument, G.makeDataForMultipleDocuments)(payload) };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateDocumentSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateDocumentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateDocumentRequest exception');
  }
}

function* removeDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.claimDocumentByGuid(payload));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeDocumentSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeDocumentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeDocumentRequest exception');
  }
}

// notes
function* getNoteListRequest() {
  try {
    yield put(openLoader());

    const claimGuid = yield select(makeSelectClaimGuid());

    const options = {
      params: { claimGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimNoteList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getNoteListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getNoteListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getNoteListRequest exception');
  }
}

function* createOrUpdateNoteRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const method = G.ifElse(R.isNil(version), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.claimNote, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateNoteSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateNoteRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateNoteRequest exception');
  }
}

function* removeNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.claimNoteByGuid(payload));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeNoteSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeNoteRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeNoteRequest exception');
  }
}

// related tels
function* getRelatedTelsRequest() {
  try {
    yield put(openLoader());

    const claimGuid = yield select(makeSelectClaimGuid());

    const endpoint = endpointsMap.relatedTelDetailsByClaimGuid(claimGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getRelatedTelsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getRelatedTelsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getRelatedTelsRequest exception');
  }
}

// damaged items
function* getCloItemsRequest() {
  try {
    yield put(openLoader());

    const claimGuid = yield select(makeSelectClaimGuid());

    const options = {
      params: { claimGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimDamagedItemCloItems, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCloItemsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCloItemsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getCloItemsRequest exception');
  }
}


function* getDamagedItemsByCloItemGuidRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { params: payload };

    const res = yield call(sendRequest, 'get', endpointsMap.claimDamagedItemList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDamagedItemsByCloItemGuidSuccess({ data, ...payload }));
    } else {
      yield call(G.handleFailResponse, res, 'getDamagedItemsByCloItemGuidRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDamagedItemsByCloItemGuidRequest exception');
  }
}

function* createOrUpdateDamagedItemRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const method = G.ifElse(R.isNil(version), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.claimDamagedItem, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateDamagedItemSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateDamagedItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateDamagedItemRequest exception');
  }
}

function* removeDamagedItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid } = payload;

    const res = yield call(sendRequest, 'delete', endpointsMap.claimDamagedItemByGuid(guid));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeDamagedItemSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeDamagedItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeDamagedItemRequest exception');
  }
}

// accident
function* getAccidentRequest() {
  try {
    yield put(openLoader());

    const claimGuid = yield select(makeSelectClaimGuid());

    const options = {
      params: { claimGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimAccident, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAccidentSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAccidentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAccidentRequest exception');
  }
}

function* createOrUpdateAccidentRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const method = G.ifElse(R.isNil(version), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.claimAccident, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getAccidentSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateAccidentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateAccidentRequest exception');
  }
}

function* removeAccidentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.claimAccidentByGuid(payload));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getAccidentSuccess({}));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeAccidentRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeAccidentRequest exception');
  }
}

// involved party
function* getInvolvedPartyListRequest() {
  try {
    yield put(openLoader());

    const claimGuid = yield select(makeSelectClaimGuid());

    const options = {
      params: { claimGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimInvolvedPartyList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getInvolvedPartyListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getInvolvedPartyListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getInvolvedPartyListRequest exception');
  }
}

function* createOrUpdateInvolvedPartyRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const method = G.ifElse(R.isNil(version), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.claimInvolvedParty, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateInvolvedPartySuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateInvolvedPartyRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateInvolvedPartyRequest exception');
  }
}

function* removeInvolvedPartyRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.claimInvolvedPartyByGuid(payload));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeInvolvedPartySuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeInvolvedPartyRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeInvolvedPartyRequest exception');
  }
}

// attorney
function* getAttorneyListRequest() {
  try {
    yield put(openLoader());

    const claimGuid = yield select(makeSelectClaimGuid());

    const options = {
      params: { claimGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimAttorneyList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAttorneyListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAttorneyListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAttorneyListRequest exception');
  }
}

function* createOrUpdateAttorneyRequest({ payload }: Object) {
  try {
    const { version } = payload;

    yield put(openLoader());

    const options = { data: payload };
    const method = G.ifElse(R.isNil(version), 'post', 'put');

    const res = yield call(sendRequest, method, endpointsMap.claimAttorney, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateAttorneySuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateAttorneyRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateAttorneyRequest exception');
  }
}

function* removeAttorneyRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.claimAttorneyByGuid(payload));

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeAttorneySuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeAttorneyRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeAttorneyRequest exception');
  }
}

function* handleVisitClaimManagementClaimDetailsPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE);

    const { guid } = payload;

    yield call(getClaimGeneralDetailsRequest, { payload: guid });

    yield put(closeLoader());

    break;
  }
}

function* claimManagementClaimDetailsWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE, handleVisitClaimManagementClaimDetailsPageSaga);
  // common
  yield takeLatest(A.getClaimGeneralDetailsRequest, getClaimGeneralDetailsRequest);
  // documents
  yield takeLatest(A.removeDocumentRequest, removeDocumentRequest);
  yield takeLatest(A.getDocumentListRequest, getDocumentListRequest);
  yield takeLatest(A.createOrUpdateDocumentRequest, createOrUpdateDocumentRequest);
  // notes
  yield takeLatest(A.removeNoteRequest, removeNoteRequest);
  yield takeLatest(A.getNoteListRequest, getNoteListRequest);
  yield takeLatest(A.createOrUpdateNoteRequest, createOrUpdateNoteRequest);
  // related tels
  yield takeLatest(A.getRelatedTelsRequest, getRelatedTelsRequest);
  // damaged items
  yield takeLatest(A.getCloItemsRequest, getCloItemsRequest);
  yield takeLatest(A.removeDamagedItemRequest, removeDamagedItemRequest);
  yield takeLatest(A.createOrUpdateDamagedItemRequest, createOrUpdateDamagedItemRequest);
  yield takeLatest(A.getDamagedItemsByCloItemGuidRequest, getDamagedItemsByCloItemGuidRequest);
  // accident
  yield takeLatest(A.getAccidentRequest, getAccidentRequest);
  yield takeLatest(A.removeAccidentRequest, removeAccidentRequest);
  yield takeLatest(A.createOrUpdateAccidentRequest, createOrUpdateAccidentRequest);
  // involved party
  yield takeLatest(A.removeInvolvedPartyRequest, removeInvolvedPartyRequest);
  yield takeLatest(A.getInvolvedPartyListRequest, getInvolvedPartyListRequest);
  yield takeLatest(A.createOrUpdateInvolvedPartyRequest, createOrUpdateInvolvedPartyRequest);
  // attorney
  yield takeLatest(A.removeAttorneyRequest, removeAttorneyRequest);
  yield takeLatest(A.getAttorneyListRequest, getAttorneyListRequest);
  yield takeLatest(A.createOrUpdateAttorneyRequest, createOrUpdateAttorneyRequest);
}

export default claimManagementClaimDetailsWatcherSaga;
