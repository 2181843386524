import * as R from 'ramda';
import React, { memo } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedFlex, scrollableContainerCss6px } from '../../../ui';
// feature drivers-card
import Driver from './driver';
import Filters from './filters';
import Dispatcher from './dispatcher';
import DispatchingGroup from './dispatching-group';
import CalendarLine, { CalendarLine2 } from './calendar-line';
import { GROUP_TYPE_DISPATCHER, GROUP_TYPE_DISPATCHING_GROUP } from '../constants';
import {
  DriverTelsCards,
  DriverPendingActivities,
  DriverUnavailablePeriods,
} from './driver-items-lists';
import {
  filterDriverLoadsByTeam,
  mapDriverLoadsToEmptyPeriods,
  filterDriverLoadsByLocationAndDate,
} from '../helpers';
//////////////////////////////////////////////////

export const shouldNotFilterByLocationAndDate = ({
  searchDate,
  searchRadiusValue,
  searchPlaceAutocompleteValue,
  searchPlaceAutocompleteResult,
}: Object) => {
  if (R.and(G.isNilOrEmpty(searchPlaceAutocompleteValue), R.not(G.isValidMoment(searchDate)))) return true;

  if (R.and(G.isNilOrEmpty(searchPlaceAutocompleteResult), R.not(G.isValidMoment(searchDate)))) return true;

  if (R.and(G.isNilOrEmpty(searchRadiusValue), R.not(G.isValidMoment(searchDate)))) return true;

  if (R.not(G.isValidMoment(searchDate))) return true;

  return false;
};

export const getGroupedDriverLoads = ({
  group,
  searchDate,
  filtersStore,
  teamFilterValue,
  showDispatchers,
  searchRadiusValue,
  minEmptyHoursValue,
  loadsByDispatchGroup,
  showDispatchingGroups,
  driverLoadsByDispatcher,
  searchPlaceAutocompleteValue,
  searchPlaceAutocompleteResult,
}: Object) => {
  const groupGuid = G.getGuidFromObject(group);

  let driverLoads = R.pathOr([], ['driverLoads'], group);

  if (G.isTrue(showDispatchers)) driverLoads = R.pathOr([], [groupGuid], driverLoadsByDispatcher);

  if (G.isTrue(showDispatchingGroups)) driverLoads = R.pathOr([], [groupGuid], loadsByDispatchGroup);

  if (shouldNotFilterByLocationAndDate({
    searchDate,
    searchRadiusValue,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
  })) {
    return filterDriverLoadsByTeam(teamFilterValue, driverLoads);
  }

  const mapped = mapDriverLoadsToEmptyPeriods(driverLoads, minEmptyHoursValue, filtersStore);

  const filtered = filterDriverLoadsByLocationAndDate(
    mapped, searchPlaceAutocompleteResult, searchRadiusValue, searchDate,
  );

  return filterDriverLoadsByTeam(teamFilterValue, filtered);
};

export const getGroupedAssignedLoadsToUse = ({
  searchDate,
  dispatchers,
  filtersStore,
  teamFilterValue,
  showDispatchers,
  dispatchingGroups,
  searchRadiusValue,
  minEmptyHoursValue,
  loadsByDispatchGroup,
  showDispatchingGroups,
  driverLoadsByDispatcher,
  searchPlaceAutocompleteValue,
  searchPlaceAutocompleteResult,
}: Object) => {
  let items = [];

  if (G.isTrue(showDispatchers)) items = dispatchers;

  if (G.isTrue(showDispatchingGroups)) items = dispatchingGroups;

  const result = R.compose(
    R.reject(({ driverLoads }: Object) => {
      if (shouldNotFilterByLocationAndDate({
        searchDate,
        searchRadiusValue,
        searchPlaceAutocompleteValue,
        searchPlaceAutocompleteResult,
      })) return false;

      return G.isNilOrEmpty(driverLoads);
    }),
    R.map((group: Object) => R.assoc(
      'driverLoads',
      getGroupedDriverLoads({
        group,
        searchDate,
        filtersStore,
        teamFilterValue,
        showDispatchers,
        searchRadiusValue,
        minEmptyHoursValue,
        loadsByDispatchGroup,
        showDispatchingGroups,
        driverLoadsByDispatcher,
        searchPlaceAutocompleteValue,
        searchPlaceAutocompleteResult,
      }),
      group),
    ),
  )(items);

  return result;
};

export default memo((props: Object) => {
  const {
    groupBy,
    cardHeight,
    whiteColor,
    searchDate,
    filtersStore,
    teamFilterValue,
    setValueToStore,
    driverCardsInfo,
    searchRadiusValue,
    openDriverProfile,
    minEmptyHoursValue,
    loadsByDispatchGroup,
    driverLoadsByDispatcher,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
    getDriverLoadsByDispatcherRequest,
    getDriverLoadsByDispatchGroupRequest,
  } = props;

  const dispatchingGroups = R.pathOr([], ['dispatchingGroups'], driverCardsInfo);

  const dispatchers = R.pathOr([], ['dispatchers'], driverCardsInfo);

  const showDispatchingGroups = R.equals(groupBy, GROUP_TYPE_DISPATCHING_GROUP);

  const showDispatchers = R.equals(groupBy, GROUP_TYPE_DISPATCHER);

  const groupedAssignedLoadsToUse = getGroupedAssignedLoadsToUse({
    searchDate,
    dispatchers,
    filtersStore,
    teamFilterValue,
    showDispatchers,
    searchRadiusValue,
    dispatchingGroups,
    minEmptyHoursValue,
    loadsByDispatchGroup,
    showDispatchingGroups,
    driverLoadsByDispatcher,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
  });

  return (
    <Flex
      height='100%'
      overflow='auto'
      bg={whiteColor}
      flexDirection='row'
      alignItems='flex-start'
      css={scrollableContainerCss6px}
    >
      {
        <StickedFlex
          left='0'
          pr={15}
          zIndex={2100}
          minHeight='100%'
          flexDirection='column'
          bg={G.getTheme('colors.bgGrey')}
        >
          <Filters {...props} />
          { showDispatchingGroups &&
            groupedAssignedLoadsToUse.map((group: Object, index: number) => (
              <Flex key={index} flexDirection='column'>
                <DispatchingGroup
                  dispatchingGroup={group}
                  setValueToStore={setValueToStore}
                  onClickHandler={getDriverLoadsByDispatchGroupRequest}
                  showCloseIcon={
                    G.isNotNilAndNotEmpty(R.path([G.getGuidFromObject(group)], loadsByDispatchGroup))
                  }
                />
                {
                  group.driverLoads.map((driver: Object, driverIndex: number) => (
                    <Box mt={10} key={`${index}${driverIndex}`}>
                      <Driver
                        driver={driver}
                        cardHeight={cardHeight}
                        openDriverProfile={openDriverProfile}
                        groupGuid={G.getGuidFromObject(group)}
                      />
                    </Box>
                  ))
                }
              </Flex>
            ))
          }
          { showDispatchers &&
            groupedAssignedLoadsToUse.map((group: Object, index: number) => (
              <Flex key={index} flexDirection='column'>
                <Dispatcher
                  dispatcher={group}
                  setValueToStore={setValueToStore}
                  onClickHandler={getDriverLoadsByDispatcherRequest}
                  showCloseIcon={G.isNotNilAndNotEmpty(R.path([G.getGuidFromObject(group)], driverLoadsByDispatcher))}
                />
                {
                  group.driverLoads.map((driver: Object, driverIndex: number) => (
                    <Box mt={10} key={`${index}${driverIndex}`}>
                      <Driver
                        driver={driver}
                        cardHeight={cardHeight}
                        openDriverProfile={openDriverProfile}
                        groupGuid={G.getGuidFromObject(group)}
                      />
                    </Box>
                  ))
                }
              </Flex>
            ))
          }
        </StickedFlex>
      }
      {
        <Flex
          bg={whiteColor}
          flexDirection='column'
          alignItems='flex-start'
          width='calc(100vw - 350px)'
        >
          <CalendarLine {...props} />
          <CalendarLine2 {...props} />
          {
            groupedAssignedLoadsToUse.map((group: Object, index: number) => (
              <Flex key={index} ml={10} position='relative' flexDirection='column'>
                {
                  R.equals(groupBy, GROUP_TYPE_DISPATCHER) &&
                  <Dispatcher visibility='hidden' dispatcher={R.or(group.dispatcher, group)} />
                }
                {
                  R.equals(groupBy, GROUP_TYPE_DISPATCHING_GROUP) &&
                  <DispatchingGroup
                    visibility='hidden'
                    dispatchingGroup={R.or(group.dispatchingGroup, group)}
                  />
                }
                {
                  group.driverLoads.map((driver: Object) => (
                    <Flex
                      mt={10}
                      alignItems='start'
                      height={cardHeight}
                      key={R.prop(GC.FIELD_GUID, driver)}
                    >
                      {
                        G.isNotNilAndNotEmpty(R.path(['tels'], driver)) &&
                        <DriverTelsCards
                          {...props}
                          tels={R.path(['tels'], driver)}
                          groupGuid={G.getGuidFromObject(group)}
                          driverGuid={G.getGuidFromObject(driver)}
                        />
                      }
                      {
                        G.isNotNilAndNotEmpty(R.path(['pendingActivities'], driver)) &&
                        <DriverPendingActivities
                          {...props}
                          group={group}
                          driver={driver}
                          pendingActivities={R.path(['pendingActivities'], driver)}
                        />
                      }
                      {
                        G.isNotNilAndNotEmpty(R.path(['unavailablePeriods'], driver)) &&
                        <DriverUnavailablePeriods
                          {...props}
                          group={group}
                          unavailablePeriods={R.path(['unavailablePeriods'], driver)}
                        />
                      }
                    </Flex>
                  ))
                }
              </Flex>
            ))
          }
        </Flex>
      }
    </Flex>
  );
});
