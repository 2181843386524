import * as R from 'ramda';
import React, { memo, useMemo } from 'react';
// components
import { pickerColorGrey } from '../../../components/color-picker';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature drivers-card
import Card from './card';
import NoteCard from './note-card';
import HybridCard from './hybrid-card';
import PendingActivity from './pending-activity';
import UnavailablePeriod from './unavailable-period';
//////////////////////////////////////////////////

const getFilteredByColorList = (colorPickerFilterValue: string, list: Array) => {
  if (R.or(G.isNilOrEmpty(colorPickerFilterValue), G.isNilOrEmpty(list))) return list;

  return R.filter((item: Object) => {
    const itemColor = R.prop(GC.FIELD_DRIVER_CARD_COLOR, item);

    if (R.equals(itemColor, colorPickerFilterValue)) return true;

    if (R.and(R.isNil(itemColor), R.equals(pickerColorGrey, colorPickerFilterValue))) return true;

    return false;
  }, list);
};

export const DriverTelsCards = memo((props: Object) => {
  const {
    tels,
    groupBy,
    cardView,
    groupGuid,
    truckGuid,
    driverGuid,
    closeModal,
    cardHeight,
    filtersStore,
    dispatcherGuid,
    show24HoursView,
    telNotifications,
    handleShowEvents,
    expandedContainer,
    handleClickEditIcon,
    handleClickChatIcon,
    handleOpenTelDetails,
    handleOpenChatMessages,
    colorPickerFilterValue,
    handleShowNotifications,
    expandedContainerOptions,
  } = props;

  if (R.equals(cardView, 'NOTE')) {
    const filteredTels = useMemo(
      () => getFilteredByColorList(colorPickerFilterValue, tels),
      [tels, colorPickerFilterValue],
    );

    return (
      filteredTels.map((item: Object, index: number) => (
        <NoteCard
          {...item}
          width='calculate'
          groupBy={groupBy}
          cardIndex={index}
          groupGuid={groupGuid}
          truckGuid={truckGuid}
          driverGuid={driverGuid}
          closeModal={closeModal}
          cardHeight={cardHeight}
          filtersStore={filtersStore}
          key={G.getGuidFromObject(item)}
          dispatcherGuid={dispatcherGuid}
          show24HoursView={show24HoursView}
          expandedContainer={expandedContainer}
          handleClickEditIcon={handleClickEditIcon}
          handleOpenTelDetails={handleOpenTelDetails}
          expandedContainerOptions={expandedContainerOptions}
        />
      ))
    );
  }

  if (R.equals(cardView, 'HYBRID')) {
    const filteredTels = useMemo(
      () => getFilteredByColorList(colorPickerFilterValue, tels),
      [tels, colorPickerFilterValue],
    );

    return (
      filteredTels.map((item: Object, index: number) => (
        <HybridCard
          {...item}
          width='calculate'
          cardIndex={index}
          groupBy={groupBy}
          groupGuid={groupGuid}
          truckGuid={truckGuid}
          driverGuid={driverGuid}
          closeModal={closeModal}
          cardHeight={cardHeight}
          filtersStore={filtersStore}
          key={G.getGuidFromObject(item)}
          dispatcherGuid={dispatcherGuid}
          show24HoursView={show24HoursView}
          handleShowEvents={handleShowEvents}
          expandedContainer={expandedContainer}
          handleClickEditIcon={handleClickEditIcon}
          handleOpenTelDetails={handleOpenTelDetails}
          expandedContainerOptions={expandedContainerOptions}
        />
      ))
    );
  }

  return (
    tels.map((item: Object, index: number) => (
      <Card
        {...item}
        width='calculate'
        cardIndex={index}
        closeModal={closeModal}
        cardHeight={cardHeight}
        filtersStore={filtersStore}
        key={G.getGuidFromObject(item)}
        show24HoursView={show24HoursView}
        handleShowEvents={handleShowEvents}
        telNotifications={telNotifications}
        expandedContainer={expandedContainer}
        handleClickChatIcon={handleClickChatIcon}
        handleClickEditIcon={handleClickEditIcon}
        handleOpenTelDetails={handleOpenTelDetails}
        handleOpenChatMessages={handleOpenChatMessages}
        handleShowNotifications={handleShowNotifications}
        expandedContainerOptions={expandedContainerOptions}
      />
    ))
  );
});

export const DriverPendingActivities = memo((props: Object) => {
  const {
    truck,
    group,
    driver,
    groupBy,
    openModal,
    closeModal,
    cardHeight,
    dispatcher,
    filtersStore,
    primaryDriver,
    show24HoursView,
    pendingActivities,
    colorPickerFilterValue,
  } = props;

  const filteredActivities = useMemo(
    () => getFilteredByColorList(colorPickerFilterValue, pendingActivities),
    [pendingActivities, colorPickerFilterValue],
  );

  const { loginId } = G.getUserInfo(driver);

  return (
    filteredActivities.map((item: Object, index: number) => (
      <PendingActivity
        activity={item}
        width='calculate'
        groupBy={groupBy}
        cardIndex={index}
        driverName={loginId}
        openModal={openModal}
        closeModal={closeModal}
        cardHeight={cardHeight}
        filtersStore={filtersStore}
        primaryDriver={primaryDriver}
        key={G.getGuidFromObject(item)}
        show24HoursView={show24HoursView}
        truckGuid={G.getGuidFromObject(truck)}
        groupGuid={G.getGuidFromObject(group)}
        driverGuid={G.getGuidFromObject(driver)}
        dispatcherGuid={G.getGuidFromObject(dispatcher)}
      />
    ))
  );
});

export const DriverUnavailablePeriods = memo((props: Object) => {
  const {
    group,
    truck,
    driver,
    groupBy,
    openModal,
    closeModal,
    cardHeight,
    dispatcher,
    filtersStore,
    primaryDriver,
    show24HoursView,
    unavailablePeriods,
  } = props;

  const { loginId } = G.getUserInfo(driver);

  return (
    unavailablePeriods.map((item: Object, index: number) => (
      <UnavailablePeriod
        period={item}
        width='calculate'
        groupBy={groupBy}
        cardIndex={index}
        driverName={loginId}
        openModal={openModal}
        cardHeight={cardHeight}
        closeModal={closeModal}
        filtersStore={filtersStore}
        primaryDriver={primaryDriver}
        key={G.getGuidFromObject(item)}
        show24HoursView={show24HoursView}
        groupGuid={G.getGuidFromObject(group)}
        truckGuid={G.getGuidFromObject(truck)}
        driverGuid={G.getGuidFromObject(driver)}
        dispatcherGuid={G.getGuidFromObject(dispatcher)}
      />
    ))
  );
});
