import * as R from 'ramda';
import React, { memo, Fragment } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// feature drivers-card
import {
  DriverPendingActivities,
  DriverUnavailablePeriods,
} from './driver-items-lists';
//////////////////////////////////////////////////

export default memo((props: Object) => {
  const { group, truck } = props;

  const { primaryDriver, secondaryDriver } = truck;

  const primaryDriverPendingActivities = R.pathOr([], ['primaryDriverPendingActivities'], truck);

  const secondaryDriverPendingActivities = R.pathOr([], ['secondaryDriverPendingActivities'], truck);

  const primaryDriverUnavailablePeriods = R.pathOr([], ['primaryDriverUnavailablePeriods'], truck);

  const secondaryDriverUnavailablePeriods = R.pathOr([], ['secondaryDriverUnavailablePeriods'], truck);

  return (
    <Fragment>
      {
        G.isNotNilAndNotEmpty(primaryDriverPendingActivities) &&
        <DriverPendingActivities
          {...props}
          group={group}
          primaryDriver={true}
          driver={primaryDriver}
          pendingActivities={primaryDriverPendingActivities}
        />
      }
      {
        G.isNotNilAndNotEmpty(secondaryDriverPendingActivities) &&
        <DriverPendingActivities
          {...props}
          group={group}
          driver={secondaryDriver}
          pendingActivities={secondaryDriverPendingActivities}
        />
      }
      {
        G.isNotNilAndNotEmpty(primaryDriverUnavailablePeriods) &&
        <DriverUnavailablePeriods
          {...props}
          group={group}
          primaryDriver={true}
          driver={primaryDriver}
          unavailablePeriods={primaryDriverUnavailablePeriods}
        />
      }
      {
        G.isNotNilAndNotEmpty(secondaryDriverUnavailablePeriods) &&
        <DriverUnavailablePeriods
          {...props}
          group={group}
          driver={secondaryDriver}
          unavailablePeriods={secondaryDriverUnavailablePeriods}
        />
      }
    </Fragment>
  );
});
