import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
import * as A from '../actions';
import { truckReducer } from './truck';
import { driverReducer } from './driver';
import { vendorReducer } from './vendor';
//////////////////////////////////////////////////

const initialPagination = {
  limit: 10,
  offset: 0,
};

const reportInitialState = {
  loading: {},
  totalCount: {},
  tableTitleFilters: {},
  tableTitleSortValues: {},
  pagination: {
    accessorialList: initialPagination,
    compensationList: initialPagination,
    payrollAccessorialList: initialPagination,
  },
};

const initialState = {
  type: null,
  vinLookup: false,
  configGroup: null,
  collapsedGroup: {},
  branchConfigs: null,
  accessorialConfigs: null,
  // fleet
  truck: {},
  trailer: {},
  driver: reportInitialState,
  vendor: reportInitialState,
};

const setInitialState = (state: Object, type: string) => R.assoc(GC.FIELD_TYPE, type, initialState);

// users
const getGeneralUserListSuccess = (state: Object, data: Object) => P.$set('generalUserList', data, state);

const getFleetDispatchingGroupSuccess = (state: Object, data: Object) => (
  P.$set('dispatchingGroup', data, state)
);

// common
const getGeneralDetailsSuccess = (state: Object, data: Object) => P.$set(`${state.type}.generalDetails`, data, state);

const toggleFormGroupTable = (state: Object, groupName: string) => (
  P.$set(
    `collapsedGroup.${groupName}`,
    R.not(R.pathOr(true, ['collapsedGroup', ...R.split('.', groupName)], state)),
    state,
  )
);

const setVinLookup = (state: Object, value: boolean) => P.$toggle('vinLookup', value, state);

const toggleFormGroupTableDetails = (state: Object, { guid, groupName }: Object) => {
  const { type } = state;

  const list = R.path([type, groupName], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);
  const path = `${type}.${groupName}.${index}.expanded`;

  return P.$set(path, R.not(R.path(R.split('.', path), state)), state);
};

const getItemListSuccess = (state: Object, { data, groupName }: Object) => {
  const { type } = state;

  const itemList = R.pathOr(data, ['results'], data);

  if (G.isNilOrEmpty(itemList)) return P.$set(`${type}.${groupName}`, [], state);

  return P.$set(`${type}.${groupName}`, G.ifElse(G.isArray(itemList), itemList, R.of(Array, itemList)), state);
};

const getReportItemListSuccess = (state: Object, { data, groupName }: Object) => {
  const { type } = state;

  const { results, totalCount } = data;

  const sum = R.sum(R.values(R.pathOr({}, [type, 'pagination', groupName], state)));

  const offset = G.ifElse(R.gt(totalCount, sum), sum, totalCount);

  const itemList = R.concat(R.pathOr([], [type, groupName], state), results);

  if (G.isNilOrEmpty(itemList)) return P.$set(`${type}.${groupName}`, [], state);

  return P.$all(
    P.$set(`${type}.${groupName}`, itemList),
    P.$set(`${type}.totalCount.${groupName}`, totalCount),
    P.$set(`${type}.pagination.${groupName}.offset`, offset),
    state,
  );
};

const createOrUpdateEntitySuccess = (state: Object, { data, groupName }: Object) => {
  const { type } = state;

  const guid = G.getGuidFromObject(data);
  const list = R.path([type, groupName], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.equals(index, -1)) return P.$add(`${type}.${groupName}`, data, state);

  return P.$set(`${type}.${groupName}.${index}`, data, state);
};

const removeEntitySuccess = (state: Object, { guid, groupName }: Object) => {
  const { type } = state;

  const list = R.path([type, groupName], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  return P.$drop(`${type}.${groupName}.${index}`, state);
};

const makeDropdownOptions = (dropdowns: Object) => R.compose(
  R.reduce((acc: Object, dropdownName: string) => R.assoc(
    dropdownName,
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid({ dropdowns }, dropdownName, true),
    acc,
  ), {}),
  R.keys,
)(dropdowns);

// configs
const getFleetProfileConfigsSuccess = (state: Object, data: Object) => {
  const { configGroup } = state;

  const { configs, dropdowns } = data;

  const dropdownOptions = makeDropdownOptions(dropdowns);

  if (R.isNil(configGroup)) {
    return P.$set('configGroup', { configs, dropdownOptions }, state);
  }

  const { configs: existentConfigs, dropdownOptions: existentDropdownOptions } = configGroup;

  const newConfigGroup = {
    configs: R.mergeRight(existentConfigs, configs),
    dropdownOptions: R.mergeRight(existentDropdownOptions, dropdownOptions),
  };

  return P.$set('configGroup', newConfigGroup, state);
};

const getBranchConfigsSuccess = (state: Object, data: Object) => {
  const { configs, dropdowns } = data;

  const dropdownOptions = makeDropdownOptions(dropdowns);

  return P.$set('branchConfigs', { configs, dropdownOptions }, state);
};

// working division

const changeAssignedToDivisionSuccess = (state: Object, data: Object) => {
  const { type, assignedToDivisionGuid } = data;

  const generalDetails = R.path([type, 'generalDetails'], state);

  const { version } = generalDetails;

  const newGeneralDetails = R.mergeRight(
    generalDetails,
    { assignedToDivisionGuid, [GC.FIELD_VERSION]: R.inc(version) },
  );

  return P.$set(`${type}.generalDetails`, newGeneralDetails, state);
};

// vendor
const getFleetVendorListSuccess = (state: Object, data: Object) => P.$set('fleetVendorList', data, state);

// assign vendor
const assignOrUnAssignVendorSuccess = (state: Object, data: Object) => {
  const { type, unassign, vendorGuid } = data;

  const generalDetails = R.path([type, 'generalDetails'], state);

  const { version } = generalDetails;

  const fleetVendorGuid = G.ifElse(unassign, null, vendorGuid);
  const newGeneralDetails = R.mergeRight(generalDetails, { fleetVendorGuid, [GC.FIELD_VERSION]: R.inc(version) });

  return P.$set(`${type}.generalDetails`, newGeneralDetails, state);
};

// accessorial configs
const getAccessorialConfigListSuccess = (state: Object, data: Object) => {
  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
      ]),
    )(item)),
  )(data);

  const accessorialConfigOptions = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  }), data);

  G.setItemToWindow('accessorialConfigOptions', accessorialConfigOptions);

  return P.$set('accessorialConfigs', { accessorialConfigList, accessorialConfigOptions }, state);
};

// filters
const setDocumentsFilter = (state: Object, filter: string) => (
  P.$set(`${state.type}.formGroupFilters.documentsFilter`, filter, state)
);

// report
const setPagination = (state: Object, { groupName, totalCount, increase, decrease }: Object) => {
  const { type } = state;

  let offset = R.pathOr(0, [type, 'pagination', groupName, 'offset'], state);

  if (G.isNotNil(totalCount)) {
    offset = G.ifElse(
      R.gt(R.add(offset, 10), totalCount),
      totalCount,
      R.gt(R.add(offset, 10), totalCount),
    );
  }

  if (increase) offset = R.inc(offset);

  if (decrease) offset = R.dec(offset);

  return P.$set(`${type}.pagination.${groupName}.offset`, offset, state);
};

const setTableTitleSortValues = (state: Object, { data, groupName }: Object) => {
  const { type } = state;

  const path = `${type}.tableTitleSortValues.${groupName}`;

  const titleSortValues = R.pathOr({}, R.split('.', path), state);

  const sortValues = G.getTableTitleSortValues(titleSortValues, data);

  return P.$set(path, sortValues, state);
};

const setTableTitleFilter = (state: Object, { data, groupName }: Object) => {
  const { type } = state;

  const path = `${type}.tableTitleFilters.${groupName}`;

  const tableTitleFilters = R.pathOr({}, R.split('.', path), state);

  const filterValues = G.getTableTitleFilterValues(tableTitleFilters, data);

  return P.$set(path, filterValues, state);
};

const resetListAndPagination = (state: Object, { groupName }: Object) => (
  P.$all(
    P.$set(`${state.type}.${groupName}`, null),
    P.$set(`${state.type}.pagination.${groupName}`, initialPagination),
    state,
  )
);

const setListLoading = (state: Object, { loading, groupName }: Object) => (
  P.$set(`${state.type}.loading.${groupName}`, loading, state)
);

const setReports = (state: Object, { reports, groupName }: Object) => (
  P.$set(`${state.type}.availableReports.${groupName}`, reports, state)
);

const setUsedReport = (state: Object, { groupName, usedReport }: Object) => (
  P.$all(
    P.$set(`${state.type}.${groupName}`, []),
    P.$set(`${state.type}.reportPending.${groupName}`, false),
    P.$set(`${state.type}.tableTitleFilters.${groupName}`, {}),
    P.$set(`${state.type}.usedReport.${groupName}`, usedReport),
    P.$set(`${state.type}.tableTitleSortValues.${groupName}`, {}),
    P.$set(`${state.type}.pagination.${groupName}`, initialPagination),
    state,
  )
);

const setReportPending = (state: Object, { groupName }: Object) => (
  P.$set(`${state.type}.reportPending.${groupName}`, true, state)
);

export default createReducer({
  [A.setVinLookup]: setVinLookup,
  [A.setInitialState]: setInitialState,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.removeEntitySuccess]: removeEntitySuccess,
  [A.toggleFormGroupTable]: toggleFormGroupTable,
  [A.getGeneralDetailsSuccess]: getGeneralDetailsSuccess,
  [A.toggleFormGroupTableDetails]: toggleFormGroupTableDetails,
  [A.createOrUpdateEntitySuccess]: createOrUpdateEntitySuccess,
  // users
  [A.getGeneralUserListSuccess]: getGeneralUserListSuccess,
  [A.getFleetDispatchingGroupSuccess]: getFleetDispatchingGroupSuccess,
  // configs
  [A.getBranchConfigsSuccess]: getBranchConfigsSuccess,
  [A.getFleetProfileConfigsSuccess]: getFleetProfileConfigsSuccess,
  // working division
  [A.changeAssignedToDivisionSuccess]: changeAssignedToDivisionSuccess,
  // vendor
  [A.getFleetVendorListSuccess]: getFleetVendorListSuccess,
  // assign vendor
  [A.assignOrUnAssignVendorSuccess]: assignOrUnAssignVendorSuccess,
  // accessorial configs
  [A.getAccessorialConfigListSuccess]: getAccessorialConfigListSuccess,
  // filters
  [A.setDocumentsFilter]: setDocumentsFilter,
  // report
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setPagination]: setPagination,
  [A.setListLoading]: setListLoading,
  [A.setReportPending]: setReportPending,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setTableTitleSortValues]: setTableTitleSortValues,
  [A.getReportItemListSuccess]: getReportItemListSuccess,
  // truck
  ...truckReducer,
  // driver
  ...driverReducer,
  // vendor
  ...vendorReducer,
}, initialState);
