import * as R from 'ramda';
import { initialize } from 'redux-form';
import {
  put,
  call,
  fork,
  take,
  select,
  takeLatest,
} from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import {
  getSequencesByTypeRequest,
  getSequencesByTypeSuccess,
} from '../sequence/actions';
import {
  getAllAvailableRefTypesByScopeRequest,
  getAllAvailableRefTypesByScopeRequestSuccess,
} from '../reference/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { crudSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature configs
import * as A from './actions';
import { CONFIG_PAGE_FORM } from './constants';
import { makeSelectCurrentGroupName } from './selectors';
// import uiConfigWatcherSaga from './config-pages/ui/sagas';
// import telConfigWatcherSaga from './config-pages/tel/sagas';
// import cloConfigWatcherSaga from './config-pages/clo/sagas';
// import truckConfigWatcherSaga from './config-pages/truck/sagas';
// import ratingConfigWatcherSaga from './config-pages/rating/sagas';
// import driverConfigWatcherSaga from './config-pages/driver/sagas';
// import trailerConfigWatcherSaga from './config-pages/trailer/sagas';
// import carrierConfigWatcherSaga from './config-pages/carrier/sagas';
// import generalConfigWatcherSaga from './config-pages/general/sagas';
// import invoicesConfigWatcherSaga from './config-pages/invoice/sagas';
// import optionalConfigWatcherSaga from './config-pages/optional/sagas';
// import passwordConfigWatcherSaga from './config-pages/password/sagas';
// import templatesConfigWatcherSaga from './config-pages/templates/sagas';
// import mobileAppConfigWatcherSaga from './config-pages/mobileapp/sagas';
// import integrationConfigWatcherSaga from './config-pages/integration/sagas';
// import fleetGeneralConfigWatcherSaga from './config-pages/fleet-general/sagas';
// import communicationConfigWatcherSaga from './config-pages/communication/sagas';
// import serviceVendorConfigWatcherSaga from './config-pages/service-vendor/sagas';
//////////////////////////////////////////////////

const transformConfigsToObject = (configs: Array) => {
  let result = {};
  R.forEach((item: Object) => {
    result = R.assoc(item.name, item.value, result);
  }, configs);

  return result;
};

export function* getConfigByGroupSaga(groupName: string) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        group: groupName,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getConfigByGroupSuccess({
        groupName,
        res: data,
      }));

      if (G.isNotNilAndNotEmpty(data.configs)) {
        yield put(initialize(CONFIG_PAGE_FORM, transformConfigsToObject(data.configs)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getConfigByGroupSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigByGroupSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const currentGroupName = yield select(makeSelectCurrentGroupName());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
      data: payload,
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.branchConfigsEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status, data)) {
      if (G.isNotPartiallySuccess(status)) {
        if (R.equals(currentGroupName, GC.UI_CONFIG_GROUP)) {
          yield call(G.showToastrMessage, 'info', 'messages:login:applied');
        } else {
          yield call(G.showToastrMessage, 'success', 'messages:success:save');
        }
      }
      yield put(A.updateConfigsSuccess(currentGroupName));
      yield call(getConfigByGroupSaga, currentGroupName);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateConfigsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateConfigsSaga exception');
  }
}

function* removeOptionalConfigRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const currentGroupName = yield select(makeSelectCurrentGroupName());
    const options = {
      params: {
        configName: payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.branchConfigsEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status, data)) {
      if (G.isNotPartiallySuccess(status)) {
        yield call(G.showToastrMessage, 'success', 'messages:success:remove');
      }
      yield call(getConfigByGroupSaga, currentGroupName);
    } else {
      yield call(G.handleFailResponse, res, 'removeOptionalConfigRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeOptionalConfigRequestSaga exception');
  }
}

function* restoreInheritedDropdownSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const currentGroupName = yield select(makeSelectCurrentGroupName());
    const dropdownName = payload;
    const options = {
      params: {
        dropdownName,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.restoreInheritedDropdownEndpoint,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(getConfigByGroupSaga, currentGroupName);
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateConfigsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateConfigsSaga exception');
  }
}

function* handleGetReferenceTypesGuidsNamesMap({ payload }: Object) {
  try {
    const options = {
      data: {
        elements: payload,
      },
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.referenceTypeGuidsNamesMap,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getReferenceTypesGuidsNamesMapSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetReferenceTypesGuidsNamesMap fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetReferenceTypesGuidsNamesMap exception');
  }
}

function* restoreInheritedByConfigTypeRequest({ payload }: Object) {
  try {
    const customConfigsMap = {
      [GC.CUSTOM_DOCUMENT_CONFIG]: {
        getListAction: A.getDocumentsForConfigRequest,
        endpoint: endpointsMap.documentRestoreInherited,
      },
      [GC.CARRIER_DEACTIVATION_RULES]: {
        getListAction: A.getCarrierDeactivationRuleListRequest,
        endpoint: endpointsMap.carrierDeactivationRuleRestoreInherited,
      },
      [GC.CUSTOM_ACCESSORIALS_CONFIG]: {
        getListAction: A.getAccessorialForConfigRequest,
        endpoint: endpointsMap.restoreInheritedAccessorialsEndpoint,
      },
      [GC.CUSTOM_SERVICE_MAPPING_CONFIG_SECTION]: {
        getListAction: A.getServiceMappingListRequest,
        endpoint: endpointsMap.serviceMappingRestoreInherited,
      },
      [GC.CUSTOM_STATUS_MESSAGES_CONFIG]: {
        method: 'get',
        getListAction: A.getStatusMessagesForConfigRequest,
        endpoint: endpointsMap.restoreInheritedStatusMessagesEndpoint,
      },
      [GC.CUSTOM_TOLL_CHARGES_CONFIG]: {
        getListAction: A.getTollChargesConfigListRequest,
        endpoint: endpointsMap.tollChargeConfigRestoreInherited,
      },
      [GC.CUSTOM_ADD_NOTIFICATION_CONFIG]: {
        getListAction: A.getNotificationListTriggersRequest,
        endpoint: endpointsMap.notificationsRestoreInherited,
      },
      [GC.CUSTOM_ADVANCE_PAYMENT_CONFIG]: {
        getListAction: A.getAdvancePaymentListRequest,
        endpoint: endpointsMap.advancePaymentConfigRestoreInherited,
      },
      [GC.CARRIER_ONBOARDING_PACKAGE]: {
        getListAction: A.getCarrierOnboardingPackageListRequest,
        endpoint: endpointsMap.carrierOnboardingPackageRestoreInherited,
      },
      [GC.CUSTOM_CARRIER_RATE_CONFIG]: {
        getListAction: A.getCarrierRateIntegrationListRequest,
        endpoint: endpointsMap.carrierRateIntegrationConfigRestoreInherited,
      },
      [GC.CUSTOM_USER_MONITORING_CONFIG]: {
        getListAction: A.getUserMonitoringIntegrationListRequest,
        endpoint: endpointsMap.userMonitoringConfigRestoreInherited,
      },
      [GC.CUSTOM_LOADBOARD_CONFIG]: {
        endpoint: endpointsMap.loadBoardConfigRestoreInherited,
        getListAction: A.getLoadBoardIntegrationConfigListRequest,
      },
      [GC.CUSTOM_DISTANCE_CALCULATOR_CONFIG]: {
        getListAction: A.getDistanceCalculatorConfigListRequest,
        endpoint: endpointsMap.distanceCalculatorConfigRestoreInherited,
      },
      [GC.CUSTOM_GPS_INTEGRATION_CONFIG]: {
        getListAction: A.getGpsIntegrationConfigListRequest,
        endpoint: endpointsMap.eldIntegrationConfigRestoreInherited,
      },
      [GC.CUSTOM_INTEGRATION_CONFIGS_CONFIG]: {
        getListAction: A.getIntegrationConfigListRequest,
        endpoint: endpointsMap.customerIntegrationConfigRestoreInherited,
      },
      [GC.CUSTOM_DISPATCH_INTEGRATION_CONFIG]: {
        getListAction: A.getDispatchIntegrationConfigListRequest,
        endpoint: endpointsMap.dispatchIntegrationRestoreInherited,
      },
      [GC.CUSTOM_STATUS_CODE_MAPPING_CONFIG]: {
        getListAction: A.getStatusCodeMappingListRequest,
        endpoint: endpointsMap.statusCodeMappingRestoreInherited('inboundMapping'),
      },
      [GC.CUSTOM_STATUS_CODE_OUTBOUND_MAPPING_CONFIG]: {
        getListOptions: 'outboundMapping',
        getListAction: A.getStatusCodeMappingListRequest,
        endpoint: endpointsMap.statusCodeMappingRestoreInherited('outboundMapping'),
      },
      [GC.CUSTOM_STATUS_REASON_CODE_MAPPING_CONFIG]: {
        getListAction: A.getStatusReasonCodeMappingListRequest,
        endpoint: endpointsMap.statusReasonCodeMappingRestoreInherited('inboundMapping'),
      },
      [GC.CUSTOM_STATUS_REASON_CODE_OUTBOUND_MAPPING_CONFIG]: {
        getListOptions: 'outboundMapping',
        getListAction: A.getStatusReasonCodeMappingListRequest,
        endpoint: endpointsMap.statusReasonCodeMappingRestoreInherited('outboundMapping'),
      },
      [GC.CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG]: {
        getListAction: A.getQBAccountMappingListRequest,
        endpoint: endpointsMap.quickBooksAccountMappingRestoreInherited,
      },
      [GC.CUSTOM_QUICK_BOOKS_CUSTOM_FIELD_MAPPING_CONFIG]: {
        getListAction: A.getQBCustomFieldMappingListRequest,
        endpoint: endpointsMap.quickBooksCustomFieldMappingRestoreInherited,
      },
      [GC.CUSTOM_QUICK_BOOKS_IIF_ASSESSORIAL_MAPPING_CONFIG]: {
        getListAction: A.getQBIIFAccessorialMappingListRequest,
        endpoint: endpointsMap.quickBooksIffAssessorialMappingRestoreInherited,
      },
      [GC.CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG]: {
        getListAction: A.getSageAccountMappingListRequest,
        endpoint: endpointsMap.sageAccountMappingRestoreInherited,
      },
      [GC.CUSTOM_CUSTOMER_ID_MAPPING_CONFIG]: {
        getListAction: A.getCustomerIdMappingListRequest,
        endpoint: endpointsMap.customerIdMappingRestoreInherited,
      },
      [GC.CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG]: {
        getListAction: A.getDepartmentIdMappingListRequest,
        endpoint: endpointsMap.departmentIdMappingListRestoreInherited,
      },
      [GC.CUSTOM_MARGIN_VIOLATION_RULE_CONFIG]: {
        getListAction: A.getMarginViolationRuleListRequest,
        endpoint: endpointsMap.marginViolationRuleListRestoreInherited,
      },
      [GC.CUSTOM_SERVICE_TYPE_MAPPING_CONFIG]: {
        getListAction: A.getServiceTypeMappingListRequest,
        endpoint: endpointsMap.serviceTypeMappingRestoreInherited,
      },
      [GC.CUSTOM_GL_CODE_MAPPING_CONFIG]: {
        getListAction: A.getGLCodeMappingListRequest,
        endpoint: endpointsMap.glCodeRestoreInherited,
      },
      [GC.CUSTOM_CARRIER_INTEGRATION_OVERRIDE_CONFIG]: {
        getListAction: A.getCarrierIntegrationOverrideListRequest,
        endpoint: endpointsMap.restoreInheritedCarrierIntegrationCustomerConfig,
      },
      [GC.CUSTOM_INTEGRATION_DOCUMENT_TYPE_MAPPING_CONFIG]: {
        getListAction: A.getDocumentTypeMappingListRequest,
        endpoint: endpointsMap.restoreInheritedDocumentTypeMapping,
      },
      [GC.CUSTOM_INVOICE_STATUS_RULE_CONFIG]: {
        getListAction: A.getInvoiceStatusRuleListRequest,
        endpoint: endpointsMap.restoreInheritedDefaultInvoiceStatusRule,
      },
      [GC.CUSTOM_TRANSPORTATION_MODE_MAPPING_CONFIG]: {
        getListAction: A.getTransportationModeConfigMappingListRequest,
        endpoint: endpointsMap.transportationModeIconMappingRestoreInherited,
      },
      [GC.CUSTOM_EXTERNAL_TRANSPORTATION_MODE_MAPPING_CONFIG]: {
        getListOptions: GC.INTEGRATION_CONFIG_GROUP,
        getListAction: A.getTransportationModeConfigMappingListRequest,
        endpoint: endpointsMap.transportationModeMappingRestoreInherited,
      },
      [GC.CUSTOM_LOAD_CUSTOM_STATUS_CONFIG]: {
        getListAction: A.getLoadCustomStatusListRequest,
        endpoint: endpointsMap.loadCustomStatusRestoreInherited,
      },
      [GC.CUSTOM_EQUIPMENT_MAPPING_CONFIG]: {
        getListAction: A.getEquipmentMappingConfigListRequest,
        endpoint: endpointsMap.equipmentMappingRestoreInherited,
      },
      [GC.CUSTOM_CARRIER_SCORECARD_CONFIG]: {
        getListAction: A.getCarrierScorecardConfigListRequest,
        endpoint: endpointsMap.carrierScorecardConfigRestoreInherited,
      },
      [GC.CUSTOM_TASK_TYPE_CONFIG]: {
        getListAction: A.getTaskTypeListRequest,
        endpoint: endpointsMap.taskTypeConfigRestoreInherited,
      },
      [GC.CUSTOM_TASK_STATUS_CONFIG]: {
        getListAction: A.getTaskStatusListRequest,
        endpoint: endpointsMap.taskStatusConfigRestoreInherited,
      },
      [GC.CUSTOM_DOCUMENT_SENDING_CONFIG]: {
        getListAction: A.getDocumentSendingConfigListRequest,
        endpoint: endpointsMap.documentSendingConfigRestoreInherited,
      },
      [GC.CUSTOM_TRANSPORTATION_MODE_GROUPING_CONFIG]: {
        getListAction: A.getTransportationModeGroupingListRequest,
        endpoint: endpointsMap.transportationModeGroupingRestoreInherited,
      },
      [GC.CUSTOM_DEFAULT_CARRIER_ACCESSORIAL_CONFIG]: {
        getListAction: A.getDefaultCarrierAccessorialListRequest,
        endpoint: endpointsMap.defaultCarrierAccessorialRestoreInherited,
      },
      [GC.CUSTOM_EXPENSE_TYPES_CONFIG]: {
        getListAction: A.getExpenseTypeListRequest,
        endpoint: endpointsMap.driverExpenseTypeRestoreInherited,
      },
      [GC.CUSTOM_TRUCK_TYPE_MAPPING_CONFIG]: {
        getListAction: A.getTruckTypeMappingListRequest,
        endpoint: endpointsMap.truckTypeMappingRestoreInherited,
      },
      [GC.CUSTOM_TRAILER_TYPE_MAPPING_CONFIG]: {
        getListAction: A.getTrailerTypeMappingListRequest,
        endpoint: endpointsMap.trailerTypeMappingRestoreInherited,
      },
    };

    if (G.notContain(payload, R.keys(customConfigsMap))) {
      return yield put(A.restoreInheritedDropdownRequest(payload));
    }

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const endpoint = R.path([payload, 'endpoint'], customConfigsMap);
    const method = R.pathOr('put', [payload, 'method'], customConfigsMap);
    const successAction = R.path([payload, 'getListAction'], customConfigsMap);

    const res = yield call(sendRequest, method, endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(successAction(R.path([payload, 'getListOptions'], customConfigsMap)));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'restoreInheritedByConfigTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'restoreInheritedByConfigTypeRequest exception');
  }
}

function* handleGetSequencesGuidNameMap({ payload }: Object) {
  try {
    const options = {
      data: {
        elements: payload,
      },
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.sequencesGuidNameMap,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getSequencesGuidNameMapSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSequencesGuidNameMap fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetSequencesGuidNameMap exception');
  }
}

export function* getReferenceTypesByScopeForConfigSaga({ payload }: Object) {
  try {
    yield put(getAllAvailableRefTypesByScopeRequest(payload));
    const res = yield take(getAllAvailableRefTypesByScopeRequestSuccess);
    yield put(A.getReferenceTypesByScopeForConfigSuccess({
      scope: payload,
      refTypes: res.payload,
    }));
  } catch (error) {
    yield call(G.handleException, error, 'getReferenceTypesByScopeForConfigSaga exception');
  }
}

export function* getSequencesByTypeForConfigSaga({ payload }: Object) {
  try {
    yield put(getSequencesByTypeRequest(payload));
    const res = yield take(getSequencesByTypeSuccess);
    yield put(A.getSequencesByTypeForConfigSuccess({
      type: payload,
      sequences: res.payload,
    }));
  } catch (error) {
    yield call(G.handleException, error, 'getSequencesByTypeForConfigSaga exception');
  }
}

function* handleGetAvailableDocumentTypesRequestSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableDocumentTypes,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedAvailableDocumentTypesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableDocumentTypesRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAvailableDocumentTypesRequestSaga exception');
  }
}

export function* getCustomerBranchListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_TYPE]: GC.BRANCH_TYPE_ENUM_CUSTOMER,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getCustomerBranchListSuccess,
      parentSagaName: 'getCustomerBranchListRequest',
      endpoint: endpointsMap.getBranchListByType(branchGuid),
    });
  } catch (error) {
    yield call(G.handleException, error, 'getCustomerBranchListRequest exception');
  }
}

export function* getSharedAccessorialListSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.sharedAccessorialList,
      parentSagaName: 'getSharedAccessorialListSaga',
      successAction: A.getSharedAccessorialListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getSharedAccessorialListSaga exception');
  }
}

// TODO: check while
export function* getStatusMessagesConfigSaga() {
  while (true) {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.statusMessagesListEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getStatusMessagesForGeneralConfigSuccess(data));
    }
    break;
  }
}

const filterStatusMessage = (data: Array) => (
  R.compose(
    G.mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject,
    R.filter(
      (item: Object) => {
        const { statusType } = item;

        return G.ifElse(
          R.or(
            R.equals(statusType, GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
            R.equals(statusType, GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
          ),
          null,
          item,
        );
      },
    ),
  )(data)
);

const getStatusCodeOptionsByStatusType = (statusCodes: Array, statusType: string) => R.compose(
  G.mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject,
  R.filter(R.propEq(statusType, GC.FIELD_STATUS_TYPE)),
)(statusCodes);

// TODO: save one saga to use statusMessagesListEndpoint
export function* getStatusMessagesForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.statusMessagesListEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const statusCodeList = filterStatusMessage(data);

      const allStatusCodeList = G.mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject(data);

      const allStatusCodeListWithStoredValues =
        G.mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject(data, true);

      const trackingStatusCodeList = R.compose(
        G.mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject,
        R.filter(R.prop(GC.FIELD_STATUS_MESSAGE_TRACKING)),
      )(data);

      const pickupCompletedStatusCodes = getStatusCodeOptionsByStatusType(
        data,
        GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED,
      );

      const dropCompletedStatusCodes = getStatusCodeOptionsByStatusType(
        data,
        GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED,
      );

      yield put(A.getStatusCodeListSuccess({
        statusCodeList,
        allStatusCodeList,
        trackingStatusCodeList,
        dropCompletedStatusCodes,
        pickupCompletedStatusCodes,
        allStatusCodeListWithStoredValues,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'getStatusMessagesForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getStatusMessagesForConfigSaga exception');
  }
}

// terms and conditions
const termsConfigGroupToDocumentEndpointMap = {
  [GC.GENERAL_CONFIG_GROUP]: endpointsMap.userTermsAndConditionsDocument,
  [GC.CLO_CONFIG_GROUP]: endpointsMap.customerTermsAndConditionsDocument,
  [GC.CARRIER_CONFIG_GROUP]: endpointsMap.carrierTermsAndConditionsDocument,
};

export function* getTermsAndConditionsDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const configGroup = R.pathOr(payload, ['configGroup'], payload);
    const branchGuidToUse = R.pathOr(branchGuid, ['branchGuid'], payload);

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuidToUse },
    };

    const mapper = (data: Object) => {
      if (G.isNilOrEmpty(data)) return configGroup;

      return R.assoc('configGroup', configGroup, data);
    };

    const endpoint = R.prop(configGroup, termsConfigGroupToDocumentEndpointMap);

    yield call(crudSaga, {
      mapper,
      options,
      endpoint,
      method: 'get',
      successAction: A.getTermsAndConditionsDocumentSuccess,
      parentSagaName: 'getTermsAndConditionsDocumentRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTermsAndConditionsDocumentRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* createTermsAndConditionsDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { configGroup } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = { data: G.makeDataForDocument(R.assoc(GC.BRANCH_GUID, branchGuid, payload)) };

    const mapper = R.assoc('configGroup', configGroup);

    const endpoint = R.prop(configGroup, termsConfigGroupToDocumentEndpointMap);

    yield call(crudSaga, {
      mapper,
      options,
      endpoint,
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      successAction: A.getTermsAndConditionsDocumentSuccess,
      parentSagaName: 'createTermsAndConditionsDocumentRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'createTermsAndConditionsDocumentRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* removeTermsAndConditionsDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, configGroup } = payload;

    const options = { params: { guid } };

    const endpoint = R.prop(configGroup, termsConfigGroupToDocumentEndpointMap);

    const res = yield call(sendRequest, 'delete', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTermsAndConditionsDocumentSuccess(configGroup));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeTermsAndConditionsDocumentRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'removeTermsAndConditionsDocumentRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* updateTermsAndConditionsDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const { configGroup } = payload;

    const options = { data: G.makeDataForDocument(R.assoc(GC.BRANCH_GUID, branchGuid, payload)) };

    const mapper = R.assoc('configGroup', configGroup);

    const termsUpdateConfigGroupToDocumentEndpointMap = {
      [GC.GENERAL_CONFIG_GROUP]: endpointsMap.updateUserTermsAndConditionsDocument,
      [GC.CLO_CONFIG_GROUP]: endpointsMap.updateCustomerTermsAndConditionsDocument,
      [GC.CARRIER_CONFIG_GROUP]: endpointsMap.updateCarrierTermsAndConditionsDocument,
    };

    const endpoint = R.prop(configGroup, termsUpdateConfigGroupToDocumentEndpointMap);

    yield call(crudSaga, {
      mapper,
      options,
      endpoint,
      method: 'post',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      successAction: A.getTermsAndConditionsDocumentSuccess,
      parentSagaName: 'updateTermsAndConditionsDocumentRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'updateTermsAndConditionsDocumentRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

// transportation mode mapping
export function* getTransportationModeConfigMappingListRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const mapper = (data: Object) => ({ data: R.or(data, []), configGroup: payload });
    const endpoint = G.ifElse(
      R.equals(payload, GC.INTEGRATION_CONFIG_GROUP),
      endpointsMap.transportationModeMappingList,
      endpointsMap.transportationModeIconMappingList,
    );

    yield call(crudSaga, {
      mapper,
      options,
      endpoint,
      method: 'get',
      parentSagaName: 'getTransportationModeConfigMappingListRequest',
      successAction: A.getTransportationModeConfigMappingListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTransportationModeConfigMappingListRequest exception');
  }
}

function* createOrUpdateTransportationModeConfigMappingRequest({ payload }: Object) {
  try {
    const { version, configGroup } = payload;

    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const mapper = R.assoc('configGroup', configGroup);
    const endpoint = G.ifElse(
      R.equals(configGroup, GC.INTEGRATION_CONFIG_GROUP),
      endpointsMap.transportationModeMapping,
      endpointsMap.transportationModeIconMapping,
    );

    yield call(crudSaga, {
      mapper,
      method,
      options,
      endpoint,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateTransportationModeConfigMappingSuccess,
      parentSagaName: 'createOrUpdateTransportationModeConfigMappingRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateTransportationModeConfigMappingRequest exception');
  }
}

function* removeTransportationModeConfigMappingRequest({ payload }: Object) {
  try {
    const { guid, configGroup } = payload;

    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const endpoint = G.ifElse(
      R.equals(configGroup, GC.INTEGRATION_CONFIG_GROUP),
      endpointsMap.transportationModeMapping,
      endpointsMap.transportationModeIconMapping,
    );
    const options = {
      data: {
        guid,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const mapper = () => payload;

    yield call(crudSaga, {
      mapper,
      options,
      payload,
      endpoint,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.removeTransportationModeConfigMappingSuccess,
      parentSagaName: 'removeTransportationModeConfigMappingRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeTransportationModeConfigMappingRequest exception');
  }
}

// TODO: remove after testing
function* configWatcherSaga() {
  // yield fork(uiConfigWatcherSaga);
  // yield fork(cloConfigWatcherSaga);
  // yield fork(telConfigWatcherSaga);
  // yield fork(truckConfigWatcherSaga);
  // yield fork(driverConfigWatcherSaga);
  // yield fork(ratingConfigWatcherSaga);
  // yield fork(carrierConfigWatcherSaga);
  // yield fork(generalConfigWatcherSaga);
  // yield fork(trailerConfigWatcherSaga);
  // yield fork(optionalConfigWatcherSaga);
  // yield fork(passwordConfigWatcherSaga);
  // yield fork(invoicesConfigWatcherSaga);
  // yield fork(templatesConfigWatcherSaga);
  // yield fork(mobileAppConfigWatcherSaga);
  // yield fork(integrationConfigWatcherSaga);
  // yield fork(fleetGeneralConfigWatcherSaga);
  // yield fork(communicationConfigWatcherSaga);
  // yield fork(serviceVendorConfigWatcherSaga);
  yield takeLatest(A.updateConfigsRequest, handleUpdateConfigsSaga);
  yield takeLatest(A.removeOptionalConfigRequest, removeOptionalConfigRequestSaga);
  yield takeLatest(A.getSequencesGuidNameMapRequest, handleGetSequencesGuidNameMap);
  yield takeLatest(A.restoreInheritedDropdownRequest, restoreInheritedDropdownSaga);
  yield takeLatest(A.getAvailableDocumentTypes, handleGetAvailableDocumentTypesRequestSaga);
  yield takeLatest(A.restoreInheritedByConfigTypeRequest, restoreInheritedByConfigTypeRequest);
  yield takeLatest(A.getReferenceTypesGuidsNamesMapRequest, handleGetReferenceTypesGuidsNamesMap);
  // terms and conditions
  yield takeLatest(A.getTermsAndConditionsDocumentRequest, getTermsAndConditionsDocumentRequest);
  yield takeLatest(A.updateTermsAndConditionsDocumentRequest, updateTermsAndConditionsDocumentRequest);
  yield takeLatest(A.removeTermsAndConditionsDocumentRequest, removeTermsAndConditionsDocumentRequest);
  yield takeLatest(A.createTermsAndConditionsDocumentRequest, createTermsAndConditionsDocumentRequest);
  // transportation mode config mapping
  yield takeLatest(A.removeTransportationModeConfigMappingRequest, removeTransportationModeConfigMappingRequest);
  yield takeLatest(A.getTransportationModeConfigMappingListRequest, getTransportationModeConfigMappingListRequest);
  yield takeLatest(
    A.createOrUpdateTransportationModeConfigMappingRequest,
    createOrUpdateTransportationModeConfigMappingRequest,
  );
}

export default configWatcherSaga;
