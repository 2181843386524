import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { Tabs2 } from '../../../components/tabs-mui';
import { TitlePanel } from '../../../components/title-panel';
import { ConfirmComponent } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { PageWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature geo-fencing-zone
import { getPageTabs } from '../settings';
import GeoFencingZoneForm from '../components/geo-fencing-zone-form';
import { makeSelectItemList, makeSelectIndexedList } from './selectors';
import { commonTableSettings, geoFencingZoneDetailsTableSettings } from '../settings/table-settings';
import {
  toggleGeoFencingZoneDetails,
  createGeoFencingZoneRequest,
  deleteGeoFencingZoneRequest,
  createGeoFencingZoneExportRequest,
} from './actions';
import {
  report,
  columnSettings,
  reportGeoFencingZoneDetailsReport,
  geoFencingZoneDetailsColumnSettings,
} from '../settings/column-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleCreateGeoFencingZone: (props: Object) => () => {
      const { openModal, createGeoFencingZoneRequest } = props;

      const component = <GeoFencingZoneForm type='report' submitAction={createGeoFencingZoneRequest} />;

      const modal = {
        component,
        p: '15px 10px 15px 15px',
        options: {
          width: 985,
          height: 'auto',
          title: G.getWindowLocale('titles:add-geo-fencing-zone', 'Add Geo Fencing Zone'),
        },
      };

      openModal(modal);
    },
    handleEditGeoFencingZone: (props: Object) => (_: any, initialValues: Object) => {
      const { type, openModal, createGeoFencingZoneRequest } = props;

      const component = (
        <GeoFencingZoneForm
          type={type}
          initialValues={initialValues}
          submitAction={createGeoFencingZoneRequest}
        />
      );

      const modal = {
        component,
        p: '15px 10px 15px 15px',
        options: {
          width: 985,
          height: 'auto',
          title: G.getWindowLocale('titles:edit-geo-fencing-zone', 'Edit Geo Fencing Zone'),
        },
      };

      openModal(modal);
    },
    handleDeleteGeoFencingZone: (props: Object) => (_: any, { name, guid }: Object) => {
      const { openModal, deleteGeoFencingZoneRequest } = props;

      const textLocale = G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this');
      const component = <ConfirmComponent name={name} textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => deleteGeoFencingZoneRequest(guid),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleClickTab: () => (i: number) => {
      if (R.equals(i, 1)) return;

      G.goToRoute(routesMap.geoFencingZoneList);
    },
  }),
  pure,
);

const renderGeoFencingZoneDetails = ({ parentProps }: Object) => {
  const data = {
    loading: false,
    hasSelectable: false,
    allowSelectItems: false,
    report: reportGeoFencingZoneDetailsReport,
    tableSettings: geoFencingZoneDetailsTableSettings,
    columnSettings: geoFencingZoneDetailsColumnSettings,
    itemList: parentProps[GC.FIELD_GEO_FENCING_ZONE_ZONE_ADDRESS_POINTS],
  };

  return <Table {...data} />;
};

const reportTableSettings = {
  ...commonTableSettings,
  expandedDetailsComponent: (props: Object) => renderGeoFencingZoneDetails(props),
};

const GeoFencingZoneList = (props: Object) => {
  const {
    itemList,
    handleClickTab,
    handleEditGeoFencingZone,
    handleDeleteGeoFencingZone,
    handleCreateGeoFencingZone,
    toggleGeoFencingZoneDetails,
    createGeoFencingZoneExportRequest,
  } = props;

  const customTitleComponent = (
    <Tabs2
      activeTab={1}
      tabs={getPageTabs(1)}
      count={R.length(itemList)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEditGeoFencingZone,
      text: G.getWindowLocale('titles:edit', 'Edit'),
    },
    {
      iconName: 'trash',
      action: handleDeleteGeoFencingZone,
      text: G.getWindowLocale('titles:delete', 'Delete'),
    },
  ];

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          withCount={true}
          filterProps={[]}
          noEditReport={true}
          noExportable={false}
          hideFilterInfo={true}
          popperWithCount={true}
          noSelectDropdown={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={false}
          totalCount={R.length(itemList)}
          customTitleComponent={customTitleComponent}
          exportReportDataRequest={createGeoFencingZoneExportRequest}
          title={G.getWindowLocale('titles:geo-fencing-zones', 'Geo Fencing Zones')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          report={report}
          loading={false}
          itemList={itemList}
          actionButtons={actionButtons}
          columnSettings={columnSettings}
          tableSettings={reportTableSettings}
          toggle={toggleGeoFencingZoneDetails}
        />
      </ZOrderWrapper>
      <PageActions
        shadowColor={G.getTheme('createButton.shadowColor')}
        mainAction={{
          action: handleCreateGeoFencingZone,
          text: G.getWindowLocale('titles:create-geo-fencing-zone', 'Create Geo Fencing Zone'),
        }}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  indexedList: makeSelectIndexedList(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  deleteGeoFencingZoneRequest,
  createGeoFencingZoneRequest,
  toggleGeoFencingZoneDetails,
  createGeoFencingZoneExportRequest,
})(enhance(GeoFencingZoneList));
