import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// feature drivers-card
import GroupedByCards from './grouped-by-cards';
import NotGroupedCards from './not-grouped-cards';
import TruckGroupedByDispatchCards from './truck-grouped-by-dispatch-cards';
import TruckGroupedByDispatchGroupCards from './truck-grouped-by-dispatch-group-cards';
import { GROUP_TYPE_NONE, GROUP_TYPE_GROUP_AND_DISPATCHER, GROUP_TYPE_DISPATCHING_GROUP } from '../constants';
//////////////////////////////////////////////////

const Cards = (props: Object) => {
  const { groupBy, dispatchByTruck } = props;

  const whiteColor = G.getTheme('colors.white');

  if (R.equals(groupBy, GROUP_TYPE_NONE)) {
    return <NotGroupedCards {...props} whiteColor={whiteColor} />;
  }

  if (R.and(G.isTrue(dispatchByTruck), R.equals(groupBy, GROUP_TYPE_DISPATCHING_GROUP))) {
    return <TruckGroupedByDispatchGroupCards {...props} whiteColor={whiteColor} />;
  }

  if (R.and(G.isTrue(dispatchByTruck), R.equals(groupBy, GROUP_TYPE_GROUP_AND_DISPATCHER))) {
    return <TruckGroupedByDispatchCards {...props} whiteColor={whiteColor} />;
  }

  return <GroupedByCards {...props} whiteColor={whiteColor} />;
};

export default Cards;
