import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, lifecycle, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { EmptyList } from '../../components/table/ui';
import useEditViewHook from '../../hooks/use-edit-view';
import { openModal, closeModal } from '../../components/modal/actions';
import {
  pickerColorRed,
  pickerColorGrey,
  pickerColorBlue,
  pickerColorGreen,
  pickerColorYellow,
} from '../../components/color-picker';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import DriverProfileSimple from '../driver-profile-simple';
import { makeSelectCurrentUserName } from '../../features/auth/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import {
  openDriverProfile,
  setInitialState as setDriverProfileSimpleInitialState,
} from '../driver-profile-simple/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover, withSetExpandedContainerOptions } from '../../hocs';
// ui
import { Box, ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature available-driver
import Header from './components/header';
import { Notes } from './components/notes';
import { NoteForm } from './components/note-form';
import { RowActions } from './components/row-actions';
import { getTableSettings } from './settings/table-settings';
import { ReservationForm } from './components/reservation-form';
import UnassignedLoadsList from './components/unassigned-loads-list';
import {
  report,
  sortReport,
  getColumnSettings,
  getReportFieldNames,
} from './settings/column-settings';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectActiveList,
  makeSelectListLoading,
  makeSelectColorFilter,
  makeSelectAllDriversList,
  makeSelectUnassignedLoads,
  makeSelectTeamFilterValue,
  makeSelectShowUnassignedLoads,
  makeSelectAllDriversPagination,
} from './selectors';
import {
  getItemListRequest,
  toggleUnassignedLoads,
  deleteReservationRequest,
  getAllDriversListRequest,
  getUnassignedLoadsRequest,
  createOrUpdateDriverNoteRequest,
  createOrUpdateReservationRequest,
  patchUpdateAvailableDriverRequest,
  getDriverUnavailablePeriodRequest,
  updateAvailableDriversColorRequest,
  assignDriverOnAvailableDriverRequest,
} from './actions';
//////////////////////////////////////////////////

const PICKER_COLOR_TO_ROW_RGB_MAP = {
  [pickerColorRed]: 'rgb(255,236,234)',
  [pickerColorGrey]: 'rgb(255,255,255)',
  [pickerColorBlue]: 'rgb(231,239,249)',
  [pickerColorGreen]: 'rgb(232,247,229)',
  [pickerColorYellow]: 'rgb(253,246,226)',
};

const enhance = compose(
  withFixedPopover,
  withSetExpandedContainerOptions,
  withHandlers({
    handleOpenDriverProfile: (props: Object) => (driverGuid: string) => {
      const { openDriverProfile, getDriverUnavailablePeriodRequest } = props;

      getDriverUnavailablePeriodRequest({
        driverGuid,
        callback: (unavailablePeriods: Array) => openDriverProfile({
          unavailablePeriods,
          driverProfileGuid: driverGuid,
        }),
      });
    },
    handleCreateOrUpdateReservation: (props: Object) => ({ guid, truck, reservationEndDate }: Object) => {
      const { openModal, createOrUpdateReservationRequest } = props;

      const reservationDate = G.ifElse(
        G.isNotNilAndNotEmpty(reservationEndDate),
        G.convertDateTimeToConfigTimeZone(reservationEndDate),
        null,
      );

      const component = (
        <ReservationForm
          driverGuid={guid}
          reservationEndDate={reservationDate}
          submitAction={createOrUpdateReservationRequest}
        />
      );

      const title = G.getWindowLocale(...G.ifElse(
        G.isNotNilAndNotEmpty(reservationEndDate),
        ['actions:edit-truck-reservation', 'Edit Truck Reservation'],
        ['titles:reserve-truck', 'Reserve Truck'],
      ));

      const modal = {
        p: 15,
        component,
        options: {
          width: 300,
          height: 'max-content',
          title: `${title} (${R.pathOr('', [GC.FIELD_FLEET_TRUCK_UNIT_ID], truck)})`,
        },
      };

      openModal(modal);
    },
    handleDeleteReservation: (props: Object) => ({ guid, truck }: Object) => {
      const { openModal, deleteReservationRequest } = props;

      const component = (
        <NoteForm
          isRequired={false}
          submitAction={({ text }: Object) => {
            const data = {
              note: text,
              driverGuid: guid,
            };

            deleteReservationRequest(data);
          }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'max-content',
          height: 'max-content',
          title: `${G.getWindowLocale('actions:delete-truck-reservation', 'Delete Truck Reservation')} (${
            R.pathOr('', [GC.FIELD_FLEET_TRUCK_UNIT_ID], truck)})`,
        },
      };

      openModal(modal);
    },
    handleOpenNotes: (props: Object) => ({ currentTarget }: Object, { guid }: Object) => {
      const {
        openModal,
        closeModal,
        openFixedPopup,
        closeFixedPopup,
        handleClickEditIcon,
      } = props;

      const contentProps = {
        openModal,
        closeModal,
        width: 350,
        height: 400,
        padding: '8px',
        driverGuid: guid,
        handleClickEditIcon,
        addPermissions: null,
        editPermissions: null,
        closeContent: closeFixedPopup,
        asyncOptions: { params: { driverGuid: guid }},
        asyncEndpoint: endpointsMap.availableDriverNoteList,
        addMessageText: G.getWindowLocale('actions:post', 'Post'),
      };

      const content = <Notes {...contentProps} />;

      openFixedPopup({
        content,
        version: 2,
        position: 'right',
        el: currentTarget,
        shouldNotCloseOnScroll: true,
      });
    },
    handlePatchUpdateNote: (props: Object) => (note: Object, { guid }: Object) => {
      const { createOrUpdateDriverNoteRequest } = props;

      const data = {
        ...note,
        driverGuid: guid,
        isCreate: G.isNilOrEmpty(G.getGuidFromObject(note)),
      };

      createOrUpdateDriverNoteRequest(data);
    },
    handlePatchUpdateComments: (props: Object) => (data: Object, newComments: string) => {
      const { comments, driverAvailabilityInfoGuid: guid } = data;

      if (R.equals(comments, newComments)) return;

      const { patchUpdateAvailableDriverRequest } = props;

      patchUpdateAvailableDriverRequest({ guid, data: { [GC.FIELD_COMMENTS]: newComments } });
    },
    handleOpenExpandedContainer: (props: Object) => ({ visitPageGuid, componentType }: Object) => {
      const { closeFixedPopup, handleSetExpandedContainerOptions } = props;

      G.callFunction(closeFixedPopup);

      handleSetExpandedContainerOptions({
        visitPageGuid,
        componentType,
        openContainerAsNewWindow: true,
      });
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.setDriverProfileSimpleInitialState();
    },
  }),
  pure,
);

const renderRowActions = ({ data, props }: Object) => (
  <AuthWrapper has={[PC.AVAILABLE_DRIVER_LIST_WRITE]}>
    <RowActions {...props} data={data} />
  </AuthWrapper>
);

const getFilteredItemList = (list: Object, colorFilter: string, teamFilterValue: boolean) => {
  if (G.isNilOrEmpty(list)) return [];

  let filteredList = R.values(list);

  if (G.isFalse(teamFilterValue)) {
    filteredList = R.reject(R.prop(GC.FIELD_PRIMARY_DRIVER_GUID), filteredList);
  }

  if (G.isNotNilAndNotEmpty(colorFilter)) {
    filteredList = R.filter(({ availableDriversColor }: Object) => R.or(
      R.equals(colorFilter, availableDriversColor),
      R.and(R.isNil(availableDriversColor), R.equals(colorFilter, pickerColorGrey)),
    ), filteredList);
  }

  return filteredList;
};

const AvailableDriverList = (props: Object) => {
  const {
    loading,
    totalCount,
    activeList,
    colorFilter,
    allDriversList,
    teamFilterValue,
    showUnassignedLoads,
    availableDriversList,
    allDriversPagination,
    getAllDriversListRequest,
  } = props;

  const isAllDriversList = R.equals(activeList, 'allDrivers');

  const itemList = getFilteredItemList(
    G.ifElse(isAllDriversList, allDriversList, availableDriversList),
    colorFilter,
    teamFilterValue,
  );

  const columnSettings = getColumnSettings(props);
  const defaultReportFields = getReportFieldNames(isAllDriversList);

  const {
    uiReportFields,
    editViewBtnComponent,
  } = useEditViewHook({
    ...props,
    report,
    columnSettings,
    defaultReportFields,
    disableFieldHandler: (name: string) =>
      R.and(G.isTrue(isAllDriversList), R.equals(name, GC.FIELD_DISTANCE)),
  });

  const sortedReport = sortReport(isAllDriversList, uiReportFields, report);

  const data = {
    loading,
    itemList,
    totalCount,
    columnSettings,
    report: sortedReport,
    tableSettings: getTableSettings(isAllDriversList),
    pagination: G.ifElse(isAllDriversList, allDriversPagination, null),
    renderRightStickedComponent: (data: Object) => renderRowActions({ data, props }),
    handleLoadMoreEntities: G.ifElse(isAllDriversList, getAllDriversListRequest, null),
    rowBgFunction: (_: any, { availableDriversColor }: Object) =>
      G.getPropFromObject(R.or(availableDriversColor, pickerColorGrey), PICKER_COLOR_TO_ROW_RGB_MAP),
  };

  return (
    <ListWrapper p={15} zi='1'>
      <ZOrderWrapper zIndex='2'>
        <Header {...props} editViewBtnComponent={editViewBtnComponent} />
      </ZOrderWrapper>
      {
        G.isNotNilAndNotEmpty(itemList) &&
        <ZOrderWrapper zIndex='1'>
          <Table {...data} />
        </ZOrderWrapper>
      }
      {
        G.isNilOrEmpty(itemList) &&
        <EmptyList>
          {G.getWindowLocale('titles:no-drivers-available', 'No drivers available')}
        </EmptyList>
      }
      {
        G.isTrue(showUnassignedLoads) &&
        <Box
          top={0}
          right={0}
          height='100%'
          zIndex={1101}
          position='fixed'
          bg={G.getTheme('colors.white')}
          boxShadow='0 2px 40px 0 rgba(0, 0, 0, 0.2)'
        >
          <UnassignedLoadsList {...props} />
        </Box>
      }
      <DriverProfileSimple
        isLocationRequired={true}
        hidePendingActivities={true}
        showExpeditedDivision={true}
        showDriverAvailability={true}
        shouldIgnoreSavedProfile={true}
        showTruckAdditionalEquipment={true}
      />
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loading: makeSelectListLoading(state),
  activeList: makeSelectActiveList(state),
  totalCount: makeSelectTotalCount(state),
  colorFilter: makeSelectColorFilter(state),
  userName: makeSelectCurrentUserName(state),
  availableDriversList: makeSelectItemList(state),
  allDriversList: makeSelectAllDriversList(state),
  unassignedLoads: makeSelectUnassignedLoads(state),
  teamFilterValue: makeSelectTeamFilterValue(state),
  showUnassignedLoads: makeSelectShowUnassignedLoads(state),
  allDriversPagination: makeSelectAllDriversPagination(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openDriverProfile,
  getItemListRequest,
  toggleUnassignedLoads,
  deleteReservationRequest,
  getAllDriversListRequest,
  getUnassignedLoadsRequest,
  createOrUpdateDriverNoteRequest,
  createOrUpdateReservationRequest,
  patchUpdateAvailableDriverRequest,
  getDriverUnavailablePeriodRequest,
  updateAvailableDriversColorRequest,
  setDriverProfileSimpleInitialState,
  assignDriverOnAvailableDriverRequest,
})(enhance(AvailableDriverList));
