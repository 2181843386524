import * as R from 'ramda';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
// components
import { ConfirmComponent } from '../../../../components/confirm';
// features
import { AuthWrapper } from '../../../permission';
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncDocumentPreviewDrawer } from '../../../../components/document-preview/preview-drawer';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StyledLink, MainActionButton, scrollableContainerCss4px } from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature claim-management
import DocumentForm from '../forms/document';
import { makeSelectDocuments } from '../../selectors';
import { usePreviewOrDownloadDocument } from '../../hooks';
import { removeDocumentRequest, getDocumentListRequest, createOrUpdateDocumentRequest } from '../../actions';
//////////////////////////////////////////////////

const textStyles = {
  fontSize: 12,
  color: 'darkGrey',
  lineHeight: '18px',
  wordBreak: 'break-all',
};

const hoverStyles = css`
  &:hover {
    text-decoration: underline;
    color: ${R.path('theme', 'colors', 'dark', 'blue')};
  }
`;

const DocumentName = ({ url, action, documentFilename }: Object) => {
  if (R.isNotNil(documentFilename)) {
    const documentNameProps = G.ifElse(
      G.hasAmousCurrentUserPermissions(PC.CLAIM_WRITE),
      {
        ...textStyles,
        onClick: action,
        cursor: 'pointer',
        color: 'light.blue',
        activeStyles: hoverStyles,
      },
      textStyles,
    );

    return (
      <Box {...documentNameProps} css={documentNameProps.activeStyles}>
        {documentFilename}
      </Box>
    );
  }

  return (
    <StyledLink
      href={url}
      fontSize={12}
      target='blank'
      lineHeight='18px'
      css={hoverStyles}
      color='light.blue'
      wordBreak='break-all'
    >
      {url}
    </StyledLink>
  );
};


const DocumentsCard = (props: Object) => {
  const { dispatch, openModal, claimGuid, openLoader, closeLoader, claimBranchGuid, handleOpenFilePreview } = props;

  const documents = useSelector(makeSelectDocuments());

  useEffect(() => {
    if (R.isNil(documents)) dispatch(getDocumentListRequest());
  }, []);

  const handleCreateOrUpdateDocument = useCallback((entity: Object) => {
    let initialValues = { [GC.FIELD_PRIMARY_OBJECT_GUID]: claimGuid };

    if (R.isNotNil(entity)) {
      initialValues = {
        ...entity,
        [GC.FIELD_DOCUMENT_UPLOAD]: R.pathOr(null, [GC.FIELD_DOCUMENT_FILE_NAME], entity),
        [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: R.pathOr(
          null,
          [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
          entity,
        ),
      };
    }

    const component = (
      <DocumentForm
        initialValues={initialValues}
        claimBranchGuid={claimBranchGuid}
        submitAction={(values: Object) => dispatch(createOrUpdateDocumentRequest(values))}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        title: G.ifElse(
          R.isNil(R.prop(GC.FIELD_VERSION, entity)),
          G.getAddTitle,
          G.getEditTitle,
        )(['titles:document', 'Document']),
      },
    };

    openModal(modal);
  }, []);

  const handlePreviewOrDownloadDocument = usePreviewOrDownloadDocument({
    openLoader,
    closeLoader,
    [GC.FIELD_PRIMARY_OBJECT_GUID]: claimGuid,
  });

  const handleRemoveDocument = useCallback((guid: string) => {
    const textLocale = G.getWindowLocale(
      'messages:documents:remove-confirmation-text',
      'Are you sure you want to delete this document?',
    );

    const component = <ConfirmComponent textLocale={textLocale} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => dispatch(removeDocumentRequest(guid)),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    openModal(modal);
  }, []);

  const iconColor = G.getTheme('colors.darkGrey');
  const byTxtLocale = G.getWindowLocale('titles:by', 'by');
  const addedTxtLocale = G.getWindowLocale('titles:added', 'Added');

  return (
    <Box
      bg='white'
      height={400}
      borderRadius='8px'
      border='1px solid'
      borderColor='#E0E0E0'
    >
      <Flex
        px={15}
        height={45}
        fontSize={16}
        bg='steelBlue02'
        fontWeight='bold'
        color='greyMatterhorn'
        borderTopLeftRadius='8px'
        borderTopRightRadius='8px'
        justifyContent='space-between'
      >
        {G.getWindowLocale('titles:documents', 'Documents', { caseAction: 'upperCase' })}
        <AuthWrapper has={[PC.CLAIM_WRITE]}>
          <MainActionButton
            bg='white'
            height={28}
            onClick={() => handleCreateOrUpdateDocument()}
          >
            {G.getAddTitle(['titles:document', 'Document'])}
          </MainActionButton>
        </AuthWrapper>
      </Flex>
      <Box
        p='15px'
        overflowY='auto'
        height='calc(100% - 45px)'
        css={scrollableContainerCss4px}
      >
        {R.or(documents, []).map((item: Object, index: number) => {
          const { url, guid, createdBy, createdDate, documentFilename } = item;

          return (
            <Flex
              mb={15}
              key={index}
              alignItems='flex-start'
              justifyContent='space-between'
            >
              <Flex width='calc(100% - 100px)'>
                <Box mr={10} height={20}>
                  {G.getDocumentIconByDocumentFileName(documentFilename)}
                </Box>
                <Box width='calc(100% - 30px)'>
                  <DocumentName
                    url={url}
                    documentFilename={documentFilename}
                    action={() => handleOpenFilePreview({ ...item, endpoint: endpointsMap.claimDocumentDownloadFile })}
                  />
                  <Box {...textStyles} mt='5px'>
                    {R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], item)}
                  </Box>
                  <Box {...textStyles} mt='5px'>
                    {addedTxtLocale} {G.convertDateTimeToConfigFormat(createdDate)} {byTxtLocale} {createdBy}
                  </Box>
                </Box>
              </Flex>
              <AuthWrapper has={[PC.CLAIM_WRITE]}>
                {
                  G.isNilOrEmpty(url) &&
                  <Box
                    mr={10}
                    cursor='pointer'
                    onClick={() => handlePreviewOrDownloadDocument(item)}
                  >
                    {I.downloadDocument(iconColor)}
                  </Box>
                }
                <Box mr={10} cursor='pointer' onClick={() => handleCreateOrUpdateDocument(item)}>
                  {I.pencil(iconColor)}
                </Box>
                <Box cursor='pointer' onClick={() => handleRemoveDocument(guid)}>
                  {I.trash(iconColor)}
                </Box>
              </AuthWrapper>
            </Flex>
          );
        })}
      </Box>
    </Box>
  );
};

export default withAsyncDocumentPreviewDrawer(DocumentsCard);
