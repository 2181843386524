import * as R from 'ramda';
import * as Yup from 'yup';
import moment from 'moment';
import React, { Fragment } from 'react';
// components
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { vendorPayrollAccessorialPayForUnitTypeOptions } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, RelativeBox } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import VendorMonthlyPayrollDeductionForm from '../vendor/forms/payroll-compensation';
import { FormGroupTableWithSimpleCrudOperations } from '../components/form-group-table';
//////////////////////////////////////////////////

const calendarInputWrapperStyles = {
  width: '100%',
};

const customChangeHandler = (event: Object, _: any, props: Object) => {
  const { values, setValues } = props;

  const { id, value } = event.target;

  let valuesToSet = R.assoc(id, value, values);

  const amount = R.prop(GC.FIELD_AMOUNT, valuesToSet);
  const totalAmount = R.prop(GC.FIELD_TOTAL_AMOUNT, valuesToSet);
  const dateFrom = R.prop(GC.FIELD_DATE_FROM, valuesToSet);
  const dateTo = R.prop(GC.FIELD_DATE_TO, valuesToSet);

  const cond1 = G.isFalse(R.prop(GC.FIELD_LIMITED, values));

  const cond2 = G.isAllTrue(
    R.includes(id, [GC.FIELD_AMOUNT, GC.FIELD_TOTAL_AMOUNT, GC.FIELD_DATE_FROM]),
    G.notEquals(id, GC.FIELD_DATE_TO),
    G.isAllNotNilOrNotEmpty([amount, totalAmount, dateFrom]),
  );

  const cond3 = G.isAllTrue(
    R.includes(id, [GC.FIELD_TOTAL_AMOUNT, GC.FIELD_DATE_FROM, GC.FIELD_DATE_TO]),
    G.notEquals(id, GC.FIELD_AMOUNT),
    G.isAllNotNilOrNotEmpty([totalAmount, dateFrom, dateTo]),
  );

  if (cond1) {
    valuesToSet = R.mergeRight(valuesToSet, {
      [GC.FIELD_DATE_TO]: null,
      [GC.FIELD_TOTAL_AMOUNT]: null,
      [GC.FIELD_HISTORICAL_PAID_AMOUNT]: null,
    });
  } else if (cond2) {
    const count = Math.ceil(R.divide(totalAmount, amount));
    const dateTo = moment(dateFrom).add(count, 'months').format(GC.DEFAULT_DATE_FORMAT);

    valuesToSet = R.assoc(GC.FIELD_DATE_TO, dateTo, valuesToSet);
  } else if (cond3) {
    const count = Math.ceil(Math.abs(
      moment(dateTo).endOf('month').diff(moment(dateFrom).startOf('month'), 'months', true),
    ));

    const amount = R.divide(totalAmount, count);

    valuesToSet = R.assoc(GC.FIELD_AMOUNT, G.mathRoundNumber(amount), valuesToSet);
  }

  setValues(valuesToSet);
};

const customChangeHandlerForDatePickers = ({ value, props }: Object) =>
  customChangeHandler(
    {
      target: {
        [GC.FIELD_ID]: G.getIdFromObject(props),
        [GC.FIELD_VALUE]: moment(value).format(GC.DEFAULT_DATE_FORMAT),
      },
    },
    '',
    props,
  );

const settings = {
  [GC.FIELD_PAYROLL_ASSESSORIAL_GUID]: {
    width: 200,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:accessorial2',
    options: 'accessorialConfigOptions',
    additionalInputWrapperStyles: { zIndex: 12 },
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { payrollAssessorialGuid } = data;

      return R.path(
        ['accessorialConfigs', 'accessorialConfigList', payrollAssessorialGuid, GC.FIELD_DISPLAYED_VALUE],
        callbackData,
      );
    },
  },
  [GC.FIELD_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:accessorial2',
    customComponent: ({ data = {}, callbackData = {} }: Object) => {
      const { payrollAssessorialGuid } = data;

      return R.path(
        ['accessorialConfigs', 'accessorialConfigList', payrollAssessorialGuid, GC.FIELD_DISPLAYED_VALUE],
        callbackData,
      );
    },
  },
  [GC.FIELD_AMOUNT]: {
    width: 100,
    type: 'number',
    isRequired: true,
    customChangeHandler,
    name: 'titles:amount',
    shouldCustomChange: true,
  },
  [GC.FIELD_TOTAL_AMOUNT]: {
    width: 100,
    type: 'number',
    isRequired: true,
    customChangeHandler,
    shouldCustomChange: true,
    name: 'titles:total-amount',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        R.pathEq(true, ['values', GC.FIELD_LIMITED], props),
        'flex',
        'none',
      ),
    },
  },
  [GC.FIELD_PAID_AMOUNT]: {
    width: 100,
    disabled: true,
    name: 'titles:paid-amount',
    additionalInputWrapperStyles: {
      width: 220,
      display: ({ values }: Object) => G.ifElse(
        R.isNil(G.getGuidFromObject(values)),
        'none',
      ),
    },
  },
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: {
    width: 150,
    type: 'number',
    customChangeHandler,
    shouldCustomChange: true,
    name: 'titles:historical-paid-amount',
    additionalInputWrapperStyles: {
      display: (props: Object) => G.ifElse(
        R.pathEq(true, ['values', GC.FIELD_LIMITED], props),
        'flex',
        'none',
      ),
    },
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    type: 'select',
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_DATE_FROM]: {
    width: 150,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:date-from',
    shouldCustomChange: true,
    calendarInputWrapperStyles,
    customChangeHandler2: customChangeHandlerForDatePickers,
  },
  [GC.FIELD_DATE_TO]: {
    width: 150,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:date-to',
    shouldCustomChange: true,
    calendarInputWrapperStyles,
    customChangeHandler2: customChangeHandlerForDatePickers,
    additionalInputWrapperStyles: {
      visibility: (props: Object) => G.ifElse(
        R.pathEq(true, ['values', GC.FIELD_LIMITED], props),
        'visible',
        'hidden',
      ),
    },
  },
  [GC.FIELD_PAY_FOR_UNIT]: {
    type: 'enum',
    fieldType: 'select',
    shouldCustomChange: true,
    name: 'titles:pay-for-unit',
    filterType: 'selectMultiple',
    options: vendorPayrollAccessorialPayForUnitTypeOptions,
    defaultValue: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_NONE,
    customChangeHandler: (event: Object, _: any, props: Object) => {
      const { values, setValues } = props;

      const newValues = R.mergeRight(
        values,
        {
          [GC.FIELD_TRUCK_GUIDS]: null,
          [GC.FIELD_DRIVER_GUIDS]: null,
          [GC.FIELD_PAY_FOR_UNIT]: R.path(['currentTarget', GC.FIELD_VALUE], event),
        },
      );

      setValues(newValues);
    },
  },
  [GC.FIELD_LIMITED]: {
    width: 100,
    type: 'boolean',
    defaultValue: true,
    fieldType: 'toggle',
    name: 'titles:limited',
  },
  [GC.FIELD_DEDUCTION]: {
    width: 100,
    type: 'boolean',
    fieldType: 'toggle',
    defaultValue: false,
    name: 'titles:deduction',
    additionalInputWrapperStyles: { width: 'max-content' },
    disabled: (_: any, { deductionDisabled = false }: Object) => deductionDisabled,
  },
  [GC.FIELD_ACTIVE]: {
    width: 100,
    type: 'boolean',
    fieldType: 'toggle',
    defaultValue: false,
    name: 'titles:active',
    additionalInputWrapperStyles: { width: 'max-content' },
  },
  [GC.FIELD_FULLY_PAID]: {
    width: 100,
    type: 'boolean',
    hasLabel: false,
    name: 'titles:fully-paid',
    fieldType: 'customComponent',
    additionalInputWrapperStyles: {
      width: 'max-content',
      display: (props: Object) => G.ifElse(
        G.isNotNil(R.path(['values', GC.FIELD_FULLY_PAID], props)),
        'flex',
        'none',
      ),
    },
    Component: ({ value }: Object) => {
      if (G.isNilOrEmpty(value)) return null;

      return (
        <Fragment>
          <Box mr={20}>{G.getWindowLocale('titles:fully-paid', 'Fully Paid')}</Box>
          {
            I[G.ifElse(G.isTrue(value), 'uiTrue', 'uiFalse')]()
          }
        </Fragment>
      );
    },
  },
  [GC.FIELD_COMMENTS]: {
    width: 300,
    type: 'textarea',
    name: 'titles:comments',
    additionalInputWrapperStyles: { width: 600 },
  },
  [GC.FIELD_TRUCK_GUIDS]: {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:trucks',
    options: 'truckOptions',
    useMenuPortalTarget: true,
    additionalInputWrapperStyles: {
      zIndex: 11,
      visibility: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'visible',
        'hidden',
      ),
      display: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'none',
      ),
    },
  },
  [GC.FIELD_DRIVER_GUIDS]: {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:drivers',
    options: 'driverOptions',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_DRIVER_GUIDS,
    additionalInputWrapperStyles: {
      zIndex: 11,
      visibility: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'visible',
        'hidden',
      ),
      display: (props: Object) => G.ifElse(
        R.pathEq(
          GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
          ['values', GC.FIELD_PAY_FOR_UNIT],
          props,
        ),
        'flex',
        'none',
      ),
    },
  },
};

const totalsValidation = Yup.number()
  .nullable(true)
  .required(G.getRequiredLocaleTxt())
  .typeError(G.getShouldBeIntegerLocaleTxt())
  .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
  .max(9999999, G.getShouldBeFromToCharLocaleTxt(0, 999999));

const validationSchema = Yup.lazy(({ limited, payForUnit }: Object) => Yup.object().shape({
  [GC.FIELD_AMOUNT]: totalsValidation,
  [GC.FIELD_CURRENCY]: G.yupStringRequired,
  [GC.FIELD_DATE_FROM]: G.yupStringRequired,
  [GC.FIELD_COMMENTS]: G.yupStringNotRequired,
  [GC.FIELD_FULLY_PAID]: Yup.boolean().nullable(true),
  [GC.FIELD_PAYROLL_ASSESSORIAL_GUID]: G.yupStringRequired,
  [GC.FIELD_HISTORICAL_PAID_AMOUNT]: Yup.number().nullable(true),
  [GC.FIELD_DATE_TO]: G.ifElse(G.isTrue(limited), G.yupStringRequired, G.yupStringNotRequired),
  [GC.FIELD_TOTAL_AMOUNT]: G.ifElse(G.isTrue(limited), totalsValidation, G.yupStringNotRequired),
  [GC.FIELD_DRIVER_GUIDS]: G.ifElse(
    R.equals(payForUnit, GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS),
    G.yupArrayRequired,
  ),
  [GC.FIELD_TRUCK_GUIDS]: G.ifElse(
    R.equals(payForUnit, GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS),
    G.yupArrayRequired,
  ),
}));

const defaultInputWrapperStyles = {
  mb: 25,
  width: 280,
};

const makeReportFields = (fieldsToOmit: Array) => R.compose(
  G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
  R.keys,
  R.omit(fieldsToOmit),
)(settings);

const report = {
  fields: makeReportFields([
    GC.FIELD_TRUCK_GUIDS,
    GC.FIELD_DRIVER_GUIDS,
    GC.FIELD_PAY_FOR_UNIT,
    GC.FIELD_PAYROLL_ASSESSORIAL_GUID,
  ]),
};

const vendorReport = {
  fields: makeReportFields([
    GC.FIELD_TRUCK_GUIDS,
    GC.FIELD_DRIVER_GUIDS,
    GC.FIELD_PAYROLL_ASSESSORIAL_GUID,
  ]),
};

const defaultValues = R.map(({ defaultValue = null }: Object) => defaultValue, settings);

const makeFieldSettings = (fields: Array) => R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    options,
    isMulti,
    disabled,
    fieldType,
    Component,
    type = 'text',
    hasLabel = true,
    shouldCustomChange,
    isRequired = false,
    useMenuPortalTarget,
    customChangeHandler,
    customChangeHandler2,
    calendarInputWrapperStyles,
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    type,
    isMulti,
    options,
    disabled,
    fieldName,
    Component,
    isRequired,
    shouldCustomChange,
    useMenuPortalTarget,
    customChangeHandler,
    customChangeHandler2,
    calendarInputWrapperStyles,
    [GC.FIELD_TYPE]: R.or(fieldType, type),
    label: G.ifElse(hasLabel, R.of(Array, name)),
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  })),
  R.pick(fields),
)(settings);

const fieldSettings = makeFieldSettings([
  GC.FIELD_PAYROLL_ASSESSORIAL_GUID,
  GC.FIELD_CURRENCY,
  GC.FIELD_AMOUNT,
  GC.FIELD_LIMITED,
  GC.FIELD_TOTAL_AMOUNT,
  GC.FIELD_HISTORICAL_PAID_AMOUNT,
  GC.FIELD_DATE_FROM,
  GC.FIELD_DATE_TO,
  GC.FIELD_DEDUCTION,
  GC.FIELD_ACTIVE,
  GC.FIELD_PAID_AMOUNT,
  GC.FIELD_FULLY_PAID,
  GC.FIELD_COMMENTS,
]);

const vendorFieldSettings = makeFieldSettings([
  GC.FIELD_PAYROLL_ASSESSORIAL_GUID,
  GC.FIELD_CURRENCY,
  GC.FIELD_AMOUNT,
  GC.FIELD_LIMITED,
  GC.FIELD_TOTAL_AMOUNT,
  GC.FIELD_HISTORICAL_PAID_AMOUNT,
  GC.FIELD_DATE_FROM,
  GC.FIELD_DATE_TO,
  GC.FIELD_PAY_FOR_UNIT,
  GC.FIELD_TRUCK_GUIDS,
  GC.FIELD_DRIVER_GUIDS,
  GC.FIELD_DEDUCTION,
  GC.FIELD_ACTIVE,
  GC.FIELD_PAID_AMOUNT,
  GC.FIELD_FULLY_PAID,
  GC.FIELD_COMMENTS,
]);

const columnSettings = R.map(R.pick(['type', 'name', 'width', 'customComponent']), settings);

const monthlyPaymentColumnSettings = {
  [GC.FIELD_PAYROLL_NUMBER]: {
    width: 300,
    name: 'titles:payroll-number',
    customComponent: ({ data }: Object) => (
      <ActionBox text={data.payrollNumber} action={() => G.goToRoute(routesMap.driverPayrollList)} />
    ),
  },
  [GC.FIELD_PRICE_SHEET_CURRENCY_TOTAL]: {
    width: 200,
    name: 'titles:total',
    customComponent: ({ data = {}, callbackData }: Object) => {
      const { total, currency, priceSheetCurrencyTotal } = data;

      const parentCurrency = G.getPropFromObject(GC.FIELD_CURRENCY, callbackData);

      const displayText = G.ifElse(
        R.equals(priceSheetCurrencyTotal, total),
        `${priceSheetCurrencyTotal} ${parentCurrency}`,
        `${total} ${parentCurrency} (${priceSheetCurrencyTotal} ${currency})`,
      );

      return displayText;
    },
  },
  [GC.FIELD_PAYMENT_MONTH]: {
    width: 150,
    name: 'titles:payment-month',
  },
  [GC.FIELD_PAYMENT_YEAR]: {
    width: 150,
    name: 'titles:payment-year',
  },
};

const monthlyPaymentReport = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(monthlyPaymentColumnSettings)),
};

const monthlyPaymentSettings = {
  actionsPicker: [],
  report: monthlyPaymentReport,
  tableCallbackDataProps: [GC.FIELD_CURRENCY],
  columnSettings: monthlyPaymentColumnSettings,
  primaryObjectGuidKey: GC.FIELD_MONTHLY_PAYMENT_GUID,
  endpoints: {
    list: 'driverPayrollChargesByMonthlyPaymentGuid',
  },
};

const vendorMonthlyPaymentSettings = {
  ...monthlyPaymentSettings,
  endpoints: {
    list: 'vendorPayrollChargesByMonthlyPaymentGuid',
  },
};

const MonthlyPayrollDetails = ({ data, settings, parentProps, callbackData }: Object) => {
  const primaryObjectGuid = G.getGuidFromObject(parentProps);
  const index = R.findIndex(R.propEq(primaryObjectGuid, GC.FIELD_GUID), R.pathOr([], ['itemList'], callbackData));
  const groupName = `monthlyPayrollDeductionList.${index}.details`;

  return (
    <RelativeBox zIndex={1}>
      <FormGroupTableWithSimpleCrudOperations
        {...monthlyPaymentSettings}
        {...settings}
        {...R.pick(['openLoader', 'closeLoader', 'getItemListSuccess'], callbackData)}
        margin={20}
        itemList={data}
        isOpened={true}
        groupName={groupName}
        hasFormGroupTitle={false}
        primaryObjectGuid={primaryObjectGuid}
        currency={G.getPropFromObject(GC.FIELD_CURRENCY, parentProps)}
      />
    </RelativeBox>
  );
};

const fieldsWrapperStyles = {
  width: 600,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const monthlyPayrollDeductionSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  fieldsWrapperStyles,
  groupName: 'monthlyPayrollDeductionList',
  includeItemListToTablePropsMemoDeps: true,
  itemTitleArr: ['titles:monthly-payroll-deduction', 'Monthly Payroll Deduction'],
  formGroupTitleArr: ['titles:monthly-payroll-deduction', 'Monthly Payroll Deduction'],
  additionalTableSettings: {
    expandableItems: true,
    checkBoxCellWidth: 81,
    expandedDetailsComponent: (props: Object) => <MonthlyPayrollDetails {...props} settings={monthlyPaymentSettings} />,
  },
  endpoints: {
    list: 'monthlyPaymentList',
    createOrUpdate: 'monthlyPayment',
    remove: 'getMonthlyPaymentEndpoint',
  },
  tableCallbackDataProps: [
    'itemList',
    'openLoader',
    'closeLoader',
    'accessorialConfigs',
    'getItemListSuccess',
  ],
  // helpers
  makeOptionsForSelect: R.pathOr({}, ['accessorialConfigs']),
  makeInitialValues: (initialValues: Object, { branchConfigs }: Object) => {
    const branchCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);

    return R.assoc(GC.FIELD_CURRENCY, R.pathOr(branchCurrency, [GC.FIELD_CURRENCY], initialValues), initialValues);
  },
};

export const vendorMonthlyPayrollDeductionSettings = {
  ...monthlyPayrollDeductionSettings,
  report: vendorReport,
  primaryObjectGuidKey: GC.FIELD_FLEET_VENDOR_GUID,
  endpoints: {
    list: 'vendorMonthlyPaymentList',
    createOrUpdate: 'vendorMonthlyPayment',
    remove: 'getVendorMonthlyPaymentEndpoint',
  },
  additionalTableSettings: {
    expandableItems: true,
    checkBoxCellWidth: 81,
    expandedDetailsComponent: (props: Object) =>
      <MonthlyPayrollDetails {...props} settings={vendorMonthlyPaymentSettings} />,
  },
  CustomForm: (props: Object) => (
    <VendorMonthlyPayrollDeductionForm
      {...props}
      validationSchema={validationSchema}
      fieldSettings={vendorFieldSettings}
      fieldsWrapperStyles={{
        ...fieldsWrapperStyles,
        overflow: 'hidden auto',
        maxHeight: 'calc(100vh - 155px)',
      }}
    />
  ),
};
