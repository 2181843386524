import React from 'react';
import { pure, compose, defaultProps } from 'react-recompose';
// components
import { FormGroupTable } from '../../../components/form-group-table';
import { withAsyncDocumentPreviewDrawer } from '../../../components/document-preview/preview-drawer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature branch
import { documentsColumnSettings } from '../settings/branch-document-form-settings';
//////////////////////////////////////////////////

const enhance = compose(
  defaultProps({ getDownloadDocumentEndpoint: () => endpointsMap.branchDocumentDownload }),
  withAsyncDocumentPreviewDrawer,
  pure,
);

const getAdditionalColumnActions = (downloadDocument: Function) => [
  {
    title: G.getWindowLocale('actions:download', 'Download'),
    icon: I.downloadDocument(G.getTheme('colors.darkRed'), 15, 15),
    getVisibility: ({ documentFilename }: Object) => G.ifElse(
      G.isNilOrEmpty(documentFilename),
      'hidden',
      'visible',
    ),
    handleClick: ({ documentFilename, primaryObjectGuid }: Object) => {
      if (G.isNotNilAndNotEmpty(documentFilename)) {
        downloadDocument({
          primaryObjectGuid,
          [GC.FIELD_FILE_NAME]: documentFilename,
        });
      }
    },
  },
];

const DocumentList = enhance((props: Object) => {
  const {
    documents,
    collapsedGroup,
    downloadDocument,
    handleAddDocument,
    handleEditDocument,
    handleDeleteDocument,
    handleOpenFilePreview,
    collapseBranchFormGroup,
  } = props;

  return (
    <FormGroupTable
      entities={documents}
      tableColumnWidth={90}
      fields={collapsedGroup}
      groupName='editDocuments'
      handleAddClick={handleAddDocument}
      handleEditRow={handleEditDocument}
      handleDeleteRow={handleDeleteDocument}
      entitiesFields={documentsColumnSettings}
      handlePreviewDocument={handleOpenFilePreview}
      isOpened={G.getPropFromObject('editDocuments', collapsedGroup)}
      panelTitle={G.getWindowLocale('titles:documents', 'Documents')}
      handleToggleFormGroup={() => collapseBranchFormGroup('editDocuments')}
      additionalColumnActions={getAdditionalColumnActions(downloadDocument)}
    />
  );
});

export default DocumentList;
