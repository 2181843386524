import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
// components
import { TabsMui } from '../../../../components/tabs-mui';
// features
import Audit2 from '../../../audit2';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, MainActionButton } from '../../../../ui';
// feature claim-management
import CloItems from './clo-items';
import AccidentDetails from './accident';
import RelatedTels from './related-tels';
import { Attorney, InvolvedParty } from './involved-party';
import { AttorneyForm, InvolvedPartyForm } from '../forms/involved-party';
import { createOrUpdateAttorneyRequest, createOrUpdateInvolvedPartyRequest } from '../../actions';
//////////////////////////////////////////////////

const TabsGroup = (props: Object) => {
  const { dispatch, openModal, claimGuid, claimBranchGuid } = props;

  const [activeTab, setActiveTab] = useState(0);

  const itemNameMap = {
    3: ['titles:involved-party', 'Involved Party'],
    4: ['titles:attorney', 'Attorney'],
  };

  const handleCreateOrUpdateItem = useCallback((entity: Object) => {
    const formsMap = {
      3: InvolvedPartyForm,
      4: AttorneyForm,
    };

    const submitActionMap = {
      3: createOrUpdateInvolvedPartyRequest,
      4: createOrUpdateAttorneyRequest,
    };

    const Form = R.prop(activeTab, formsMap);
    const submitAction = R.prop(activeTab, submitActionMap);

    const component = (
      <Form
        claimBranchGuid={claimBranchGuid}
        initialValues={R.or(entity, { claimGuid })}
        submitAction={(values: Object) => dispatch(submitAction(values))}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        title: G.ifElse(
          R.isNil(R.prop(GC.FIELD_VERSION, entity)),
          G.getAddTitle,
          G.getEditTitle,
        )(R.prop(activeTab, itemNameMap)),
      },
    };

    openModal(modal);
  }, [activeTab]);

  const tabs = [
    {
      component: <RelatedTels {...props} />,
      text: G.getWindowLocale('titles:related-trips', 'Related Trips'),
    },
    {
      component: <CloItems {...props} />,
      text: G.getWindowLocale('titles:items', 'Items'),
    },
    {
      component: <AccidentDetails {...props} />,
      text: G.getWindowLocale('titles:accident-details', 'Accident Details'),
    },
    {
      text: G.getWindowLocale('titles:involved-party', 'Involved Party'),
      component: <InvolvedParty {...props} handleCreateOrUpdateItem={handleCreateOrUpdateItem} />,
    },
    {
      text: G.getWindowLocale('titles:attorneys', 'Attorneys'),
      component: <Attorney {...props} handleCreateOrUpdateItem={handleCreateOrUpdateItem} />,
    },
    {
      text: G.getWindowLocale('titles:audit', 'Audit'),
      component: (
        <Audit2
          maxHeight={600}
          useFullScreen={true}
          requestOptions={{
            [GC.FIELD_OBJECT_GUID]: claimGuid,
            [GC.FIELD_TYPE]: GC.AUDIT_TYPE_CLAIM,
          }}
        />
      ),
    },
  ];

  return (
    <Box mt={25}>
      <Flex flexWrap='wrap-reverse' justifyContent='space-between'>
        <TabsMui
          tabs={tabs}
          activeMuiTab={activeTab}
          setActiveMuiTab={setActiveTab}
        />
        {
          R.includes(activeTab, [3, 4]) &&
          <MainActionButton onClick={() => handleCreateOrUpdateItem()}>
            {G.getAddTitle(R.prop(activeTab, itemNameMap))}
          </MainActionButton>
        }
      </Flex>
      {R.path([activeTab, 'component'], tabs)}
    </Box>
  );
};

export default TabsGroup;
