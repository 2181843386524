import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// features
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectIsCurrentUserSuperAdmin } from '../../auth/selectors';
// components
import { TextComponent } from '../../../components/text';
import { openModal, closeModal } from '../../../components/modal/actions';
import { LocationFormWithSearch } from '../../../forms/forms/location-form-with-search';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Info } from '../../../forms';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature branch
import AddBranchForm from './add-branch-form';
import { ROOT_BRANCH_GUID } from '../constants';
import BranchTreeComponent from './branch-tree';
import { createNewBranchRequest } from '../actions';
import { addMasterTypeToOptions } from '../helpers';
import { getBranchFormikFields } from '../settings/formik-settings';
import { makeSelectBranchesTree, makeSelectCurrentBranchGuid } from '../selectors';
//////////////////////////////////////////////////

const getBranchByGuid = (tree: Object, guid: string) => {
  if (R.equals(tree.guid, guid)) {
    return {
      guid,
      type: R.prop(GC.BRANCH_TYPE, tree),
      name: R.prop(GC.FIELD_BRANCH_NAME, tree),
    };
  } else if (G.isNotNilAndNotEmpty(tree.children)) {
    let i;
    let result = null;

    for (i = 0; result == null && i < tree.children.length; i++) {
      result = getBranchByGuid(tree.children[i], guid);
    }

    return result;
  }

  return null;
};

const enhance = compose(
  withState('billTo', 'setBillTo', null),
  withState('locationTypeOptions', 'setLocationTypeOptions', []),
  withState('parent', 'setParent', (props: Object) => {
    const { tree, parentGuid, currentBranchGuid } = props;

    const defaultParentGuid = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_PARENT_FOR_CUSTOMER);

    const guid = defaultParentGuid || parentGuid || currentBranchGuid;

    return getBranchByGuid(tree, guid);
  }),
  withHandlers({
    handleSelectBranch: (props: Object) => (guid: string) => {
      if (R.isEmpty(guid)) return;

      const { tree, setParent, closeModal } = props;

      const parent = getBranchByGuid(tree, guid);

      setParent(parent);
      closeModal();
    },
  }),
  withHandlers({
    handleOpenSelectBranch: ({ openModal, closeModal, handleSelectBranch }: Object) => () => {
      const modal = {
        p: '0px',
        component: (
          <BranchTreeComponent
            width={500}
            cursor='pointer'
            singleSelect={true}
            closeAction={closeModal}
            onClickBranch={handleSelectBranch}
          />
        ),
        options: {
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:select-division', 'Select Division'),
        },
      };

      openModal(modal);
    },
    handleSubmitCreateBranch: ({ billTo, parent, callbackAction, createNewBranchRequest }: Object) =>
      (values: any) => {
        const data = R.compose(
          R.assoc('billTo', billTo),
          R.assoc('parentGuid', R.path([GC.FIELD_GUID], parent)),
        )(values);

        createNewBranchRequest({ data, callbackAction });
      },
    handleClickCancel: ({ closeModal }: Object) => () => closeModal(),
  }),
  withHandlers({
    handleAddLocation: ({ setBillTo, closeModal }: Object) => (values: Object) => {
      setBillTo(values);
      closeModal();
    },
  }),
  withHandlers({
    handleOpenLocationForm: (props: Object) => () => {
      const {
        billTo,
        openModal,
        closeModal,
        branchGuid,
        handleAddLocation,
        locationTypeOptions,
      } = props;

      const mode = G.ifElse(
        G.isNilOrEmpty(billTo),
        G.getAddTitle,
        G.getEditTitle,
      )(['titles:bill-to', 'Bill To']);

      const modalContent = (
        <LocationFormWithSearch
          mode={mode}
          openModal={openModal}
          initialValues={billTo}
          closeModal={closeModal}
          branchGuid={branchGuid}
          returnFullLocation={true}
          submitAndSaveLocation={false}
          submitHandler={handleAddLocation}
          addBillToFromCreateBranchFrom={true}
          formType={GC.LOCATION_FORM_TYPE_BILL_TO}
          submitBtnText={G.getWindowLocale('titles:add', 'Add')}
          optionsForSelect={{ [GC.FIELD_LOCATION_TYPE]: locationTypeOptions }}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          height: 'auto',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const ParentInfo = ({ data }: Object) => (
  <Flex>
    <Box
      pl='5px'
      color={G.getTheme('colors.darkGrey')}
    >
      {G.getWindowLocale('titles:parent', 'Parent')}:
    </Box>
    <TextComponent
      mx='8px'
      maxWidth={300}
      fontWeight={700}
      withEllipsis={true}
    >
      {R.or(R.prop('name', data), G.getWindowLocale('messages:no-parent', 'No parent'))}
    </TextComponent>
    <Info
      text={G.getWindowLocale(
        'messages:enterprise-parent-info',
        'Used to identify current company relationship to enterprise branch',
      )}
    />
  </Flex>
);

const AddBranch = (props: Object) => {
  const {
    billTo,
    parent,
    branchGuid,
    branchTypes,
    authorities,
    isSuperAdmin,
    callbackAction,
    handleOpenSelectBranch,
    handleOpenLocationForm,
    setLocationTypeOptions,
    handleSubmitCreateBranch,
  } = props;

  const allowDivision = R.and(
    R.not(R.propEq(GC.BRANCH_TYPE_ENUM_CUSTOMER, GC.FIELD_TYPE, parent)),
    G.checkStringsContains(R.of(Array, PC.CREATE_DIVISION_EXECUTE), authorities),
  );

  const fieldsToUse = getBranchFormikFields(allowDivision);

  const allowAddMaster = R.and(isSuperAdmin, R.propEq(ROOT_BRANCH_GUID, GC.FIELD_GUID, parent));

  const addBranchFormProps = {
    branchGuid,
    branchTypes,
    callbackAction,
    handleSubmitCreateBranch,
    handleSetLocationTypeOption: setLocationTypeOptions,
    fields: addMasterTypeToOptions(fieldsToUse, allowAddMaster),
    sequenceConfigName: GC.GENERAL_BRANCH_ACCOUNT_NUMBER_SEQUENCE,
    autogenerateConfigName: GC.GENERAL_BRANCH_ACCOUNT_NUMBER_AUTOGENERATED,
    initialValues: {
      [GC.FIELD_CURRENCY]: G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
    },
    configsNamesArray: [
      GC.GENERAL_BRANCH_SUBTYPE,
      GC.TEMPLATES_LOCATION_TYPE,
      GC.GENERAL_BRANCH_ACCOUNT_NUMBER_SEQUENCE,
      GC.GENERAL_BRANCH_ACCOUNT_NUMBER_AUTOGENERATED,
    ],
  };

  const getTextBillTo = G.ifElse(
    G.isNotNilAndNotEmpty(billTo),
    G.getEditTitle,
    G.getAddTitle,
  );

  return (
    <Box width={550} p='10px 15px 15px 15px'>
      <Flex mb='8px' justifyContent='space-between'>
        <ParentInfo data={parent} />
        <Flex
          cursor='pointer'
          onClick={handleOpenLocationForm}
          color={G.getTheme('common.linkColor')}
        >
          <Box mr='8px'>{I.location(G.getTheme('icons.iconColor'))}</Box>
          {
            getTextBillTo(['titles:bill-to', 'Bill To'])
          }
        </Flex>
        <Flex
          cursor='pointer'
          onClick={handleOpenSelectBranch}
          color={G.getTheme('common.linkColor')}
        >
          <Box mr='8px'>{I.addChild(G.getTheme('icons.iconColor'))}</Box>
          {G.getWindowLocale('actions:change-division', 'Change Division')}
        </Flex>
      </Flex>
      <AddBranchForm {...addBranchFormProps} />
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  tree: makeSelectBranchesTree(state),
  authorities: makeSelectAuthorities(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  isSuperAdmin: makeSelectIsCurrentUserSuperAdmin(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  createNewBranchRequest,
})(enhance(AddBranch));
