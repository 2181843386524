import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, lifecycle } from 'react-recompose';
// features
import { visitWorkOrdersOnFleetPage } from '../../../work-order/actions';
import { visitInspectionsOnFleetPage } from '../../../inspections/actions';
import { visitFleetListOnFleetProfilePage, changeFleetServiceIssueStatusRequest } from '../../../fleet-list/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import { getFleetProfileConfigsRequest } from '../../actions';
import { withDocumentActions } from '../../hocs/with-document-actions';
import { trailerTrackingSettings } from '../settings/trailer-tracking';
import { withHeightProps, withDocumentAdditionalTableSettings } from '../../hocs/with-report-common-props';
import {
  makeSelectTrailerGeneralTabEntities,
  makeSelectTrailerTrackingTabEntities,
  makeSelectTrailerDocumentsTabEntities,
  makeSelectTrailerMaintenanceTabEntities,
  makeSelectTrailerSpecificationsTabEntities,
  makeSelectTrailerLastKnownLocationTabEntities,
} from '../../selectors';
import {
  referenceSettings,
  integrationSettings,
  trailerLocationSettings,
  trailerDocumentSettings,
  trailerOwnershipSettings,
  trailerInsuranceSettings,
  equipmentServiceSettings,
  fleetServiceIssueSettings,
  trailerSpecificationSettings,
  trailerLastKnownLocationSettings,
  trailerEquipmentComponentSettings,
} from '../../settings';
//////////////////////////////////////////////////

const withTabGroupSettings = (groupsSettings: Object) => compose(
  withProps((props: Object) => {
    const { entities, withoutAddition, collapsedGroup } = props;

    const groups = R.map((item: Object) => {
      const {
        groupName,
        getEditPermissions,
        getRemovePermissions,
        primaryObjectGuidKey = GC.FIELD_TRAILER_GUID,
      } = item;

      return {
        ...item,
        withoutAddition,
        primaryObjectGuidKey,
        isOpened: R.pathOr(true, [groupName], collapsedGroup),
        itemList: R.or(R.prop(groupName, entities), R.prop(groupName, props)),
        getEditPermissions: G.isFunction(getEditPermissions) ?
          (entity: Object) => getEditPermissions(entity, props) :
          null,
        getRemovePermissions: G.isFunction(getRemovePermissions) ?
          (entity: Object) => getRemovePermissions(entity, props) :
          null,
      };
    }, groupsSettings);

    return { groups };
  }),
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTrailerGeneralTabEntities(state),
});

export const withGeneralSettings = compose(
  connect(mapStateToProps),
  withTabGroupSettings([
    integrationSettings,
    trailerLastKnownLocationSettings,
    trailerInsuranceSettings,
    referenceSettings,
  ]),
  pure,
);

const documentsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTrailerDocumentsTabEntities(state),
});

export const withDocumentsSettings = compose(
  connect(documentsMapStateToProps),
  withTabGroupSettings([trailerDocumentSettings]),
  withDocumentAdditionalTableSettings,
  withDocumentActions,
  pure,
);

export const withLastKnownLocationSettings = compose(
  connect(makeSelectTrailerLastKnownLocationTabEntities),
  withTabGroupSettings([trailerLastKnownLocationSettings]),
  pure,
);

const specificationsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTrailerSpecificationsTabEntities(state),
});

export const withSpecificationsTab = compose(
  connect(specificationsMapStateToProps),
  withTabGroupSettings([trailerSpecificationSettings, trailerOwnershipSettings, trailerLocationSettings]),
);

const maintenanceMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTrailerMaintenanceTabEntities(state),
});

export const withMaintenanceSettings = compose(
  connect(maintenanceMapStateToProps, { getFleetProfileConfigsRequest, visitFleetListOnFleetProfilePage }),
  withTabGroupSettings([equipmentServiceSettings, trailerEquipmentComponentSettings]),
  lifecycle({
    componentDidMount() {
      const {
        entities,
        primaryObjectGuid,
        primaryObjectBranchGuid,
        getFleetProfileConfigsRequest,
        visitFleetListOnFleetProfilePage,
      } = this.props;

      const equipmentComponent = G.getPropFromObject('equipmentComponent', entities);

      if (R.isNil(equipmentComponent)) {
        getFleetProfileConfigsRequest({
          group: GC.FLEET_GENERAL_CONFIG_GROUP,
          [GC.BRANCH_GUID]: primaryObjectBranchGuid,
        });
      }

      visitFleetListOnFleetProfilePage({
        fleetProfileType: GC.FIELD_TRAILER,
        fleetProfileGuid: primaryObjectGuid,
        fleetProfileGuidType: GC.FIELD_TRAILER_GUID,
        reportType: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
        isPageVisited: G.isNotNil(equipmentComponent),
      });
    },
  }),
  pure,
);

export const withServiceIssueSettings = compose(
  connect(null, { visitFleetListOnFleetProfilePage, changeFleetServiceIssueStatusRequest }),
  withTabGroupSettings([fleetServiceIssueSettings]),
  lifecycle({
    componentDidMount() {
      const { shared, primaryObjectGuid, visitFleetListOnFleetProfilePage } = this.props;

      visitFleetListOnFleetProfilePage({
        fleetProfileType: GC.FIELD_TRAILER,
        fleetProfileGuid: primaryObjectGuid,
        reportType: GC.FLEET_SERVICE_ISSUE_REPORT,
        fleetProfileGuidType: GC.FIELD_TRAILER_GUID,
        additionalSettings: { isTrailerShared: shared },
      });
    },
  }),
  pure,
);

export const withWorkOrdersTab = compose(
  connect(null, { visitWorkOrdersOnFleetPage }),
  withHeightProps,
  withProps(() => ({ pl: 15, withoutPageActions: true })),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitWorkOrdersOnFleetPage } = this.props;

      visitWorkOrdersOnFleetPage({
        fleetProfileType: GC.FIELD_TRAILER,
        fleetProfileGuid: primaryObjectGuid,
        fleetProfileGuidType: GC.FIELD_TRAILER_GUID,
      });
    },
  }),
  pure,
);

export const withAuditTab = compose(
  withProps(({ primaryObjectGuid, tabsCollapsedView }: Object) => ({
    useFullScreen: true,
    maxHeight: `calc(100vh - ${G.ifElse(tabsCollapsedView, 600, 215)}px)`,
    requestOptions: {
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_TRAILER,
      [GC.FIELD_OBJECT_GUID]: primaryObjectGuid,
    },
  })),
  pure,
);

export const withInspectionsTab = compose(
  connect(null, { visitInspectionsOnFleetPage }),
  withHeightProps,
  withProps(() => ({ pl: 15, withoutPageActions: true })),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitInspectionsOnFleetPage } = this.props;

      visitInspectionsOnFleetPage({
        fleetProfileType: GC.FIELD_TRAILER,
        fleetProfileGuid: primaryObjectGuid,
        fleetProfileGuidType: GC.FIELD_TRAILER_GUID,
      });
    },
  }),
  pure,
);

const trailerTrackingMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTrailerTrackingTabEntities(state),
});

export const withTrailerTrackingTab = compose(
  connect(trailerTrackingMapStateToProps),
  withTabGroupSettings([trailerTrackingSettings]),
);
