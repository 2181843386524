import React from 'react';
import * as R from 'ramda';
// component
import { pickerColorGrey } from '../../../components/color-picker';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui/box';
// feature available-driver
import { NoteCell, StatusCell, DriverCell, CommentsCell, CellComponent } from '../components/table-cells';
//////////////////////////////////////////////////

const mainBlueColor = G.getTheme('colors.mainBlue');
const locationIcon = I.locationMarker(mainBlueColor, 15, 15);

export const getColumnSettings = ({
  handlePatchUpdateNote,
  handleOpenDriverProfile,
  handlePatchUpdateComments,
  handleOpenExpandedContainer,
}: Object) => ({
  [GC.FIELD_AVAILABLE_DRIVERS_COLOR]: {
    width: '6px',
    customComponent: ({ data: { availableDriversColor } }: Object) => (
      <Box width='6px' height='100%' backgroundColor={R.or(availableDriversColor, pickerColorGrey)} />
    ),
  },
  [GC.FIELD_PREFER_DISTANCE_RATE]: {
    width: 120,
    name: 'titles:rate',
    customComponent: ({ data }: Object) => {
      const preferDistanceRate = R.pathOr('', [GC.FIELD_PREFER_DISTANCE_RATE], data);
      const preferDistanceRateUom = R.pathOr('', [GC.FIELD_PREFER_DISTANCE_RATE_UOM], data);

      if (G.isNilOrEmpty(preferDistanceRate)) return null;

      const singleText = `$${preferDistanceRate} / ${G.getUomLocale(preferDistanceRateUom, 'abbreviation')}`;

      return (
        <CellComponent
          singleText={singleText}
          icon={I.moneyWithArrow(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_DRIVER]: {
    width: 200,
    name: 'titles:driver',
    customComponent: ({ data }: Object) => {
      const { guid, phoneNumber, secondaryDriver, primaryDriverGuid } = data;

      const { firstLastMiddleNames } = G.getUserInfo(data);

      const primaryDriverName = `${G.ifElse(G.isNotNilAndNotEmpty(primaryDriverGuid), 'T. ', '')}${firstLastMiddleNames}`;

      const handleClickDriver = G.ifElse(
        G.hasAmousCurrentUserPermissions([PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE]),
        (guid: string) => handleOpenDriverProfile(guid),
        null,
      );

      const isPrimaryDriver = G.isNotNilAndNotEmpty(secondaryDriver);

      const icon = isPrimaryDriver
        ? I.renderTeamDriverIcon(mainBlueColor, 15, 15, 'transparent')
        : I.renderDriverIcon(mainBlueColor, 15, 15, mainBlueColor);

      const secondaryDriverName = R.pathOr('', ['firstLastMiddleNames'], G.getUserInfo(secondaryDriver));

      return (
        <DriverCell
          icon={icon}
          driverGuid={guid}
          phoneNumber={phoneNumber}
          primaryDriverName={primaryDriverName}
          handleClickDriver={handleClickDriver}
          secondaryDriverName={secondaryDriverName}
          secondaryDriverGuid={G.getGuidFromObject(secondaryDriver)}
        />
      );
    },
  },
  [GC.FIELD_TRUCK_UNIT_ID]: {
    width: 180,
    name: 'titles:truck',
    customComponent: ({ data }: Object) => {
      const { truck } = data;

      const truckUnitId = R.pathOr('', [GC.FIELD_FLEET_TRUCK_UNIT_ID], truck);

      if (G.isNilOrEmpty(truckUnitId)) return null;

      const truckGuid = R.pathOr('', [GC.FIELD_GUID], truck);
      const truckType = R.pathOr('', [GC.FIELD_TRUCK_TYPE], truck);

      const handleClickMainText = G.ifElse(
        G.hasAmousCurrentUserPermissions([PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE]),
        () => handleOpenExpandedContainer({
          visitPageGuid: truckGuid,
          componentType: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
        }),
        null,
      );

      return (
        <CellComponent
          subText={truckType}
          mainText={truckUnitId}
          handleClickMainText={handleClickMainText}
          icon={I.truckGroup2(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_TRUCK]: {
    width: 250,
    name: ['titles:truck', 'titles:dimensions'],
    customComponent: ({ data }: Object) => {
      const { truck } = data;

      const gvwt = R.pathOr('', [GC.FIELD_GVWT], truck);
      const width = R.pathOr('', [GC.FIELD_WIDTH], truck);
      const height = R.pathOr('', [GC.FIELD_HEIGHT], truck);
      const length = R.pathOr('', [GC.FIELD_LENGTH], truck);
      const widthUom = R.pathOr('', [GC.FIELD_WIDTH_UOM], truck);
      const heightUom = R.pathOr('', [GC.FIELD_HEIGHT_UOM], truck);
      const lengthUom = R.pathOr('', [GC.FIELD_LENGTH_UOM], truck);
      const gvwtWeightType = R.pathOr('', [GC.FIELD_GVWT_WEIGHT_TYPE], truck);

      const doorDimension = R.pathOr({}, ['doorDimension'], truck);
      const doorWidth = R.pathOr('', ['doorWidth'], doorDimension);
      const doorHeight = R.pathOr('', ['doorHeight'], doorDimension);
      const doorWidthUom = R.pathOr('', ['doorWidthUom'], doorDimension);
      const doorHeightUom = R.pathOr('', ['doorHeightUom'], doorDimension);

      const doorDimensionsArray = [doorWidth, doorHeight];
      const truckDimensionsArray = [width, height, length];

      if (G.isAllTrue(
        G.isNilOrEmpty(gvwt),
        G.isAnyNilOrEmpty(doorDimensionsArray),
        G.isAnyNilOrEmpty(truckDimensionsArray),
      )) {
        return null;
      }

      let mainText = '';

      if (G.isAllNotNilOrNotEmpty(truckDimensionsArray)) {
        mainText = `${
          length} ${G.getUomLocale(lengthUom, 'abbreviation')} x ${
          width} ${G.getUomLocale(widthUom, 'abbreviation')} x ${
          height} ${G.getUomLocale(heightUom, 'abbreviation')}`;
      }

      let subText = '';

      if (G.isNotNilAndNotEmpty(gvwt)) {
        subText = `${gvwt} ${G.getUomLocale(gvwtWeightType, 'abbreviation')}`;
      }

      if (G.isAllNotNilOrNotEmpty(doorDimensionsArray)) {
        const doorDimensionText = `${G.getWindowLocale('text:door', 'Door')}: ${
          doorWidth} ${G.getUomLocale(doorWidthUom, 'abbreviation')} x ${
          doorHeight} ${G.getUomLocale(doorHeightUom, 'abbreviation')}`;

        subText = G.ifElse(
          G.isNotEmptyString(subText),
          `${subText}, ${doorDimensionText}`,
          doorDimensionText,
        );
      }

      return (
        <CellComponent
          subText={subText}
          mainText={mainText}
          icon={I.truck(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    name: 'titles:status',
    customComponent: ({ data }: Object) => {
      const { tel, status, reserved, statusSource, futureAvailabilityDate, futureAvailabilityLocation } = data;

      if (G.isNilOrEmpty(status)) return null;

      const handleOpenTelDetails = G.ifElse(
        G.hasAmousCurrentUserPermissions([PC.TEL_READ, PC.TEL_WRITE]),
        () => handleOpenExpandedContainer({
          visitPageGuid: G.getGuidFromObject(tel),
          componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
        }),
        null,
      );

      return (
        <StatusCell
          tel={tel}
          status={status}
          reserved={reserved}
          statusSource={statusSource}
          handleOpenTelDetails={handleOpenTelDetails}
          futureAvailabilityDate={futureAvailabilityDate}
          futureAvailabilityLocation={futureAvailabilityLocation}
        />
      );
    },
  },
  [GC.FIELD_COMMENTS]: {
    width: 350,
    name: 'titles:comment',
    customComponent: ({ data }: Object) => (
      <CommentsCell
        comments={R.pathOr('', [GC.FIELD_COMMENTS], data)}
        handlePatchUpdateComments={(comments: string) => handlePatchUpdateComments(data, comments)}
      />
    ),
  },
  [GC.FIELD_RESERVED_BY]: {
    width: 250,
    name: 'titles:reserved-by',
    customComponent: ({ data }: Object) => {
      const { reservedBy, reservationEndDate } = data;

      if (G.isNilOrEmpty(reservedBy)) return null;

      const subText = `${G.getWindowLocale('titles:exp', 'exp')}: ${
        G.convertDateTimeToConfigTimeZone(reservationEndDate)}`;

      return (
        <CellComponent
          subText={subText}
          mainText={reservedBy}
          icon={I.clock(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_DISTANCE]: {
    width: 120,
    name: 'titles:distance',
    customComponent: ({ data }: Object) => {
      const { distance } = data;

      if (G.isNilOrEmpty(distance)) return null;

      const singleText = `${distance} ${G.getDistanceUomLocale()}`;

      return (
        <CellComponent
          singleText={singleText}
          icon={I.analyticMaps2(mainBlueColor, 15, 15)}
        />
      );
    },
  },
  [GC.FIELD_LOCATION]: {
    width: 320,
    name: 'titles:last-known-location',
    customComponent: ({ data: { location } }: Object) => {
      if (G.isNilOrEmpty(location)) return null;

      return (
        <CellComponent
          icon={locationIcon}
          mainText={G.concatLocationFields(location)}
          subText={G.convertDateTimeToConfigTimeZone(G.getPropFromObject(GC.FIELD_LAST_MODIFIED_DATE, location))}
        />
      );
    },
  },
  [GC.FIELD_FUTURE_AVAILABILITY_LOCATION]: {
    width: 320,
    name: 'titles:future-availability-location',
    customComponent: ({ data: { futureAvailabilityDate, futureAvailabilityLocation } }: Object) => {
      if (G.isNilOrEmpty(futureAvailabilityLocation)) return null;

      return (
        <CellComponent
          icon={locationIcon}
          mainText={G.concatLocationFields(futureAvailabilityLocation)}
          subText={G.convertDateTimeToConfigTimeZone(futureAvailabilityDate)}
        />
      );
    },
  },
  [GC.FIELD_NOTE]: {
    width: 350,
    name: 'titles:note',
    customComponent: ({ data }: Object) => {
      const note = R.pathOr({}, [GC.FIELD_NOTE], data);

      return (
        <NoteCell
          note={note}
          handlePatchUpdateNote={(note: Object) => handlePatchUpdateNote(note, data)}
        />
      );
    },
  },
  [GC.SYSTEM_OBJECT_ASSIGNED_DISPATCHER]: {
    name: 'titles:dispatcher',
    customComponent: ({ data }: Object) => {
      const { assignedDispatcher } = data;

      if (G.isNilOrEmpty(assignedDispatcher)) return null;

      const { fullText } = G.getUserInfo(assignedDispatcher);

      return <CellComponent singleText={fullText} />;
    },
  },
  [GC.SYSTEM_OBJECT_DISPATCHING_GROUP]: {
    name: 'titles:dispatching-group',
    customComponent: ({ data }: Object) => (
      <CellComponent singleText={R.pathOr('', [GC.SYSTEM_OBJECT_DISPATCHING_GROUP, GC.FIELD_NAME], data)} />
    ),
  },
  [GC.FIELD_TEL]: {
    width: 320,
    name: 'titles:next-stop',
    customComponent: ({ data }: Object) => {
      const nextStop = R.pathOr({}, [GC.FIELD_TEL, GC.SYSTEM_OBJECT_NEXT_EVENT], data);

      if (G.isNilOrEmpty(nextStop)) return null;

      const { location, eventLateDate, eventEarlyDate } = nextStop;

      return (
        <CellComponent
          icon={locationIcon}
          mainText={G.concatLocationFields(location)}
          subText={`${G.convertDateTimeToConfigTimeZone(eventEarlyDate)} - ${
            G.convertDateTimeToConfigTimeZone(eventLateDate)}`}
        />
      );
    },
  },
});

const defaultReportFieldNames = [
  GC.FIELD_PREFER_DISTANCE_RATE,
  GC.FIELD_DRIVER,
  GC.FIELD_TRUCK_UNIT_ID,
  GC.FIELD_TRUCK,
  GC.FIELD_STATUS,
  GC.FIELD_COMMENTS,
  GC.FIELD_NOTE,
  GC.FIELD_DISTANCE,
  GC.FIELD_LOCATION,
  GC.FIELD_FUTURE_AVAILABILITY_LOCATION,
  GC.FIELD_RESERVED_BY,
  GC.SYSTEM_OBJECT_ASSIGNED_DISPATCHER,
  GC.SYSTEM_OBJECT_DISPATCHING_GROUP,
  GC.FIELD_TEL,
];

export const getReportFieldNames = (isAllDriversList: boolean) => {
  if (G.isTrue(isAllDriversList)) return R.reject(R.equals(GC.FIELD_DISTANCE), defaultReportFieldNames);

  return defaultReportFieldNames;
};

export const report = {
  guid: GC.AVAILABLE_DRIVERS_UI_REPORTS,
  fields: R.compose(
    R.prepend({ p: '0px', sequence: 0, freezed: true, name: GC.FIELD_AVAILABLE_DRIVERS_COLOR }),
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence: R.inc(sequence) })),
  )(defaultReportFieldNames),
};

export const sortReport = (isAllDriversList: boolean, uiReportFields: Array) => {
  const fieldNames = G.ifElse(
    G.isNotNilAndNotEmpty(uiReportFields),
    uiReportFields,
    defaultReportFieldNames,
  );

  const fields = R.compose(
    R.prepend({ p: '0px', sequence: 0, freezed: true, name: GC.FIELD_AVAILABLE_DRIVERS_COLOR }),
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence: R.inc(sequence) })),
    R.when(() => G.isTrue(isAllDriversList), R.reject(R.equals(GC.FIELD_DISTANCE))),
  )(fieldNames);

  return { ...report, fields };
};
