import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, lifecycle, withHandlers } from 'react-recompose';
// components
import UpdateAdvancePaymentStatusForm from '../../../../components/advance-payment/update-advance-payment-status-form';
// features
import Audit2 from '../../../audit2';
import { visitFuelCardsOnFleetPage } from '../../../fuel-cards/actions';
import { visitInspectionsOnFleetPage } from '../../../inspections/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet-profile
import { withHeightProps } from '../../hocs/with-report-common-props';
import AddFuelTransaction from '../../components/add-fuel-transaction';
import { withDocumentActions } from '../../hocs/with-document-actions';
import { visitTollChargesOnFleetPage } from '../../../toll-charges/actions';
import { withBranchAndAccessorialConfigs } from '../../hocs/with-branch-and-accessorial-configs';
import {
  withPayrollMonthlyDeductionAndAccessorialDidUpdate,
} from '../../hocs/with-payroll-monthly-deduction-and-accessorial-did-update';
import {
  getItemListRequest,
  getDriverBranchConfigsRequest,
  getAccessorialConfigListRequest,
  setUnavailablePeriodDatesFilter,
  setUnavailablePeriodReasonFilter,
  updateAdvancePaymentStatusRequest,
  createOrUpdateDriverExpenseRequest,
  getDriverPayrollChargesIsPaidOnPayrollRequest,
  getDriverPayrollAccessorialsIsPaidOnPayrollRequest,
} from '../../actions';
import {
  makeSelectBranchConfigs,
  makeSelectDriverPaidByTrip,
  makeSelectAssignedTruckGuid,
  makeSelectAccessorialConfigs,
  makeSelectGeneralTabEntities,
  makeSelectPayrollTabEntities,
  makeSelectRatingEngineTabEntities,
  makeSelectDriverExpenseTabEntities,
  makeSelectAdvancePaymentTabEntities,
  makeSelectDriverDailyLogTabEntities,
  makeSelectDriverInfoForAdvancePayment,
  makeSelectLicenseDocumentsTabEntities,
  makeSelectUnavailablePeriodDatesFilter,
  makeSelectUnavailablePeriodsTabEntities,
  makeSelectUnavailablePeriodReasonFilter,
} from '../../selectors';
import {
  contactSettings,
  documentSettings,
  referenceSettings,
  integrationSettings,
  accessorialSettings,
  compensationSettings,
  payToLocationSettings,
  lastKnownLocationSettings,
  payrollAccessorialSettings,
  monthlyPayrollDeductionSettings,
} from '../../settings';
// feature fleet-profile/driver
import {
  licenseSettings,
  expenseSettings,
  deductionSettings,
  dailyHoursSettings,
  citizenshipSettings,
  driverHoursSettings,
  certificationSettings,
  advancePaymentSettings,
  safetyViolationSettings,
  unavailablePeriodSettings,
  payrollCompensationSettings,
} from '../settings';
//////////////////////////////////////////////////

const withTabGroupSettings = (groupsSettings: Object) => compose(
  withProps((props: Object) => {
    const { entities, collapsedGroup } = props;

    const groups = R.map((item: Object) => {
      const { groupName, filterProps, getRemovePermissions, primaryObjectGuidKey = GC.FIELD_DRIVER_GUID } = item;

      return {
        ...item,
        primaryObjectGuidKey,
        isOpened: R.pathOr(true, [groupName], collapsedGroup),
        itemList: R.or(R.prop(groupName, entities), R.prop(groupName, props)),
        filterProps: G.isFunction(filterProps) ? filterProps(props) : filterProps,
        getRemovePermissions: G.isFunction(getRemovePermissions) ? (
          (item: Object) => getRemovePermissions(item, props)
        ) : getRemovePermissions,
      };
    }, groupsSettings);

    return { groups };
  }),
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectGeneralTabEntities(state),
});

export const withGeneralSettings = compose(
  connect(mapStateToProps),
  withTabGroupSettings([
    driverHoursSettings,
    safetyViolationSettings,
    integrationSettings,
    lastKnownLocationSettings,
    payToLocationSettings,
    contactSettings,
    referenceSettings,
  ]),
  withHandlers({
    handleOpenLastKnownLocationAudit: (props: Object) => () => {
      const { openModal, primaryObjectGuid } = props;

      const component = (
        <Audit2
          requestOptions={{
            [GC.FIELD_OBJECT_GUID]: primaryObjectGuid,
            [GC.FIELD_TYPE]: GC.AUDIT_TYPE_DRIVER_LATEST_LOCATION,
          }}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: G.getWindowLocale('titles:audit', 'Audit'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const licenseDocumentsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectLicenseDocumentsTabEntities(state),
});

export const withLicenseDocumentsSettings = compose(
  connect(licenseDocumentsMapStateToProps, { getDocumentListRequest: getItemListRequest }),
  withTabGroupSettings([
    licenseSettings,
    citizenshipSettings,
    documentSettings,
    certificationSettings,
  ]),
  withDocumentActions,
  withHandlers({
    getDocumentListRequest: ({ primaryObjectGuid, getDocumentListRequest }: Object) => () =>
      getDocumentListRequest({
        groupName: 'documents',
        endpoint: endpointsMap.driverDocList,
        requestOptions: {
          params: { primaryObjectGuid },
        },
      }),
  }),
  pure,
);

const ratingEngineMapStateToProps = (state: Object) => createStructuredSelector({
  branchConfigs: makeSelectBranchConfigs(state),
  entities: makeSelectRatingEngineTabEntities(state),
  accessorialConfigs: makeSelectAccessorialConfigs(state),
});


export const withRatingEngineSettings = compose(
  connect(
    ratingEngineMapStateToProps,
    { getAccessorialConfigListRequest, getBranchConfigsRequest: getDriverBranchConfigsRequest },
  ),
  withTabGroupSettings([accessorialSettings, compensationSettings]),
  withBranchAndAccessorialConfigs,
  pure,
);

const payrollMapStateToProps = (state: Object) => createStructuredSelector({
  branchConfigs: makeSelectBranchConfigs(state),
  paidByTrip: makeSelectDriverPaidByTrip(state),
  entities: makeSelectPayrollTabEntities(state),
  accessorialConfigs: makeSelectAccessorialConfigs(state),
});

export const withPayrollSettings = compose(
  connect(
    payrollMapStateToProps,
    {
      getAccessorialConfigListRequest,
      getBranchConfigsRequest: getDriverBranchConfigsRequest,
      getPayrollChargesIsPaidOnPayrollRequest: getDriverPayrollChargesIsPaidOnPayrollRequest,
      getPayrollAccessorialsIsPaidOnPayrollRequest: getDriverPayrollAccessorialsIsPaidOnPayrollRequest,
    },
  ),
  withTabGroupSettings([
    deductionSettings,
    monthlyPayrollDeductionSettings,
    payrollAccessorialSettings,
    payrollCompensationSettings,
  ]),
  withBranchAndAccessorialConfigs,
  withPayrollMonthlyDeductionAndAccessorialDidUpdate,
  pure,
);

const dailyLogMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectDriverDailyLogTabEntities(state),
});

export const withDailyLogSettings = compose(
  connect(dailyLogMapStateToProps),
  withTabGroupSettings([dailyHoursSettings]),
);

const advancePaymentMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectAdvancePaymentTabEntities(state),
  generalDetails: makeSelectDriverInfoForAdvancePayment(state),
});

export const withAdvancePaymentSettings = compose(
  connect(advancePaymentMapStateToProps, { updateAdvancePaymentStatusRequest }),
  withProps(({ tabsCollapsedView }: Object) => ({
    additionalTableSettings: {
      checkBoxCellJustifyContent: 'center',
      maxHeight: G.ifElse(tabsCollapsedView, 320, 'calc(100vh - 250px)'),
    },
  })),
  withTabGroupSettings([advancePaymentSettings]),
  withHandlers({
    handleUpdateAdvancePaymentStatus: (props: Object) => ({ guid, status }: string) => {
      const { closeModal, openModal, updateAdvancePaymentStatusRequest } = props;

      const initialValues = { guid };

      const component = (
        <UpdateAdvancePaymentStatusForm
          status={status}
          closeModal={closeModal}
          initialValues={initialValues}
          submitAction={updateAdvancePaymentStatusRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 320,
          title: G.getWindowLocale('titles:update-advance-payment-status', 'Update Advance Payment Status'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const fuelTransactionsMapStateToProps = (state: Object) => createStructuredSelector({
  branchConfigs: makeSelectBranchConfigs(state),
  assignedTruckGuid: makeSelectAssignedTruckGuid(state),
  generalDetails: makeSelectDriverInfoForAdvancePayment(state),
});

export const withFuelTransactionsTab = compose(
  connect(fuelTransactionsMapStateToProps, { visitFuelCardsOnFleetPage, getDriverBranchConfigsRequest }),
  withHeightProps,
  withProps((props: Object) => {
    const { assignedTruckGuid } = props;

    const driverLastName = R.path(['generalDetails', GC.FIELD_LAST_NAME], props);
    const driverFirstName = R.path(['generalDetails', GC.FIELD_FIRST_NAME], props);

    return {
      pl: 15,
      driverLastName,
      driverFirstName,
      assignedTruckGuid,
      pageActionsBottom: 70,
      withoutPageActions: true,
      additionalComponent: (
        <AddFuelTransaction
          driverLastName={driverLastName}
          driverFirstName={driverFirstName}
          assignedTruckGuid={assignedTruckGuid}
          defaultBranchCurrency={G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, props.branchConfigs)}
        />
      ),
    };
  }),
  lifecycle({
    componentDidMount() {
      const {
        branchConfigs,
        primaryObjectGuid,
        visitFuelCardsOnFleetPage,
        getDriverBranchConfigsRequest,
      } = this.props;

      if (R.isNil(branchConfigs)) getDriverBranchConfigsRequest();

      visitFuelCardsOnFleetPage({ [GC.FIELD_TRUCK_GUID]: null, [GC.FIELD_DRIVER_GUID]: primaryObjectGuid });
    },
  }),
  pure,
);

export const withTollChargesTab = compose(
  connect(null, { visitTollChargesOnFleetPage }),
  withHeightProps,
  withProps(() => ({ pl: 15 })),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitTollChargesOnFleetPage } = this.props;

      visitTollChargesOnFleetPage({ [GC.FIELD_TRUCK_GUID]: null, [GC.FIELD_DRIVER_GUID]: primaryObjectGuid });
    }
  }),
  pure,
);

export const withAuditTab = compose(
  withProps(({ primaryObjectGuid, tabsCollapsedView }: Object) => ({
    useFullScreen: true,
    maxHeight: `calc(100vh - ${G.ifElse(tabsCollapsedView, 600, 215)}px)`,
    requestOptions: {
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_DRIVER,
      [GC.FIELD_OBJECT_GUID]: primaryObjectGuid,
    },
  })),
  pure,
);

const unavailablePeriodMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectUnavailablePeriodsTabEntities(state),
  unavailablePeriodDatesFilter: makeSelectUnavailablePeriodDatesFilter(state),
  unavailablePeriodReasonFilter: makeSelectUnavailablePeriodReasonFilter(state),
});

export const withUnavailablePeriodSettings = compose(
  connect(unavailablePeriodMapStateToProps, { setUnavailablePeriodDatesFilter, setUnavailablePeriodReasonFilter }),
  withProps(({ tabsCollapsedView }: Object) => ({
    additionalTableSettings: {
      checkBoxCellJustifyContent: 'center',
      maxHeight: G.ifElse(tabsCollapsedView, 320, 'calc(100vh - 250px)'),
    },
  })),
  withTabGroupSettings([unavailablePeriodSettings]),
  pure,
);

const tasksMapStateToProps = (state: Object) => createStructuredSelector({
  generalDetails: makeSelectDriverInfoForAdvancePayment(state),
});

export const withTasksTab = compose(
  connect(
    tasksMapStateToProps,
    (dispatch: Function) => ({
      visitTaskManagementPage: (payload: Object) => dispatch({ payload, type: GC.VISIT_TASK_MANAGEMENT_PAGE }),
    }),
  ),
  withHeightProps,
  withProps(({ generalDetails: { lastName, firstName } = {} }: Object) => ({
    pl: '0px',
    title: `${firstName} ${lastName} ${G.getWindowLocale('titles:tasks', 'Tasks')}`,
  })),
  lifecycle({
    componentDidMount() {
      const { generalDetails, visitTaskManagementPage } = this.props;

      visitTaskManagementPage({
        [GC.FIELD_TYPE]: 'driverTasks',
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(generalDetails),
        [GC.FIELD_ASSIGNEE_GUID]: R.prop('userGuid', generalDetails),
      });
    },
  }),
);

const expensesMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectDriverExpenseTabEntities(state),
  generalDetails: makeSelectDriverInfoForAdvancePayment(state),
});

export const withExpensesSettings = compose(
  connect(expensesMapStateToProps, { createOrUpdateDriverExpenseRequest }),
  withProps(({ tabsCollapsedView }: Object) => ({
    additionalTableSettings: {
      checkBoxCellJustifyContent: 'center',
      maxHeight: G.ifElse(tabsCollapsedView, 320, 'calc(100vh - 250px)'),
    },
  })),
  withTabGroupSettings([expenseSettings]),
  pure,
);

export const withInspectionsTab = compose(
  connect(null, { visitInspectionsOnFleetPage }),
  withHeightProps,
  withProps(() => ({ pl: 15, withoutPageActions: true })),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitInspectionsOnFleetPage } = this.props;

      visitInspectionsOnFleetPage({
        fleetProfileType: GC.FIELD_DRIVER,
        fleetProfileGuid: primaryObjectGuid,
        fleetProfileGuidType: GC.FIELD_DRIVER_GUID,
      });
    },
  }),
  pure,
);
