import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../../../components/text';
// features
import { AuthWrapper } from '../../../../permission';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex, BoxHovered, StyledLink } from '../../../../../ui';
// feature dispatch-details-new
import { withLoadDocumentActions } from '../../../load/hocs/with-document-actions';
import { withOrderDocumentActions } from '../../../order/hocs/with-document-actions';
//////////////////////////////////////////////////

const greyColor = G.getTheme('colors.dark.grey');
const blueColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const textStyles = {
  fontSize: 12,
  color: textColor,
  wordBreak: 'break-all',
};

const DocumentNameComponent = (props: Object) => {
  const { url, documentAction, documentFilename } = props;

  if (G.isNilOrEmpty(documentFilename)) {
    return (
      <StyledLink
        {...textStyles}
        target='_blank'
        maxWidth='100%'
        color={blueColor}
        href={G.makeURLString(url)}
      >
        {url}
      </StyledLink>
    );
  }

  return (
    <BoxHovered
      {...textStyles}
      maxWidth='100%'
      cursor='pointer'
      color={blueColor}
      hoverColor={blueColor}
      onClick={documentAction}
      hoverTextDecoration='underline'
    >
      {documentFilename}
    </BoxHovered>
  );
};

const getOrderName = (document: Object, orders: Object, showOrderName: any) => {
  if (R.not(G.isTrue(showOrderName))) return null;

  const { loadType, primaryObjectGuid } = document;

  if (G.isLoadTypeTel(loadType)) return null;

  const order = R.find(R.propEq(primaryObjectGuid, GC.FIELD_GUID), R.or(orders, []));

  if (G.isNilOrEmpty(order)) return null;

  const { primaryReferenceValue } = order;

  return primaryReferenceValue;
};

export const renderDocuments = (documents: Array, props: Object) => {
  const {
    byTxtLocale,
    showOrderName,
    wrapperStyles,
    addedTxtLocale,
    mailPermissions,
    relatedDataList,
    handleEditDocument,
    handleSendMultiMail,
    documentPermissions,
    handleRemoveDocument,
    handleOpenFilePreview,
    handleDownloadDocument,
  } = props;

  return documents.map((document: Object, index: number) => {
    const {
      url,
      guid,
      loadType,
      loadGuid,
      comments,
      createdBy,
      createdDate,
      documentFilename,
      primaryObjectGuid,
    } = document;

    const orderName = getOrderName(document, relatedDataList, showOrderName);

    return (
      <Flex mt='8px' key={index} alignItems='flex-start' {...wrapperStyles}>
        <Flex width='calc(100% - 115px)'>
          <Box mr='7px' height={20}>
            {G.getDocumentIconByDocumentFileName(documentFilename)}
          </Box>
          <Box width='calc(100% - 30px)'>
            <DocumentNameComponent
              url={url}
              documentFilename={documentFilename}
              documentAction={() => handleOpenFilePreview(document)}
            />
            {
              orderName &&
              <Box
                {...textStyles}
                mt='5px'
                maxWidth='100%'
                color={blueColor}
              >
                {orderName}
              </Box>
            }
            <Box
              {...textStyles}
              mt='5px'
              maxWidth='100%'
              color={greyColor}
            >
              {R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], document)}
            </Box>
            <Box
              {...textStyles}
              mt='5px'
              maxWidth='100%'
              color={greyColor}
            >
              {addedTxtLocale} {G.convertDateTimeToConfigFormat(createdDate)} {byTxtLocale} {createdBy}
            </Box>
            {
              G.isNotNilAndNotEmpty(comments) &&
              <TextComponent
                {...textStyles}
                mt='5px'
                maxWidth='100%'
                display='block'
                title={comments}
                color={greyColor}
                withEllipsis={true}
              >
                {comments}
              </TextComponent>
            }
          </Box>
        </Flex>
        <Flex ml='auto'>
          {
            G.isNilOrEmpty(url) &&
            <AuthWrapper has={documentPermissions}>
              <Box
                mr='8px'
                cursor='pointer'
                title={G.getWindowLocale('actions:download', 'Download')}
                onClick={() => handleDownloadDocument({ loadType, documentFilename, primaryObjectGuid })}
              >
                {I.downloadDocument(greyColor)}
              </Box>
            </AuthWrapper>
          }
          <AuthWrapper has={mailPermissions}>
            <Box
              mr='8px'
              cursor='pointer'
              onClick={() => handleSendMultiMail(document)}
              title={G.getWindowLocale('titles:send-email', 'Send Email')}
            >
              {I.renderMailIcon(greyColor)}
            </Box>
          </AuthWrapper>
          <AuthWrapper has={documentPermissions}>
            <Box
              mr='8px'
              cursor='pointer'
              onClick={() => handleEditDocument(document)}
              title={G.getWindowLocale('titles:edit-document', 'Edit Document')}
            >
              {I.pencil(greyColor)}
            </Box>
          </AuthWrapper>
          <AuthWrapper has={documentPermissions}>
            <Box
              cursor='pointer'
              onClick={() => handleRemoveDocument({ guid, loadType, loadGuid })}
              title={G.getWindowLocale('titles:remove-document', 'Remove Document')}
            >
              {I.remove(greyColor)}
            </Box>
          </AuthWrapper>
        </Flex>
      </Flex>
    );
  });
};

const DocumentsCardComponent = (props: Object) => {
  const { documents, linkedOrdersDocuments } = props;

  const byTxtLocale = G.getWindowLocale('titles:by', 'by');
  const addedTxtLocale = G.getWindowLocale('titles:added', 'Added');

  return (
    <Box p='8px' pt={0} height={400} overflowY='auto'>
      {
        renderDocuments(documents, { ...props, byTxtLocale, addedTxtLocale })
      }
      {
        G.isNotNilAndNotEmpty(linkedOrdersDocuments) &&
        <Box mt={10} width='100%' textAlign='center' color={textColor}>
          {G.getWindowLocale('titles:crossdock-clos', 'Cross Dock Orders')}
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(linkedOrdersDocuments) &&
        renderDocuments(linkedOrdersDocuments, { ...props, byTxtLocale, addedTxtLocale })
      }
    </Box>
  );
};

const LoadDocumentsCardComponent = withLoadDocumentActions(DocumentsCardComponent);
const OrderDocumentsCardComponent = withOrderDocumentActions(DocumentsCardComponent);

export default DocumentsCardComponent;

export {
  LoadDocumentsCardComponent,
  OrderDocumentsCardComponent,
};
