import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, defaultProps, withHandlers } from 'react-recompose';
// common
import { createLoadDocumentsRequest } from '../../../../common/actions';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { withAsyncDocumentPreviewDrawer } from '../../../../components/document-preview/preview-drawer';
// features
import DocumentForm from '../../forms/document-form';
import { withMultipleMailSending } from '../../../mail-sending/hocs';
import { uploadMailSendingRequest } from '../../../mail-sending/actions';
// forms
import AddDocumentsForm from '../../../../forms/forms/add-load-documents-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withLoadFilePreview, withAsyncAvailableDocumentTypes } from '../../../../hocs';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature dispatch-details new
import PrintForm from '../../forms/print-form';
import {
  printLoadDocumentsRequest,
  updateOrderDocumentRequest,
  removeOrderDocumentRequest,
  getOrderDocumentListRequest,
  downloadOrderDocumentRequest,
  printDispatchDocumentRequest,
} from '../actions';
//////////////////////////////////////////////////

const EnhancedDocumentForm = withAsyncAvailableDocumentTypes(DocumentForm);
const EnhancedAddDocumentForm = withAsyncAvailableDocumentTypes(AddDocumentsForm);

export const withOrderAddDocumentWithoutConnect = compose(
  withHandlers({
    handleAddDocument: (props: Object) => (event: Object, files: Array) => {
      const {
        load,
        proofType,
        openModal,
        closeModal,
        availableDocumentTypes,
        createLoadDocumentsRequest,
        getOrderDocumentListRequest } = props;

      const cloGuid = G.getGuidFromObject(load);

      const initialValues = {
        [GC.FIELD_PRIMARY_OBJECT_GUID]: cloGuid,
        [GC.FIELD_DOCUMENT_UPLOAD]: R.or(files, []),
        [GC.FIELD_DOCUMENT_PROOF_TYPE]: R.or(proofType, GC.DOCUMENT_PROOF_TYPE_GENERAL_VALUE),
      };

      const handleCreateLoadDocument = (values: Object, callback: Function) => {
        const valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);
        let data;

        if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_DOCUMENT_UPLOAD, valuesToUse))) {
          const commonProps = R.omit([GC.FIELD_DOCUMENT_UPLOAD], valuesToUse);
          data = R.compose(
            R.map((file: Object) => R.assoc(GC.FIELD_DOCUMENT_UPLOAD, file, commonProps)),
            R.prop(GC.FIELD_DOCUMENT_UPLOAD),
          )(valuesToUse);
        } else {
          data = R.of(Array, valuesToUse);
        }

        createLoadDocumentsRequest({
          data,
          callback,
          loadType: GC.LOAD_TYPE_CLO,
          refreshCallback: () => getOrderDocumentListRequest(cloGuid),
        });
      };
      const component = (
        <EnhancedAddDocumentForm
          load={load}
          closeModal={closeModal}
          entityName={GC.FIELD_TEL}
          initialValues={initialValues}
          branchGuid={R.prop(GC.BRANCH_GUID, load)}
          availableDocumentTypes={availableDocumentTypes}
          handleActionLoadDocument={handleCreateLoadDocument}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-document', 'Add Document'),
        },
      };

      openModal(modal);
    },
    handlePrintLoad: (props: Object) => (entity: Object) => {
      const { load, closeModal, openModal, printDispatchDocumentRequest } = props;

      const branchGuid = G.getBranchGuidFromObject(load);

      const modalContent = (
        <PrintForm
          closeModal={closeModal}
          selectedEntity={entity}
          branchGuid={branchGuid}
          cloGuid={G.getGuidFromObject(load)}
          submitAction={printDispatchDocumentRequest}
          printableSection={GC.DOCUMENT_PRINTABLE_SECTION_CLO}
          eventOptions={G.getLoadEventOptions(load.events, true)}
        />
      );

      const modal = G.getDefaultModalOptions(
        modalContent,
        G.getWindowLocale('titles:print-invoice', 'Print Invoice'),
      );

      openModal(modal);
    },
    handlePrintLoadDocumentsInvoice: (props: Object) => (event: Object, initialValues: Object) => {
      const { load, configs, closeModal, openModal, printLoadDocumentsRequest } = props;

      const documentsOptions = R.compose(
        R.map(G.renameKeys({ documentFilename: 'label', guid: 'value' })),
        R.filter((document: Object) => {
          const fileExtension = R.last(R.split('.', R.pathOr('', ['documentFilename'], document)));
          return R.includes(fileExtension, GC.DOCUMENTS_EXTENSIONS);
        }),
        R.pathOr([], ['documents']),
      )(props);

      const types = R.map(
        (option: Object) => ({ label: option.displayedValue, value: R.or(option.parentGuid, option.guid) }),
        G.getFullOptionsFromDropdownConfigStore(
          configs,
          GC.COMMUNICATION_DOCUMENT_TYPE,
        ),
      );

      const modalContent = (
        <PrintForm
          withType={true}
          closeModal={closeModal}
          documentTypeOptions={types}
          initialValues={initialValues}
          documentsOptions={documentsOptions}
          submitAction={(data: Object) => (
            printLoadDocumentsRequest(R.assoc('loadGuid', G.getGuidFromObject(load), data))
          )}
        />
      );

      const modal = G.getDefaultModalOptions(
        modalContent,
        G.getWindowLocale('titles:print-create-document', 'Print/Create Document'),
      );

      openModal(modal);
    },
  }),
  pure,
);

export const withOrderAddDocument = compose(
  connect(null, {
    printLoadDocumentsRequest,
    createLoadDocumentsRequest,
    getOrderDocumentListRequest,
    printDispatchDocumentRequest,
  }),
  withOrderAddDocumentWithoutConnect,
);

export const withDownloadMail = compose(
  connect(null, { uploadMailSendingRequest }),
  withHandlers({
    handleDownloadMail: ({ entity, closeFixedPopup, uploadMailSendingRequest }: Object) => () => {
      G.callFunction(closeFixedPopup);
      uploadMailSendingRequest(G.getGuidFromObject(entity));
    },
  }),
);

export const withOrderDocumentActionsWithoutConnect = compose(
  defaultProps({
    getDownloadDocumentEndpoint: (payload: Object) => G.ifElse(
      G.isLoadTypeClo(payload),
      endpointsMap.cloDocumentDownloadFile,
      endpointsMap.telDocumentDownloadFile,
    ),
  }),
  withAsyncDocumentPreviewDrawer,
  withMultipleMailSending,
  withHandlers({
    handleDownloadDocument: ({ downloadOrderDocumentRequest }: Object) => (document: Object) =>
      downloadOrderDocumentRequest(document),
    handleSendMultiMail: (props: Object) => (document: Object) => {
      const { load, useBillToContactEmail, handleSendMultiMail} = props;

      const branchGuid = R.path([GC.BRANCH_GUID], load);
      const billToEmails = R.pathOr([], [GC.SYSTEM_OBJECT_BILL_TO, GC.FIELD_EMAILS], load);
      const to = G.ifElse(useBillToContactEmail, billToEmails, []);

      const mailProps = {
        to,
        branchGuid,
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_MAIL_SENDING_MESSAGE]: '',
        [GC.FIELD_MAIL_SENDING_SUBJECT]: '',
        [GC.FIELD_MAIL_SENDING_REPLY_TO]: '',
        [GC.FIELD_LOAD_TYPE]: R.prop(GC.FIELD_LOAD_TYPE, document),
        [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_PRIMARY_OBJECT_GUID, document),
        [GC.FIELD_MAIL_SENDING_ATTACHED_DOCUMENT_GUIDS]: [{
          [GC.FIELD_GUID]: G.getGuidFromObject(document),
          [GC.FIELD_NAME]: R.pathOr(R.prop(GC.FIELD_DOCUMENT_URL, document), [GC.FIELD_DOCUMENT_FILE_NAME], document),
        }],
      };

      handleSendMultiMail(load, 'cloDocumentList', branchGuid, mailProps);
    },
    handleEditDocument: (props: Object) => (initialValues: Object) => {
      const {
        load,
        openModal,
        closeModal,
        availableDocumentTypes,
        updateOrderDocumentRequest,
      } = props;

      const file = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, initialValues);
      const documentType = R.path([GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], initialValues);

      const component = (
        <EnhancedDocumentForm
          load={load}
          closeModal={closeModal}
          entityName={GC.FIELD_TEL}
          branchGuid={R.prop(GC.BRANCH_GUID, load)}
          availableDocumentTypes={availableDocumentTypes}
          handleActionLoadDocument={updateOrderDocumentRequest}
          initialValues={R.mergeRight(initialValues, { file, documentType })}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:edit-document', 'Edit Document'),
        },
      };

      openModal(modal);
    },
    handleRemoveDocument: ({ openModal, removeOrderDocumentRequest }: Object) => (document: Object) => {
      const textLocale = G.getWindowLocale(
        'messages:documents:remove-confirmation-text',
        'Are you sure you want delete this document ?',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => removeOrderDocumentRequest(document),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const withOrderDocumentActions = compose(
  connect(
    null,
    {
      updateOrderDocumentRequest,
      removeOrderDocumentRequest,
      downloadOrderDocumentRequest,
    },
  ),
  withOrderDocumentActionsWithoutConnect,
);
