import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, all, call, fork, take, select, takeLatest } from 'redux-saga/effects';
// common
import { setSocketConnection } from '../../../common/actions';
import { makeSelectSocketConnected } from '../../../common/selectors';
// components
import { closeModal } from '../../../components/modal/actions';
import { closeLoader, openLoader } from '../../../components/loader/actions';
// features
import PC from '../../permission/role-permission';
import { messageType } from '../../sockets-v2/constants';
import { socketPostMessage } from '../../sockets-v2/actions';
import { makeSelectAuthorities } from '../../permission/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { createMailSendingSuccess } from '../../mail-sending/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { markAllAsReadSuccess, getUnreadCountRequest } from '../../notification/actions';
import { sendCrossBorderIntegrationRequest } from '../../dispatch-board-new/load/actions';
import {
  getAvailableDocumentTypes,
  getServiceMappingListRequest,
  getTelDispatchBoardActionListRequest,
} from '../../configurations/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// feature load-details-new
import * as A from './actions';
import * as C from '../constants';
import { getSyncRateFromCustomerInvoiceValue } from '../helpers';
import {
  makeSelectLists,
  makeSelectEvents,
  makeSelectLoadGuid,
  makeSelectLoadConfigs,
  makeSelectLoadDetails,
  makeSelectNotification,
  makeSelectMessageCenter,
  makeSelectSelectedCarrierDriverRateGuid,
} from './selectors';
//////////////////////////////////////////////////

function* getLoadDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const tel = yield select(makeSelectLoadDetails());
    const telGuid = R.or(payload, G.getGuidFromObject(tel));
    const endpoint = endpointsMap.getTelEndpoint(telGuid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const containers = G.getContainersFromEventsUniqByGuid(data.events);
      const items = R.reduce(
        (items: Array, stop: Object) => {
          const filteredItems = R.filter(
            (stopItem: Object) => R.not(R.any(R.eqProps(GC.FIELD_GUID, stopItem), items)),
            stop.items,
          );
          return R.concat(items, filteredItems);
        },
        [],
        data.events,
      );
      yield put(A.getLoadItemListSuccess(items));
      yield put(A.getLoadEventsSuccess(data.events));
      yield put(A.getLoadContainerListSuccess(containers));
      yield put(A.getLoadBoardListSuccess(R.pathOr([], ['loadBoardShipments'], data)));
      const load = R.mergeRight(
        data,
        {
          items,
          [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL,
        },
      );
      yield put(A.getLoadDetailsSuccess(load));
      yield put(A.getLoadInvoiceTotalsRequest(telGuid));
      yield put(A.getLoadReferenceListSuccess({ data: R.prop(GC.FIELD_REFERENCES, data) }));
      const eventGuids = R.compose(
        R.map(G.getGuidFromObject),
        R.filter(G.isLoadTypeClo),
        R.prop(GC.FIELD_LOAD_STOPS),
      )(data);
      yield put(A.getCloReferencesByEventGuidsRequest(eventGuids));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadDetailsRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadDetailsRequest exception');
  }
}

function* toggleLoadVehicleItemRequest({ payload }: Object) {
  try {
    const { guid, damages, expanded, tableNumber } = payload;

    if (R.or(G.isTrue(expanded), G.isNotNilAndNotEmpty(damages))) {
      return yield put(A.toggleLoadVehicleItemSuccess({ damages, tableNumber, itemGuid: guid }));
    }

    yield put(openLoader());
    const options = {
      data: { elements: R.of(Array, guid) },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.getDamageReportListByItemGuids, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.toggleLoadVehicleItemSuccess({ tableNumber, damages: data, itemGuid: guid }));
    } else {
      yield call(G.handleFailResponse, res, 'toggleLoadVehicleItemRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'toggleLoadVehicleItemRequest exception');
  }
}

function* getLoadInvoiceTotalsRequest({ payload }: Object) {
  try {
    const loadGuid = yield select(makeSelectLoadGuid());
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.TEL_FLEET_INVOICE_READ,
      PC.TEL_FLEET_INVOICE_WRITE,
      PC.TEL_CARRIER_INVOICE_READ,
      PC.TEL_CARRIER_INVOICE_WRITE,
      PC.TEL_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: { [GC.FIELD_TEL_GUID]: R.or(payload, loadGuid) },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telInvoiceTotals, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadInvoiceTotalsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getLoadInvoiceTotalsRequest exception');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadInvoiceTotalsRequest exception');
  }
}

function* getCloReferencesByEventGuidsSaga({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.cloReferenceByEventGuids, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCloReferencesByEventGuidsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCloReferencesByEventGuidsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getCloReferencesByEventGuidsSaga exception');
  }
}

function* updateLoadDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const tel = yield select(makeSelectLoadDetails());
    const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, tel);
    const updateData = R.pick(GC.GROUPED_FIELDS.LOAD_PICK_ARR, tel);

    const options = {
      data: {
        ...updateData,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        ...payload,
        billToGuid: R.path([GC.SYSTEM_OBJECT_BILL_TO, GC.FIELD_GUID], tel),
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.tel, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateLoadDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadDetailsRequest exception');
  }
}

// events
function* getLoadEventsSaga({ payload }: Object) {
  try {
    const params = { telGuid: payload };
    const endpoint = endpointsMap.telEventList;
    const res = yield call(sendRequest, 'get', endpoint, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadEventsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadEventsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadEventsSaga exception');
  }
}

// documents
function* openLoadDocumentRequest({ documentOptions, downloadDocumentEndpoint }: Object) {
  try {
    const loadGuid = yield select(makeSelectLoadGuid());
    const options = {
      resType: 'arraybuffer',
      params: documentOptions,
    };
    const res = yield call(sendRequest, 'get', downloadDocumentEndpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const file = new window.Blob([data], { type: 'application/pdf' });
      G.openFile(file);
      yield put(closeModal());
      yield put(A.getLoadDocumentListRequest(loadGuid));
    } else {
      yield call(G.handleFailResponse, res, 'openLoadDocumentRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'openLoadDocumentRequest exception');
  }
}

function* printDocumentRequest({ endpoint, options, loadType }: Object) {
  try {
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const primaryObjectGuid = R.prop(GC.FIELD_PRIMARY_OBJECT_GUID, data);
      const documentOptions = {
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: R.prop(GC.FIELD_DOCUMENT_FILE_NAME, data),
      };
      const downloadDocumentEndpoint = G.ifElse(
        R.equals(loadType, GC.LOAD_TYPE_TEL),
        endpointsMap.telDocumentDownloadFile,
        endpointsMap.cloDocumentDownloadFile,
      );
      yield call(openLoadDocumentRequest, { documentOptions, downloadDocumentEndpoint });
    } else {
      yield call(G.handleFailResponse, res, 'printDocumentRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printDocumentRequest exception');
  }
}

export function* getLoadDocumentListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.TEL_DOCUMENT_READ,
      PC.TEL_DOCUMENT_WRITE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_PRIMARY_OBJECT_GUID]: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.telDocumentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDocumentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadDocumentListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadDocumentListRequest exception');
  }
}

function* getLoadLinkedOrdersDocumentListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.TEL_DOCUMENT_READ,
      PC.TEL_DOCUMENT_WRITE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_TEL_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telLinkedCloDocuments, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadLinkedOrdersDocumentListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadLinkedOrdersDocumentListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadLinkedOrdersDocumentListRequest exception');
  }
}

function* updateLoadDocumentRequest({ payload }: Object) {
  const { data, callback } = payload;

  try {
    yield put(openLoader());
    const reqData = G.makeDataForDocument(data);
    const options = { data: reqData };
    const endpoint = G.ifElse(
      G.isLoadTypeClo(data),
      endpointsMap.cloDocumentUpdate,
      endpointsMap.telDocumentUpdate,
    );
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateLoadDocumentSuccess(res.data));
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      G.callFunction(callback);
      yield call(G.handleFailResponse, res, 'updateLoadDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    G.callFunction(callback);
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadDocumentRequest exception');
  }
}

function* removeLoadDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid } = payload;

    const isLoadDocumentTypeClo = G.isLoadTypeClo(payload);

    const endpoint = G.ifElse(
      isLoadDocumentTypeClo,
      endpointsMap.getCloDocumentEndpoint,
      endpointsMap.getTelDocumentEndpoint,
    )(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (isLoadDocumentTypeClo) {
        yield put(A.removeLoadDocumentSuccess(guid));// TODO: check and remove after socket v2 is deployed
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCloDocumentOnTelRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCloDocumentOnTelRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* downloadLoadDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { documentFilename, primaryObjectGuid } = payload;
    const endpoint = G.ifElse(
      G.isLoadTypeClo(payload),
      endpointsMap.cloDocumentDownloadFile,
      endpointsMap.telDocumentDownloadFile,
    );
    const options = {
      resType: 'arraybuffer',
      params: {
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: documentFilename,
      },
    };
    const res = yield call(sendRequestWithQSParamsSerializer, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      FileDownload(data, documentFilename);
    } else {
      yield call(G.handleFailResponse, res, 'downloadLoadDocumentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'downloadLoadDocumentRequest exception');
  }
}

function* getLoadXMLRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, fileName } = payload;
    const options = {
      resType: 'arraybuffer',
    };
    const endpoint = endpointsMap.getTelXMLEndpoint(guid);
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      FileDownload(data, `${fileName}.xml`);
    } else {
      yield call(G.handleFailResponse, res, 'getLoadXMLRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadXMLRequest exception');
  }
}

// references
function* getLoadReferenceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.TEL_REFERENCE_READ, PC.TEL_REFERENCE_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { [GC.FIELD_TEL_GUID]: loadGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.telReferenceList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const sortedRefs = R.sortBy((a: Object) => R.not(a.primary), data);
      yield put(A.getLoadReferenceListSuccess({ tableNumber, data: sortedRefs }));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadReferenceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadReferenceListRequest exception');
  }
}

function* createLoadReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const options = {
      data: R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, loadGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createLoadReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadReferenceRequest exception');
  }
}

function* updateLoadReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.telReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateLoadReferenceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadReferenceRequest exception');
  }
}

function* removeLoadReferenceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getTelReferenceEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeLoadReferenceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadReferenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadReferenceRequest exception');
  }
}

// linked order references
export function* getLinkedOrderRefListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.CLO_REFERENCE_READ, PC.CLO_REFERENCE_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;

    const telGuid = yield select(makeSelectLoadGuid());
    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: G.ifElse(
          G.isNotNilAndNotEmpty(loadGuid),
          loadGuid,
          telGuid,
        ),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telLinkedCloReferences, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLinkedOrderRefListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getLinkedOrderRefListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLinkedOrderRefListRequest exception');
  }
}

// status messages
function* getLoadStatusMessageListRequest({ payload }: Object) {
  try {
    const { tableNumber } = payload;

    const loadGuid = yield select(makeSelectLoadGuid());
    const options = {
      params: {
        [GC.FIELD_LOAD_GUID]: R.pathOr(loadGuid, [GC.FIELD_LOAD_GUID], payload)
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telStatusMessageList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadStatusMessageListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelStatusMessageListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTelStatusMessageListRequest exception');
  }
}

function* createLoadStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const options = { data: R.assoc(GC.FIELD_LOAD_GUID, loadGuid, payload) };

    const res = yield call(sendRequest, 'post', endpointsMap.telStatusMessage, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'createLoadStatusMessageRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadStatusMessageRequest exception');
  }
}

function* createLoadMultipleStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { callback, statusMessages } = payload;
    const completedStatusMessages = [];
    const loadGuid = yield select(makeSelectLoadGuid());
    for (let i = 0; R.lt(i, R.length(statusMessages)); i++) {
      const statusMessage = G.getPropFromObject(i, statusMessages);
      const options = { data: R.assoc(GC.FIELD_LOAD_GUID, loadGuid, statusMessage) };
      const res = yield call(sendRequest, 'post', endpointsMap.telStatusMessage, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        completedStatusMessages.push(data);
      } else {
        G.callFunctionWithArgs(callback, completedStatusMessages);
        yield call(G.handleFailResponse, res, 'createLoadStatusMessageRequest fail');
        break;
      }
    }
    if (G.isNotEmpty(completedStatusMessages)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
      if (R.equals(R.length(statusMessages), R.length(completedStatusMessages))) yield put(closeModal());
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadStatusMessageRequest exception');
  }
}

function* updateLoadStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const locationArr = [
      GC.FIELD_ZIP,
      GC.FIELD_CITY,
      GC.FIELD_STATE,
      GC.FIELD_COUNTRY,
      GC.FIELD_LATITUDE,
      GC.FIELD_ADDRESS_1,
      GC.FIELD_LONGITUDE,
      GC.FIELD_PROOF_TYPE,
    ];

    const useStatusMessageLocation = R.compose(
      R.omit([GC.FIELD_ADDRESS_1]),
      R.assoc(GC.FIELD_ADDRESS, payload.address1),
      R.mergeRight(R.prop('statusMessageLocation', payload)),
      R.pick(locationArr),
    )(payload);

    const options = {
      data: R.compose(
        R.assoc('telStatusMessageLocation', useStatusMessageLocation),
        R.omit(locationArr),
      )(payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.telStatusMessage, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadStatusMessageRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadStatusMessageRequest exception');
  }
}

function* removeLoadStatusMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getTelStatusMessageEndpoint(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadStatusMessageRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadStatusMessageRequest exception');
  }
}

// macro point
function* getMacroPointListRequest({ payload }: Object) {
  try {
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: loadGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.macroPointInfo, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getMacroPointListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getMacroPointListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getMacroPointListRequest exception');
  }
}

// advance payment
function* getLoadAdvancePaymentListRequest({ payload }: Object) {
  try {
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: loadGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.advancePaymentList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadAdvancePaymentListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadAdvancePaymentListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadAdvancePaymentListRequest exception');
  }
}

function* createLoadAdvancePaymentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.advancePayment, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createLoadAdvancePaymentSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadAdvancePaymentRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createLoadAdvancePaymentRequest exception');
  }
}

function* updateLoadAdvancePaymentStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.advancePaymentChangeStatus, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateLoadAdvancePaymentStatusSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateAdvancePaymentStatusRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'updateAdvancePaymentStatusRequest exception');
  }
}

// driver/carrier rates
function* getLoadDriverCarrierRateListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());

    const permissions = [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE];

    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const telGuid = yield select(makeSelectLoadGuid());

    const tableNumber = G.getPropFromObject('tableNumber', payload);
    const loadGuid = G.getPropFromObject(GC.FIELD_LOAD_GUID, payload);

    if (R.and(G.isNilOrEmpty(telGuid), G.isNilOrEmpty(loadGuid))) return false;

    const options = {
      params: { [GC.FIELD_TEL_GUID]: R.or(loadGuid, telGuid) },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telRate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadDriverCarrierRateListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getTelRatesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getTelRatesSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* createLoadDriverRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const omittedPayload = R.omit(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);
    const fleetAssignment = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);
    const options = {
      data: {
        ...omittedPayload,
        fleetAssignment,
        [GC.FIELD_TEL_GUID]: loadGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telFleetRate, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadDetailsRequest(loadGuid));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadDriverRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadDriverRateRequest exception');
  }
}

function* createLoadCarrierRateRequest({ payload }: Object) {
  try {
    const { selected, carrierContractGuid } = payload;

    yield put(openLoader());

    const loadGuid = yield select(makeSelectLoadGuid());
    const options = {
      data: R.assoc(GC.FIELD_TEL_GUID, loadGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telCarrierRate, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      if (R.and(G.isTrue(selected), G.isNotNilAndNotEmpty(carrierContractGuid))) {
        yield fork(getLoadDetailsRequest, { payload: loadGuid });
        const getAction = yield take(A.getLoadDetailsSuccess);
        const billTo = R.path(['payload', GC.SYSTEM_OBJECT_BILL_TO], getAction);
        yield put(A.getLoadBillToSuccess(billTo));
      }
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadCarrierRateRequest exception');
  }
}

function* updateLoadDriverRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const fleetAssignmentFields = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);
    const fleetAssignment = R.pathOr({}, [GC.FIELD_FLEET_ASSIGNMENT], payload);
    const fullFleetAssignment = R.omit(GC.SYSTEM_LIST_TRAILERS, R.mergeRight(fleetAssignment, fleetAssignmentFields));
    const omittedPayload = R.omit(
      [
        GC.FIELD_FLEET_ASSIGNMENT,
        ...GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR,
      ],
      payload,
    );
    const options = {
      data: {
        ...omittedPayload,
        [GC.FIELD_TEL_GUID]: loadGuid,
        [GC.FIELD_FLEET_ASSIGNMENT]: fullFleetAssignment,
      },
    };
    const res = yield call(sendRequest, 'put', endpointsMap.telFleetRate, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadDriverRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadDriverRateRequest exception');
  }
}

function* updateLoadCarrierRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const options = {
      data: R.assoc(GC.FIELD_TEL_GUID, loadGuid, payload),
    };
    let endpoint = endpointsMap.telCarrierRate;
    if (G.isCurrentUserTypeCarrier()) endpoint = endpointsMap.telCarrierRateForCarrier;
    const res = yield call(sendRequest, 'put', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadCarrierRateRequest exception');
  }
}

function* removeLoadDriverRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getTelRateItemEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadDriverRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadDriverRateRequest exception');
  }
}

function* removeLoadCarrierRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getTelRateItemEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadCarrierRateRequest exception');
  }
}

function* selectDriverCarrierRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, telGuid, carrierContractGuid } = payload;

    const endpoint = endpointsMap.getTelRateSelectEndpoint(guid);

    const res = yield call(sendRequest, 'put', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.selectDriverCarrierRateSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      if (G.isNotNilAndNotEmpty(carrierContractGuid)) {
        yield fork(getLoadDetailsRequest, { payload: telGuid });
        const getAction = yield take(A.getLoadDetailsSuccess);
        const billTo = R.path(['payload', GC.SYSTEM_OBJECT_BILL_TO], getAction);
        yield put(A.getLoadBillToSuccess(billTo));
      }
    } else {
      yield call(G.handleFailResponse, res, 'selectDriverCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'selectDriverCarrierRateRequest exception');
  }
}

function* acceptLoadDriverCarrierRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, isCarrierRate } = payload;
    const endpoint = G.ifElse(
      isCarrierRate,
      endpointsMap.getTelCarrierRateAcceptEndpoint,
      endpointsMap.getTelRateAcceptEndpoint,
    )(guid);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'acceptLoadDriverCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'acceptLoadDriverCarrierRateRequest exception');
  }
}

function* declineLoadDriverCarrierRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, isCarrierRate, declineReasonCodeGuid } = payload;
    const endpoint = G.ifElse(
      isCarrierRate,
      endpointsMap.getTelCarrierRateDeclineEndpoint,
      endpointsMap.getTelRateDeclineEndpoint,
    )(guid);
    const options = {
      params: { declineReasonCodeGuid },
    };
    const res = yield call(sendRequest, 'put', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'declineLoadDriverCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'declineLoadDriverCarrierRateRequest exception');
  }
}

function* sendUpdateEdiOrApiRequest() {
  try {
    yield put(openLoader());

    const selectedRateGuid = yield select(makeSelectSelectedCarrierDriverRateGuid());
    const endpoint = endpointsMap.sendCarrierRateUpdate(selectedRateGuid);

    const res = yield call(sendRequest, 'post', endpoint);

    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'sendUpdateEdiOrApiRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendUpdateEdiOrApiRequest exception');
  }
}

// driver invoices
function* getLoadDriverInvoiceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.TEL_FLEET_INVOICE_READ,
      PC.TEL_FLEET_INVOICE_WRITE,
      PC.TEL_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { [GC.FIELD_TEL_GUID]: loadGuid },
    };
    const res = yield all([
      call(sendRequest, 'get', endpointsMap.telInvoice, options),
      call(sendRequest, 'get', endpointsMap.telFleetVendorInvoice, options),
    ]);
    const responseSuccessStatus = R.compose(
      R.all(G.isResponseSuccess),
      R.map(R.prop(GC.FIELD_STATUS)),
    )(res);
    if (responseSuccessStatus) {
      const data = R.compose(
        R.flatten,
        R.map(R.prop('data')),
      )(res);
      yield put(A.getLoadDriverInvoiceListSuccess({ data, tableNumber }));
    } else {
      yield all(res.map((item: Object) =>
        call(G.handleFailResponse, item, 'getLoadDriverInvoiceListRequest fail')),
      );
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadDriverInvoiceListRequest exception');
  }
}

function* createDriverInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const endpoint = G.ifElse(
      R.propEq(GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE, GC.FIELD_TYPE, payload),
      endpointsMap.telFleetVendorInvoice,
      endpointsMap.telFleetInvoice,
    );
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.createDriverInvoiceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createDriverInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createDriverInvoiceRequest exception');
  }
}

function* updateDriverInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { invoice } = payload;

    const options = { data: invoice };

    const endpoint = G.ifElse(
      R.propEq(GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE, GC.FIELD_TYPE, invoice),
      endpointsMap.telFleetVendorInvoice,
      endpointsMap.telFleetInvoice,
    );

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.updateLoadDriverInvoiceSuccess({ invoice: data }));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateDriverInvoiceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateDriverInvoiceRequest exception');
  }
}

function* removeLoadDriverInvoiceRequest({ payload: { guid, type } }: Object) {
  try {
    yield put(openLoader());
    const options = { data: R.of(Array, guid) };
    const endpoint = G.ifElse(
      R.equals(GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE, type),
      endpointsMap.telFleetVendorInvoice,
      endpointsMap.telFleetInvoice,
    );
    const res = yield call(sendRequest, 'delete', endpoint, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.removeLoadDriverInvoiceSuccess(guid));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadDriverInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadDriverInvoiceRequest exception');
  }
}

function* sendDriverInvoiceToFinancialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { type, guids } = payload;
    const loadGuid = yield select(makeSelectLoadGuid());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: { guids, currentEnterprise },
    };
    const endpoint = G.ifElse(
      G.isInvoiceTypeFleetVendorInvoice(type),
      endpointsMap.telFleetVendorInvoiceExportToQuickBooks,
      endpointsMap.driverPayrollFinancialExport,
    );
    const res = yield call(sendRequest, 'post', endpoint, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      }
      // TODO: with success action
      yield put(A.getLoadDriverInvoiceListRequest({ loadGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'sendDriverInvoiceToFinancialRequest fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendDriverInvoiceToFinancialRequest exception');
  }
}

function* printLoadDriverInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guids, documents, templateGuid } = payload;
    const endpoint = endpointsMap.telInvoiceExport;
    const options = {
      data: {
        templateGuid,
        format: 'pdf',
        [GC.FIELD_GUID]: R.head(guids),
        documentsGuids: R.or(documents, []),
      },
    };
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printLoadDriverInvoiceRequest exception');
  }
}

// carrier invoices
function* getLoadCarrierInvoiceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.TEL_CARRIER_INVOICE_READ,
      PC.TEL_CARRIER_INVOICE_WRITE,
      PC.TEL_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { [GC.FIELD_TEL_GUID]: loadGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.telCarrierInvoice, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadCarrierInvoiceListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadCarrierInvoiceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadCarrierInvoiceListRequest exception');
  }
}

function* createLoadCarrierInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.telCarrierInvoice, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.createLoadCarrierInvoiceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadCarrierInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadCarrierInvoiceRequest exception');
  }
}

function* updateLoadCarrierInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.telCarrierInvoice, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.updateLoadCarrierInvoiceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadCarrierInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadCarrierInvoiceRequest exception');
  }
}

function* removeLoadCarrierInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const data = {
      currentEnterprise,
      guids: R.of(Array, payload),
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.telCarrierInvoice, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.removeLoadCarrierInvoiceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeCarrierLoadInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeCarrierLoadInvoiceRequest exception');
  }
}

function* sendCarrierToFinancialRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const loadGuid = yield select(makeSelectLoadGuid());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        currentEnterprise,
        guids: R.of(Array, payload),
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.carrierInvoiceSendToQuickBooks, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      }
      // TODO: with success action
      yield put(A.getLoadCarrierInvoiceListRequest({ loadGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'sendCarrierToFinancialRequest fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendCarrierToFinancialRequest exception');
  }
}

function* printLoadCarrierInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        templateGuid: payload.templateGuid,
        documentsGuids: R.or(payload.documents, []),
      },
    };
    const endpoint = endpointsMap.carrierInvoiceExport;
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printLoadCarrierInvoiceRequest exception');
  }
}

// service vendor invoices
function* getServiceVendorInvoiceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.SERVICE_VENDOR_READ,
      PC.SERVICE_VENDOR_WRITE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;
    const options = {
      params: { [GC.FIELD_TEL_GUID]: loadGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.serviceVendorInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getServiceVendorInvoiceListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getServiceVendorInvoiceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getServiceVendorInvoiceListRequest exception');
  }
}

function* createServiceVendorInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const endpoint = endpointsMap.serviceVendorInvoice;

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.createServiceVendorInvoiceSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createServiceVendorInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createServiceVendorInvoiceRequest exception');
  }
}

function* updateServiceVendorInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const endpoint = endpointsMap.serviceVendorInvoice;

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.updateServiceVendorInvoiceSuccess({ invoice: data }));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateServiceVendorInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateServiceVendorInvoiceRequest exception');
  }
}

function* removeServiceVendorInvoiceRequest({ payload: { guid } }: Object) {
  try {
    yield put(openLoader());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        currentEnterprise,
        guids: R.of(Array, guid),
      },
    };
    const endpoint = endpointsMap.serviceVendorInvoice;

    const res = yield call(sendRequest, 'delete', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadInvoiceTotalsRequest());
      yield put(A.removeServiceVendorInvoiceSuccess(guid));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeServiceVendorInvoiceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeServiceVendorInvoiceRequest exception');
  }
}

function* sendServiceVendorInvoiceToFinancialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        currentEnterprise,
        guids: R.of(Array, payload),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.serviceVendorFinancialExport, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      }
      // TODO: with success action
      yield put(A.getServiceVendorInvoiceListRequest({ loadGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'sendServiceVendorInvoiceToFinancialRequest fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendServiceVendorInvoiceToFinancialRequest exception');
  }
}

function* printServiceVendorInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guids, documents, templateGuid } = payload;
    const endpoint = endpointsMap.serviceVendorInvoiceExport;
    const options = {
      data: {
        templateGuid,
        format: 'pdf',
        [GC.FIELD_GUID]: R.head(guids),
        documentsGuids: R.or(documents, []),
      },
    };
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printServiceVendorInvoiceRequest exception');
  }
}

// customer rates
function* getLoadCustomerRateListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());

    const permissions = [
      PC.CLO_RATE_READ,
      PC.CLO_RATE_WRITE,
    ];

    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;

    const options = {
      params: { [GC.FIELD_TEL_GUID]: loadGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.cloRateList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadCustomerRateListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadCustomerRateListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadCustomerRateListRequest exception');
  }
}

function* createLoadCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.cloRate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createLoadCustomerRateSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadCustomerRateRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadCustomerRateRequest exception');
  }
}

function* updateLoadCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, loadData } = payload;

    const options = { data: values };

    const res = yield call(sendRequest, 'put', endpointsMap.cloRate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateLoadCustomerRateSuccess(data));

      if (G.isNotNil(loadData)) yield put(A.updateLoadDetailsRequest(loadData));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadCustomerRateRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadCustomerRateRequest exception');
  }
}

function* removeLoadCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getCloRateItemEndpoint(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    if (G.isResponseSuccess(res.status)) {
      yield put(closeModal());
      yield put(A.removeLoadCustomerRateSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadCustomerRateRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadCustomerRateRequest exception');
  }
}

function* selectLoadCustomerRateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { entity, loadData } = payload;

    const { guid } = entity;

    const endpoint = endpointsMap.getCloRateSelectEndpoint(guid);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.selectLoadCustomerRateSuccess(entity));

      if (G.isNotNil(loadData)) yield put(A.updateLoadDetailsRequest(loadData));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'selectLoadCustomerRateRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'selectLoadCustomerRateRequest exception');
  }
}

// customer invoices
export function* getLoadCustomerInvoiceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_INVOICE_READ,
      PC.CLO_INVOICE_WRITE,
      PC.CLO_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;

    const telGuid = yield select(makeSelectLoadGuid());
    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: G.ifElse(
          G.isNotNilAndNotEmpty(loadGuid),
          loadGuid,
          telGuid,
        ),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.customerInvoiceList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadCustomerInvoiceListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadCustomerInvoiceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadCustomerInvoiceListRequest exception');
  }
}

function* createLoadCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const loadGuid = yield select(makeSelectLoadGuid());
    const loadConfigs = yield select(makeSelectLoadConfigs());

    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.cloInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createLoadCustomerInvoiceSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      if (G.isTrue(getSyncRateFromCustomerInvoiceValue(loadConfigs))) {
        yield put(A.getLoadCustomerRateListRequest({ loadGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'createLoadCustomerInvoiceRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadCustomerInvoiceRequest exception');
  }
}

function* updateLoadCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const loadGuid = yield select(makeSelectLoadGuid());
    const loadConfigs = yield select(makeSelectLoadConfigs());

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.cloInvoice, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateLoadCustomerInvoiceSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      if (G.isTrue(getSyncRateFromCustomerInvoiceValue(loadConfigs))) {
        yield put(A.getLoadCustomerRateListRequest({ loadGuid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadCustomerInvoiceRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadCustomerInvoiceRequest exception');
  }
}

function* removeLoadCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const data = {
      currentEnterprise,
      guids: R.of(Array, payload),
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.cloInvoice, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeLoadCustomerInvoiceSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadCustomerInvoiceRequest fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadCustomerInvoiceRequest exception');
  }
}

export function* getLinkedCustomerInvoiceListRequest({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [
      PC.CLO_INVOICE_READ,
      PC.CLO_INVOICE_WRITE,
      PC.CLO_INVOICE_OVERWRITE_EXECUTE,
    ];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const { loadGuid, tableNumber } = payload;

    const telGuid = yield select(makeSelectLoadGuid());
    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: G.ifElse(
          G.isNotNilAndNotEmpty(loadGuid),
          loadGuid,
          telGuid,
        ),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telLinkedCloInvoices, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLinkedCustomerInvoiceListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'getLinkedCustomerInvoiceListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLinkedCustomerInvoiceListRequest exception');
  }
}

function* sendCIToFinancialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        currentEnterprise,
        guids: R.of(Array, payload),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.customerInvoiceFinancialExport, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:update');
      }
      yield put(A.getLoadCustomerInvoiceListRequest({ loadGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleSendCIToFinancialSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSendCIToFinancialSaga exception');
  }
}

function* printLoadCustomerInvoiceRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.customerInvoiceExport;
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        templateGuid: payload.templateGuid,
        documentsGuids: R.or(payload.documents, []),
        [GC.FIELD_VENDOR_DOCUMENT_GUIDS]: R.pathOr([], [GC.FIELD_VENDOR_DOCUMENT_GUIDS], payload),
      },
    };
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printLoadCustomerInvoiceRequest exception');
  }
}

// external system
const getEventData = (eventGuid: string, events: Array) => {
  const event = R.find(R.propEq(eventGuid, GC.FIELD_GUID), events);

  if (G.isNilOrEmpty(event)) return null;

  const { loadType, eventType } = event;

  const orderProp = G.getEventIndexPropByLoadType(loadType);

  const order = G.getPropFromObject(orderProp, event);

  const stopTypeLocaleMap = {
    [GC.EVENT_TYPE_DROP]: 'titles:drop',
    [GC.EVENT_TYPE_PICKUP]: 'titles:pickup',
  };

  const address = G.concatLocationFields(G.getLocationFromObject(event));

  return `
    ${G.getWindowLocale(stopTypeLocaleMap[eventType], eventType)}:
    ${G.getWindowLocale('titles:stop', 'stop')} ${order}.
    ${address}
  `;
};

function* getExternalSystemListRequest({ payload }: Object) {
  try {
    const { loadGuid, tableNumber } = payload;

    const options = {
      params: { [GC.FIELD_TEL_GUID]: loadGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.getExternalSystemList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const events = yield select(makeSelectEvents());

      const mapped = R.map(
        (item: Object) => R.assoc(GC.FIELD_STOP, getEventData(G.getPropFromObject('eventGuid', item), events), item),
        data,
      );

      yield put(A.getExternalSystemListSuccess({ tableNumber, data: mapped }));
    } else {
      yield call(G.handleFailResponse, res, 'getExternalSystemListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getExternalSystemListRequest exception');
  }
}

function* removeTelFromExternalSystemRequest({ payload }: Object) {
  try {
    const { guid, telGuid, integrationType } = payload;

    yield put(openLoader());
    const options = {
      params: { telGuid, integrationType },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.removeFromExternalSystem, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeTelFromExternalSystemSuccess(guid));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeTelFromExternalSystemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeTelFromExternalSystemRequest exception');
  }
}

// configs
function* getStatusMessagesConfigListRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.statusCodeList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getStatusMessagesConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getStatusMessagesConfigListRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getStatusMessagesConfigListRequest');
  }
}

function* getLoadConfigsByNamesRequest({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      const defaultNotesCard = G.getConfigValueFromStore(GC.UI_ORDER_TRIP_DEFAULT_NOTES_CARD, mappedConfigs);

      yield call(G.setAmousConfigToWindow, mappedConfigs);

      yield put(A.getLoadConfigsByNamesSuccess(mappedConfigs));

      if (R.and(G.isTrue(defaultNotesCard), R.not(G.isCurrentUserTypeCarrier()))) {
        yield put(A.changeMessageCenterActiveTabSuccess('noteList'));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getLoadConfigsByNamesRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadConfigsByNamesRequest exception');
  }
}

function* getLoadConfigByGroupNamesRequest({ payload }: Object) {
  try {
    const groups = [
      GC.UI_CONFIG_GROUP,
      GC.GENERAL_CONFIG_GROUP,
      GC.INVOICE_CONFIG_GROUP,
      GC.COMMUNICATION_CONFIG_GROUP,
    ];
    const res = yield all(groups.map((group: Object) => call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      {
        params: {
          group,
          [GC.BRANCH_GUID]: payload,
        },
      },
    )));
    const status = R.all(({ status }: Object) => G.isResponseSuccess(status), res);
    if (status) {
      const configs = R.compose(
        R.map(({ data }: Object) => G.mapConfigValuesByName(data)),
        R.indexBy(R.path(['config', 'params', 'group'])),
      )(res);
      yield put(A.getLoadConfigByGroupNamesSuccess(configs));
    } else {
      yield all(res.map((item: Object) => call(G.handleFailResponse, item, 'getLoadConfigByGroupNamesRequest fail')));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getRouteConfigByGroupSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* getDataAndConfigsByBranchGuidRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.getLoadConfigByGroupNamesRequest(payload));
    yield put(A.getStatusMessagesConfigListRequest(payload));
    yield put(A.getLoadConfigsByNamesRequest({
      branchGuid: payload,
      names: R.join(',', [GC.GROUPED_CONFIGS.LOAD_CONFIGS_ARR_FOR_DISPATCH_DETAILS]),
    }));
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getDataAndConfigsByBranchGuidRequest exception');
  }
}

// dispatch
function* dispatchLoadRateRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { telGuid, action } = payload;
    const endpoint = endpointsMap.getTelActionEndpoint(telGuid, action);
    const res = yield call(sendRequest, 'put', endpoint);
    const { status } = res;
    if (G.isResponseFail(status)) {
      yield call(G.handleFailResponse, res, 'dispatchLoadRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'dispatchLoadRateRequest exception');
  }
}

function* dispatchLoadCarrierRateRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.telCarrierRateDispatch;
    const res = yield call(sendRequest, 'put', endpoint, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'dispatchLoadCarrierRateRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'dispatchLoadCarrierRateRequest  exception');
  }
}

function* approveLoadRateRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { telGuid } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.telRateApprove, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getLoadDriverCarrierRateListRequest({ [GC.FIELD_LOAD_GUID]: telGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'approveLoadRateRequest fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'approveLoadRateRequest  exception');
  }
}

function* printDispatchDocumentRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: {
        format: 'pdf',
        guid: R.head(payload.guids),
        ...R.pick([GC.FIELD_TEMPLATE_GUID, 'eventGuids'], payload),
      },
    };
    const endpoint = endpointsMap.telDispatchDocumentExport;
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL });
    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'printDispatchDocumentRequest exception');
  }
}

function* handlePrintLoadDocumentsSaga({ payload }: Object) {
  try {
    const { loadGuid, fileName, documents, documentType } = payload;
    const options = {
      data: {
        loadGuid,
        fileName,
        documentType,
        format: 'pdf',
        documentsGuids: documents,
      },
    };
    const endpoint = endpointsMap.telExportDocuments;
    yield call(printDocumentRequest, { options, endpoint, [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL });
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintLoadDocumentsSaga exception');
  }
}

function* handleSuspendResumeCarrierRateEmailsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, endpointName } = payload;
    const endpoint = R.prop(endpointName, endpointsMap)(guid);
    const res = yield call(sendRequest, 'put', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateLoadDriverCarrierRateSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleSuspendResumeCarrierRateEmailsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleSuspendResumeCarrierRateEmailsSaga exception');
  }
}

// mails
function* getLoadMailListRequest() {
  try {
    const loadGuid = yield select(makeSelectLoadGuid());
    if (G.isNilOrEmpty(loadGuid)) return false;
    const options = {
      params: { loadGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.mailSending, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadMailListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadMailListRequest fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadMailListRequest exception');
  }
}

// message center
function* getLoadChatMessageListRequest({ payload, notSwitchTab }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());

    const permissions = [
      PC.TEL_CHAT_MESSAGE_READ,
      PC.TEL_CHAT_MESSAGE_WRITE,
    ];

    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return yield put(A.getLoadNoteListRequest(payload));

    const options = {
      params: { loadGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telChatMessageList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadChatMessageListSuccess(data));

      if (R.not(notSwitchTab)) yield put(A.changeMessageCenterActiveTabSuccess('chatMessageList'));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadChatMessageListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getLoadChatMessageListRequest exception');
  }
}

function* getLoadNoteListRequest({ payload, notSwitchTab }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_LOAD_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telNoteList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadNoteListSuccess(data));

      if (R.not(notSwitchTab)) yield put(A.changeMessageCenterActiveTabSuccess('noteList'));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadNoteListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadNoteListRequest exception');
  }
}

function* changeMessageCenterActiveTab({ payload }: Object) {
  try {
    const loadGuid = yield select(makeSelectLoadGuid());
    const messageCenter = yield select(makeSelectMessageCenter());

    if (G.isNilOrEmpty(messageCenter[payload])) {
      const messageCenterActionMap = {
        noteList: A.getLoadNoteListRequest,
        chatMessageList: A.getLoadChatMessageListRequest,
      };

      yield put(messageCenterActionMap[payload](loadGuid));
    } else {
      yield put(A.changeMessageCenterActiveTabSuccess(payload));
    }
  } catch (error) {
    yield call(G.handleException, error, 'changeMessageCenterActiveTab exception');
  }
}

function* createLoadNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.telNote, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createLoadNoteSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadNoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadNoteRequest exception');
  }
}

function* createLoadChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'post', endpointsMap.telChatMessage, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadChatMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadChatMessageRequest exception');
  }
}

function* updateLoadChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.telChatMessage, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadChatMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadChatMessageRequest exception');
  }
}

function* updateLoadNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.telNote, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateLoadNoteSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadNoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadNoteRequest exception');
  }
}

function* removeLoadChatMessageRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getTelChatMessageEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadChatMessageRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadChatMessageRequest exception');
  }
}

function* removeLoadNoteRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.getTelNoteEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeLoadNoteSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'removeLoadNoteRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeLoadNoteRequest exception');
  }
}

// payments
function* updateLoadBillToRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = { data: payload };
    const res = yield call(sendRequest, 'put', endpointsMap.telBillTo, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadBillToSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:update');
    } else {
      yield call(G.handleFailResponse, res, 'updateLoadBillToRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateLoadBillToRequest exception');
  }
}

function* createLoadBillToRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const loadGuid = yield select(makeSelectLoadGuid());
    const options = { data: R.assoc(GC.FIELD_LOAD_GUID, loadGuid, payload) };
    const res = yield call(sendRequest, 'post', endpointsMap.telBillTo, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadBillToSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'createLoadBillToRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createLoadBillToRequest exception');
  }
}

// appointment
const fieldsToPickUpdateAppts = [GC.FIELD_STOP_NUMBER, ...GC.GROUPED_FIELDS.APPOINTMENTS_PICK_ARR];

function* handleUpdateAppointmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      data: R.pick(fieldsToPickUpdateAppts, payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.telEventAppointment, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.updateAppointmentSuccess(data));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTelEventAppointmentRequest');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleUpdateTelEventAppointmentRequest');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}

// notifications
function* getLoadNotificationListRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        offset: 0,
        limit: 1000,
        objectGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.notificationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadNotificationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getLoadNotificationListRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getLoadNotificationListRequest');
  }
}

function* hideLoadNotificationsRequest() {
  try {
    const { list } = yield select(makeSelectNotification());
    const unreadNotifications = R.compose(
      R.map(G.getGuidFromObject),
      R.filter(({ read }: Object) => G.isFalse(read)),
    )(list);
    const data = { read: true, guids: unreadNotifications };
    const options = { data };
    const res = yield call(sendRequest, 'put', endpointsMap.notificationMark, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(getUnreadCountRequest());
      yield put(markAllAsReadSuccess(data));
      yield put(A.hideLoadNotificationsSuccess());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'hideLoadNotificationsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'hideLoadNotificationsRequest exception');
  }
}

function* handleReadLoadNotification({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid } = payload;

    const options = {
      data: {
        read: true,
        guids: [guid],
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.notificationMark, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.markLoadNotificationAsReadSuccess(guid));
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleReadLoadNotification exception');
  } finally {
    yield put(closeLoader());
  }
}

// lists
const getListActionsMap = {
  [C.ACTIVE_LIST_ITEMS]: A.getLoadItemListRequest,
  [C.ACTIVE_LIST_MACRO_POINT]: A.getMacroPointListRequest,
  [C.ACTIVE_LIST_LOAD_BOARDS]: A.getPostedShipmentsRequest,
  [C.ACTIVE_LIST_REFERENCES]: A.getLoadReferenceListRequest,
  [C.ACTIVE_LIST_CUSTOMER_RATES]: A.getLoadCustomerRateListRequest,
  [C.ACTIVE_LIST_STATUS_MESSAGES]: A.getLoadStatusMessageListRequest,
  [C.ACTIVE_LIST_DRIVER_INVOICES]: A.getLoadDriverInvoiceListRequest,
  [C.ACTIVE_LIST_ADVANCE_PAYMENT]: A.getLoadAdvancePaymentListRequest,
  [C.ACTIVE_LIST_EXTERNAL_SYSTEM_LIST]: A.getExternalSystemListRequest,
  [C.ACTIVE_LIST_CARRIER_INVOICES]: A.getLoadCarrierInvoiceListRequest,
  [C.ACTIVE_LIST_LINKED_ORDER_REF_LIST]: A.getLinkedOrderRefListRequest,
  [C.ACTIVE_LIST_CUSTOMER_INVOICES]: A.getLoadCustomerInvoiceListRequest,
  [C.ACTIVE_LIST_CARRIER_FLEET_RATES]: A.getLoadDriverCarrierRateListRequest,
  [C.ACTIVE_LIST_SERVICE_VENDOR_INVOICES]: A.getServiceVendorInvoiceListRequest,
  [C.ACTIVE_LIST_LINKED_CUSTOMER_INVOICES]: A.getLinkedCustomerInvoiceListRequest,
  [C.ACTIVE_LIST_CROSS_BORDER_INTEGRATION_LIST]: A.getTelCrossBorderIntegrationListRequest,
};

function* changeActiveList({ payload }: Object) {
  try {
    const { listName, tableNumber } = payload;
    const lists = yield select(makeSelectLists());
    const loadGuid = yield select(makeSelectLoadGuid());
    const list = R.or(R.path([listName, 'list'], lists), R.path([listName], lists));
    if (R.and(
      G.isNilOrEmpty(list),
      G.notContain(
        listName,
        [
          C.ACTIVE_LIST_ITEMS,
          C.ACTIVE_LIST_CONTAINERS,
          C.ACTIVE_LIST_VEHICLE_ITEMS,
        ],
      ),
    )) {
      yield put(getListActionsMap[listName]({ loadGuid, tableNumber }));
    } else if (R.equals(listName, C.ACTIVE_LIST_STATUS_MESSAGES)) {
      yield put(A.getLoadStatusMessageListRequest({ loadGuid, tableNumber }));
    } else {
      yield put(A.changeActiveListSuccess({ listName, tableNumber }));
    }
  } catch (error) {
    yield call(G.handleException, error, 'changeActiveList exception');
  }
}

// related orders
function* handleGetRelatedOrderListSaga({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.CLO_READ, PC.CLO_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);
    if (R.not(permission)) return;
    const options = {
      params: {
        telGuid: payload,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.cloDispatchBoardByTelGuid, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getRelatedOrderListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetRelatedOrderListSaga');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetRelatedOrderListSaga');
  }
}

// linked orders
function* handleGetLinkedOrderListSaga({ payload }: Object) {
  try {
    const authorities = yield select(makeSelectAuthorities());
    const permissions = [PC.CLO_READ, PC.CLO_WRITE];
    const permission = G.checkStringsContains(permissions, authorities);

    if (R.not(permission)) return;

    const options = {
      params: {
        telGuid: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telLinkedCloCloInfos, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLinkedOrderListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetLinkedOrderListSaga');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetLinkedOrderListSaga');
  }
}


// claim
function* getClaimGeneralDetailsRequest({ payload }: Object) {
  try {
    if (G.hasNotAmousCurrentUserPermissions([PC.CLAIM_READ, PC.CLAIM_WRITE])) return;

    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.claimGeneralDetailsForDispatchDetails, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getClaimGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getClaimGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getClaimGeneralDetailsRequest exception');
  }
}

// load board posted shipments
function* handleGetPostedShipmentsSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { loadGuid, tableNumber } = payload;

    const options = {
      params: { [GC.FIELD_OBJECT_GUID]: loadGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.postedShipment, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getPostedShipmentsSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetPostedShipmentsSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetPostedShipmentsSaga exception');
  }
}

function* handleDeletePostedShipmentSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.postedShipmentById(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deletePostedShipmentSuccess());
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeletePostedShipmentSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeletePostedShipmentSaga exception');
  }
}

// cross border integration
function* handleGetTelCrossBorderIntegrationListRequest({ payload }: Object) {
  try {
    const { tableNumber } = payload;

    const telGuid = yield select(makeSelectLoadGuid());

    const options = {
      params: {
        [GC.FIELD_TEL_GUID]: R.pathOr(telGuid, [GC.FIELD_TEL_GUID], payload),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.telCrossBorderIntegrationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTelCrossBorderIntegrationListSuccess({ data, tableNumber }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelCrossBorderIntegrationListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetTelCrossBorderIntegrationListRequest exception');
  }
}

function* handleCreateCrossBorderIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { clos, guid, values, saveAndSend } = payload;

    const options = {
      data: {
        ...values,
        clos,
        [GC.FIELD_TEL_GUID]: guid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telCrossBorderIntegration, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      if (G.isTrue(saveAndSend)) {
        yield put(sendCrossBorderIntegrationRequest(data));
      } else {
        yield put(A.refreshTelCrossBorderIntegrationSuccess(data));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCrossBorderIntegrationRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCrossBorderIntegrationRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* handleUpdateCrossBorderIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { clos, values, saveAndSend } = payload;

    const reqBody = {
      data: {
        ...values,
        clos,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.telCrossBorderIntegration, reqBody);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      if (G.isTrue(saveAndSend)) {
        yield put(sendCrossBorderIntegrationRequest(data));
      }

      yield put(A.updateCrossBorderIntegrationSuccess(data));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCrossBorderIntegrationRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCrossBorderIntegrationRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* handleRemoveCrossBorderIntegrationRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.removeTelCrossBorderIntegration(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.removeCrossBorderIntegrationSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:remove');
    } else {
      yield call(G.handleFailResponse, res, 'handleRemoveCrossBorderIntegrationRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRemoveCrossBorderIntegrationRequest exception');
  } finally {
    yield put(closeLoader());
  }
}

function* handleGetIsAnyEnabledCrossBorderRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        enterpriseGuid: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.crossBorderIntegrationConfigIsAnyEnabled, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getIsAnyEnabledCrossBorderSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetIsAnyEnabledCrossBorderRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetIsAnyEnabledCrossBorderRequest exception');
  }
}

// socket
function* subscribeToSocketSaga({ payload }: Object) {
  try {
    if (G.isTrue(payload)) {
      const guid = yield select(makeSelectLoadGuid());

      yield put(socketPostMessage({
        destination: `/tel/${guid}`,
        action: messageType.subscribe,
      }));
    }
  } catch (error) {
    yield call(G.handleException, error, 'subscribeToSocketSaga exception');
  }
}

// visit page
function* visitDispatchDetailsLoadPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DISPATCH_DETAILS_LOAD_PAGE);
    const { guid } = payload;

    // TODO: remove or uncomment after testing sockets-v2
    // const currentUser = yield select(makeSelectCurrentUserData());
    // yield put(socketTelConnectRequest({ currentUser, telGuid: guid }));

    yield put(A.setInitialState());
    yield put(openLoader());
    yield put(A.setInitialState());

    yield fork(getLoadDetailsRequest, { payload: guid });

    const getTelAction = yield take(A.getLoadDetailsSuccess);

    const branchGuid = R.path(['payload', GC.BRANCH_GUID], getTelAction);

    const socketConnected = yield select(makeSelectSocketConnected());

    if (socketConnected) {
      yield put(socketPostMessage({
        destination: `/tel/${guid}`,
        action: messageType.subscribe,
      }));
    }

    yield takeLatest(setSocketConnection, subscribeToSocketSaga);

    yield all([
      put(getAvailableDocumentTypes()),
      put(getServiceMappingListRequest()),
      put(A.getLinkedOrderListRequest(guid)),
      put(A.getRelatedOrderListRequest(guid)),
      put(A.getLoadDocumentListRequest(guid)),
      put(A.getClaimGeneralDetailsRequest(guid)),
      put(A.getLoadNotificationListRequest(guid)),
      put(A.getLoadLinkedOrdersDocumentListRequest(guid)),
      put(A.getIsAnyEnabledCrossBorderRequest(branchGuid)),
      put(getTelDispatchBoardActionListRequest(branchGuid)),
      put(A.getDataAndConfigsByBranchGuidRequest(branchGuid)),
      put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_TEL)),
      put(A.getLoadCustomerRateListRequest({ [GC.FIELD_LOAD_GUID]: guid })),
      put(A.getLoadDriverCarrierRateListRequest({ [GC.FIELD_LOAD_GUID]: guid })),
      call(getLoadNoteListRequest, { payload: guid, notSwitchTab: true }),
      call(getLoadChatMessageListRequest, { payload: guid, notSwitchTab: true }),
    ]);

    yield put(closeLoader());
    break;
  }
}

function* loadDetailsNewWatcherSaga() {
  yield takeLatest(A.changeActiveList, changeActiveList);
  yield takeLatest(A.getLoadDetailsRequest, getLoadDetailsRequest);
  yield takeLatest(A.updateLoadDetailsRequest, updateLoadDetailsRequest);
  yield takeLatest(A.getLoadInvoiceTotalsRequest, getLoadInvoiceTotalsRequest);
  yield takeLatest(GC.VISIT_DISPATCH_DETAILS_LOAD_PAGE, visitDispatchDetailsLoadPage);
  // configs
  yield takeLatest(A.getLoadConfigsByNamesRequest, getLoadConfigsByNamesRequest);
  yield takeLatest(A.getLoadConfigByGroupNamesRequest, getLoadConfigByGroupNamesRequest);
  yield takeLatest(A.getStatusMessagesConfigListRequest, getStatusMessagesConfigListRequest);
  yield takeLatest(A.getDataAndConfigsByBranchGuidRequest, getDataAndConfigsByBranchGuidRequest);
  // events
  yield takeLatest(A.getLoadEventsRequest, getLoadEventsSaga);
  // items
  yield takeLatest(A.toggleLoadVehicleItemRequest, toggleLoadVehicleItemRequest);
  // references
  yield takeLatest(A.updateLoadReferenceRequest, updateLoadReferenceRequest);
  yield takeLatest(A.createLoadReferenceRequest, createLoadReferenceRequest);
  yield takeLatest(A.removeLoadReferenceRequest, removeLoadReferenceRequest);
  yield takeLatest(A.getLoadReferenceListRequest, getLoadReferenceListRequest);
  yield takeLatest(A.getLinkedOrderRefListRequest, getLinkedOrderRefListRequest);
  // status messages
  yield takeLatest(A.removeLoadStatusMessageRequest, removeLoadStatusMessageRequest);
  yield takeLatest(A.updateLoadStatusMessageRequest, updateLoadStatusMessageRequest);
  yield takeLatest(A.createLoadStatusMessageRequest, createLoadStatusMessageRequest);
  yield takeLatest(A.getLoadStatusMessageListRequest, getLoadStatusMessageListRequest);
  yield takeLatest(A.createLoadMultipleStatusMessageRequest, createLoadMultipleStatusMessageRequest);
  // macro point
  yield takeLatest(A.getMacroPointListRequest, getMacroPointListRequest);
  // advance payment
  yield takeLatest(A.createLoadAdvancePaymentRequest, createLoadAdvancePaymentRequest);
  yield takeLatest(A.getLoadAdvancePaymentListRequest, getLoadAdvancePaymentListRequest);
  yield takeLatest(A.updateLoadAdvancePaymentStatusRequest, updateLoadAdvancePaymentStatusRequest);
  // driver/carrier rates
  yield takeLatest(A.sendUpdateEdiOrApiRequest, sendUpdateEdiOrApiRequest);
  yield takeLatest(A.updateLoadDriverRateRequest, updateLoadDriverRateRequest);
  yield takeLatest(A.removeLoadDriverRateRequest, removeLoadDriverRateRequest);
  yield takeLatest(A.createLoadDriverRateRequest, createLoadDriverRateRequest);
  yield takeLatest(A.removeLoadCarrierRateRequest, removeLoadCarrierRateRequest);
  yield takeLatest(A.updateLoadCarrierRateRequest, updateLoadCarrierRateRequest);
  yield takeLatest(A.createLoadCarrierRateRequest, createLoadCarrierRateRequest);
  yield takeLatest(A.selectDriverCarrierRateRequest, selectDriverCarrierRateRequest);
  yield takeLatest(A.acceptLoadDriverCarrierRateRequest, acceptLoadDriverCarrierRateRequest);
  yield takeLatest(A.declineLoadDriverCarrierRateRequest, declineLoadDriverCarrierRateRequest);
  yield takeLatest(A.getLoadDriverCarrierRateListRequest, getLoadDriverCarrierRateListRequest);
  // driver invoices
  yield takeLatest(A.updateDriverInvoiceRequest, updateDriverInvoiceRequest);
  yield takeLatest(A.createDriverInvoiceRequest, createDriverInvoiceRequest);
  yield takeLatest(A.printLoadDriverInvoiceRequest, printLoadDriverInvoiceRequest);
  yield takeLatest(A.removeLoadDriverInvoiceRequest, removeLoadDriverInvoiceRequest);
  yield takeLatest(A.getLoadDriverInvoiceListRequest, getLoadDriverInvoiceListRequest);
  yield takeLatest(A.sendDriverInvoiceToFinancialRequest, sendDriverInvoiceToFinancialRequest);
  // carrier invoices
  yield takeLatest(A.sendCarrierToFinancialRequest, sendCarrierToFinancialRequest);
  yield takeLatest(A.printLoadCarrierInvoiceRequest, printLoadCarrierInvoiceRequest);
  yield takeLatest(A.removeLoadCarrierInvoiceRequest, removeLoadCarrierInvoiceRequest);
  yield takeLatest(A.updateLoadCarrierInvoiceRequest, updateLoadCarrierInvoiceRequest);
  yield takeLatest(A.createLoadCarrierInvoiceRequest, createLoadCarrierInvoiceRequest);
  yield takeLatest(A.getLoadCarrierInvoiceListRequest, getLoadCarrierInvoiceListRequest);
  // service vendor invoices
  yield takeLatest(A.printServiceVendorInvoiceRequest, printServiceVendorInvoiceRequest);
  yield takeLatest(A.createServiceVendorInvoiceRequest, createServiceVendorInvoiceRequest);
  yield takeLatest(A.updateServiceVendorInvoiceRequest, updateServiceVendorInvoiceRequest);
  yield takeLatest(A.removeServiceVendorInvoiceRequest, removeServiceVendorInvoiceRequest);
  yield takeLatest(A.getServiceVendorInvoiceListRequest, getServiceVendorInvoiceListRequest);
  yield takeLatest(A.sendServiceVendorInvoiceToFinancialRequest, sendServiceVendorInvoiceToFinancialRequest);
  // customer invoices
  yield takeLatest(A.sendCIToFinancialRequest, sendCIToFinancialRequest);
  yield takeLatest(A.printLoadCustomerInvoiceRequest, printLoadCustomerInvoiceRequest);
  yield takeLatest(A.updateLoadCustomerInvoiceRequest, updateLoadCustomerInvoiceRequest);
  yield takeLatest(A.createLoadCustomerInvoiceRequest, createLoadCustomerInvoiceRequest);
  yield takeLatest(A.removeLoadCustomerInvoiceRequest, removeLoadCustomerInvoiceRequest);
  yield takeLatest(A.getLoadCustomerInvoiceListRequest, getLoadCustomerInvoiceListRequest);
  yield takeLatest(A.getLinkedCustomerInvoiceListRequest, getLinkedCustomerInvoiceListRequest);
  // customer rates
  yield takeLatest(A.selectLoadCustomerRateRequest, selectLoadCustomerRateRequest);
  yield takeLatest(A.removeLoadCustomerRateRequest, removeLoadCustomerRateRequest);
  yield takeLatest(A.updateLoadCustomerRateRequest, updateLoadCustomerRateRequest);
  yield takeLatest(A.createLoadCustomerRateRequest, createLoadCustomerRateRequest);
  yield takeLatest(A.getLoadCustomerRateListRequest, getLoadCustomerRateListRequest);
  // external system
  yield takeLatest(A.getExternalSystemListRequest, getExternalSystemListRequest);
  yield takeLatest(A.removeTelFromExternalSystemRequest, removeTelFromExternalSystemRequest);
  // documents
  yield takeLatest(A.getLoadXMLRequest, getLoadXMLRequest);
  yield takeLatest(A.printDocumentRequest, printDocumentRequest);
  yield takeLatest(A.removeLoadDocumentRequest, removeLoadDocumentRequest);
  yield takeLatest(A.updateLoadDocumentRequest, updateLoadDocumentRequest);
  yield takeLatest(A.getLoadDocumentListRequest, getLoadDocumentListRequest);
  yield takeLatest(A.printLoadDocumentsRequest, handlePrintLoadDocumentsSaga);
  yield takeLatest(A.downloadLoadDocumentRequest, downloadLoadDocumentRequest);
  yield takeLatest(A.getLoadLinkedOrdersDocumentListRequest, getLoadLinkedOrdersDocumentListRequest);
  // dispatch/approve
  yield takeLatest(A.approveLoadRateRequest, approveLoadRateRequest);
  yield takeLatest(A.dispatchLoadRateRequest, dispatchLoadRateRequest);
  yield takeLatest(A.printDispatchDocumentRequest, printDispatchDocumentRequest);
  yield takeLatest(A.dispatchLoadCarrierRateRequest, dispatchLoadCarrierRateRequest);
  yield takeLatest(A.suspendResumeCarrierRateEmailsRequest, handleSuspendResumeCarrierRateEmailsSaga);
  // mails
  yield takeLatest(A.getLoadMailListRequest, getLoadMailListRequest);
  yield takeLatest(createMailSendingSuccess, getLoadMailListRequest);
  // message center
  yield takeLatest(A.updateLoadNoteRequest, updateLoadNoteRequest);
  yield takeLatest(A.removeLoadNoteRequest, removeLoadNoteRequest);
  yield takeLatest(A.createLoadNoteRequest, createLoadNoteRequest);
  yield takeLatest(A.getLoadNoteListRequest, getLoadNoteListRequest);
  yield takeLatest(A.updateLoadChatMessageRequest, updateLoadChatMessageRequest);
  yield takeLatest(A.removeLoadChatMessageRequest, removeLoadChatMessageRequest);
  yield takeLatest(A.changeMessageCenterActiveTab, changeMessageCenterActiveTab);
  yield takeLatest(A.createLoadChatMessageRequest, createLoadChatMessageRequest);
  yield takeLatest(A.getLoadChatMessageListRequest, getLoadChatMessageListRequest);
  // payments
  yield takeLatest(A.updateLoadBillToRequest, updateLoadBillToRequest);
  yield takeLatest(A.createLoadBillToRequest, createLoadBillToRequest);
  // appointment
  yield takeLatest(A.updateAppointmentRequest, handleUpdateAppointmentSaga);
  // notifications
  yield takeLatest(A.hideLoadNotificationsRequest, hideLoadNotificationsRequest);
  yield takeLatest(A.markLoadNotificationAsReadRequest, handleReadLoadNotification);
  yield takeLatest(A.getLoadNotificationListRequest, getLoadNotificationListRequest);
  // related/linked orders
  yield takeLatest(A.getLinkedOrderListRequest, handleGetLinkedOrderListSaga);
  yield takeLatest(A.getRelatedOrderListRequest, handleGetRelatedOrderListSaga);
  yield takeLatest(A.getCloReferencesByEventGuidsRequest, getCloReferencesByEventGuidsSaga);
  // load board posted shipments
  yield takeLatest(A.getPostedShipmentsRequest, handleGetPostedShipmentsSaga);
  yield takeLatest(A.deletePostedShipmentRequest, handleDeletePostedShipmentSaga);
  // claim
  yield takeLatest(A.getClaimGeneralDetailsRequest, getClaimGeneralDetailsRequest);
  // cross border integration
  yield takeLatest(A.getIsAnyEnabledCrossBorderRequest, handleGetIsAnyEnabledCrossBorderRequest);
  yield takeLatest(A.createCrossBorderIntegrationRequest, handleCreateCrossBorderIntegrationRequest);
  yield takeLatest(A.updateCrossBorderIntegrationRequest, handleUpdateCrossBorderIntegrationRequest);
  yield takeLatest(A.removeCrossBorderIntegrationRequest, handleRemoveCrossBorderIntegrationRequest);
  yield takeLatest(A.getTelCrossBorderIntegrationListRequest, handleGetTelCrossBorderIntegrationListRequest);
}

export default loadDetailsNewWatcherSaga;
