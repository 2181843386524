import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { TextComponent } from '../../components/text';
import EditableBox from '../../components/editable-box';
import { getButtonsStyles } from '../../components/page-actions';
// features
import { withPendingActivity } from '../drivers-card/hocs/with-pending-activity';
import { withUnavailablePeriod } from '../drivers-card/hocs/with-unavailable-period';
import { DriverAvailability } from '../available-driver/components/driver-availability';
// forms
import { Toggle } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { DRIVER_TYPE_OPTIONS } from '../../helpers/options';
// hocs
import { withComponentDidUpdatePropCallback, withConnectModalAndLoaderActions } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, FixedBox, ActionButton } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature driver-profile-simple
import PayrollInfo from './components/payroll-info';
import AssignInfoComponent from './components/assign-info';
import LicenseNumberComponent from './components/license-number';
import {
  makeSelectDriverProfile,
  makeSelectDriverTruckGuid,
  makeSelectDriverGroupGuid,
  makeSelectDriverProfileGuid,
  makeSelectOpenedDriverProfile,
  makeSelectDriverPrimaryDriver,
  makeSelectDriverDispatcherGuid,
  makeSelectDriverPendingActivities,
  makeSelectDriverUnavailablePeriods,
  makeSelectDriverAvailabilityStatus,
} from './selectors';
import {
  setValueToStore,
  closeDriverProfile,
  toggleDriverFuelCards,
  toggleDriverCertificates,
  getDriverFuelCardsRequest,
  getDriverProfileInfoRequest,
  toggleDriverFuelCardRequest,
  getDriverCertificatesRequest,
  getDriverAvailabilityStatusRequest,
} from './actions';
import {
  Avatar,
  LastTrip,
  InfoPair,
  FuelCards,
  DriverHours,
  Certificates,
  ContactInfoPair,
  PendingActivities,
  UnavailablePeriods,
  InfoPairWithHandler,
  LatestKnownLocation,
  TruckAdditionalEquipment,
} from './components';
//////////////////////////////////////////////////

const bgGreyColor = G.getTheme('colors.bgGrey');
const blackColor = G.getTheme('colors.light.black');
const textColor = G.getTheme('colors.greyMatterhorn');
const textStyles = {
  display: 'block',
  color: textColor,
  withEllipsis: true,
  whiteSpace: 'nowrap',
};

const DriverProfileComponent = (props: Object) => {
  const {
    openLoader,
    closeLoader,
    driverProfile,
    setValueToStore,
    isLocationRequired,
    closeDriverProfile,
    hidePendingActivities,
    showExpeditedDivision,
    showDriverAvailability,
    driverPendingActivities,
    driverUnavailablePeriods,
    handleAddPendingActivity,
    driverAvailabilityStatus,
    handleAddUnavailablePeriod,
    toggleDriverFuelCardRequest,
    handleToggleDriverFuelCards,
    handleUpdatePendingActivity,
    handleRemovePendingActivity,
    showTruckAdditionalEquipment,
    handleUpdateUnavailablePeriod,
    handleRemoveUnavailablePeriod,
    handleToggleDriverCertificates,
  } = props;

  const {
    guid,
    email,
    truck,
    loginId,
    license,
    comments,
    trailers,
    lastTrip,
    lastName,
    firstName,
    fuelCards,
    driverType,
    teamDriver,
    driverHours,
    certificates,
    latestLocation,
    openedFuelCards,
    residenceStatus,
    assignedDivision,
    domicileTerminal,
    expeditedDivision,
    openedCertificates,
    assignedDispatcher,
    primaryPhoneNumber,
  } = driverProfile;

  const { fullText } = G.getUserInfo(assignedDispatcher);

  const { firstLastMiddleNames } = G.getUserInfo(driverProfile);

  const { mainActionButtonStyles } = getButtonsStyles();

  const truckAdditionalEquipment = R.pathOr([], [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT], truck);

  const changeNoteCallback = useCallback(async (text: string) => {
    try {
      openLoader(true);

      const options = {
        data: { [GC.FIELD_COMMENTS]: text },
      };

      const res = await sendRequest('patch', endpointsMap.getFleetDriver(guid), options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        setValueToStore({ value: text, path: `driverProfiles.${guid}.${GC.FIELD_COMMENTS}` });
      } else {
        G.handleFailResponseSimple(res);
      }
    } catch (error) {
      await G.showToastrMessageSimple('error', 'messages:error:unknown');
      await G.handleException(error, 'DriverProfileComponent/changeNoteCallback exception');
    } finally {
      closeLoader();
    }
  }, []);

  return (
    <Box>
      <Box p={20} bg={bgGreyColor}>
        <Flex alignItems='flex-start'>
          <Avatar driver={driverProfile} />
          <Box ml={15}>
            <TextComponent
              {...textStyles}
              fontSize={16}
              maxWidth={300}
              fontWeight='bold'
              title={firstLastMiddleNames}
            >
              {firstLastMiddleNames}
            </TextComponent>
            <Box mt='5px'>
              <ContactInfoPair phone={true} text={primaryPhoneNumber} />
              <ContactInfoPair mail={true} text={email} />
            </Box>
          </Box>
          <Box ml='auto' cursor='pointer' onClick={closeDriverProfile}>
            {I.closeIcon(blackColor, 12)}
          </Box>
        </Flex>
        <AssignInfoComponent truck={truck} trailers={trailers} />
      </Box>
      <Box
        px={20}
        pb={15}
        borderBottom='1px solid'
        borderColor={bgGreyColor}
      >
        <Flex mt={15} justifyContent='space-between'>
          <InfoPairWithHandler
            text={loginId}
            label={G.getWindowLocale('titles:driver-id', 'Driver ID')}
            handleClick={() => G.goToRoute(routesMap[G.getFleetProfileRoutePathNameByFleetType()](guid))}
          />
          <LicenseNumberComponent license={license} />
        </Flex>
        <Flex mt={15} justifyContent='space-between'>
          <InfoPairWithHandler
            text={fullText}
            label={G.getWindowLocale('titles:dispatcher', 'Dispatcher')}
            handleClick={() => G.goToRoute(routesMap.getUserSettingsRoute(G.getGuidFromObject(assignedDispatcher)))}
          />
          <InfoPair
            label={G.getWindowLocale('titles:driver-type', 'Driver Type')}
            text={R.path([driverType, GC.FIELD_LABEL], DRIVER_TYPE_OPTIONS)}
          />
        </Flex>
        <Flex mt={15} justifyContent='space-between'>
          <InfoPair
            text={G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, domicileTerminal)}
            label={G.getWindowLocale('titles:domicile-terminal', 'Domicile Terminal')}
          />
          <InfoPair
            text={G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, assignedDivision)}
            label={G.getWindowLocale('titles:assigned-division', 'Assigned Division')}
          />
        </Flex>
        <Flex mt={15} fontSize={12} color={textColor} fontWeight='bold' justifyContent='space-between'>
          <Flex width='45%'>
            {G.getWindowLocale('titles:team-driver', 'Team Driver')}
            <Box ml={10}>
              <Toggle icons={false} disabled={true} checked={teamDriver} />
            </Box>
          </Flex>
          <InfoPair
            text={G.getEnumLocale(R.or(residenceStatus, ''))}
            label={G.getWindowLocale('titles:residence-status', 'Residence Status')}
          />
        </Flex>
        <Flex mt={15} fontSize={12} color={textColor} fontWeight='bold' justifyContent='space-between'>
          {
            G.isTrue(showExpeditedDivision) &&
            <Flex width='45%'>
              {G.getWindowLocale('titles:expedited-division', 'Expedited Division')}
              <Box ml={10}>
                <Toggle icons={false} disabled={true} checked={expeditedDivision} />
              </Box>
            </Flex>
          }
        </Flex>
      </Box>
      <EditableBox
        value={R.or(comments, '')}
        onBlurCallback={changeNoteCallback}
        placeholder={G.getWindowLocale('titles:click-to-add-comment', 'Click to add a comment')}
        wrapperStyles={{ p: 10, height: 70, width: '100%', borderBottom: '1px solid', borderColor: bgGreyColor }}
      />
      {
        G.isNotNilAndNotEmpty(driverHours) &&
        <DriverHours driverHours={driverHours} />
      }
      {
        G.isNotNilAndNotEmpty(latestLocation) &&
        <LatestKnownLocation latestLocation={latestLocation} />
      }
      {
        G.isNotNilAndNotEmpty(lastTrip) &&
        <LastTrip {...lastTrip} />
      }
      {
        R.and(G.isTrue(showTruckAdditionalEquipment), G.isNotNilAndNotEmpty(truckAdditionalEquipment)) &&
        <TruckAdditionalEquipment truckAdditionalEquipment={truckAdditionalEquipment} />
      }
      <PayrollInfo driverGuid={guid} />
      <Certificates
        certificates={certificates}
        openedCertificates={openedCertificates}
        handleToggleDriverCertificates={handleToggleDriverCertificates}
      />
      <FuelCards
        fuelCards={fuelCards}
        openedFuelCards={openedFuelCards}
        toggleDriverFuelCardRequest={toggleDriverFuelCardRequest}
        handleToggleDriverFuelCards={handleToggleDriverFuelCards}
      />
      <UnavailablePeriods
        openedFuelCards={openedFuelCards}
        isLocationRequired={isLocationRequired}
        unavailablePeriods={driverUnavailablePeriods}
        handleUpdateUnavailablePeriod={handleUpdateUnavailablePeriod}
        handleRemoveUnavailablePeriod={handleRemoveUnavailablePeriod}
      />
      {
        G.isNilOrFalse(hidePendingActivities) &&
        <PendingActivities
          openedFuelCards={openedFuelCards}
          pendingActivities={driverPendingActivities}
          handleUpdatePendingActivity={handleUpdatePendingActivity}
          handleRemovePendingActivity={handleRemovePendingActivity}
        />
      }
      <Flex my={15}>
        <ActionButton
          {...mainActionButtonStyles}
          cursor='pointer'
          onClick={() => handleAddUnavailablePeriod(guid)}
        >
          {G.getWindowLocaleArr(['titles:create', 'titles:unavailable-period'])}
        </ActionButton>
        {
          G.isNilOrFalse(hidePendingActivities) &&
          <ActionButton
            {...mainActionButtonStyles}
            cursor='pointer'
            onClick={() => handleAddPendingActivity(guid)}
          >
            {G.getWindowLocaleArr(['titles:create', 'titles:pending-activity'])}
          </ActionButton>
        }
      </Flex>
      {
        G.isTrue(showDriverAvailability) &&
        <DriverAvailability
          driverGuid={guid}
          status={driverAvailabilityStatus}
          mainActionButtonStyles={mainActionButtonStyles}
        />
      }
    </Box>
  );
};

const DriverProfileSimple = ({
  openLoader,
  closeLoader,
  driverProfile,
  setValueToStore,
  isLocationRequired,
  closeDriverProfile,
  hidePendingActivities,
  showExpeditedDivision,
  showDriverAvailability,
  driverPendingActivities,
  shouldIgnoreSavedProfile,
  driverAvailabilityStatus,
  driverUnavailablePeriods,
  handleAddPendingActivity,
  handleAddUnavailablePeriod,
  handleToggleDriverFuelCards,
  toggleDriverFuelCardRequest,
  handleUpdatePendingActivity,
  handleRemovePendingActivity,
  showTruckAdditionalEquipment,
  handleUpdateUnavailablePeriod,
  handleRemoveUnavailablePeriod,
  handleToggleDriverCertificates,
}: Object) => (
  <FixedBox
    top='0'
    right='0'
    width={500}
    zIndex={1601}
    height='100vh'
    overflow='auto'
    bg={G.getTheme('colors.white')}
    boxShadow={`0 2px 40px 0 ${G.getTheme('colors.boxShadowGrey')}`}
  >
    {
      G.isNotNilAndNotEmpty(driverProfile) &&
      <DriverProfileComponent
        openLoader={openLoader}
        closeLoader={closeLoader}
        driverProfile={driverProfile}
        setValueToStore={setValueToStore}
        isLocationRequired={isLocationRequired}
        closeDriverProfile={closeDriverProfile}
        hidePendingActivities={hidePendingActivities}
        showExpeditedDivision={showExpeditedDivision}
        showDriverAvailability={showDriverAvailability}
        driverPendingActivities={driverPendingActivities}
        shouldIgnoreSavedProfile={shouldIgnoreSavedProfile}
        driverAvailabilityStatus={driverAvailabilityStatus}
        driverUnavailablePeriods={driverUnavailablePeriods}
        handleAddPendingActivity={handleAddPendingActivity}
        handleAddUnavailablePeriod={handleAddUnavailablePeriod}
        toggleDriverFuelCardRequest={toggleDriverFuelCardRequest}
        handleToggleDriverFuelCards={handleToggleDriverFuelCards}
        handleUpdatePendingActivity={handleUpdatePendingActivity}
        handleRemovePendingActivity={handleRemovePendingActivity}
        showTruckAdditionalEquipment={showTruckAdditionalEquipment}
        handleUpdateUnavailablePeriod={handleUpdateUnavailablePeriod}
        handleRemoveUnavailablePeriod={handleRemoveUnavailablePeriod}
        handleToggleDriverCertificates={handleToggleDriverCertificates}
      />
    }
  </FixedBox>
);

const driverProfileMapStateToProps = (state: Object) => createStructuredSelector({
  truckGuid: makeSelectDriverTruckGuid(state),
  groupGuid: makeSelectDriverGroupGuid(state),
  driverProfile: makeSelectDriverProfile(state),
  primaryDriver: makeSelectDriverPrimaryDriver(state),
  dispatcherGuid: makeSelectDriverDispatcherGuid(state),
  driverProfileGuid: makeSelectDriverProfileGuid(state),
  driverProfileOpened: makeSelectOpenedDriverProfile(state),
  driverPendingActivities: makeSelectDriverPendingActivities(state),
  driverUnavailablePeriods: makeSelectDriverUnavailablePeriods(state),
  driverAvailabilityStatus: makeSelectDriverAvailabilityStatus(state),
});

export const enhance = compose(
  connect(
    driverProfileMapStateToProps,
    {
      setValueToStore,
      closeDriverProfile,
      toggleDriverFuelCards,
      toggleDriverCertificates,
      getDriverFuelCardsRequest,
      getDriverProfileInfoRequest,
      toggleDriverFuelCardRequest,
      getDriverCertificatesRequest,
      getDriverAvailabilityStatusRequest,
    },
  ),
  withConnectModalAndLoaderActions,
  withPendingActivity,
  withUnavailablePeriod,
  withHandlers({
    handleGetDriverProfileInfo: (props: Object) => () => {
      const {
        driverProfile,
        driverProfileGuid,
        showDriverAvailability,
        shouldIgnoreSavedProfile,
        getDriverProfileInfoRequest,
        getDriverAvailabilityStatusRequest,
      } = props;

      if (G.isNotNilAndNotEmpty(driverProfileGuid)) {
        if (R.or(G.isNilOrEmpty(driverProfile), G.isTrue(shouldIgnoreSavedProfile))) {
          getDriverProfileInfoRequest(driverProfileGuid);
        }

        if (G.isTrue(showDriverAvailability)) {
          getDriverAvailabilityStatusRequest(driverProfileGuid);
        }
      }
    },
    handleToggleDriverCertificates: (props: Object) => () => {
      const {
        driverProfile,
        driverProfileGuid,
        toggleDriverCertificates,
        getDriverCertificatesRequest,
      } = props;

      const { certificates, openedCertificates } = driverProfile;

      if (R.and(G.isNilOrEmpty(certificates), G.isFalse(openedCertificates))) {
        getDriverCertificatesRequest(driverProfileGuid);
      } else {
        toggleDriverCertificates(driverProfileGuid);
      }
    },
    handleToggleDriverFuelCards: (props: Object) => () => {
      const {
        driverProfile,
        driverProfileGuid,
        toggleDriverFuelCards,
        getDriverFuelCardsRequest,
      } = props;

      const { fuelCards, openedFuelCards } = driverProfile;

      if (R.and(G.isNilOrEmpty(fuelCards), G.isFalse(openedFuelCards))) {
        getDriverFuelCardsRequest(driverProfileGuid);
      } else {
        toggleDriverFuelCards(driverProfileGuid);
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'driverProfileGuid',
    callbackName: 'handleGetDriverProfileInfo',
  }),
  branch(
    ({ driverProfileOpened }: Object) => G.isFalse(driverProfileOpened),
    renderNothing,
  ),
  pure,
);

export default enhance(DriverProfileSimple);
