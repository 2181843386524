import * as R from 'ramda';
import React from 'react';
// components
import { Tabs, withTabs } from '../../../components/tabs';
import { LocalLoader } from '../../../components/local-loader';
import { InfoPair, ContactInfoPair } from '../../../components/info-pair';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
// dispatch-board-new
import { withAsyncDataOnDidMount } from '../hocs/with-async-data-on-did-mount';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');
const textColor = G.getTheme('colors.greyMatterhorn');

const getTabs = R.map(({ primaryReferenceValue }: Object) => ({ text: primaryReferenceValue }));

const SectionHeader = ({ text }: Object) => (
  <Box p='4px 10px' bg={G.getTheme('colors.light.darkGrey')}>{text}</Box>
);

const infoPairCommonProps = {
  margin: '0 10px',
  lineHeight: 'unset',
  width: 'calc(100% - 10px)',
};

const infoPairCommonPropsForChassisSection = {
  marginRight: '5px',
  flexDirection: 'column',
  alignItems: 'flexStart',
};

const DispatchInfo = (props: Object) => {
  const { autodialApp, dispatchName, dispatchPhone, dispatchEmails } = props;

  if (G.isAllNilOrEmpty([dispatchName, dispatchPhone, dispatchEmails])) return null;

  return (
    <div>
      <SectionHeader text={G.getWindowLocale('titles:dispatch-info', 'Dispatch Info')} />
      <InfoPair
        {...infoPairCommonProps}
        text={R.or(dispatchName, '-')}
        label={G.getWindowLocale('titles:dispatcher-name', 'Dispatcher Name')}
      />
      <ContactInfoPair
        {...infoPairCommonProps}
        phone={true}
        iconMargin='3px'
        autodialApp={autodialApp}
        text={R.or(dispatchPhone, null)}
        label={`${G.getWindowLocale('titles:dispatcher-phone', 'Dispatcher Phone')}: `}
      />
      <Flex ml={10} alignItems='flex-start' width='calc(100% - 10px)'>
        <Box mr='5px' color={textColor} whiteSpace='nowrap'>
          {G.getWindowLocale('titles:dispatcher-emails', 'Dispatcher Emails')}:
        </Box>
        {
          G.isNotNilAndNotEmpty(dispatchEmails) &&
          <Flex flexWrap='wrap'>
            {
              dispatchEmails.map((item: string, index: number) => (
                <StyledLink
                  mr='5px'
                  key={index}
                  color={blueColor}
                  fontWeight='bold'
                  href={`mailto:${item}`}
                >
                  {item}{R.and(R.lt(R.inc(index), R.length(dispatchEmails)), ', ')}
                </StyledLink>
              ))
            }
          </Flex>
        }
      </Flex>
    </div>
  );
};

const CarrierAssignment = ({
  comments,
  autodialApp,
  dispatchName,
  dispatchPhone,
  dispatchEmails,
  carrierAssignment,
  primaryReferenceValue,
}: Object) => (
  <Box py={10}>
    <InfoPair
      {...infoPairCommonProps}
      text={primaryReferenceValue}
      label={G.getWindowLocale('titles:tel', 'Trip')}
    />
    <InfoPair
      {...infoPairCommonProps}
      text={R.pathOr('-', [GC.FIELD_NAME], carrierAssignment)}
      label={G.getWindowLocale('titles:carrier-name', 'Carrier Name')}
    />
    <ContactInfoPair
      {...infoPairCommonProps}
      iconMargin='3px'
      label={G.getWindowLocale('titles:email', 'Email')}
      text={R.pathOr(null, [GC.FIELD_EMAIL], carrierAssignment)}
    />
    <ContactInfoPair
      {...infoPairCommonProps}
      phone={true}
      iconMargin='3px'
      autodialApp={autodialApp}
      text={R.pathOr(null, ['carrierPhone'], carrierAssignment)}
      label={`${G.getWindowLocale('titles:phone-number', 'Phone Number')}: `}
    />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:scac', 'SCAC')}
      text={R.pathOr('-', [GC.FIELD_CARRIER_SCAC], carrierAssignment)}
    />
    <InfoPair
      {...infoPairCommonProps}
      text={R.pathOr('-', [GC.FIELD_US_DOT_NUMBER], carrierAssignment)}
      label={G.getWindowLocale('titles:usdot-number', 'USDOT Number')}
    />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:truck', 'Truck')}
      text={R.pathOr('-', [GC.FIELD_TRUCK], carrierAssignment)}
    />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:trailer', 'Trailer')}
      text={R.pathOr('-', [GC.FIELD_TRAILER], carrierAssignment)}
    />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:fleet-trailer', 'Fleet Trailer')}
      text={R.pathOr('-', [GC.FIELD_FLEET_TRAILER_UNIT_ID], carrierAssignment)}
    />
    <SectionHeader text={G.getWindowLocale('titles:primary-driver', 'Primary Driver')} />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:name', 'Name')}
      text={R.pathOr('-', [GC.FIELD_PRIMARY_DRIVER], carrierAssignment)}
    />
    <ContactInfoPair
      {...infoPairCommonProps}
      phone={true}
      iconMargin='3px'
      autodialApp={autodialApp}
      label={`${G.getWindowLocale('titles:phone-number', 'Phone Number')}: `}
      text={R.pathOr(null, [GC.FIELD_PRIMARY_DRIVER_PHONE], carrierAssignment)}
    />
    <SectionHeader text={G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')} />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:name', 'Name')}
      text={R.pathOr('-', [GC.FIELD_SECONDARY_DRIVER], carrierAssignment)}
    />
    <ContactInfoPair
      {...infoPairCommonProps}
      phone={true}
      iconMargin='3px'
      autodialApp={autodialApp}
      label={`${G.getWindowLocale('titles:phone-number', 'Phone Number')}: `}
      text={R.pathOr(null, [GC.FIELD_SECONDARY_DRIVER_PHONE], carrierAssignment)}
    />
    <DispatchInfo
      autodialApp={autodialApp}
      dispatchName={dispatchName}
      dispatchPhone={dispatchPhone}
      dispatchEmails={dispatchEmails}
    />
    {
      G.isNotNilAndNotEmpty(comments) &&
      <InfoPair
        {...infoPairCommonProps}
        text={comments}
        margin='4px 0 0 10px'
        label={G.getWindowLocale('titles:comments', 'Comments')}
      />
    }
  </Box>
);

const renderDriverInfo = (driver: Object, autodialApp: string) => (
  <div>
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:name', 'Name')}
      text={R.pathOr('-', ['firstLastMiddleNames'], G.getUserInfo(driver))}
    />
    <InfoPair
      {...infoPairCommonProps}
      text={R.pathOr('-', [GC.FIELD_NICK_NAME], driver)}
      label={G.getWindowLocale('titles:nick-name', 'Nick Name')}
    />
    <ContactInfoPair
      {...infoPairCommonProps}
      phone={true}
      iconMargin='3px'
      autodialApp={autodialApp}
      text={R.pathOr(null, [GC.FIELD_PHONE_NUMBER], driver)}
      label={`${G.getWindowLocale('titles:phone-number', 'Phone Number')}: `}
    />
    <ContactInfoPair
      {...infoPairCommonProps}
      iconMargin='3px'
      text={R.pathOr(null, [GC.FIELD_EMAIL], driver)}
      label={G.getWindowLocale('titles:email', 'Email')}
    />
  </div>
);

const FleetAssignment = ({ comments, fleetAssignment, primaryReferenceValue }: Object) => (
  <Box py={10}>
    <InfoPair
      {...infoPairCommonProps}
      text={primaryReferenceValue}
      label={G.getWindowLocale('titles:tel', 'Trip')}
    />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:truck-number', 'Truck Number')}
      text={R.pathOr('-', [GC.FIELD_TRUCK, GC.FIELD_UNIT_ID], fleetAssignment)}
    />
    <InfoPair
      {...infoPairCommonProps}
      label={G.getWindowLocale('titles:trailer-number', 'Trailer Number')}
      text={R.compose(
        R.join(', '),
        R.map(R.prop(GC.FIELD_UNIT_ID)),
        R.pathOr([], [GC.FIELD_TRAILERS]),
      )(fleetAssignment)}
    />
    <SectionHeader text={G.getWindowLocale('titles:primary-driver', 'Primary Driver')} />
    {renderDriverInfo(R.pathOr({}, [GC.FIELD_PRIMARY_DRIVER], fleetAssignment))}
    <SectionHeader text={G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')} />
    {renderDriverInfo(R.pathOr({}, [GC.FIELD_SECONDARY_DRIVER], fleetAssignment))}
    <SectionHeader text={G.getWindowLocale('titles:chassis-section', 'Chassis Section')} />
    <InfoPair
      {...infoPairCommonProps}
      {...infoPairCommonPropsForChassisSection}
      text={R.pathOr('-', [GC.FIELD_CHASSIS_NUMBER], fleetAssignment)}
      label={G.getWindowLocale('titles:chassis-number', 'Chassis Number')}
    />
    <InfoPair
      {...infoPairCommonProps}
      {...infoPairCommonPropsForChassisSection}
      text={R.pathOr('-', [GC.FIELD_DRAY_CONTAINER_PICK_UP_NUMBER], fleetAssignment)}
      label={G.getWindowLocale('titles:dray-container-pick-up-number', 'Dray Container Pick Up Number')}
    />
    <InfoPair
      {...infoPairCommonProps}
      {...infoPairCommonPropsForChassisSection}
      text={R.pathOr('-', [GC.FIELD_DRAY_CONTAINER_DROP_NUMBER], fleetAssignment)}
      label={G.getWindowLocale('titles:dray-container-drop-number', 'Dray Container Drop Number')}
    />
    {
      G.isNotNilAndNotEmpty(comments) &&
      <InfoPair
        {...infoPairCommonProps}
        {...infoPairCommonPropsForChassisSection}
        text={comments}
        margin='4px 0 0 10px'
        label={G.getWindowLocale('titles:comments', 'Comments')}
      />
    }
  </Box>
);

const Assignment = (props: Object) => {
  const { fleetAssignment } = props;

  if (G.isNotNil(fleetAssignment)) return <FleetAssignment {...props} />;

  return <CarrierAssignment {...props} />;
};

const CloAssignment = withTabs(({ activeTab, assignments, autodialApp, handleSetActiveTab }: Object) => (
  <div>
    {
      R.gt(R.length(assignments), 1) &&
      <Tabs
        activeTab={activeTab}
        textDisplay='inline-block'
        tabs={getTabs(assignments)}
        handleClickTab={handleSetActiveTab}
        textWidth={`calc(400px / ${R.length(assignments)} - 12px)`}
      />
    }
    <Assignment {...R.prop(activeTab, assignments)} autodialApp={autodialApp} />
  </div>
));

const AsyncRateInfo = withAsyncDataOnDidMount(({ isClo, asyncData, asyncLoading }: Object) => {
  const autodialApp = G.getAmousConfigByNameFromWindow(GC.UI_LOADBOARD_AUTODIALACTION);

  return (
    <LocalLoader width={400} minHeight={380} height='auto' localLoaderOpen={asyncLoading}>
      <Box width='100%' minHeight={380} fontSize='13px'>
        {
          R.not(isClo) &&
          <Assignment {...asyncData} autodialApp={autodialApp} />
        }
        {
          isClo &&
          <CloAssignment assignments={asyncData} autodialApp={autodialApp} />
        }
      </Box>
    </LocalLoader>
  );
});

export default AsyncRateInfo;
