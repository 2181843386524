import * as R from 'ramda';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { useMemo, useState, Fragment, useEffect, useCallback } from 'react';
import { pure, compose, branch, withState, withProps, lifecycle, withHandlers, renderNothing } from 'react-recompose';
// components
import { Tabs2 } from '../../../../components/tabs-mui';
import { AddDocument2 } from '../../../../components/activities';
import { ConfirmComponent } from '../../../../components/confirm';
import { MessageItem } from '../../../../components/message-center';
import { openModal, closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import PC from '../../../permission/role-permission';
import { setDefaultInvoiceValues } from '../../../invoice/helpers';
import { setDefaultCustomerRateValues } from '../../../rate/helpers';
import { enhanceUpdate, CustomerRateForm } from '../../../rate/customer';
import { enhanceEdit, CustomerInvoiceForm, enhanceUpdateWithoutForm } from '../../../invoice/components/customer-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withFixedPopover } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import {
  Box,
  Flex,
  Iframe,
  FixedFlex,
  RelativeBox,
  AbsoluteBox,
  MainActionButton,
  scrollableContainerCss4px,
} from '../../../../ui';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequestWithQSParamsSerializer } from '../../../../utilities/http';
// feature dispatch-details-new
import PageHeader from '../../components/page-header';
import { getDefaultUomFieldsFromConfigs } from '../../helpers';
import { withOrderPageActions } from '../hocs/with-page-actions';
import { renderDocuments } from '../../components/cards/documents';
import { messageCenterRowActionsEnhance } from '../hocs/with-message-center-actions';
import { withOrderAddDocument, withOrderDocumentActions } from '../hocs/with-document-actions';
import {
  createOrderNoteRequest,
  updateOrderCustomerRateRequest,
  updateOrderCustomerInvoiceListRequest,
  getOrderTransportationModeGroupingListSuccess,
} from '../actions';
import {
  makeSelectNotes,
  makeSelectLoadConfigs,
  makeSelectOrderDetails,
  makeSelectLoadDocuments,
  makeSelectOrderTotalWeight,
  makeSelectOrderTotalDistance,
  makeSelectCustomerInvoiceList,
  makeSelectSelectedCustomerRate,
  makeSelectOrderTransportationModeGroupingList,
} from '../selectors';
//////////////////////////////////////////////////

const Textarea = styled.textarea`
  pb: 3px;
  width: 100%;
  height: 60px;
  border: none;
  resize: none;
  cursor: text;
  outline: none;

  ${scrollableContainerCss4px}
`;

const EnhancedPageHeader = withFixedPopover(withOrderPageActions(PageHeader), true);

const defaultMapper = R.compose(
  R.map((item: string) => {
    if (G.isNilOrEmpty(item)) return null;

    if (isNaN(G.toNumber(item))) return item;

    return G.toNumber(item);
  }),
  G.omitObjectSystemFields,
);

const invoiceMapper = ({
  items = [],
  billTo = {},
  charges = [],
  remitTo = {},
  payments = [],
  references = [],
  ...rest
}: Object) => ({
  ...defaultMapper(rest),
  [GC.SYSTEM_OBJECT_BILL_TO]: defaultMapper(billTo),
  [GC.SYSTEM_OBJECT_REMIT_TO]: defaultMapper(remitTo),
  [GC.SYSTEM_LIST_ITEMS]: R.map(defaultMapper, items),
  [GC.FIELD_CHARGES]: R.map(({ id, ...item }: Object) => defaultMapper(item), charges),
  [GC.FIELD_PAYMENTS]: R.map(({ uniq, ...item }: Object) => defaultMapper(item), payments),
  [GC.FIELD_REFERENCES]: R.map(({ uniq, ...item }: Object) => defaultMapper(item), references),
});

const rateMapper = ({ items = [], charges = [], ...rest }: Object) => ({
  ...defaultMapper(rest),
  [GC.SYSTEM_LIST_ITEMS]: R.map(defaultMapper, items),
  [GC.FIELD_CHARGES]: R.compose(
    R.sortBy(R.prop(GC.FIELD_TYPE)),
    R.map(({ id, total, ...item }: Object) => defaultMapper({ ...item, total: G.mathRoundNumber(total) })),
  )(charges),
});

const enhanceRate = compose(
  connect(
    createStructuredSelector({
      totalWeight: makeSelectOrderTotalWeight(),
      totalDistance: makeSelectOrderTotalDistance(),
      transportationModeGroupingList: makeSelectOrderTransportationModeGroupingList(),
    }),
    {
      getTransportationModeGroupingListSuccess: getOrderTransportationModeGroupingListSuccess,
    },
  ),
  withProps(({ load, rate, loadConfigs, handleUpdateRate }: Object) => ({
    initialValues: rate,
    branchGuid: G.getBranchGuidFromObject(load),
    [GC.FIELD_CLO_GUID]: G.getGuidFromObject(load),
    stopsQuantity: R.pathOr(0, [GC.FIELD_STOPS, 'length'], load),
    defaultUomFields: getDefaultUomFieldsFromConfigs(loadConfigs.configsByNames),
    handleSendCloRate: (values: Object, loadData: Object) => handleUpdateRate(values, loadData),
  })),
  enhanceUpdate,
  lifecycle({
    componentDidUpdate({ values }: Object) {
      const { setRate, values: nextValues } = this.props;

      if (
        R.and(G.isNotEmpty(values), G.isNotEmpty(nextValues)) &&
        G.notEquals(rateMapper(values), rateMapper(nextValues))
      ) setRate(nextValues);
    },
  }),
  branch(
    ({ initialValues }: Object) => G.isNilOrEmpty(initialValues),
    renderNothing,
  ),
);

const buttonsStyles = {
  submitBtnStyles: { ml: 'auto' },
  cancelBtnStyles: { display: 'none' },
};

const Rate = enhanceRate((props: Object) => {
  const { activeTab } = props;

  if (G.notEquals(activeTab, 'rate')) return null;

  return (
    <CustomerRateForm
      {...props}
      width='100%'
      {...buttonsStyles}
      formBorder={`1px solid ${G.getTheme('colors.#E4E4E4')}}`}
    />
  );
});

const enhanceEditCustomerInvoice = compose(
  enhanceEdit,
  withHandlers({
    handleChangeSeparateBillTo: (props: Object) => (event: Object) => {
      const { orderBillTo, setFieldValue, storedBillTo } = props;

      const separateInvoiceBillTo = R.pathOr(false, ['currentTarget', 'checked'], event);
      const newBillTo = G.ifElse(separateInvoiceBillTo, storedBillTo, orderBillTo);

      setFieldValue(GC.SYSTEM_OBJECT_BILL_TO, { ...newBillTo, separateInvoiceBillTo });
    },
  }),
  lifecycle({
    componentDidUpdate({ values }: Object) {
      const { invoices, setInvoices, values: nextValues } = this.props;

      const { guid } = nextValues;

      if (
        R.and(G.isNotEmpty(G.getGuidFromObject(values)), R.isNotNil(guid)) &&
        G.notEquals(invoiceMapper(values), invoiceMapper(nextValues))
      ) {
        const invoiceIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), R.or(invoices, []));

        setInvoices(R.assoc(invoiceIndex, nextValues, invoices));
      }
    },
  }),
  branch(
    R.propEq('rate', 'activeTab'),
    renderNothing,
  ),
);

const EditCustomerInvoice = enhanceEditCustomerInvoice(CustomerInvoiceForm);

const enhanceInvoice = compose(
  withProps(({ load }: Object) => ({
    cloGuid: G.getGuidFromObject(load),
    branchGuid: G.getBranchGuidFromObject(load),
    orderBillTo: R.propOr({}, [GC.SYSTEM_OBJECT_BILL_TO], load),
    stopsQuantity: R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load)),
    itemsVolume: G.calculateTotalVolumeWithoutQty(R.pathOr([], ['items'], load)),
  })),
  enhanceUpdateWithoutForm,
);

const Invoice = enhanceInvoice((props: Object) => {
  const { invoices, activeTab, storedInvoices, updateOrderCustomerInvoiceRequest } = props;

  const asyncInitialData = {
    data: R.find(R.propEq(activeTab, GC.FIELD_GUID), R.or(invoices, [])),
  };

  const storedBillTo = R.prop(
    GC.FIELD_BILL_TO,
    R.find(R.propEq(activeTab, GC.FIELD_GUID), R.or(storedInvoices, [])),
  );

  return (
    <EditCustomerInvoice
      {...props}
      {...buttonsStyles}
      storedBillTo={storedBillTo}
      asyncInitialData={asyncInitialData}
      formBorder={`1px solid ${G.getTheme('colors.#E4E4E4')}}`}
      handleSendCloInvoice={(values: Object) => updateOrderCustomerInvoiceRequest(values)}
    />
  );
});

const Invoices = (props: Object) => {
  const {
    invoices,
    setInvoices,
    storedInvoices,
    updateOrderCustomerInvoiceListRequest,
  } = props;

  const [activeTab, setActiveTab] = useState(null);

  const initialSeparateInvoiceBillTo = R.path(
    [GC.FIELD_BILL_TO, GC.FIELD_SEPARATE_INVOICE_BILL_TO],
    R.find(R.propEq(activeTab, GC.FIELD_GUID), R.or(storedInvoices, [])),
  );

  const hasInvoicePermissions = G.hasAmousCurrentUserPermissions([
    PC.CLO_INVOICE_WRITE,
    PC.CLO_INVOICE_OVERWRITE_EXECUTE,
  ]);

  const tabs = useMemo(() => {
    let tabs = [];

    if (hasInvoicePermissions) {
      tabs = [
        ...tabs,
        ...R.map(({ guid, invoiceNumber }: Object) => ({
          value: guid,
          text: `${G.getWindowLocale('titles:invoice', 'Invoice')}: #${invoiceNumber}`,
        }), storedInvoices),
      ];
    }

    return tabs;
  }, [storedInvoices]);

  useEffect(() => {
    if (R.and(R.isNil(activeTab), G.isNotNilAndNotEmpty(storedInvoices))) {
      setActiveTab(R.path([0, GC.FIELD_GUID], storedInvoices));
    }
  }, [activeTab, storedInvoices]);

  if (R.isEmpty(tabs)) return null;

  return (
    <Box
      pb={15}
      width={880}
      height='100%'
      minWidth={880}
      borderRight='1px solid'
      borderColor='lightGrey'
    >
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabStyles={GC.COMMON_MUI_TAB_STYLES}
        tabsProps={{ variant: 'scrollable' }}
        tabsStyles={{ margin: '15px', minHeight: 36, maxHeight: 36 }}
      />
      <RelativeBox
        pr={3}
        ml={15}
        mr={12}
        zIndex={0}
        overflow='auto'
        height='calc(100% - 66px)'
        css={scrollableContainerCss4px}
      >
        <Invoice
          {...props}
          invoices={invoices}
          activeTab={activeTab}
          setInvoices={setInvoices}
          storedInvoices={storedInvoices}
          initialSeparateInvoiceBillTo={initialSeparateInvoiceBillTo}
          updateOrderCustomerInvoiceRequest={(values: Object) => (
            updateOrderCustomerInvoiceListRequest(R.of(Array, values))
          )}
        />
      </RelativeBox>
    </Box>
  );
};

const enhanceDocuments = compose(
  withOrderDocumentActions,
  withState('preview', 'setPreview', null),
  withHandlers({
    handleOpenFilePreview: (props: Object) => (document: Object) => {
      const { setPreview, openLoader, closeLoader, handleOpenFilePreview } = props;

      const { documentFilename } = document;

      const fileExtension = R.compose(
        R.last,
        R.split('.'),
      )(R.or(documentFilename, ''));

      const previewExtensions = [
        GC.EXTENSION_PNG, GC.EXTENSION_GIF, GC.EXTENSION_PDF, GC.EXTENSION_JPEG, GC.EXTENSION_JPG,
      ];

      if (R.includes(fileExtension, previewExtensions)) {
        (async () => {
          const { documentFilename, primaryObjectGuid } = document;

          openLoader();

          const endpoint = G.ifElse(
            G.isLoadTypeClo(document),
            endpointsMap.cloDocumentDownloadFile,
            endpointsMap.telDocumentDownloadFile,
          );

          const options = {
            resType: 'arraybuffer',
            params: {
              primaryObjectGuid,
              fileName: documentFilename,
            },
          };

          const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

          const { status } = res;

          if (G.isResponseSuccess(status)) {
            setPreview({ fileExtension, url: G.getImageSrcFromArrayBufferResponse(res) });
          } else {
            G.handleFailResponseSimple(res, 'handleOpenFilePreview fail');
          }

          closeLoader();
        })();

        return;
      }

      handleOpenFilePreview(document);
    },
  }),
);

const imageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'top',
};

const DocumentPreview = ({ url, fileExtension, setPreview }: Object) => (
  <Box
    mr={10}
    overflowY='auto'
    p='15px 5px 0 15px'
    height='calc(100% - 66px)'
    css={scrollableContainerCss4px}
  >
    <Box
      mr={10}
      mb={10}
      cursor='pointer'
      textAlign='right'
      onClick={() => setPreview(null)}
      title={G.getWindowLocale('titles:close-document', 'Close Document')}
    >
      {I.closeIcon(G.getTheme('colors.#7D828C'), 15, 15)}
    </Box>
    <Box height='calc(100% - 50px)'>
      {
        R.equals(fileExtension, GC.EXTENSION_PDF) ? (
          <Iframe src={url} />
        ) : (
          <img src={url} alt='preview' style={imageStyles} />
        )
      }
    </Box>
  </Box>
);

const wrapperStyles = {
  mt: 10,
  p: '7px',
  bg: 'white',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'lightGrey',
};

const Documents = enhanceDocuments((props: Object) => {
  const { preview, documents, setPreview, handleAddDocument } = props;

  if (R.isNotNil(preview)) {
    return <DocumentPreview {...preview} setPreview={setPreview} />;
  }

  const byTxtLocale = G.getWindowLocale('titles:by', 'by');
  const addedTxtLocale = G.getWindowLocale('titles:added', 'Added');

  return (
    <Flex
      alignItems='stretch'
      flexDirection='column'
      height='calc(100% - 66px)'
      justifyContent='space-between'
    >
      {
        G.isNotNilAndNotEmpty(documents) &&
        <Box
          pr={3}
          ml={15}
          mr={12}
          overflow='auto'
          width='calc(100% - 30px)'
          height='calc(100% - 130px)'
          css={scrollableContainerCss4px}
        >
          {renderDocuments(documents, { ...props, byTxtLocale, wrapperStyles, addedTxtLocale })}
        </Box>
      }
      <AddDocument2 onDropHandler={(files: Array) => handleAddDocument(null, files)} />
    </Flex>
  );
});

const enhanceNotes = compose(
  connect(createStructuredSelector({ notes: makeSelectNotes() }), { createOrderNoteRequest }),
  messageCenterRowActionsEnhance,
  withState('message', 'setMessage', { text: '', isValid: true }),
  withHandlers({
    handleAddMessage: (props: Object) => () => {
      const {
        load,
        message,
        setMessage,
        createOrderNoteRequest,
      } = props;

      const { text, isValid } = R.or(message, {});

      if (G.isNotNilAndNotEmpty(R.trim(text))) {
        if (R.not(isValid)) return;

        setMessage({ text: '', isValid: true });

        createOrderNoteRequest({
          pinned: false,
          [GC.FIELD_TEXT]: text,
          [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load),
        });
      }
    },
    handleChangeMessage: ({ setMessage }: Object) => (event: Object) => {
      const text = R.path(['currentTarget', GC.FIELD_VALUE], event);
      const isValid = R.lt(R.length(R.trim(text)), 2000);

      setMessage({ text, isValid });
    },
  }),
);

const Notes = enhanceNotes((props: Object) => {
  const {
    notes,
    handleEditNote,
    handlePinMessage,
    handleAddMessage,
    handleChangeMessage,
    handleRemoveMessage,
    message: { text, isValid } = {},
  } = props;

  const sortedNotes = R.sort(R.descend(R.prop('pinned')), notes);

  const handleKeyDown = (event: Object) => {
    if (R.propEq(GC.EVENT_KEY_CODE_ENTER, 'keyCode', event)) {
      event.preventDefault();

      handleAddMessage();
    }
  };

  const notEmptyNotes = G.isNotNilAndNotEmpty(notes);

  return (
    <Flex
      alignItems='stretch'
      flexDirection='column'
      height='calc(100% - 66px)'
      justifyContent={G.ifElse(notEmptyNotes, 'space-between', 'flex-end')}
    >
      {
        notEmptyNotes &&
        <Box
          pr={3}
          ml={15}
          mr={12}
          overflow='auto'
          width='calc(100% - 30px)'
          height='calc(100% - 130px)'
          css={scrollableContainerCss4px}
        >
          {
            sortedNotes.map((note: Object, index: number) => (
              <MessageItem
                key={index}
                index={index}
                message={note}
                isActiveTabNotes={true}
                handleEdit={handleEditNote}
                wrapperStyles={wrapperStyles}
                handleRemove={handleRemoveMessage}
                handlePinMessage={handlePinMessage}
              />
            ))
          }
        </Box>
      }
      <Flex
        m={15}
        p='7px'
        border='1px solid'
        borderRadius='4px'
        position='relative'
        alignItems='flex-end'
        flexDirection='column'
        width='calc(100% - 30px)'
        borderColor={G.ifElse(R.not(isValid), 'light.lightRed', 'lightGrey')}
      >
        <Textarea
          value={text}
          onKeyDown={handleKeyDown}
          onChange={handleChangeMessage}
        />
        <MainActionButton
          mt='5px'
          height={25}
          textTransform='uppercase'
          onClick={handleAddMessage}
        >
          {G.getWindowLocale('actions:post', 'Post')}
        </MainActionButton>
        {
          R.not(isValid) &&
          <AbsoluteBox top='101%' left='5px' fontSize={11} color='light.lightRed'>
            {G.getShouldBeFromToCharLocaleTxt(1, 2000)}
          </AbsoluteBox>
        }
      </Flex>
    </Flex>
  );
});

const enhanceRightSection = compose(
  connect(
    createStructuredSelector({ documents: makeSelectLoadDocuments() }),
    { openLoader, closeLoader },
  ),
  withOrderAddDocument,
  pure,
);

const RightSection = enhanceRightSection(((props: Object) => {
  const { defaultDocumentType, primaryReferenceValue, handlePrintLoadDocumentsInvoice } = props;

  const [rateVisited, setRateVisited] = useState(false);
  const [activeTab, setActiveTab] = useState('documents');

  useEffect(() => {
    if (R.or(rateVisited, G.notEquals(activeTab, 'rate'))) return;

    setRateVisited(true);
  }, [activeTab, rateVisited]);

  const tabs = [
    {
      value: 'documents',
      text: G.getWindowLocale('titles:documents', 'Documents'),
    },
    {
      value: 'notes',
      text: G.getWindowLocale('titles:notes', 'Notes'),
    },
    {
      value: 'rate',
      permissions: [PC.CLO_RATE_WRITE],
      text: G.getWindowLocale('titles:rate', 'Rate'),
    },
  ];

  return (
    <Box width='calc(100% - 880px)' minWidth={G.ifElse(R.equals(activeTab, 'rate'), 790, 400)}>
      <Flex>
        <Tabs2
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabStyles={GC.COMMON_MUI_TAB_STYLES}
          tabsStyles={{ ...GC.COMMON_MUI_TABS_STYLES, margin: '15px' }}
        />
        <MainActionButton
          ml={20}
          height={25}
          textTransform='uppercase'
          onClick={() => handlePrintLoadDocumentsInvoice(
            null,
            { [GC.FIELD_FILE_NAME]: primaryReferenceValue, [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: defaultDocumentType },
          )}
        >
          {G.getWindowLocale('actions:merge', 'Merge')}
        </MainActionButton>
      </Flex>
      {R.equals(activeTab, 'documents') && <Documents {...props} />}
      {R.equals(activeTab, 'notes') && <Notes {...props} activeTab='noteList' />}
      {
        R.or(rateVisited, R.equals(activeTab, 'rate')) &&
        <RelativeBox
          pr={3}
          zIndex={0}
          width={765}
          overflow='auto'
          m='0 12px 15px 15px'
          css={scrollableContainerCss4px}
          height={G.ifElse(R.equals(activeTab, 'rate'), 'calc(100% - 81px)', 0)}
        >
          <Rate {...props} activeTab={activeTab} />
        </RelativeBox>
      }
    </Box>
  );
}));

const getPrimaryReferenceValue = R.compose(
  R.path([GC.FIELD_VALUE]),
  R.find(R.propEq(true, GC.FIELD_PRIMARY)),
  R.pathOr([], [GC.FIELD_REFERENCES]),
);

const Footer = (props: Object) => {
  const { hasChanges, handleClose, handleSaveData, expandedContainer } = props;

  const buttonStyles = {
    width: 150,
    type: 'button',
    background: 'none',
    border: '1px solid',
    textTransform: 'uppercase',
  };

  return (
    <FixedFlex
      right='0px'
      bottom='0px'
      p='14px 20px'
      left={window.isMobile ? 60 : '0px'}
      boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
      background={G.getTheme('colors.white')}
      width={window.isMobile ? 'auto' : '100%'}
    >
      {
        expandedContainer &&
        <MainActionButton
          {...buttonStyles}
          mr={20}
          color='greyMatterhorn'
          borderColor='greyMatterhorn'
          onClick={() => handleClose()}
        >
          {G.getWindowLocale('actions:close', 'Close')}
        </MainActionButton>
      }
      <MainActionButton
        {...buttonStyles}
        ml='auto'
        onClick={handleSaveData}
        disabled={R.not(hasChanges)}
      >
        {G.getWindowLocale('actions:save', 'Save')}
      </MainActionButton>
    </FixedFlex>
  );
};

const InvoicingTab = (props: Object) => {
  const {
    load,
    openModal,
    closeModal,
    setActiveTab,
    loadConfigs = {},
    expandedContainer,
    closeExpandedContainer,
    updateOrderCustomerRateRequest,
    updateOrderCustomerInvoiceListRequest,
  } = props;

  const selectedRate = useSelector(makeSelectSelectedCustomerRate());
  const storedInvoices = useSelector(makeSelectCustomerInvoiceList());

  const [rate, setRate] = useState(selectedRate);
  const [invoices, setInvoices] = useState(storedInvoices);
  const [dataWasChanged, setDataWasChanged] = useState({ rate: false });

  const hasChanges = R.any((item: boolean) => item, R.values(dataWasChanged));

  const handleLeavePage = useCallback((activeTab: string) => {
    const callback = () => (
      G.isString(activeTab) ? setActiveTab(activeTab) : closeExpandedContainer()
    );

    if (G.isFalse(hasChanges)) return callback();

    const component = (
      <ConfirmComponent
        textProps={{ width: 340, textAlign: 'center' }}
        textLocale={G.getWindowLocale(
          'messages:confirm-changes-wont-be-saved',
          'Some changes you made won`t be saved. Do you want to continue?',
        )}
      />
    );

    const modal = {
      component,
      options: {
        width: 400,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:confirm', 'Confirm'),
            action: () => {
              callback();
              closeModal();
            },
          },
        ],
      },
    };

    openModal(modal);
  }, [hasChanges]);

  const handleUpdateRate = useCallback((values: Object, loadData: Object) => updateOrderCustomerRateRequest({
    loadData,
    shouldCloseModal: false,
    values: G.omitEmptyChargesFromData(values),
  }), []);

  const handleUpdateInvoices = useCallback((changedInvoices: Array) => {
    const mapped = G.mapIndexed((values: Object, index: number) => {
      const { billTo, cloGuid, references, integratedDate } = values;

      const referencesWithCloGuid = R.map(R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, cloGuid), R.or(references, []));

      const formattedIntegratedDate = G.checkAndConvertMomentInstanceToFormattedDate(
        integratedDate,
        GC.DEFAULT_DATE_TIME_FORMAT,
      );

      const initialSeparateInvoiceBillTo = R.path(
        [index, GC.FIELD_BILL_TO, GC.FIELD_SEPARATE_INVOICE_BILL_TO],
        R.or(storedInvoices, []),
      );

      const shouldCreateSeparateBillTo = R.and(
        G.isFalse(initialSeparateInvoiceBillTo),
        G.isTrue(R.pathOr(false, [GC.FIELD_SEPARATE_INVOICE_BILL_TO], billTo)),
      );

      return R.compose(
        R.mergeLeft({
          [GC.FIELD_REFERENCES]: referencesWithCloGuid,
          [GC.FIELD_INTEGRATED_DATE]: formattedIntegratedDate,
          [GC.FIELD_BILL_TO]: shouldCreateSeparateBillTo ? G.omitObjectSystemFields(billTo) : billTo,
        }),
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
        G.omitEmptyPaymentsFromData,
      )(G.omitEmptyChargesFromData(values, GC.FIELD_CHARGES));
    }, changedInvoices);

    updateOrderCustomerInvoiceListRequest(mapped);
  }, [storedInvoices]);

  const handleSaveData = () => {
    const { rate: rateChanged, ...rest } = dataWasChanged;

    if (rateChanged) handleUpdateRate(rate);

    const changedInvoices = R.map((item: number) => R.prop(item, invoices), R.keys(rest));

    handleUpdateInvoices(changedInvoices);
  };

  useEffect(() => {
    setRate(selectedRate);
  }, [selectedRate]);

  useEffect(() => {
    setInvoices(storedInvoices);
  }, [storedInvoices]);

  useEffect(() => {
    const mapper = (item: Object) => setDefaultInvoiceValues({ initialValues: invoiceMapper(item) });

    const changes = {};

    invoices.forEach((item: Object, index: number) => {
      if (G.notEquals(mapper(item), mapper(storedInvoices[index]))) {
        return changes[index] = true;
      }

      delete changes[index];
    });

    setDataWasChanged(({ rate }: Object) => ({ ...changes, rate }));
  }, [invoices, storedInvoices]);

  useEffect(() => {
    const hasChanges = G.notEquals(
      setDefaultCustomerRateValues({ initialValues: rateMapper(R.or(rate, {})) }),
      setDefaultCustomerRateValues({ initialValues: rateMapper(R.or(selectedRate, {})) }),
    );

    setDataWasChanged((prev: Object) => ({ ...prev, rate: hasChanges }));
  }, [rate, selectedRate]);

  const primaryReferenceValue = getPrimaryReferenceValue(load);

  const pageHeaderProps = {
    load,
    openModal,
    my: '0px',
    primaryReferenceValue,
    setActiveTab: handleLeavePage,
    showFast: G.getConfigValueFromStore(GC.UI_SHOW_FAST_LOAD_INDICATOR, loadConfigs.configsByNames),
  };

  return (
    <Fragment>
      <Box pt={20} mx='auto' maxWidth={1800} height={`calc(100% - ${G.ifElse(expandedContainer, 0, 60)}px)`}>
        <EnhancedPageHeader {...pageHeaderProps} activeTab='invoicing' />
        <Box
          mt={20}
          bg='white'
          overflowX='auto'
          borderRadius='4px'
          height='calc(100% - 90px)'
          css={scrollableContainerCss4px}
          boxShadow={`0 0 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
        >
          <Flex height='100%' alignItems='stretch'>
            <Invoices
              {...props}
              invoices={invoices}
              setInvoices={setInvoices}
              storedInvoices={storedInvoices}
            />
            <RightSection
              {...props}
              rate={rate}
              setRate={setRate}
              handleUpdateRate={handleUpdateRate}
              primaryReferenceValue={primaryReferenceValue}
              configs={R.path(['configGroups', GC.COMMUNICATION_CONFIG_GROUP], loadConfigs)}
              defaultDocumentType={G.getConfigValueFromStore(
                GC.CLO_GENERAL_MERGE_DEFAULT_DOCUMENT_TYPE,
                R.pathOr(null, ['configGroups', GC.CLO_CONFIG_GROUP], loadConfigs),
                null,
              )}
            />
          </Flex>
        </Box>
      </Box>
      <Footer
        hasChanges={hasChanges}
        handleClose={handleLeavePage}
        handleSaveData={handleSaveData}
        expandedContainer={expandedContainer}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  load: makeSelectOrderDetails(state),
  loadConfigs: makeSelectLoadConfigs(state),
});

const enhance = compose(
  connect(
    mapStateToProps,
    { openModal, closeModal, updateOrderCustomerRateRequest, updateOrderCustomerInvoiceListRequest },
  ),
  branch(
    ({ load }: Object) => G.isNilOrEmpty(load),
    renderNothing,
  ),
);

export default enhance(InvoicingTab);
