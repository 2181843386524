import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { ITEM_DIMENSIONS_OPTIONS } from '../../../../helpers/options';
// feature fleet
import { VINLookupTruck } from '../../components/vin-lookup';
import {
  uomValidation,
  customRequired,
  customIsNumeric,
  documentValidation,
} from '../../validation';
//////////////////////////////////////////////////

export const truckDetailsFields = {
  name: 'truckDetails',
  title: 'titles:primary-details',
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      options: 'truckType',
      validate: G.required,
      nameForAttribute: 'truckType',
    },
    {
      type: 'text',
      validate: G.required,
      name: 'titles:unit-id',
      nameForAttribute: 'unitId',
    },
    {
      type: 'text',
      name: 'titles:legacy-unit-id',
      nameForAttribute: 'legacyUnitId',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'text',
      name: 'titles:serial-number-vin',
      nameForAttribute: 'serialNumber',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
      additionalLabelComponent: <VINLookupTruck />,
    },
    {
      type: 'text',
      name: 'titles:manufacturer',
      nameForAttribute: 'manufacturer',
      validate: G.curriedIsEmptyOrMinMax(0, 100),
    },
    {
      type: 'text',
      name: 'titles:year',
      nameForAttribute: 'year',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1900, 2100)],
    },
    {
      type: 'text',
      name: 'titles:model',
      nameForAttribute: 'model',
      validate: [G.curriedIsEmptyOrMinMax(0, 100)],
    },
    {
      type: 'text',
      name: 'titles:series',
      nameForAttribute: 'series',
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
    {
      type: 'toggle',
      name: 'titles:in-service',
      nameForAttribute: 'inService',
    },
    {
      type: 'select',
      validate: G.required,
      options: 'outOfServiceReason',
      nameForAttribute: 'outOfServiceReason',
      name: 'titles:out-of-service-status-reason',
      showField: (params: Object) => R.not(params.inService),
    },
    {
      type: 'toggle',
      name: 'titles:available',
      nameForAttribute: 'available',
    },
    {
      type: 'select',
      validate: G.required,
      options: 'unavailabilityReason',
      name: 'titles:unavailable-status-reason',
      nameForAttribute: 'unavailabilityReason',
      showField: (params: Object) => R.not(params.available),
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:in-service-date',
      nameForAttribute: 'inServiceDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:out-service-date',
      nameForAttribute: 'outOfServiceDate',
    },
    {
      type: 'multiselect',
      name: 'titles:additional-equipment-type',
      options: GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE,
      selectAction: 'handleChangeAdditionalEquipments',
      nameForAttribute: GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT,
    },
    {
      type: 'select',
      options: 'usersGeneral',
      name: 'titles:dispatcher',
      nameForAttribute: 'assignedDispatcherGuid',
    },
    {
      type: 'select',
      name: 'titles:dispatching-group',
      options: 'dispatchingGroupOptions',
      nameForAttribute: 'dispatchingGroupGuid',
    },
  ],
};

export const truckRegistrationFields = {
  name: 'truckRegistration',
  title: 'titles:registration',
  fields: [
    {
      type: 'text',
      name: 'titles:license-plate',
      nameForAttribute: 'licensePlate',
      validate: G.curriedIsEmptyOrMinMax(0, 50),
    },
    {
      type: 'text',
      name: 'titles:registration-state',
      nameForAttribute: 'registrationState',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:registration-expiration-date',
      nameForAttribute: 'registrationExpirationDate',
    },
    {
      type: 'text',
      name: 'titles:title-state',
      nameForAttribute: 'titleState',
    },
    {
      type: 'text',
      name: 'titles:title-number',
      nameForAttribute: 'titleNumber',
    },
    {
      type: 'toggle',
      name: 'titles:ifta-required',
      nameForAttribute: 'iftaRequired',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:ifta-date',
      nameForAttribute: 'iftaDate',
    },
    {
      type: 'text',
      name: 'titles:ifta-decal-number',
      nameForAttribute: GC.FIELD_IFTA_DECAL,
      validate: [G.curriedIsEmptyOrMinMax(0, 40)],
    },
  ],
};

export const insuranceFields = {
  fields: [
    {
      type: 'select',
      options: 'insuranceType',
      validate: customRequired,
      name: 'titles:insurance-type',
      nameForAttribute: 'insuranceType',
    },
    {
      width: 159,
      type: 'calendar',
      isClearable: true,
      align: 'baseline',
      validate: customRequired,
      name: 'titles:effective-date',
      nameForAttribute: 'effectiveDate',
    },
    {
      width: 159,
      type: 'calendar',
      isClearable: true,
      align: 'baseline',
      validate: customRequired,
      name: 'titles:expiration-date',
      nameForAttribute: 'expirationDate',
    },
    {
      width: 185,
      type: 'text',
      name: 'titles:payment',
      nameForAttribute: 'paymentAmount',
      validate: [customRequired, customIsNumeric],
    },
    {
      width: 185,
      type: 'select',
      validate: customRequired,
      name: 'titles:payment-interval',
      nameForAttribute: 'paymentInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
    },
    {
      type: 'textarea',
      name: 'titles:description',
      nameForAttribute: 'description',
    },
  ],
};

export const documentFields = {
  fields: [
    {
      type: 'select',
      name: 'titles:type',
      options: 'documentType',
      validate: customRequired,
      nameForAttribute: 'documentType',
    },
    {
      type: 'select',
      name: 'titles:status',
      validate: customRequired,
      nameForAttribute: 'status',
      options: ['', 'CURRENT', 'HISTORICAL'],
    },
    {
      type: 'textarea',
      direction: 'column',
      name: 'titles:description',
      nameForAttribute: 'description',
      validate: [G.curriedIsEmptyOrMinMax(0, 2000)],
    },
    {
      width: 159,
      type: 'calendar',
      isClearable: true,
      align: 'baseline',
      name: 'titles:signed-date',
      nameForAttribute: 'signedDate',
    },
    {
      width: 159,
      type: 'calendar',
      align: 'baseline',
      isClearable: true,
      name: 'titles:expired-date',
      nameForAttribute: 'expirationDate',
    },
    {
      type: 'text',
      name: 'titles:url',
      nameForAttribute: 'url',
      validate: documentValidation,
      disabled: ({ documentFilename }: Object) => G.isNotNilAndNotEmpty(documentFilename),
    },
    {
      type: 'file',
      name: 'actions:upload',
      validate: documentValidation,
      nameForAttribute: 'documentFilename',
      disabled: (params: Object) => G.isNotNilAndNotEmpty(params.url),
    },
  ],
};

const defaultFieldStyles = {
  width: 190,
  afterTop: 14,
  errorLeft: 10,
  errorTop: '110%',
  labelWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

const emptyFileOrUrl = () =>
  G.getWindowLocale('messages:empty-file-or-url', 'Please, select a file or enter file URL');

const requiredField = () => (
  G.getWindowLocale('validation:field-G.required', 'Field is required!')
);

export const defaultValidationSchemaDocumentFormObject = () => ({
  [GC.FIELD_DOCUMENT_DESCRIPTION]: Yup.string()
    .nullable(true),
  [GC.FIELD_DOCUMENT_STATUS]: Yup.string()
    .nullable(true)
    .required(requiredField()),
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: Yup.string()
    .nullable(true)
    .required(requiredField()),
});

export const getDocumentFormValidationSchemaObject = (values: Object) => {
  const requiredFields = [R.prop(GC.FIELD_DOCUMENT_URL, values), R.prop(GC.FIELD_DOCUMENT_UPLOAD, values)];
  if (R.any(G.isNotNilAndNotEmpty)(requiredFields)) return defaultValidationSchemaDocumentFormObject;
  const requiredFieldsValidationSchemaObject = {
    [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string()
      .nullable(true)
      .required(emptyFileOrUrl()),
    [GC.FIELD_DOCUMENT_URL]: Yup.string()
      .nullable(true)
      .required(emptyFileOrUrl()),
  };
  return R.mergeRight(defaultValidationSchemaDocumentFormObject(), requiredFieldsValidationSchemaObject);
};

export const getDocFields = (initialValues: Object) => ([
  {
    ...defaultFieldStyles,
    loc: 'titles:type',
    type: 'searchselect',
    options: 'documentType',
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
  },
  {
    ...defaultFieldStyles,
    type: 'select',
    loc: 'titles:status',
    fieldName: GC.FIELD_DOCUMENT_STATUS,
    options: [
      { value: '', label: '' },
      {
        value: GC.DOCUMENT_STATUS_CURRENT,
        label: G.getWindowLocale('titles:current', 'Current'),
      },
      {
        value: GC.DOCUMENT_STATUS_HISTORICAL,
        label: G.getWindowLocale('titles:historical', 'Historical'),
      },
    ],
  },
  {
    ...defaultFieldStyles,
    width: 390,
    type: 'textarea',
    direction: 'column',
    loc: 'titles:description',
    fieldName: GC.FIELD_DOCUMENT_DESCRIPTION,
  },
  {
    ...defaultFieldStyles,
    type: 'calendar',
    isClearable: true,
    loc: 'titles:signed-date',
    fieldName: GC.FIELD_DOCUMENT_SIGNED_DATE,
  },
  {
    ...defaultFieldStyles,
    type: 'calendar',
    isClearable: true,
    calendarIconMr: '0',
    loc: 'titles:expired-date',
    fieldName: GC.FIELD_DOCUMENT_EXPIRATION_DATE,
  },
  {
    ...defaultFieldStyles,
    width: 390,
    type: 'text',
    loc: 'titles:url',
    fieldName: GC.FIELD_DOCUMENT_URL,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_UPLOAD, initialValues),
  },
  {
    ...defaultFieldStyles,
    width: 390,
    type: 'file',
    fieldName: GC.FIELD_DOCUMENT_UPLOAD,
    fieldGroupDisplay: (_: any, values: Object) =>
      G.setFieldsGroupDisplay(values, GC.FIELD_DOCUMENT_URL, initialValues),
  },
]);

export const defaultDocFields = {
  [GC.FIELD_DOCUMENT_URL]: '',
  [GC.FIELD_DOCUMENT_STATUS]: '',
  [GC.FIELD_DOCUMENT_UPLOAD]: null,
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: '',
  [GC.FIELD_DOCUMENT_DESCRIPTION]: '',
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: '',
  [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: '',
};

export const mainSpecificationFields = {
  name: 'mainSpecification',
  title: 'titles:main-specifications',
  fields: [
    {
      name: 'titles:tare-weight',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'tareWeight',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'kg', 'lb'],
          nameForAttribute: 'tareWeightType',
        },
      ],
    },
    {
      name: 'titles:gvwr',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'gvwt',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'kg', 'lb'],
          nameForAttribute: 'gvwtWeightType',
        },
      ],
    },
    {
      type: 'select',
      name: 'titles:class',
      nameForAttribute: 'specificationClass',
      options: [
        '',
        '8: HT +33k pounds',
        '7: HT 26k - 33k pounds',
        '5: MT 16k - 19,5k pounds',
        '6: MT 19,5k - 26k pounds',
        '9: SH +33k pounds, special duty',
      ],
    },
    {
      type: 'select',
      name: 'titles:axle-count',
      nameForAttribute: 'axleCount',
      options: ['', '1', '2', '3', '4'],
    },
    {
      type: 'customSelect',
      name: 'titles:axle-config',
      nameForAttribute: 'axleConfig',
      options: [
        { label: 'truckOne', value: '1-1' },
        { label: 'truckTwo', value: '1-2' },
      ],
    },
    {
      separator: 'x',
      name: 'titles:truck-dimensions',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: GC.FIELD_LENGTH,
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ITEM_DIMENSIONS_OPTIONS,
          nameForAttribute: GC.FIELD_LENGTH_UOM,
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: GC.FIELD_WIDTH,
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ITEM_DIMENSIONS_OPTIONS,
          nameForAttribute: GC.FIELD_WIDTH_UOM,
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: GC.FIELD_HEIGHT,
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ITEM_DIMENSIONS_OPTIONS,
          nameForAttribute: GC.FIELD_HEIGHT_UOM,
        },
      ],
    },
    {
      separator: 'x',
      name: 'titles:door-dimensions',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'doorDimension.doorWidth',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 10000)],
        },
        {
          width: 111,
          separate: true,
          type: 'select',
          validate: uomValidation,
          options: ITEM_DIMENSIONS_OPTIONS,
          nameForAttribute: 'doorDimension.doorWidthUom',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'doorDimension.doorHeight',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 10000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ITEM_DIMENSIONS_OPTIONS,
          nameForAttribute: 'doorDimension.doorHeightUom',
        },
      ],
    },
  ],
};

export const tractorSpecificationFields = {
  name: 'tractorSpecification',
  title: 'titles:tractor-specifications',
  fields: [
    {
      type: 'toggle',
      name: 'titles:sleeper',
      nameForAttribute: 'sleeper',
    },
    {
      type: 'text',
      name: 'titles:mpg-high',
      nameForAttribute: 'mpgHigh',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000)],
    },
    {
      type: 'text',
      name: 'titles:mpg-low',
      nameForAttribute: 'mpgLow',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000)],
    },
    {
      type: 'select',
      name: 'titles:fuel-type',
      nameForAttribute: 'fuelType',
      options: ['', 'Gasoline', 'Diesel fuel', 'Autogas', 'Biodiesel', 'Ethanol fuel'],
    },
    {
      separator: 'x',
      name: 'titles:5th-wheel-height',
      inputs: [
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'fifthWheelHeight',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'fifthWheelHeightType',
        },
        {
          width: 111,
          type: 'text',
          nameForAttribute: 'fifthWheelWidth',
          validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000)],
        },
        {
          width: 111,
          type: 'select',
          validate: uomValidation,
          options: ['', 'cm', 'm', 'inch', 'ft'],
          nameForAttribute: 'fifthWheelWidthType',
        },
      ],
    },
  ],
};

export const ownershipFields = {
  name: 'ownership',
  title: 'titles:ownership-details',
  fields: [
    {
      type: 'select',
      name: 'titles:ownership-type',
      nameForAttribute: 'ownershipType',
      options: ['Own', 'Lease', 'Rental'],
    },
    {
      type: 'text',
      name: 'titles:contract-name',
      nameForAttribute: 'contractName',
      showField: (params: Object) => (
        R.and(
          G.isNotEmpty(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
    {
      type: 'text',
      name: 'titles:contract-number',
      nameForAttribute: 'contractNumber',
      showField: (params: Object) => (
        R.and(
          G.isNotEmpty(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
  ],
};

export const costDetailsFields = {
  name: 'costDetails',
  title: 'titles:cost-details',
  fields: [
    {
      type: 'text',
      name: 'titles:payment-amount',
      nameForAttribute: 'paymentAmount',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(0, 1000000000)],
    },
    {
      type: 'select',
      name: 'titles:payment-interval',
      nameForAttribute: 'paymentInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
    },
    {
      type: 'select',
      name: 'titles:payment-method',
      options: ['', 'ACH', 'Invoice'],
      nameForAttribute: 'paymentMethod',
    },
    {
      type: 'select',
      name: 'titles:maintenance-cost-interval',
      nameForAttribute: 'maintenanceCostInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
      showField: (params: Object) => (
        R.and(
          G.isNotEmpty(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
    {
      type: 'select',
      name: 'titles:maintenance-cost-method',
      nameForAttribute: 'maintenanceCostMethod',
      options: ['', 'ACH', 'Invoice'],
      showField: (params: Object) => (
        R.and(
          G.isNotEmpty(params.ownershipType),
          G.notEquals(params.ownershipType, 'Own'),
        )
      ),
    },
    {
      type: 'text',
      validate: G.isEmptyOrNumeric,
      name: 'titles:registration-cost',
      nameForAttribute: 'registrationCost',
    },
    {
      type: 'select',
      name: 'titles:registration-interval',
      nameForAttribute: 'registrationPaymentInterval',
      options: ['', 'Weekly', 'Monthly', 'Quarterly', 'Annually'],
    },
  ],
};

export const termDetailsFields = {
  name: 'termDetails',
  title: 'titles:term-details',
  fields: [
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:start-date',
      nameForAttribute: 'startDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:end-date',
      nameForAttribute: 'endDate',
    },
    {
      isClearable: true,
      type: 'datePicker',
      name: 'titles:date-returned',
      nameForAttribute: 'returnedDate',
    },
  ],
};

export const equipmentReturnFields = {
  name: 'equipmentReturn',
  title: 'titles:equipment-return',
  fields: [
    {
      type: 'text',
      name: 'titles:cost',
      nameForAttribute: 'equipmentReturnCost',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:cost-ratio',
      nameForAttribute: 'equipmentReturnCostRatio',
    },
    {
      type: 'text',
      name: 'titles:cost-accrued',
      nameForAttribute: 'equipmentReturnCostAccrued',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:cost-paid',
      nameForAttribute: 'equipmentReturnCostPaid',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:cost-not-provisioned',
      nameForAttribute: 'equipmentReturnCostNotProvisioned',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
    {
      type: 'text',
      name: 'titles:balance-required',
      nameForAttribute: 'equipmentReturnBalanceRequired',
      validate: [G.isEmptyOrNumeric, G.curriedIsEmptyOrMinMaxNumber(1, 1000000000)],
    },
  ],
};

export const componentFields = {
  fields: [
    {
      type: 'text',
      align: 'start',
      labelPosition: true,
      name: 'titles:component-id',
      label: 'titles:component-id',
      nameForAttribute: 'componentId',
      validate: [G.required, G.curriedIsEmptyOrMinMax(0, 85)],
    },
    {
      type: 'text',
      align: 'start',
      labelPosition: true,
      name: 'titles:serial-number',
      label: 'titles:serial-number',
      nameForAttribute: 'serialNumber',
      validate: [G.required, G.curriedIsEmptyOrMinMax(0, 200)],
    },
    {
      type: 'select',
      align: 'start',
      name: 'titles:type',
      labelPosition: true,
      label: 'titles:type',
      validate: G.required,
      options: 'componentType',
      nameForAttribute: 'componentType',
    },
    {
      type: 'select',
      align: 'start',
      labelPosition: true,
      validate: G.required,
      name: 'titles:manufacturer',
      label: 'titles:manufacturer',
      options: 'componentManufacturer',
      nameForAttribute: 'componentManufacturer',
    },
    {
      width: 159,
      type: 'calendar',
      direction: 'row',
      align: 'baseline',
      isClearable: true,
      validate: customRequired,
      name: 'titles:installed-date',
      label: 'titles:installed-date',
      nameForAttribute: 'installedDate',
    },
    {
      width: 159,
      type: 'calendar',
      direction: 'row',
      isClearable: true,
      align: 'baseline',
      name: 'titles:removed-date',
      label: 'titles:removed-date',
      nameForAttribute: 'replaceOrRemoveDate',
    },
    {
      width: 170,
      type: 'calendar',
      direction: 'row',
      isClearable: true,
      align: 'baseline',
      nameForAttribute: 'warrantyExpiration',
      name: 'titles:warranty-expiration-date',
      label: 'titles:warranty-expiration-date',
    },
    {
      align: 'start',
      type: 'textarea',
      labelPosition: true,
      name: 'titles:specification',
      nameForAttribute: 'specification',
      label: 'titles:warranty-expiration-date',
      validate: G.curriedIsEmptyOrMinMax(0, 300),
    },
  ],
};
