import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const fields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_FIELD_NAME,
    label: ['titles:field-name', 'Field Name'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'referenceTypes',
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    label: ['titles:reference-type', 'Reference Type'],
  },
];

const defaultFields = {
  [GC.FIELD_FIELD_NAME]: '',
  [GC.FIELD_REFERENCE_TYPE_GUID]: '',
};

const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const validationSchema = Yup.object().shape({
  [GC.FIELD_FIELD_NAME]: fieldRequired,
  [GC.FIELD_REFERENCE_TYPE_GUID]: fieldRequired,
});

const enhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultFields,
      initialValues,
    ),
  }),
  pure,
);

const QBCustomFieldMappingForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fields}
      referenceTypes={props.referenceTypes}
      fieldsWrapperStyles={{ pt: 11, flexDirection: 'column' }}
    />
    <FormFooter />
  </form>
);

export default enhance(QBCustomFieldMappingForm);
