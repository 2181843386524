import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withState } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, FixedFlex, PageWrapper, CancelButton } from '../../../ui';
// feature dispatch details new
import DocumentsTab from './components/documents-tab';
import InvoicingTab from './components/invoicing-tab';
import GeneralTabComponent from './components/general-tab';
//////////////////////////////////////////////////

const enhance = compose(
  withState('activeTab', 'setActiveTab', R.propOr('general', 'activeTab')),
  pure,
);

const commonBtnStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
};

const ActiveTab = (props: Object) => {
  const { activeTab, setActiveTab, expandedContainer, closeExpandedContainer } = props;

  const map = {
    invoicing: InvoicingTab,
    documents: DocumentsTab,
    general: GeneralTabComponent,
  };

  const Tab = map[activeTab];

  if (expandedContainer) {
    return (
      <Fragment>
        <Box p={25} overflow='auto' height='calc(100vh - 60px)'>
          <Tab
            setActiveTab={setActiveTab}
            expandedContainer={expandedContainer}
            closeExpandedContainer={closeExpandedContainer}
          />
        </Box>
        {
          G.notEquals(activeTab, 'invoicing') &&
          <FixedFlex
            right='0px'
            bottom='0px'
            p='14px 20px'
            left={window.isMobile ? 60 : '0px'}
            boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
            background={G.getTheme('colors.white')}
            width={window.isMobile ? 'auto' : '100%'}
          >
            <CancelButton {...cancelStyles} onClick={closeExpandedContainer}>
              {G.getWindowLocale('actions:close', 'Close')}
            </CancelButton>
          </FixedFlex>
        }
      </Fragment>
    );
  }

  return <Tab setActiveTab={setActiveTab} />;
};

const LoadDetailsNewComponent = ({
  activeTab,
  setActiveTab,
  closeExpandedContainer,
  expandedContainer = false,
}: Object) => (
  <PageWrapper
    bgColor={G.getTheme('colors.bgGrey')}
    p={G.ifElse(G.isTrue(expandedContainer), '0px', 20)}
    pb={G.ifElse(G.isTrue(expandedContainer), '0px', 60)}
    pl={G.ifElse(G.isTrue(expandedContainer), '0px', 75)}
  >
    <ActiveTab
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      expandedContainer={expandedContainer}
      closeExpandedContainer={closeExpandedContainer}
    />
  </PageWrapper>
);

export default enhance(LoadDetailsNewComponent);
