import * as R from 'ramda';
import randomColor from 'randomcolor';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { memo, useMemo, useState, useEffect } from 'react';
import { compose, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import EditableBox from '../../../components/editable-box';
import { PopperComponent } from '../../../components/popper';
import { pickerColorGrey } from '../../../components/color-picker';
import PopperColorPicker from '../../../components/color-picker/popper-color-picker';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withHoveredZIndex } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
  scrollableContainerCss3px,
} from '../../../ui';
// utilities
import T from '../../../theme';
// features drivers-card
import { makeSelectZoom } from '../selectors';
import { withCardNotes } from '../hocs/with-card-notes';
import { getCardWidth, getCardLeftPosition } from './card';
import { getMainCardWidth, getCardActionColor, getCardDayMultiplier } from '../helpers';
import { PICKER_COLOR_TO_CARD_RGB_MAP, PICKER_COLOR_TO_TEXT_COLOR_MAP } from '../constants';
import { withTelDriverCardColor, withConnectTripDriverCardColor } from '../hocs/with-tel-driver-card-color';
//////////////////////////////////////////////////

const subtextColor = G.getTheme('colors.#7D828C');
const darkBlueColor = G.getTheme('colors.dark.blue');
const mainLightColor = G.getTheme('colors.light.mainLight');
const stopRejectColor = G.getTheme('dispatchBoardPopper.stopRejectColor');
const stopSuccessColor = G.getTheme('dispatchBoardPopper.stopSuccessColor');

const enhance = compose(
  withCardNotes,
  withTelDriverCardColor,
  withConnectTripDriverCardColor,
  withPropsOnChange(['guid'], () => ({ focusRandomColor: randomColor() })),
  withHandlers({
    onClickOpenTelDetails: (props: Object) => () => {
      const { editable, handleOpenTelDetails } = props;

      if (G.isFalse(editable)) return;

      handleOpenTelDetails(props);
    },
    onClickEditIcon: (props: Object) => (e: Object) => props.handleClickEditIcon(e, props),
  }),
);

const NoteCard = enhance(memo((props: Object) => {
  const {
    guid,
    zoom,
    width,
    notes,
    status,
    groupBy,
    groupGuid,
    truckGuid,
    cardIndex,
    lastEvent,
    driverGuid,
    cardHeight,
    firstEvent,
    filtersStore,
    hoveredZIndex,
    dispatcherGuid,
    patchUpdateNote,
    handleNoteClick,
    driverCardColor,
    show24HoursView,
    focusRandomColor,
    handleZIndexOnHover,
    onClickOpenTelDetails,
    handleZIndexOnUnHover,
    primaryReferenceValue,
    updateTelDriverCardColor,
    updateTripDriverCardColorSuccess,
  } = props;

  const [colorValue, setColorValue] = useState(driverCardColor);

  const colorValueToUse = R.or(colorValue, pickerColorGrey);

  const _notes = G.sortListByDate(
    R.or(notes, []), 'createdDate', false,
  );

  const note = R.head(_notes);

  useEffect(() => {
    setColorValue(driverCardColor);
  }, [driverCardColor]);

  const changeColorCallback = (color: string) => {
    updateTelDriverCardColor(color, () => {
      setColorValue(color);
      updateTripDriverCardColorSuccess({
        color, groupBy, groupGuid, truckGuid, driverGuid, dispatcherGuid, tripGuid: guid,
      });
    });
  };

  const changeNoteCallback = (text: string) => {
    patchUpdateNote(R.assoc(GC.FIELD_TEXT, text, note));
  };

  const mainCardWidth = getMainCardWidth(show24HoursView, zoom);
  const cardDayMultiplier = getCardDayMultiplier(show24HoursView, zoom);

  const lastEventDate = R.prop('lateDate', lastEvent);
  const firstEventDate = R.prop('earlyDate', firstEvent);

  const statusBorderColor = R.path(['status', status], T);

  const widthToUse = useMemo(() => getCardWidth(
    width,
    firstEventDate,
    lastEventDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  ), [
    width,
    firstEventDate,
    lastEventDate,
    mainCardWidth,
    cardDayMultiplier,
    show24HoursView,
    filtersStore,
  ]);

  if (R.and(R.equals(width, 'calculate'), G.isNilOrEmpty(widthToUse))) return null;

  const showInfo = R.or(G.isNilOrEmpty(widthToUse), R.gt(widthToUse, 400));
  const showMainInfo = R.gt(widthToUse, 200);
  const zIndex = R.subtract(1000, cardIndex);

  const left = useMemo(() => getCardLeftPosition(
    true,
    filtersStore,
    firstEventDate,
    mainCardWidth,
    cardDayMultiplier,
  ), [
    filtersStore,
    firstEventDate,
    mainCardWidth,
    cardDayMultiplier,
  ]);

  const focusRight = R.add(5, R.multiply(cardIndex, 10));

  const headerTextColor = R.path([colorValueToUse], PICKER_COLOR_TO_TEXT_COLOR_MAP);

  const cardActionColor = getCardActionColor(colorValueToUse);

  const cardBg = R.path([colorValueToUse], PICKER_COLOR_TO_CARD_RGB_MAP);

  return (
    <Flex
      left={left}
      alignItems='start'
      borderRadius='3px'
      border='1px solid'
      bg={mainLightColor}
      position='absolute'
      height={cardHeight}
      flexDirection='column'
      borderLeft='3px solid'
      width={R.or(widthToUse, 'auto')}
      onMouseEnter={handleZIndexOnHover}
      borderLeftColor={statusBorderColor}
      onMouseLeave={handleZIndexOnUnHover}
      zIndex={R.or(hoveredZIndex, zIndex)}
      boxShadow='0 0 10px 0 rgba(215, 215, 215, 0.5)'
      borderColor={G.getTheme('colors.light.darkGrey')}
    >
      <Box width='100%' height='100%' overflow='auto' justifyContent='space-between' css={scrollableContainerCss3px}>
        <Flex
          px='5px'
          height={24}
          width='100%'
          borderRadius='1px'
          bg={colorValueToUse}
          justifyContent='space-between'
        >
          <Flex>
            <Box
              mr={10}
              fontWeight={600}
              cursor='pointer'
              color={headerTextColor}
              textDecoration='underline'
              onClick={onClickOpenTelDetails}
              title={G.getWindowLocale('actions:go-to-tel', 'Go to Trip')}
            >
              <TextComponent
                maxWidth={120}
                display='block'
                withEllipsis={true}
                title={primaryReferenceValue}
                fontSize={G.ifElse(R.lt(widthToUse, 300), 12, 13)}
              >
                {primaryReferenceValue}
              </TextComponent>
            </Box>
            {
              showMainInfo &&
              <Box mr='5px' color={headerTextColor} fontSize={G.ifElse(R.lt(widthToUse, 300), 12, 13)}>
                {G.getWindowLocale(GC.statusLocaleMap[status])}
              </Box>
            }
          </Flex>
          <Flex>
            <PopperColorPicker
              arrowColor={cardActionColor}
              activeBorder={`1px solid ${cardActionColor}`}
              pickerProps={{
                changeColorCallback,
                value: colorValueToUse,
                wrapperStyles: { width: 150, justifyContent: 'space-between' },
                itemStyles: { width: 20, height: 20, opacity: 0.9, borderRadius: '4px' },
              }}
            />
            <Flex
              cursor='pointer'
              onClick={(e: Object) => handleNoteClick(e)}
              title={G.getWindowLocale('titles:notes', 'Notes')}
            >
              {I.renderFileIcon(G.ifElse(G.isNotNilAndNotEmpty(notes), darkBlueColor, cardActionColor))}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          p='5px'
          bg={cardBg}
          alignItems='start'
          borderRadius='2px'
          flexDirection='column'
          height={R.subtract(cardHeight, 26)}
        >
          <Flex mb='5px' width='100%' fontSize={12} justifyContent='space-between'>
            <Flex mr={10}>
              <PopperComponent
                zi={1002}
                type='hover'
                position='top'
                content={
                  <Box>
                    <Box>{firstEventDate}</Box>
                    <Box>{G.concatLocationFields(firstEvent.location)}</Box>
                  </Box>
                }
                borderColor={G.getTheme('listActions.borderColor')}
              >
                <Flex
                  mr='5px'
                  p='1px 4px'
                  fontSize={11}
                  fontWeight='bold'
                  border='1px solid'
                  borderRadius='8px'
                  color={stopSuccessColor}
                  borderColor={stopSuccessColor}
                  bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                >
                  P-{R.pathOr(1, ['eventsInfo', 'pickupCount'], props)}
                </Flex>
              </PopperComponent>
              {
                showInfo &&
                <Box>{G.concatLocationFields(firstEvent.location, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
              }
            </Flex>
            <Flex>
              <PopperComponent
                zi={1002}
                type='hover'
                position='top'
                content={
                  <Box>
                    <Box>{lastEventDate}</Box>
                    <Box>{G.concatLocationFields(lastEvent.location)}</Box>
                  </Box>
                }
                borderColor={G.getTheme('listActions.borderColor')}
              >
                <Flex
                  mr='5px'
                  p='1px 4px'
                  fontSize={11}
                  fontWeight='bold'
                  border='1px solid'
                  borderRadius='8px'
                  color={stopRejectColor}
                  borderColor={stopRejectColor}
                  bg={G.getTheme('dispatchBoardPopper.stopIconBg')}
                >
                  D-{R.pathOr(1, ['eventsInfo', 'dropCount'], props)}
                </Flex>
              </PopperComponent>
              {
                showInfo &&
                <Box>{G.concatLocationFields(lastEvent.location, [GC.FIELD_CITY, GC.FIELD_STATE])}</Box>
              }
            </Flex>
          </Flex>
          {
            G.isNotNilAndNotEmpty(note) &&
            <Box
              fontSize={12}
              color={subtextColor}
            >
              {G.getWindowLocale('titles:notes', 'Notes')}:
            </Box>
          }
          <EditableBox
            bgColor={cardBg}
            onBlurCallback={changeNoteCallback}
            value={G.getPropFromObject(GC.FIELD_TEXT, note)}
            wrapperStyles={{
              pt: '5px',
              width: '100%',
              height: R.subtract(cardHeight, 70),
              color: G.getTheme('colors.dark.mainDark'),
            }}
          />
        </Flex>
      </Box>
      <Flex width='100%' justifyContent='flex-end'>
        <RelativeBox>
          <AbsoluteBox
            top='1px'
            width={20}
            height='5px'
            borderRadius='3px'
            right={focusRight}
            bg={focusRandomColor}
          />
        </RelativeBox>
      </Flex>
    </Flex>
  );
}));

const mapStateToProps = (state: Object) => createStructuredSelector({
  zoom: makeSelectZoom(state),
});

export default connect(mapStateToProps, {})(withHoveredZIndex({ zIndex: 1001 })(NoteCard));
