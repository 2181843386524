import * as R from 'ramda';
import React, { useMemo, Fragment } from 'react';
// components
import { SelectMui } from '../../../components';
import { Switcher } from '../../../components/switcher';
import { TitlePanel } from '../../../components/title-panel';
import AutocompleteMui from '../../../components/select-mui/autocomplete-mui';
import { QuickFilterOutsideControl3 } from '../../../components/quick-filter';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Toggle } from '../../../forms';
// ui
import { Box, Flex, RelativeFlex, AbsoluteWrapper } from '../../../ui';
// feature fleet-map
import {
  getFleetsLength,
  idleTimeOptions,
  countFleetEntities,
  getMultiSwitchOptions,
} from '../helpers';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const greyColor = G.getTheme('colors.greyMatterhorn');

const fleetMapTitles = {
  [GC.FLEET_TRUCK_REPORT]: G.getWindowLocale('titles:truck-map', 'Truck Map'),
  [GC.FLEET_TRAILER_REPORT]: G.getWindowLocale('titles:trailer-map', 'Trailer Map'),
};

const FiltersPanel = ({
  loaded,
  configs,
  reportType,
  trucksToUse,
  driversToUse,
  trailersToUse,
  fromFleetList,
  trailersActive,
  withoutClustering,
  withoutGeoFencing,
  trailerLoadedFilter,
  trailerUnitIdFilter,
  divisionFilterOptions,
  handleSetIdleTimeFilter,
  handleSetActiveFleetType,
  handleSetEnterpriseFilter,
  handleSetWithoutClustering,
  handleSetWithoutGeoFencing,
  handleSetTrailerLoadedFilter,
  handleSetTrailerUnitIdFilter,
  handleSetGeoFencingLocationTypeFilter,
}: Object) => {
  const fleets = useMemo(() => ({
    trucks: trucksToUse,
    drivers: driversToUse,
    trailers: trailersToUse,
  }), [trucksToUse, driversToUse, trailersToUse]);

  const count = useMemo(() =>
    R.propOr(getFleetsLength(fleets), reportType, {
      [GC.FLEET_TRUCK_REPORT]: countFleetEntities(trucksToUse),
      [GC.FLEET_TRAILER_REPORT]: countFleetEntities(trailersToUse),
    }), [fleets, reportType, trucksToUse, trailersToUse]);

  const geoFencingLocationTypeOptions = useMemo(() => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    configs,
    GC.FLEET_GENERAL_GEO_FENCING_LOCATION_TYPE,
  ), [configs]);

  const mapTitle = useMemo(() =>
    R.propOr(G.getWindowLocale('titles:fleet-map', 'Fleet Map'), reportType, fleetMapTitles), [reportType]);

  const trailerIdLabel = G.getWindowLocale('titles:trailer-id', 'Trailer Id');

  return (
    <Flex>
      <TitlePanel
        display='flex'
        title={mapTitle}
        withCount={true}
        totalCount={count}
        withReload={false}
        usePortalForFilters={true}
        hiddenRightFilterInfo={true}
      />
      <RelativeFlex width='100%' height={62}>
        {
        loaded &&
        <AbsoluteWrapper top='24px' left='15px' zIndex='11'>
          <Flex ml={16}>
            { R.not(fromFleetList) &&
              <Switcher
                version={3}
                selectedOptionIndex={0}
                onSwitch={handleSetActiveFleetType}
                options={getMultiSwitchOptions(fleets)}
              />
            }
            <Toggle
              ml={20}
              icons={false}
              checked={R.not(withoutClustering)}
              onChange={handleSetWithoutClustering}
            />
            <Box
              ml={10}
              color={greyColor}
            >
              {G.getWindowLocale('titles:cluster-locations', 'Cluster Locations')}
            </Box>
            { trailersActive &&
              <Fragment>
                <Toggle
                  ml={20}
                  icons={false}
                  checked={trailerLoadedFilter}
                  onChange={handleSetTrailerLoadedFilter}
                />
                <Box
                  ml={10}
                  color={greyColor}
                >
                  {G.getWindowLocale('titles:trailers-loaded', 'Loaded')}
                </Box>
              </Fragment>
            }
            { trailersActive &&
              <Fragment>
                <Toggle
                  ml={20}
                  icons={false}
                  checked={R.not(withoutGeoFencing)}
                  onChange={handleSetWithoutGeoFencing}
                />
                <Box
                  ml={10}
                  color={greyColor}
                >
                  {G.getWindowLocale('titles:geo-fencing-location', 'Geo Fencing Location')}
                </Box>
              </Fragment>
            }
            { R.and(trailersActive, R.not(withoutGeoFencing)) &&
              <Box
                ml={20}
                borderRadius={4}
                backgroundColor={whiteColor}
              >
                <SelectMui
                  multiple={false}
                  wrapperStyles={{ width: 155 }}
                  options={geoFencingLocationTypeOptions}
                  handleChangeCallback={handleSetGeoFencingLocationTypeFilter}
                  label={G.getWindowLocale('titles:geo-fencing-location-type', 'Geo Location Type')}
                />
              </Box>
            }
            <Box
              ml={20}
              borderRadius={4}
              backgroundColor={whiteColor}
            >
              <AutocompleteMui
                multiple={true}
                maxMenuHeight={380}
                wrapperStyles={{ width: 220 }}
                options={divisionFilterOptions}
                handleChangeCallback={handleSetEnterpriseFilter}
                label={G.getWindowLocale('titles:division-name', 'Division Name')}
              />
            </Box>
            { trailersActive &&
              <Box
                ml={20}
                borderRadius={4}
                backgroundColor={whiteColor}
              >
                <SelectMui
                  multiple={false}
                  options={idleTimeOptions}
                  wrapperStyles={{ width: 175 }}
                  handleChangeCallback={handleSetIdleTimeFilter}
                  label={G.getWindowLocale('titles:idle-trailers-time', 'Idle Trailers Time')}
                />
              </Box>
            }
            { trailersActive &&
              <Box
                ml={20}
                borderRadius={4}
                backgroundColor={whiteColor}
              >
                <QuickFilterOutsideControl3
                  label={trailerIdLabel}
                  placeholder={trailerIdLabel}
                  outsideValue={trailerUnitIdFilter}
                  shouldClearTimeoutOnKeyPress={true}
                  inputStyles={{ width: 120, maxWidth: 120 }}
                  handleSetFilter={handleSetTrailerUnitIdFilter}
                  onKeyPressHandler={handleSetTrailerUnitIdFilter}
                />
              </Box>
            }
          </Flex>
        </AbsoluteWrapper>
      }
      </RelativeFlex>
    </Flex>
  );
};

export default FiltersPanel;
