import React from 'react';
import * as R from 'ramda';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// components
import { QuickFilterOutsideControl3 } from '../../../components/quick-filter';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, StickedFlex } from '../../../ui';
// feature driver-cards
import {
  GROUP_TYPE_NONE,
  FILTER_TYPE_NONE,
  GROUP_TYPE_DISPATCHER,
  FILTER_TYPE_CURRENT_USER,
  FILTER_TYPE_CURRENT_GROUPS,
  GROUP_TYPE_DISPATCHING_GROUP,
  GROUP_TYPE_GROUP_AND_DISPATCHER,
} from '../constants';
//////////////////////////////////////////////////

const getRequestActionOnChangeFilter = (value: string, props: Object) => {
  const { groupBy, filtersStore, getGroupedAssignedLoadsRequest, getNotGroupedAssignedLoadsRequest } = props;

  const { dateTo, dateFrom } = filtersStore;

  const action = G.ifElse(
    R.equals(groupBy, GROUP_TYPE_NONE),
    getNotGroupedAssignedLoadsRequest,
    getGroupedAssignedLoadsRequest,
  );

  return {
    action,
    options: {
      data: {
        groupBy,
        filter: value,
        dateTo: G.toEndOfDayFromDate(dateTo),
        dateFrom: G.toStartOfDayFromDate(dateFrom),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      },
    },
  };
};

const getRequestActionOnChangeGroupBy = (value: string, props: Object) => {
  const { filtersStore, getGroupedAssignedLoadsRequest, getNotGroupedAssignedLoadsRequest } = props;

  const { filter, dateTo, dateFrom } = filtersStore;

  const action = G.ifElse(
    R.equals(value, GROUP_TYPE_NONE),
    getNotGroupedAssignedLoadsRequest,
    getGroupedAssignedLoadsRequest,
  );

  return {
    action,
    options: {
      data: {
        filter,
        groupBy: value,
        dateTo: G.toEndOfDayFromDate(dateTo),
        dateFrom: G.toStartOfDayFromDate(dateFrom),
        currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
      },
    },
  };
};

const selectOptions = [
  {
    width: 60,
    value: FILTER_TYPE_NONE,
    name: G.getWindowLocale('titles:all', 'All'),
  },
  {
    width: 65,
    value: FILTER_TYPE_CURRENT_USER,
    name: G.getWindowLocale('titles:only-my', 'Only My'),
  },
  {
    width: 70,
    value: FILTER_TYPE_CURRENT_GROUPS,
    name: G.getWindowLocale('titles:my-group', 'My Group'),
  },
];

const selectOptionsDriver = [
  {
    width: 55,
    value: GROUP_TYPE_NONE,
    name: G.getWindowLocale('titles:none', 'None'),
  },
  {
    width: 78,
    value: GROUP_TYPE_DISPATCHER,
    name: G.getWindowLocale('titles:dispatcher', 'Dispatcher'),
  },
  {
    width: 65,
    value: GROUP_TYPE_DISPATCHING_GROUP,
    name: G.getWindowLocale('titles:dispatch-group', 'Dispatch Group'),
  },
];

const selectOptionsTruck = [
  {
    width: 55,
    value: GROUP_TYPE_NONE,
    name: G.getWindowLocale('titles:none', 'None'),
  },
  {
    width: 65,
    value: GROUP_TYPE_DISPATCHING_GROUP,
    name: G.getWindowLocale('titles:dispatch-group', 'Dispatch Group'),
  },
  {
    width: 78,
    value: GROUP_TYPE_GROUP_AND_DISPATCHER,
    name: G.getWindowLocale('titles:group-dispatcher', 'Group/Dispatcher'),
  },
];

const Filters = (props: Object) => {
  const {
    groupBy,
    dispatchByTruck,
    setFilterToStore,
    globalFilterValue,
    onGroupBySwitched,
    handleChangeGlobalFilter,
  } = props;

  const entityFilterLabel = G.isTrue(dispatchByTruck) ?
    G.getWindowLocale('titles:trucks', 'Trucks') : G.getWindowLocale('titles:drivers', 'Drivers');

  const entityFilterOptions = G.isTrue(dispatchByTruck) ?
    R.remove(1, 1, selectOptions) : selectOptions;

  const groupFilterOptions = G.isTrue(dispatchByTruck) ?
    selectOptionsTruck : selectOptionsDriver;

  return (
    <StickedFlex
      top='0'
      height={60}
      width={400}
      alignItems='end'
      bg={G.getTheme('colors.bgGrey')}
    >
      <Box>
        <FormControl size='small' variant='standard' sx={{ m: 1, width: 80 }}>
          <InputLabel id='driver-filter-label'>
            {entityFilterLabel}
          </InputLabel>
          <Select
            sx={{ fontSize: 12 }}
            id='driver-filter-select'
            labelId='driver-filter-label'
            value={R.path(['filtersStore', 'filter'], props)}
            onChange={(event: SelectChangeEvent) => {
              const value = G.getEventTargetValue(event);

              setFilterToStore(value);

              const { action, options } = getRequestActionOnChangeFilter(value, props);

              action(options);
            }}
            label={entityFilterLabel}
          >
            {
              entityFilterOptions.map(({ name, value }: Object, index: number) => (
                <MenuItem key={`${index}${name}`} value={value}>{name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl size='small' variant='standard' sx={{ m: 1, width: 120 }}>
          <InputLabel id='group-by-label'>
            {G.getWindowLocale('titles:group', 'Group')}
          </InputLabel>
          <Select
            value={groupBy}
            id='group-by-select'
            sx={{ fontSize: 12 }}
            labelId='group-by-label'
            label={G.getWindowLocale('titles:group', 'Group')}
            onChange={(event: SelectChangeEvent) => {
              const value = G.getEventTargetValue(event);

              onGroupBySwitched(value);

              const { action, options } = getRequestActionOnChangeGroupBy(value, props);

              action(options);
            }}
          >
            {
              groupFilterOptions.map(({ name, value }: Object, index: number) => (
                <MenuItem key={`${index}${name}`} value={value}>{name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      <Box ml='5px' mb='8px'>
        <QuickFilterOutsideControl3
          inputStyles={{ width: 160 }}
          outsideValue={globalFilterValue}
          handleSetFilter={handleChangeGlobalFilter}
          placeholder={G.getWindowLocale('titles:search', 'Search')}
          label={G.getWindowLocale('titles:search-by-driver-truck', 'Search By Driver/Truck')}
        />
      </Box>
    </StickedFlex>
  );
};

export default Filters;
