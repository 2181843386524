import * as R from 'ramda';
import React, { memo, Fragment, useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, StickedFlex, scrollableContainerCss6px } from '../../../ui';
// feature drivers-card
import Filters from './filters';
import { DriverTelsCards } from './driver-items-lists';
import CalendarLine, { CalendarLine2 } from './calendar-line';
import { shouldNotFilterByLocationAndDate } from './grouped-by-cards';
import DispatchingGroupWithDispatchers, { Dispatcher } from './dispatching-group-with-dispatchers';
import TruckPendingActivitiesAndUnavailablePeriods from './truck-pending-activities-and-unavailable-periods';
import {
  filterDriverLoadsByTeam,
  mapDriverLoadsToEmptyPeriods,
  filterDriverLoadsByLocationAndDate,
} from '../helpers';
//////////////////////////////////////////////////

// TODO: check advanced search for dispatchByTruck
const getGroupedLoads = ({
  groupGuid,
  searchDate,
  filtersStore,
  dispatcherGuid,
  teamFilterValue,
  searchRadiusValue,
  minEmptyHoursValue,
  searchPlaceAutocompleteValue,
  searchPlaceAutocompleteResult,
  loadsByDispatchGroupAndDispatchers,
}: Object) => {
  const loads = R.pathOr([], [groupGuid, dispatcherGuid], loadsByDispatchGroupAndDispatchers);

  if (shouldNotFilterByLocationAndDate({
    searchDate,
    searchRadiusValue,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
  })) {
    return filterDriverLoadsByTeam(teamFilterValue, loads);
  }

  const mapped = mapDriverLoadsToEmptyPeriods(loads, minEmptyHoursValue, filtersStore);

  const filtered = filterDriverLoadsByLocationAndDate(
    mapped, searchPlaceAutocompleteResult, searchRadiusValue, searchDate,
  );

  return filterDriverLoadsByTeam(teamFilterValue, filtered);
};

const getGroupedDataToUse = ({
  searchDate,
  filtersStore,
  teamFilterValue,
  dispatchingGroups,
  searchRadiusValue,
  minEmptyHoursValue,
  searchPlaceAutocompleteValue,
  searchPlaceAutocompleteResult,
  loadsByDispatchGroupAndDispatchers,
}: Object) => {
  const items = dispatchingGroups;

  const result = R.compose(
    R.map((group: Object) => {
      const { dispatchers } = group;

      const dispatchersWithLoads = R.map((dispatcher: Object) => {
        const groupGuid = G.getGuidFromObject(group);
        const dispatcherGuid = G.getGuidFromObject(dispatcher);

        return R.assoc(
          'loads',
          getGroupedLoads({
            groupGuid,
            searchDate,
            filtersStore,
            dispatcherGuid,
            teamFilterValue,
            searchRadiusValue,
            minEmptyHoursValue,
            searchPlaceAutocompleteValue,
            searchPlaceAutocompleteResult,
            loadsByDispatchGroupAndDispatchers,
          }),
          dispatcher,
        );
      }, R.or(dispatchers, []));

      return { ...group, dispatchers: dispatchersWithLoads };
    }),
  )(items);

  return result;
};

export default memo((props: Object) => {
  const {
    cardHeight,
    whiteColor,
    searchDate,
    filtersStore,
    openedGroups,
    teamFilterValue,
    setValueToStore,
    driverCardsInfo,
    openTruckDetails,
    searchRadiusValue,
    openDriverProfile,
    minEmptyHoursValue,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
    loadsByDispatchGroupAndDispatchers,
    getLoadsByDispatchGroupAndDispatcherRequest,
    dropLoadsByDispatchGroupAndDispatchersGroup,
  } = props;

  const setOpenedGroup = useCallback(
    (groupGuid: string, removeGuid: any) => {
      if (G.isTrue(removeGuid)) {
        return setValueToStore({
          path: 'openedGroups',
          value: R.without([groupGuid], openedGroups),
        });
      }

      setValueToStore({
        path: 'openedGroups',
        value: R.append(groupGuid, openedGroups),
      });
    },
    [openedGroups],
  );

  const dispatchingGroups = R.pathOr([], ['dispatchingGroups'], driverCardsInfo);

  const groupedDataToUse = getGroupedDataToUse({
    searchDate,
    filtersStore,
    teamFilterValue,
    searchRadiusValue,
    dispatchingGroups,
    minEmptyHoursValue,
    searchPlaceAutocompleteValue,
    searchPlaceAutocompleteResult,
    loadsByDispatchGroupAndDispatchers,
  });

  return (
    <Flex
      height='100%'
      overflow='auto'
      bg={whiteColor}
      flexDirection='row'
      alignItems='flex-start'
      css={scrollableContainerCss6px}
    >
      {
        <StickedFlex
          left='0'
          pb={10}
          pr={15}
          zIndex={2100}
          minHeight='100%'
          flexDirection='column'
          bg={G.getTheme('colors.bgGrey')}
        >
          <Filters {...props} />
          {
            groupedDataToUse.map((group: Object) => (
              <Flex key={G.getGuidFromObject(group)} flexDirection='column'>
                <DispatchingGroupWithDispatchers
                  cardHeight={cardHeight}
                  dispatchingGroup={group}
                  setOpenedGroup={setOpenedGroup}
                  setValueToStore={setValueToStore}
                  openTruckDetails={openTruckDetails}
                  openDriverProfile={openDriverProfile}
                  onClickHandler={getLoadsByDispatchGroupAndDispatcherRequest}
                  opened={R.includes(G.getGuidFromObject(group), openedGroups)}
                  dropLoadsByDispatchGroupAndDispatchersGroup={dropLoadsByDispatchGroupAndDispatchersGroup}
                />
              </Flex>
            ))
          }
        </StickedFlex>
      }
      <Flex
        bg={whiteColor}
        flexDirection='column'
        alignItems='flex-start'
        width='calc(100vw - 350px)'
      >
        <CalendarLine {...props} />
        <CalendarLine2 {...props} />
        {
          groupedDataToUse.map((group: Object) => (
            <Flex key={G.getGuidFromObject(group)} ml={10} position='relative' flexDirection='column'>
              <DispatchingGroupWithDispatchers
                visibility='hidden'
                dispatchingGroup={group}
              />
              {
                R.pathOr([], ['dispatchers'], group).map((dispatcher: Object) => (
                  <Fragment key={G.getGuidFromObject(dispatcher)}>
                    {
                      R.includes(G.getGuidFromObject(group), openedGroups) &&
                      <Dispatcher
                        visibility='hidden'
                        dispatcher={dispatcher}
                      />
                    }
                    {
                      R.includes(G.getGuidFromObject(group), openedGroups) &&
                      R.pathOr([], ['loads'], dispatcher).map((load: Object) => (
                        <Flex
                          mt={10}
                          alignItems='start'
                          height={cardHeight}
                          key={G.getGuidFromObject(load)}
                        >
                          {
                            G.isNotNilAndNotEmpty(R.path(['tels'], load)) &&
                            <DriverTelsCards
                              {...props}
                              tels={R.path(['tels'], load)}
                              truckGuid={G.getGuidFromObject(load)}
                              groupGuid={G.getGuidFromObject(group)}
                              dispatcherGuid={G.getGuidFromObject(dispatcher)}
                            />
                          }
                          <TruckPendingActivitiesAndUnavailablePeriods
                            {...props}
                            truck={load}
                            group={group}
                            dispatcher={dispatcher}
                          />
                        </Flex>
                      ))
                    }
                  </Fragment>
                ))
              }
            </Flex>
          ))
        }
      </Flex>
    </Flex>
  );
});
