import React from 'react';
// ui
import { Grid } from '../../../../ui';
// feature claim-management
import NotesCard from './notes';
import DocumentsCard from './documents';
import { CloCard, TelCard } from './clo-tel-cards';
//////////////////////////////////////////////////

const GeneralDetails = (props: Object) => {
  const { dispatch, claimGuid, openModal, closeModal, openLoader, closeLoader, claimBranchGuid } = props;

  return (
    <Grid gridGap={25} gridTemplateColumns='1fr 1fr'>
      <CloCard dispatch={dispatch} />
      <TelCard dispatch={dispatch} />
      <DocumentsCard
        dispatch={dispatch}
        openModal={openModal}
        claimGuid={claimGuid}
        closeModal={closeModal}
        openLoader={openLoader}
        closeLoader={closeLoader}
        claimBranchGuid={claimBranchGuid}
      />
      <NotesCard
        dispatch={dispatch}
        openModal={openModal}
        claimGuid={claimGuid}
        openLoader={openLoader}
        closeLoader={closeLoader}
        claimBranchGuid={claimBranchGuid}
      />
    </Grid>
  );
};

export default GeneralDetails;
