import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, RelativeFlex, AbsoluteBox } from '../../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

const getDispatcherInfo = (dispatcher: Object) => {
  const fullText = R.path(['fullText'], G.getUserInfo(dispatcher));

  if (G.isNilOrEmpty(fullText)) {
    return {
      dispatched: false,
      text: G.getWindowLocale('titles:no-dispatcher', 'No Dispatcher'),
    };
  }

  return {
    text: fullText,
    dispatched: true,
  };
};

const Dispatcher = (props: Object) => {
  const { visibility, dispatcher, onClickHandler, showCloseIcon, setValueToStore } = props;

  const { text, dispatched } = getDispatcherInfo(dispatcher);

  const guid = G.getGuidFromObject(dispatcher);

  if (dispatched) {
    return (
      <RelativeFlex
        p='5px'
        mt={10}
        width={400}
        border='1px solid'
        borderRadius='5px'
        bg={G.getTheme('colors.white')}
        visibility={R.or(visibility, 'visible')}
        borderColor={G.getTheme('colors.dark.blue')}
        onClick={() => onClickHandler({ dispatcherGuid: guid })}
      >
        {I.groupIcon()}
        <TextComponent
          ml={10}
          title={text}
          display='block'
          maxWidth='180px'
          withEllipsis={true}
          color={darkBlueColor}
        >
          {text}
        </TextComponent>
        { showCloseIcon &&
          <AbsoluteBox
            right='5px'
            width='30px'
            height='35px'
            cursor='pointer'
            title={G.getWindowLocale('titles:close', 'Close')}
            onClick={(event: Event) => {
              G.stopPropagation(event);

              setValueToStore({ value: null, path: `driverLoadsByDispatcher.${guid}` });
            }}
          >
            {I.closeIcon(darkBlueColor)}
          </AbsoluteBox>
        }
        { R.not(showCloseIcon) &&
          <AbsoluteBox
            right='5px'
            width='30px'
            height='35px'
            cursor='pointer'
            title={G.getWindowLocale('titles:open', 'Open')}
          >
            {I.arrowDownSimple(darkBlueColor)}
          </AbsoluteBox>
        }
      </RelativeFlex>
    );
  }

  return (
    <Box mt={10} visibility={R.or(visibility, 'visible')} color={darkBlueColor}>
      {text}
    </Box>
  );
};

export default Dispatcher;
