import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import VinLookup from '../../components/vin-lookup';
//////////////////////////////////////////////////

const settings = {
  // first fields
  [GC.FIELD_FLEET_IN_SERVICE]: {
    type: 'toggle',
    name: 'titles:in-service',
    additionalInputWrapperSettings: { mr: 20, mb: 10, height: 36, minWidth: 'unset', width: 'max-content' },
  },
  [GC.FIELD_OUT_OF_SERVICE_REASON]: {
    type: 'select',
    isRequired: true,
    options: GC.TRUCK_OUT_SERVICE_REASON,
    name: 'titles:out-of-service-status-reason',
    additionalInputWrapperSettings: {
      mr: 20,
      mb: 10,
      width: 200,
      display: (props: Object) => G.ifElse(
        R.path(['values', GC.FIELD_FLEET_IN_SERVICE], props),
        'none',
      ),
    },
  },
  [GC.FIELD_AVAILABLE]: {
    type: 'toggle',
    name: 'titles:available',
    additionalInputWrapperSettings: { mr: 20, mb: 10, height: 36, minWidth: 'unset', width: 'max-content' },
  },
  [GC.FIELD_UNAVAILABILITY_REASON]: {
    type: 'select',
    isRequired: true,
    options: GC.TRUCK_UNAVAILABILITY_REASON,
    name: 'titles:unavailable-status-reason',
    additionalInputWrapperSettings: {
      mr: 20,
      mb: 10,
      width: 200,
      display: (props: Object) => G.ifElse(
        R.path(['values', GC.FIELD_AVAILABLE], props),
        'none',
      ),
    },
  },
  // general
  [GC.FIELD_TRUCK_TYPE]: {
    type: 'select',
    isRequired: true,
    name: 'titles:type',
    options: GC.TRUCK_TRUCK_TYPE,
  },
  [GC.FIELD_UNIT_ID]: {
    type: 'text',
    isRequired: true,
    name: 'titles:unit-id',
  },
  [GC.FIELD_LEGACY_UNIT_ID]: {
    type: 'text',
    minmax: [0, 40],
    isRequired: false,
    name: 'titles:legacy-unit-id',
  },
  [GC.FIELD_SERIAL_NUMBER]: {
    type: 'text',
    minmax: [0, 40],
    additionalLabelComponent: <VinLookup />,
    additionalLabelStyles: {
      right: 0,
      left: '5px',
      width: 'calc(100% - 10px)',
      justifyContent: 'space-between',
    },
  },
  [GC.FIELD_MANUFACTURER]: {
    type: 'text',
    minmax: [0, 100],
    name: 'titles:manufacturer',
  },
  [GC.FIELD_YEAR]: {
    type: 'number',
    name: 'titles:year',
    minmax: [1900, 2100],
    validationType: 'number',
  },
  [GC.FIELD_MODEL]: {
    type: 'text',
    minmax: [0, 100],
    name: 'titles:model',
  },
  [GC.FIELD_SERIES]: {
    type: 'text',
    minmax: [0, 40],
    name: 'titles:series',
  },
  [GC.FIELD_FLEET_IN_SERVICE_DATE]: {
    type: 'datePicker',
    isClearable: true,
    name: 'titles:in-service-date',
  },
  [GC.FIELD_FLEET_OUT_OF_SERVICE_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:out-service-date',
  },
  [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT]: {
    isMulti: true,
    type: 'reactSelect',
    name: 'titles:additional-equipment-type',
    options: GC.TRUCK_ADDITIONAL_EQUIPMENT_TYPE,
  },
  [GC.FIELD_ODOMETER]: {
    type: 'text',
    name: 'titles:odometer',
    validationType: 'number',
  },
  assignedDispatcherGuid: {
    type: 'select',
    options: 'usersGeneral',
    name: 'titles:dispatcher',
  },
  dispatchingGroupGuid: {
    type: 'select',
    name: 'titles:dispatching-group',
    options: 'dispatchingGroupOptions',
  },
  // registration
  [GC.FIELD_LICENSE_PLATE]: {
    type: 'text',
    minmax: [0, 50],
    name: 'titles:license-plate',
  },
  [GC.FIELD_REGISTRATION_STATE]: {
    type: 'text',
    name: 'titles:registration-state',
  },
  [GC.FIELD_REGISTRATION_EXPIRATION_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:registration-expiration-date',
  },
  [GC.FIELD_TITLE_STATE]: {
    type: 'text',
    name: 'titles:title-state',
  },
  [GC.FIELD_TITLE_NUMBER]: {
    type: 'text',
    name: 'titles:title-number',
  },
  [GC.FIELD_IFTA_REQUIRED]: {
    type: 'toggle',
    name: 'titles:ifta-required',
    additionalInputWrapperSettings: { mb: 0, justifyContent: 'center' },
  },
  [GC.FIELD_IFTA_DATE]: {
    isClearable: true,
    type: 'datePicker',
    name: 'titles:ifta-date',
    additionalInputWrapperSettings: { mb: 0 },
  },
  [GC.FIELD_IFTA_DECAL]: {
    type: 'text',
    minmax: [0, 40],
    name: 'titles:ifta-decal-number',
    nameForAttribute: GC.FIELD_IFTA_DECAL,
    additionalInputWrapperSettings: { mb: 0 },
  },
  field1: {
    additionalInputWrapperSettings: {
      mb: 0,
      visibility: () => 'hidden',
    },
  },
  field2: {
    additionalInputWrapperSettings: {
      mb: 0,
      visibility: () => 'hidden',
    },
  },
};

const fieldsWrapperStyles = {
  mx: 20,
  mt: 25,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

const groups = [
  {
    fields: [
      GC.FIELD_FLEET_IN_SERVICE,
      GC.FIELD_OUT_OF_SERVICE_REASON,
      GC.FIELD_AVAILABLE,
      GC.FIELD_UNAVAILABILITY_REASON,
    ],
  },
  {
    fieldsWrapperStyles,
    formSectionTitle: G.getWindowLocale('titles:primary-details', 'Primary Details'),
    fields: [
      GC.FIELD_UNIT_ID,
      GC.FIELD_LEGACY_UNIT_ID,
      GC.FIELD_TRUCK_TYPE,
      GC.FIELD_FLEET_IN_SERVICE_DATE,
      GC.FIELD_FLEET_OUT_OF_SERVICE_DATE,
      GC.FIELD_SERIAL_NUMBER,
      GC.FIELD_MANUFACTURER,
      GC.FIELD_YEAR,
      GC.FIELD_MODEL,
      GC.FIELD_SERIES,
      GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT,
      GC.FIELD_ODOMETER,
      'assignedDispatcherGuid',
      'dispatchingGroupGuid',
      'field1',
      'field1',
      'field1',
    ],
  },
  {
    fieldsWrapperStyles,
    formSectionTitle: G.getWindowLocale('titles:registration', 'Registration'),
    fields: [
      GC.FIELD_LICENSE_PLATE,
      GC.FIELD_REGISTRATION_STATE,
      GC.FIELD_REGISTRATION_EXPIRATION_DATE,
      GC.FIELD_TITLE_STATE,
      GC.FIELD_TITLE_NUMBER,
      GC.FIELD_IFTA_REQUIRED,
      GC.FIELD_IFTA_DATE,
      GC.FIELD_IFTA_DECAL,
      'field1',
      'field2',
    ],
  },
];

const defaultInputWrapperStyles = {
  mb: 25,
  width: '19%',
  maxWidth: 280,
  minWidth: 125,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

const mappedGroups = R.map((group: Object) => {
  const { fields } = group;

  const fieldSettings = R.map((fieldName: Object) => {
    const field = R.pathOr({}, [fieldName], settings);

    const {
      name,
      inputStyles,
      additionalLabelStyles,
      additionalLabelComponent,
      additionalInputWrapperSettings,
    } = field;

    return {
      ...field,
      fieldName,
      inputStyles,
      errorWidth: '100%',
      additionalLabelStyles,
      additionalLabelComponent,
      calendarInputWrapperStyles,
      label: G.ifElse(G.isNotNil(name), R.of(Array, name)),
      inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperSettings },
    };
  }, fields);

  return R.assoc('fieldSettings', fieldSettings, group);
}, groups);

const validationSchema = Yup.lazy(({ available, inService }: Object) => Yup.object().shape({
  ...R.map(({ minmax, isRequired, validationType = 'string' }: Object) => {
    const fieldsMap = {
      number: G.yupNumberNotRequired,
      string: G.ifElse(isRequired, G.yupStringRequired, G.yupStringNotRequired),
    };

    let field = R.prop(validationType, fieldsMap);

    if (G.isNotNil(minmax)) {
      const locale = G.ifElse(
        R.equals(validationType, 'string'),
        G.getShouldBeFromToCharLocaleTxt,
        G.getShouldBeFromToLocaleTxt,
      )(...minmax);

      field = field.min(R.head(minmax), locale).max(R.last(minmax), locale);
    }

    return field;
  }, settings),
  [GC.FIELD_TRUCK_ADDITIONAL_EQUIPMENT]: G.yupArrayNotRequired,
  [GC.FIELD_OUT_OF_SERVICE_REASON]: G.ifElse(
    G.isFalse(inService),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
  [GC.FIELD_UNAVAILABILITY_REASON]: G.ifElse(
    G.isFalse(available),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

export const generalDetailsSettings = {
  validationSchema,
  firstFields: R.head(mappedGroups),
  fieldsGroups: R.tail(mappedGroups),
};
