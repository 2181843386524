import { useSelector } from 'react-redux';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Text, Iframe, MainActionButton } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// hooks
import { useLoaderActions } from '../../../hooks';
// feature auth
import { makeSelectCurrentUserBranchGuid } from '../selectors';
//////////////////////////////////////////////////

const UserTermsAndConditions = () => {
  const { openLoader, closeLoader } = useLoaderActions();

  const branchGuid = useSelector(makeSelectCurrentUserBranchGuid());

  const [filePublicLink, setFilePublicLink] = useState(null);

  const getTermsDocument = useCallback(async () => {
    openLoader();

    const options = { params: { [GC.BRANCH_GUID]: branchGuid }};

    const res = await sendRequest('get', endpointsMap.userTermsAndConditionsDocument, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setFilePublicLink(G.getPropFromObject('filePublicLink', data));
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, [branchGuid]);

  const acceptTermsAndConditions = useCallback(async () => {
    openLoader();

    const res = await sendRequest('put', endpointsMap.userAcceptTermsAndConditions);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.goToRoute(routesMap.profile);
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, []);

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(branchGuid)) getTermsDocument();
  }, [branchGuid]);

  return (
    <Box p={15}>
      <Text
        mb={25}
        fontSize={32}
        textAlign='center'
      >
        {G.getWindowLocale('titles:user-terms-and-conditions', 'User Terms & Conditions')}
      </Text>
      {
        filePublicLink && (
          <Fragment>
            <Box height='calc(100vh - 200px)'>
              <Iframe src={filePublicLink} />
            </Box>
            <MainActionButton
              mt={25}
              onClick={acceptTermsAndConditions}
            >
              {G.getWindowLocale('titles:accept-terms-and-conditions', 'Accept Terms & Conditions')}
            </MainActionButton>
          </Fragment>
        )
      }
    </Box>
  );
};

export default UserTermsAndConditions;
