import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, AbsoluteBox, RelativeFlex } from '../../../ui';
// feature drivers-card
import Truck from './truck';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');

export const Dispatcher = (props: Object) => {
  const {
    visibility,
    cardHeight,
    dispatcher,
    onClickHandler,
    openTruckDetails,
    openDriverProfile,
    dispatchingGroupGuid,
  } = props;

  const { guid, loads } = dispatcher;

  const { fullText } = G.getUserInfo(dispatcher);

  return (
    <Fragment>
      <RelativeFlex
        p='5px'
        mt={10}
        width={300}
        cursor='pointer'
        border='1px solid'
        borderRadius='5px'
        bg={G.getTheme('colors.white')}
        visibility={R.or(visibility, 'visible')}
        borderColor={G.getTheme('colors.dark.blue')}
        onClick={() => onClickHandler({ dispatchingGroupGuid, dispatcherGuid: guid })}
      >
        <TextComponent
          ml={10}
          title={fullText}
          display='block'
          maxWidth='180px'
          withEllipsis={true}
          color={darkBlueColor}
        >
          {fullText}
        </TextComponent>
      </RelativeFlex>
      { R.not(R.equals(visibility, 'hidden')) && loads &&
        loads.map((entity: Object) => (
          <Box mt={10} key={G.getGuidFromObject(entity)}>
            <Truck
              {...entity}
              dispatcherGuid={guid}
              cardHeight={cardHeight}
              groupGuid={dispatchingGroupGuid}
              openTruckDetails={openTruckDetails}
              openDriverProfile={openDriverProfile}
            />
          </Box>
        ))
      }
    </Fragment>
  );
};

const DispatchingGroupWithDispatchers = (props: Object) => {
  const {
    opened,
    visibility,
    cardHeight,
    onClickHandler,
    setOpenedGroup,
    setValueToStore,
    dispatchingGroup,
    openTruckDetails,
    openDriverProfile,
    dropLoadsByDispatchGroupAndDispatchersGroup,
  } = props;

  const { name, guid, dispatchers } = dispatchingGroup;

  return (
    <Fragment>
      <RelativeFlex
        p='5px'
        mt={10}
        width={400}
        cursor='pointer'
        border='1px solid'
        borderRadius='5px'
        bg={G.getTheme('colors.white')}
        onClick={() => setOpenedGroup(guid)}
        visibility={R.or(visibility, 'visible')}
        borderColor={G.getTheme('colors.dark.blue')}
      >
        {I.groupIcon()}
        <TextComponent
          ml={10}
          title={name}
          display='block'
          maxWidth='180px'
          withEllipsis={true}
          color={darkBlueColor}
        >
          {name}
        </TextComponent>
        { opened &&
          <AbsoluteBox
            right='5px'
            width='30px'
            height='35px'
            cursor='pointer'
            title={G.getWindowLocale('titles:close', 'Close')}
            onClick={(event: Event) => {
              G.stopPropagation(event);

              dropLoadsByDispatchGroupAndDispatchersGroup(guid);

              setOpenedGroup(guid, true);
            }}
          >
            {I.closeIcon(darkBlueColor)}
          </AbsoluteBox>
        }
        { R.not(opened) &&
          <AbsoluteBox
            right='5px'
            width='30px'
            height='35px'
            cursor='pointer'
            title={G.getWindowLocale('titles:open', 'Open')}
          >
            {I.arrowDownSimple(darkBlueColor)}
          </AbsoluteBox>
        }
      </RelativeFlex>
      { opened && G.isNotNilAndNotEmpty(dispatchers) &&
        dispatchers.map((dispatcher: Object) => (
          <Dispatcher
            cardHeight={cardHeight}
            dispatcher={dispatcher}
            dispatchingGroupGuid={guid}
            onClickHandler={onClickHandler}
            setValueToStore={setValueToStore}
            openTruckDetails={openTruckDetails}
            openDriverProfile={openDriverProfile}
            key={G.getGuidFromObject(dispatcher)}
          />
        ))
      }
    </Fragment>
  );
};

export default DispatchingGroupWithDispatchers;
