import React, { useState } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// components
import ToggleBtn from '../../../components/toggle-btn';
import ColorPicker from '../../../components/color-picker';
import { DatePickerMui } from '../../../components/datepicker-mui';
import { QuickFilterOutsideControl3 } from '../../../components/quick-filter';
import { PlacesAutocompleteInputMui } from '../../../components/places-autocomplete-input/mui';
// ui
import { Box, Flex, Text, ActionButton, RelativeFlex } from '../../../ui';
// feature drivers-card
import LeftFilters from './left-filters';
//////////////////////////////////////////////////

const commonBtnStyles = {
  height: 32,
  p: '4px 8px',
  fontSize: 10,
  fontWeight: 600,
  borderRadius: '5px',
  textTransform: 'uppercase',
  bgColor: G.getTheme('colors.white'),
  textColor: G.getTheme('colors.dark.blue'),
  border: `1px solid ${G.getTheme('colors.dark.blue')}`,
};

const SubHeader = (props: Object) => {
  const {
    loadRefFilterValue,
    toggleUnassignedLoads,
    handleOpenCreateNewDO,
    colorPickerFilterValue,
    handleChangeLoadRefFilter,
    setColorPickerFilterValue,
  } = props;

  return (
    <Flex height={60} background={G.getTheme('colors.white')} justifyContent='space-between'>
      <LeftFilters {...props} />
      <Flex>
        <Box mr={20} ml='5px' mt='7px'>
          <QuickFilterOutsideControl3
            inputStyles={{ width: 150 }}
            outsideValue={loadRefFilterValue}
            handleSetFilter={handleChangeLoadRefFilter}
            placeholder={G.getWindowLocale('titles:search', 'Search')}
            label={G.getWindowLocale('titles:search-by-load-ref', 'Search By Load Ref')}
          />
        </Box>
        <Flex mr={20} flexDirection='column'>
          <Text
            mb='3px'
            fontSize={12}
            color='darkGrey'
          >
            {G.getWindowLocale('titles:filter-by-color', 'Filter By Color')}
          </Text>
          <ColorPicker
            shouldTransformActive={true}
            value={colorPickerFilterValue}
            changeColorCallback={setColorPickerFilterValue}
            itemStyles={{ width: 25, height: 25, borderRadius: '4px' }}
            wrapperStyles={{ width: 150, justifyContent: 'space-between' }}
          />
        </Flex>
        <ActionButton
          {...commonBtnStyles}
          mt='7px'
          onClick={toggleUnassignedLoads}
        >
          {G.getWindowLocale('actions:search-loads', 'Search Loads')}
        </ActionButton>
        <ActionButton
          {...commonBtnStyles}
          ml={15}
          mr={15}
          mt='7px'
          onClick={handleOpenCreateNewDO}
        >
          {G.getWindowLocale('actions:create-order', 'Create Order')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

const Header = (props: Object) => {
  const {
    searchDate,
    driversCount,
    dispatchByTruck,
    searchRadiusValue,
    setSearchDateToStore,
    setSearchRadiusValue,
    filterByPlaceAutocomplete,
    searchPlaceAutocompleteValue,
    setSearchPlaceAutocompleteValue,
  } = props;

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const title = `${G.getWindowLocale('titles:driver-cards', 'Driver Cards')} (${driversCount})`;
  const radiusPlaceholder = G.getDistanceUomLocale();

  return (
    <RelativeFlex
      px={15}
      pt={25}
      bg='white'
      zIndex={2}
      height={60}
    >
      <Text
        mr={15}
        fontSize={20}
        fontWeight='bold'
        color='dark.mainDark'
      >
        {title}
      </Text>
      {
        G.isFalse(dispatchByTruck) &&
        <ToggleBtn
          mr={15}
          width={100}
          checked={showAdvancedSearch}
          onToggleHandler={setShowAdvancedSearch}
          title={G.getWindowLocale('titles:advanced-search', 'Advanced Search')}
        />
      }
      {
        showAdvancedSearch &&
        <Flex>
          <ActionButton
            {...commonBtnStyles}
            mr={15}
            onClick={() => setSearchDateToStore(G.getCurrentDate())}
          >
            {G.getWindowLocale('actions:available-today', 'Available Today')}
          </ActionButton>
          <Flex>
            <PlacesAutocompleteInputMui
              width={150}
              withCloseIcon={true}
              useOutsideValue={true}
              inputWrapperStyles={{ mr: 15 }}
              resultCallback={filterByPlaceAutocomplete}
              outsideValue={searchPlaceAutocompleteValue}
              onCloseCallback={setSearchPlaceAutocompleteValue}
              setOutsideValue={setSearchPlaceAutocompleteValue}
              label={G.getWindowLocale('titles:search-location', 'Search Location')}
            />
            <QuickFilterOutsideControl3
              inputStyles={{ width: 100 }}
              placeholder={radiusPlaceholder}
              outsideValue={searchRadiusValue}
              handleSetFilter={setSearchRadiusValue}
              label={G.getWindowLocale('titles:search-radius', 'Search Radius')}
            />
            <DatePickerMui
              width={100}
              m='0px 15px'
              isClearable={true}
              value={searchDate}
              withCalendarIcon={false}
              useNewMuiInputField={true}
              label={G.getWindowLocale('titles:search-date', 'Search Date')}
              onChange={(date: Object) => G.isNilOrEmpty(date) && setSearchDateToStore(null)}
              onAccept={(date: Object) =>
                G.isNotNilAndNotEmpty(date) && setSearchDateToStore(G.convertInstanceToDefaultDateFormat(date))}
            />
            <ActionButton
              {...commonBtnStyles}
              width={80}
              onClick={() => filterByPlaceAutocomplete()}
            >
              {G.getWindowLocale('titles:search', 'Search')}
            </ActionButton>
          </Flex>
        </Flex>
      }
    </RelativeFlex>
  );
};

export default Header;

export {
  SubHeader,
  commonBtnStyles,
};
