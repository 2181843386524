import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature claim-management
import * as A from '../actions';
import { setBranchConfigsToStoreByPath } from '../helpers';
//////////////////////////////////////////////////

const getClaimGeneralDetailsSuccess = (state: Object, data: Object) => (
  P.$set('claimDetails.generalDetails', data, state)
);

const setDetailsInitialState = (state: Object) => P.$set('claimDetails', {}, state);

// configs
const getDetailsBranchConfigsByNamesSuccess = (state: Object, data: Object) => setBranchConfigsToStoreByPath({
  data,
  state,
  path: 'claimDetails.configGroup',
});

// documents
const getDocumentListSuccess = (state: Object, data: Array) => (
  P.$set('claimDetails.documents', data, state)
);

const createOrUpdateDocumentSuccess = (state: Object, data: Object) => {
  const { documents } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), documents);

  if (G.isArray(data)) return P.$set('claimDetails.documents', R.concat(data, documents), state);

  return P.$set(`claimDetails.documents.${index}`, data, state);
};

const removeDocumentSuccess = (state: Object, guid: Object) => {
  const { documents } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), documents);

  return P.$drop(`claimDetails.documents.${index}`, state);
};

// notes
const getNoteListSuccess = (state: Object, data: Array) => (
  P.$set('claimDetails.notes', data, state)
);

const createOrUpdateNoteSuccess = (state: Object, data: Object) => {
  const { notes } = R.propOr({}, 'claimDetails', state);

  const { pinned, guid } = data;

  if (R.and(pinned, R.gt(R.length(notes), 1))) {
    const mappedNotes = R.map((item: Object) => {
      const pinnedItem = R.propEq(guid, GC.FIELD_GUID, item);

      return { ...G.ifElse(pinnedItem, data, item), pinned: G.ifElse(pinnedItem, pinned, false) };
    }, notes);

    return P.$set('claimDetails.notes', mappedNotes, state);
  }

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), notes);

  if (R.equals(index, -1)) return P.$add('claimDetails.notes', data, state);

  return P.$set(`claimDetails.notes.${index}`, data, state);
};

const removeNoteSuccess = (state: Object, guid: Object) => {
  const { notes } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), notes);

  return P.$drop(`claimDetails.notes.${index}`, state);
};

// related tels
const getRelatedTelsSuccess = (state: Object, data: Array) => {
  const mapped = R.map(({ fleetAssignment, carrierAssignment, ...item }: Object) => ({
    ...item,
    withoutTracking: true,
    [GC.FIELD_SHOWN_FLEET_ASSIGNMENT]: fleetAssignment,
    [GC.FIELD_SHOWN_CARRIER_ASSIGNMENT]: {
      ...carrierAssignment,
      [GC.FIELD_SHOWN_ASSIGNMENT_CARRIER_NAME]: R.prop(GC.FIELD_NAME, carrierAssignment),
    },
  }), R.or(data, []));

  return P.$set('claimDetails.relatedTels', mapped, state);
};

// damaged items
const getCloItemsSuccess = (state: Object, data: Object) => {
  const mapped = R.map((item: Object) => R.assoc('expanded', false, item), R.or(data, []));

  return P.$set('claimDetails.cloItems', mapped, state);
};

const toggleCloItem = (state: Object, guid: string) => {
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), R.path(['claimDetails', 'cloItems'], state));

  return P.$toggle(`claimDetails.cloItems.${index}.expanded`, state);
};

const getDamagedItemsByCloItemGuidSuccess = (state: Object, { data, cloItemGuid  }) => (
  P.$set(`claimDetails.groupedByCloItemGuidDamagedItems.${cloItemGuid}`, data, state)
);

const createOrUpdateDamagedItemSuccess = (state: Object, data: Object) => {
  const { guid, cloItemGuid } = data;

  const { groupedByCloItemGuidDamagedItems } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(
    R.propEq(guid, GC.FIELD_GUID),
    R.propOr([], cloItemGuid, groupedByCloItemGuidDamagedItems),
  );

  if (R.equals(index, -1)) {
    return P.$add(`claimDetails.groupedByCloItemGuidDamagedItems.${cloItemGuid}`, data, state);
  }

  return P.$set(`claimDetails.groupedByCloItemGuidDamagedItems.${cloItemGuid}.${index}`, data, state);
};

const removeDamagedItemSuccess = (state: Object, { guid, cloItemGuid }: Object) => {
  const { groupedByCloItemGuidDamagedItems } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(
    R.propEq(guid, GC.FIELD_GUID),
    R.propOr([], cloItemGuid, groupedByCloItemGuidDamagedItems),
  );

  return P.$drop(`claimDetails.groupedByCloItemGuidDamagedItems.${cloItemGuid}.${index}`, state);
};

// accident
const getAccidentSuccess = (state: Object, data: Array) => (
  P.$set('claimDetails.accident', data, state)
);

// involved party
const getInvolvedPartyListSuccess = (state: Object, data: Array) => (
  P.$set('claimDetails.involvedPartyList', data, state)
);

const createOrUpdateInvolvedPartySuccess = (state: Object, data: Object) => {
  const { involvedPartyList } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), involvedPartyList);

  if (R.equals(index, -1)) return P.$add('claimDetails.involvedPartyList', data, state);

  return P.$set(`claimDetails.involvedPartyList.${index}`, data, state);
};

const removeInvolvedPartySuccess = (state: Object, guid: Object) => {
  const { involvedPartyList } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), involvedPartyList);

  return P.$drop(`claimDetails.involvedPartyList.${index}`, state);
};

// attorney
const getAttorneyListSuccess = (state: Object, data: Array) => (
  P.$set('claimDetails.attorneyList', data, state)
);

const createOrUpdateAttorneySuccess = (state: Object, data: Object) => {
  const { attorneyList } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), attorneyList);

  if (R.equals(index, -1)) return P.$add('claimDetails.attorneyList', data, state);

  return P.$set(`claimDetails.attorneyList.${index}`, data, state);
};

const removeAttorneySuccess = (state: Object, guid: Object) => {
  const { attorneyList } = R.propOr({}, 'claimDetails', state);

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), attorneyList);

  return P.$drop(`claimDetails.attorneyList.${index}`, state);
};

export const detailsReducer = {
  [A.setDetailsInitialState]: setDetailsInitialState,
  [A.getClaimGeneralDetailsSuccess]: getClaimGeneralDetailsSuccess,
  [A.getDetailsBranchConfigsByNamesSuccess]: getDetailsBranchConfigsByNamesSuccess,
  // configs
  [A.getDetailsBranchConfigsByNamesSuccess]: getDetailsBranchConfigsByNamesSuccess,
  // documents
  [A.removeDocumentSuccess]: removeDocumentSuccess,
  [A.getDocumentListSuccess]: getDocumentListSuccess,
  [A.createOrUpdateDocumentSuccess]: createOrUpdateDocumentSuccess,
  // notes
  [A.removeNoteSuccess]: removeNoteSuccess,
  [A.getNoteListSuccess]: getNoteListSuccess,
  [A.createOrUpdateNoteSuccess]: createOrUpdateNoteSuccess,
  // related tels
  [A.getRelatedTelsSuccess]: getRelatedTelsSuccess,
  // damaged items
  [A.toggleCloItem]: toggleCloItem,
  [A.getCloItemsSuccess]: getCloItemsSuccess,
  [A.removeDamagedItemSuccess]: removeDamagedItemSuccess,
  [A.createOrUpdateDamagedItemSuccess]: createOrUpdateDamagedItemSuccess,
  [A.getDamagedItemsByCloItemGuidSuccess]: getDamagedItemsByCloItemGuidSuccess,
  // accident
  [A.getAccidentSuccess]: getAccidentSuccess,
  // involved party
  [A.removeInvolvedPartySuccess]: removeInvolvedPartySuccess,
  [A.getInvolvedPartyListSuccess]: getInvolvedPartyListSuccess,
  [A.createOrUpdateInvolvedPartySuccess]: createOrUpdateInvolvedPartySuccess,
  // attorney
  [A.removeAttorneySuccess]: removeAttorneySuccess,
  [A.getAttorneyListSuccess]: getAttorneyListSuccess,
  [A.createOrUpdateAttorneySuccess]: createOrUpdateAttorneySuccess,
};
