import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { css } from 'styled-components';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  cubeTypeOptions,
  oldItemWeightOptions,
  ITEM_DIMENSIONS_OPTIONS,
  oldItemDimensionsOptions,
  bulkCompVolumeTypeOptions,
  refrigerationCoolantTypeOptions,
} from '../../../../helpers/options';
// icons
import * as I from '../../../../svgs';
//////////////////////////////////////////////////

const renderOptionComponent = (props: Object) => (
  <div
    onClick={() => props.selectOption(props.data)}
    css={css`
      padding: 10px;

      &:hover {
        background: ${R.path(['theme', 'colors', 'light', 'lightGrey'])};
      }
    `}
  >
    {I[props.children]()}
  </div>
);

const renderValueComponent = (props: Object) => (
  <div css={css`grid-area: 1/1/2/3;`}>
    {I[props.data.label]()}
  </div>
);

const makePairFields = G.mapIndexed((item: Object, index: number) => ({
  ...item,
  additionalInputWrapperStyles: {
    width: 110,
    mr: G.ifElse(G.isZero(index), 0, R.or(item.mr, 20)),
  },
  inputStyles: {
    borderRadius: G.ifElse(G.isZero(index), '4px 0 0 4px', '0 4px 4px 0'),
  },
}));

const TemperatureControlLabel = () => (
  <div
    css={css`
      :after {
        content: '*';
        color: ${R.path(['theme', 'colors', 'light', 'mainRed'])};
      }
  `}
  >
    {G.getWindowLocale('titles:temperature-range', 'Temperature Range')}
  </div>
);

const additionalLabelStyles = {
  left: '5px',
  fontSize: 11,
  top: '-13px',
  right: '0px',
  color: 'darkGrey',
  width: 'max-content',
};

const truckSettings = {
  // main specifications
  ...makePairFields({
    [GC.FIELD_TARE_WEIGHT]: {
      name: 'titles:tare-weight',
    },
    [GC.FIELD_TARE_WEIGHT_TYPE]: {
      type: 'select',
      options: oldItemWeightOptions,
    },
  }),
  ...makePairFields({
    [GC.FIELD_GVWT]: {
      name: 'titles:gvwr',
    },
    [GC.FIELD_GVWT_WEIGHT_TYPE]: {
      type: 'select',
      options: oldItemWeightOptions,
    },
  }),
  [GC.FIELD_SPECIFICATION_CLASS]: {
    type: 'select',
    name: 'titles:class',
    additionalInputWrapperStyles: { width: 220 },
    options: [
      '',
      '8: HT +33k pounds',
      '7: HT 26k - 33k pounds',
      '5: MT 16k - 19,5k pounds',
      '6: MT 19,5k - 26k pounds',
      '9: SH +33k pounds, special duty',
    ],
  },
  [GC.FIELD_AXLE_COUNT]: {
    type: 'select',
    name: 'titles:axle-count',
    options: ['', '1', '2', '3', '4'],
    additionalInputWrapperStyles: { width: 120 },
  },
  [GC.FIELD_AXLE_CONFIG]: {
    type: 'reactSelect',
    name: 'titles:axle-config',
    additionalInputWrapperStyles: { mr: '0px', width: 140 },
    options: [
      { label: 'truckOne', value: '1-1' },
      { label: 'truckTwo', value: '1-2' },
    ],
    components: {
      SingleValue: renderValueComponent,
      Option: (props: Object) => renderOptionComponent(props),
    },
  },
  ...makePairFields({
    [GC.FIELD_LENGTH]: {
      placeholder: 'L',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:truck-length', 'Truck Length'),
    },
    [GC.FIELD_LENGTH_UOM]: {
      type: 'select',
      options: ITEM_DIMENSIONS_OPTIONS,
    },
  }),
  ...makePairFields({
    [GC.FIELD_WIDTH]: {
      placeholder: 'W',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:truck-width', 'Truck Width'),
    },
    [GC.FIELD_WIDTH_UOM]: {
      type: 'select',
      options: ITEM_DIMENSIONS_OPTIONS,
    },
  }),
  ...makePairFields({
    [GC.FIELD_HEIGHT]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:truck-height', 'Truck Height'),
    },
    [GC.FIELD_HEIGHT_UOM]: {
      mr: 200,
      type: 'select',
      options: ITEM_DIMENSIONS_OPTIONS,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_WIDTH}`]: {
      placeholder: 'W',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:door-width', 'Door Width'),
    },
    [`${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_WIDTH_UOM}`]: {
      type: 'select',
      options: ITEM_DIMENSIONS_OPTIONS,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_HEIGHT}`]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:door-height', 'Door Height'),
    },
    [`${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_HEIGHT_UOM}`]: {
      type: 'select',
      options: ITEM_DIMENSIONS_OPTIONS,
    },
  }),
  // tractor
  [GC.FIELD_SLEEPER]: {
    type: 'toggle',
    name: 'titles:sleeper',
    additionalInputWrapperStyles: { width: 'max-content' },
  },
  [GC.FIELD_MPG_HIGH]: {
    name: 'titles:mpg-high',
  },
  [GC.FIELD_MPG_LOW]: {
    name: 'titles:mpg-low',
  },
  [GC.FIELD_FUEL_TYPE]: {
    type: 'select',
    name: 'titles:fuel-type',
    additionalInputWrapperStyles: { width: 133 },
    options: ['', 'Gasoline', 'Diesel fuel', 'Autogas', 'Biodiesel', 'Ethanol fuel'],
  },
  ...makePairFields({
    fifthWheelHeight: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:5th-wheel-height', '5th Wheel Height'),
    },
    fifthWheelHeightType: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    fifthWheelWidth: {
      placeholder: 'W',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:5th-wheel-width', '5th Wheel Width'),
    },
    fifthWheelWidthType: {
      mr: '0px',
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
};

const trailerSettings = {
  // main specifications
  ...makePairFields({
    [GC.FIELD_TARE_WEIGHT]: {
      name: 'titles:tare-weight',
    },
    [GC.FIELD_WEIGHT_TARE_TYPE]: {
      type: 'select',
      options: oldItemWeightOptions,
    },
  }),
  ...makePairFields({
    [GC.FIELD_GVWT]: {
      name: 'titles:gvwr',
    },
    [GC.FIELD_WEIGHT_TYPE_GVWT]: {
      mr: 220,
      type: 'select',
      options: oldItemWeightOptions,
    },
  }),
  [GC.FIELD_SPECIFICATION_CLASS]: {
    type: 'select',
    name: 'titles:class',
    options: GC.TRAILER_SPECIFICATION_CLASS,
    additionalInputWrapperStyles: { width: 220 },
  },
  [GC.FIELD_AXLE_COUNT]: {
    type: 'select',
    name: 'titles:axle-count',
    options: ['', '1', '2', '3', '4', '5'],
    additionalInputWrapperStyles: { width: 220 },
  },
  [GC.FIELD_AXLE_CONFIG]: {
    type: 'reactSelect',
    name: 'titles:axle-config',
    additionalInputWrapperStyles: { mr: '0px', width: 220 },
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
    options: [
      { label: 'trailerTwo', value: '0-2' },
      { label: 'trailerThree', value: '0-3' },
      { label: 'trailerFour', value: '0-4' },
      { label: 'trailerThreeTwo', value: '3-2' },
    ],
    components: {
      SingleValue: renderValueComponent,
      Option: (props: Object) => renderOptionComponent(props),
    },
  },
  ...makePairFields({
    [`${GC.FIELD_INTERNAL_DIMENSION}.${GC.FIELD_LENGTH}`]: {
      placeholder: 'L',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:internal-length', 'Internal Length'),
    },
    [`${GC.FIELD_INTERNAL_DIMENSION}.lengthUOM`]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_INTERNAL_DIMENSION}.${GC.FIELD_WIDTH}`]: {
      placeholder: 'W',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:internal-width', 'Internal Width'),
    },
    [`${GC.FIELD_INTERNAL_DIMENSION}.widthUOM`]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_INTERNAL_DIMENSION}.${GC.FIELD_HEIGHT}`]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:internal-height', 'Internal Height'),
    },
    [`${GC.FIELD_INTERNAL_DIMENSION}.heightUOM`]: {
      mr: '0px',
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_EXTERNAL_DIMENSION}.${GC.FIELD_LENGTH}`]: {
      placeholder: 'L',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:external-length', 'External Length'),
    },
    [`${GC.FIELD_EXTERNAL_DIMENSION}.lengthUOM`]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_EXTERNAL_DIMENSION}.${GC.FIELD_WIDTH}`]: {
      placeholder: 'W',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:external-width', 'External Width'),
    },
    [`${GC.FIELD_EXTERNAL_DIMENSION}.widthUOM`]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_EXTERNAL_DIMENSION}.${GC.FIELD_HEIGHT}`]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:external-height', 'External Height'),
    },
    [`${GC.FIELD_EXTERNAL_DIMENSION}.heightUOM`]: {
      mr: '0px',
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_WELL_DIMENSION}.${GC.FIELD_HEIGHT}`]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:well-height', 'Well Height'),
    },
    [`${GC.FIELD_WELL_DIMENSION}.heightUOM`]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_WELL_DIMENSION}.${GC.FIELD_LENGTH}`]: {
      placeholder: 'L',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:well-length', 'Well Length'),
    },
    [`${GC.FIELD_WELL_DIMENSION}.lengthUOM`]: {
      mr: 220,
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  [GC.FIELD_DOOR_TYPE]: {
    type: 'select',
    name: 'titles:door-type',
    options: GC.TRAILER_DOOR_TYPE,
    additionalInputWrapperStyles: { width: 220 },
  },
  ...makePairFields({
    [`${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_WIDTH}`]: {
      placeholder: 'W',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:door-width', 'Door Width'),
    },
    [`${GC.FIELD_DOOR_DIMENSION}.widthUOM`]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [`${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_HEIGHT}`]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:door-height', 'Door Height'),
    },
    [`${GC.FIELD_DOOR_DIMENSION}.heightUOM`]: {
      mr: '0px',
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  [GC.FIELD_BODY_MATERIAL]: {
    type: 'select',
    name: 'titles:body-material',
    options: GC.TRAILER_BODY_MATERIAL,
    additionalInputWrapperStyles: { width: 220 },
  },
  ...makePairFields({
    [GC.FIELD_DECK_HEIGHT]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:deck-height', 'Deck Height'),
    },
    [GC.FIELD_DECK_HEIGHT_TYPE]: {
      type: 'select',
      options: oldItemDimensionsOptions,
    },
  }),
  ...makePairFields({
    [GC.FIELD_CUBE]: {
      placeholder: 'H',
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:cube', 'Cube'),
    },
    [GC.FIELD_CUBE_TYPE]: {
      mr: '0px',
      type: 'select',
      options: cubeTypeOptions,
    },
  }),
  [GC.FIELD_BULK_COMP_COUNT]: {
    name: 'titles:bulk-compartment-count',
    additionalInputWrapperStyles: { width: 220 },
  },
  ...makePairFields({
    [GC.FIELD_BULK_COMP_VOLUME]: {
      additionalLabelStyles,
      additionalLabelComponent: G.getWindowLocale('titles:bulk-compartment-volume', 'Bulk Compartment Volume'),
    },
    [GC.FIELD_BULK_COMP_VOLUME_TYPE]: {
      type: 'select',
      options: bulkCompVolumeTypeOptions,
    },
  }),
  [GC.FIELD_TEMPERATURE_CONTROL]: {
    type: 'toggle',
    name: 'titles:temperature-control',
    additionalInputWrapperStyles: { width: 'max-content' },
  },
  // refrigeration
  [GC.FIELD_REFRIGERATION_MANUFACTURER]: {
    type: 'select',
    isRequired: true,
    name: 'titles:manufacturer',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { width: 220 },
    options: GC.TRAILER_REFTIGERATION_MANUFACTURER,
  },
  [GC.FIELD_OUTPUT_YEAR]: {
    isRequired: true,
    name: 'titles:year',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { width: 220 },
  },
  [GC.FIELD_MODEL]: {
    isRequired: true,
    name: 'titles:model',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { mr: '0px', width: 220 },
  },
  [GC.FIELD_SERIES]: {
    isRequired: true,
    name: 'titles:series',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { width: 220 },
  },
  [GC.FIELD_SERIAL_NUMBER]: {
    isRequired: true,
    name: 'titles:serial-number',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { width: 220 },
  },
  [GC.FIELD_COOLANT_TYPE]: {
    type: 'select',
    isRequired: true,
    name: 'titles:coolant-type',
    prefix: GC.FIELD_REFRIGERATION,
    options: refrigerationCoolantTypeOptions,
    additionalInputWrapperStyles: { mr: '0px', width: 220 },
  },
  [GC.FIELD_CARB_ID]: {
    name: 'titles:carb-id',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { width: 220 },
  },
  [GC.FIELD_TRUATCM_ID]: {
    name: 'titles:tru-atcm-id',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { mr: 220, width: 220 },
  },
  ...makePairFields({
    [GC.FIELD_TEMP_RANGE_FROM]: {
      width: 55,
      additionalLabelStyles,
      prefix: GC.FIELD_REFRIGERATION,
      placeholder: G.getWindowLocale('titles:from', 'From'),
      additionalLabelComponent: <TemperatureControlLabel />,
    },
    [GC.FIELD_TEMP_RANGE_TO]: {
      mr: '0px',
      width: 55,
      border: '1px solid',
      prefix: GC.FIELD_REFRIGERATION,
      placeholder: G.getWindowLocale('titles:to', 'To'),
    },
    [GC.FIELD_TEMP_RANGE_TYPE]: {
      mr: 20,
      type: 'select',
      prefix: GC.FIELD_REFRIGERATION,
      options: ['', 'Celsius', 'Fahrenheit'],
    },
  }),
  [GC.FIELD_HOURS]: {
    isRequired: true,
    name: 'titles:hours',
    prefix: GC.FIELD_REFRIGERATION,
    additionalInputWrapperStyles: { width: 110 },
  },
  [GC.FIELD_HOURS_DATE]: {
    isRequired: true,
    type: 'datePicker',
    name: 'titles:hours-date',
    prefix: GC.FIELD_REFRIGERATION,
    calendarInputWrapperStyles: { width: '100%' },
    additionalInputWrapperStyles: { mr: '0px', width: 220 },
  },
};

const defaultInputWrapperStyles = {
  mb: 25,
  mr: 20,
  width: 120,
};

const makeFieldSettings = (fields: Object, settings: Object) => R.compose(
  R.values,
  R.mapObjIndexed((
    {
      name,
      prefix,
      options,
      components,
      inputStyles,
      placeholder,
      type = 'text',
      locationMapper,
      isRequired = false,
      additionalLabelStyles,
      additionalLabelComponent,
      calendarInputWrapperStyles,
      reactSelectAdditionalStyles,
      additionalInputWrapperStyles,
    }: Object,
    fieldName: string,
  ) => ({
    type,
    options,
    isRequired,
    components,
    placeholder,
    inputStyles,
    locationMapper,
    additionalLabelStyles,
    additionalLabelComponent,
    calendarInputWrapperStyles,
    reactSelectAdditionalStyles,
    label: G.ifElse(G.isNotNil(name), R.of(Array, name)),
    fieldName: G.ifElse(R.isNotNil(prefix), `${prefix}.${fieldName}`, fieldName),
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      ...G.spreadUiStyles(additionalInputWrapperStyles),
    },
  })),
  R.pick(fields),
)(settings);

const makeDefaultFields = R.reduce((acc: Object, item: string) => {
  const splitted = R.split('.', item);

  if (R.equals(R.length(splitted), 1)) return R.assoc(item, '', acc);

  return R.assocPath(splitted, '', acc);
}, {});

const mainSpecificationFields = [
  GC.FIELD_TARE_WEIGHT,
  GC.FIELD_TARE_WEIGHT_TYPE,
  GC.FIELD_GVWT,
  GC.FIELD_GVWT_WEIGHT_TYPE,
  GC.FIELD_SPECIFICATION_CLASS,
  GC.FIELD_AXLE_COUNT,
  GC.FIELD_AXLE_CONFIG,
  GC.FIELD_LENGTH,
  GC.FIELD_LENGTH_UOM,
  GC.FIELD_WIDTH,
  GC.FIELD_WIDTH_UOM,
  GC.FIELD_HEIGHT,
  GC.FIELD_HEIGHT_UOM,
  `${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_WIDTH}`,
  `${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_WIDTH_UOM}`,
  `${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_HEIGHT}`,
  `${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_DOOR_HEIGHT_UOM}`,
];

const tractorSpecificationFields = [
  GC.FIELD_SLEEPER,
  GC.FIELD_MPG_HIGH,
  GC.FIELD_MPG_LOW,
  GC.FIELD_FUEL_TYPE,
  'fifthWheelHeight',
  'fifthWheelHeightType',
  'fifthWheelWidth',
  'fifthWheelWidthType',
];

// TODO: rename fields (lengthUOM, widthUOM, heightUOM, GC.FIELD_WEIGHT_TYPE_GVWT, GC.FIELD_WEIGHT_TARE_TYPE)
const trailerMainSpecificationFields = [
  GC.FIELD_TARE_WEIGHT,
  GC.FIELD_WEIGHT_TARE_TYPE,
  GC.FIELD_GVWT,
  GC.FIELD_WEIGHT_TYPE_GVWT,
  GC.FIELD_SPECIFICATION_CLASS,
  GC.FIELD_AXLE_COUNT,
  GC.FIELD_AXLE_CONFIG,
  `${GC.FIELD_INTERNAL_DIMENSION}.${GC.FIELD_LENGTH}`,
  `${GC.FIELD_INTERNAL_DIMENSION}.lengthUOM`,
  `${GC.FIELD_INTERNAL_DIMENSION}.${GC.FIELD_WIDTH}`,
  `${GC.FIELD_INTERNAL_DIMENSION}.widthUOM`,
  `${GC.FIELD_INTERNAL_DIMENSION}.${GC.FIELD_HEIGHT}`,
  `${GC.FIELD_INTERNAL_DIMENSION}.heightUOM`,
  `${GC.FIELD_EXTERNAL_DIMENSION}.${GC.FIELD_LENGTH}`,
  `${GC.FIELD_EXTERNAL_DIMENSION}.lengthUOM`,
  `${GC.FIELD_EXTERNAL_DIMENSION}.${GC.FIELD_WIDTH}`,
  `${GC.FIELD_EXTERNAL_DIMENSION}.widthUOM`,
  `${GC.FIELD_EXTERNAL_DIMENSION}.${GC.FIELD_HEIGHT}`,
  `${GC.FIELD_EXTERNAL_DIMENSION}.heightUOM`,
  `${GC.FIELD_WELL_DIMENSION}.${GC.FIELD_HEIGHT}`,
  `${GC.FIELD_WELL_DIMENSION}.heightUOM`,
  `${GC.FIELD_WELL_DIMENSION}.${GC.FIELD_LENGTH}`,
  `${GC.FIELD_WELL_DIMENSION}.lengthUOM`,
  GC.FIELD_DOOR_TYPE,
  `${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_WIDTH}`,
  `${GC.FIELD_DOOR_DIMENSION}.widthUOM`,
  `${GC.FIELD_DOOR_DIMENSION}.${GC.FIELD_HEIGHT}`,
  `${GC.FIELD_DOOR_DIMENSION}.heightUOM`,
  GC.FIELD_BODY_MATERIAL,
  GC.FIELD_DECK_HEIGHT,
  GC.FIELD_DECK_HEIGHT_TYPE,
  GC.FIELD_CUBE,
  GC.FIELD_CUBE_TYPE,
  GC.FIELD_BULK_COMP_COUNT,
  GC.FIELD_BULK_COMP_VOLUME,
  GC.FIELD_BULK_COMP_VOLUME_TYPE,
  GC.FIELD_TEMPERATURE_CONTROL,
];

const refrigerationFields = [
  GC.FIELD_REFRIGERATION_MANUFACTURER,
  GC.FIELD_OUTPUT_YEAR,
  GC.FIELD_MODEL,
  GC.FIELD_SERIES,
  GC.FIELD_SERIAL_NUMBER,
  GC.FIELD_COOLANT_TYPE,
  GC.FIELD_CARB_ID,
  GC.FIELD_TRUATCM_ID,
  GC.FIELD_TEMP_RANGE_FROM,
  GC.FIELD_TEMP_RANGE_TO,
  GC.FIELD_TEMP_RANGE_TYPE,
  GC.FIELD_HOURS,
  GC.FIELD_HOURS_DATE,
];


export const truckDefaultFields = makeDefaultFields(R.concat(mainSpecificationFields, tractorSpecificationFields));

export const trailerDefaultFields = {
  ...makeDefaultFields(trailerMainSpecificationFields),
  [GC.FIELD_REFRIGERATION]: makeDefaultFields(refrigerationFields),
};

export const truckFieldsGroups = [
  {
    fieldSettings: makeFieldSettings(mainSpecificationFields, truckSettings),
    formSectionTitle: G.getWindowLocale('titles:main-specifications', 'Main Specifications'),
  },
  {
    fieldSettings: makeFieldSettings(tractorSpecificationFields, truckSettings),
    formSectionTitle: G.getWindowLocale('titles:tractor-specifications', 'Tractor Specifications'),
  },
];


export const trailerFieldsGroups = [
  {
    fieldsWrapperStyles: { width: 730 },
    fieldSettings: makeFieldSettings(trailerMainSpecificationFields, trailerSettings),
    formSectionTitle: G.getWindowLocale('titles:main-specifications', 'Main Specifications'),
  },
  {
    fieldsWrapperStyles: { width: 730 },
    fieldSettings: makeFieldSettings(refrigerationFields, trailerSettings),
    formSectionTitle: G.getWindowLocale('titles:refrigeration', 'Refrigeration'),
  },
];

const uomValidation = (field: string) => Yup.string().when(
  field,
  {
    is: G.isNotNilAndNotEmpty,
    otherwise: (schema: Object) => schema.notRequired(),
    then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
  },
);

const minmaxValidation = (max: number) => Yup.number()
  .notRequired()
  .nullable(true)
  .typeError(G.getShouldBeNumericLocaleTxt())
  .min(0, G.getShouldBeFromToLocaleTxt(0, max))
  .max(max, G.getShouldBeFromToLocaleTxt(0, max))
  .transform((value: number) => G.ifElse(G.isNotNaN(value), value));

const dimensionValidationSchema = Yup.object().nullable(true).shape({
  [GC.FIELD_WIDTH]: minmaxValidation(1000000000),
  [GC.FIELD_HEIGHT]: minmaxValidation(1000000000),
  [GC.FIELD_LENGTH]: minmaxValidation(1000000000),
  // uom
  widthUOM: uomValidation(GC.FIELD_WIDTH),
  lengthUOM: uomValidation(GC.FIELD_LENGTH),
  heightUOM: uomValidation(GC.FIELD_HEIGHT),
});

export const truckValidationSchema = Yup.object().shape({
  [GC.FIELD_GVWT]: minmaxValidation(1000000),
  [GC.FIELD_WIDTH]: minmaxValidation(1000000000),
  [GC.FIELD_HEIGHT]: minmaxValidation(1000000000),
  [GC.FIELD_LENGTH]: minmaxValidation(1000000000),
  [GC.FIELD_TARE_WEIGHT]: minmaxValidation(1000000),
  // uom
  [GC.FIELD_WIDTH_UOM]: uomValidation(GC.FIELD_WIDTH),
  [GC.FIELD_LENGTH_UOM]: uomValidation(GC.FIELD_LENGTH),
  [GC.FIELD_HEIGHT_UOM]: uomValidation(GC.FIELD_HEIGHT),
  [GC.FIELD_GVWT_WEIGHT_TYPE]: uomValidation(GC.FIELD_GVWT),
  [GC.FIELD_TARE_WEIGHT_TYPE]: uomValidation(GC.FIELD_TARE_WEIGHT),
  [GC.FIELD_FIFTH_WHEEL_WIDTH_TYPE]: uomValidation(GC.FIELD_FIFTH_WHEEL_WIDTH),
  [GC.FIELD_FIFTH_WHEEL_HEIGHT_TYPE]: uomValidation(GC.FIELD_FIFTH_WHEEL_HEIGHT),
  [GC.FIELD_DOOR_DIMENSION]: Yup.object().nullable(true).shape({
    [GC.FIELD_DOOR_WIDTH]: minmaxValidation(10000),
    [GC.FIELD_DOOR_HEIGHT]: minmaxValidation(10000),
    // uom
    [GC.FIELD_DOOR_WIDTH_UOM]: uomValidation(GC.FIELD_DOOR_WIDTH),
    [GC.FIELD_DOOR_HEIGHT_UOM]: uomValidation(GC.FIELD_DOOR_HEIGHT),
  }),
});

export const trailerValidationSchema = Yup.lazy(({ temperatureControl }: Object) => {
  let schema = {
    [GC.FIELD_GVWT]: minmaxValidation(1000000),
    [GC.FIELD_CUBE]: minmaxValidation(1000000000),
    [GC.FIELD_TARE_WEIGHT]: minmaxValidation(1000000),
    [GC.FIELD_DECK_HEIGHT]: minmaxValidation(1000000000),
    [GC.FIELD_BULK_COMP_VOLUME]: minmaxValidation(1000000000),
    // dimensions
    [GC.FIELD_WELL_DIMENSION]: dimensionValidationSchema,
    [GC.FIELD_DOOR_DIMENSION]: dimensionValidationSchema,
    [GC.FIELD_INTERNAL_DIMENSION]: dimensionValidationSchema,
    [GC.FIELD_EXTERNAL_DIMENSION]: dimensionValidationSchema,
    // uom
    [GC.FIELD_CUBE_TYPE]: uomValidation(GC.FIELD_CUBE),
    [GC.FIELD_WEIGHT_TYPE_GVWT]: uomValidation(GC.FIELD_GVWT),
    [GC.FIELD_DECK_HEIGHT_TYPE]: uomValidation(GC.FIELD_DECK_HEIGHT),
    [GC.FIELD_WEIGHT_TARE_TYPE]: uomValidation(GC.FIELD_TARE_WEIGHT),
    [GC.FIELD_BULK_COMP_VOLUME_TYPE]: uomValidation(GC.FIELD_BULK_COMP_VOLUME),
  };

  if (temperatureControl) {
    const refrigerationSchema = Yup.object().shape({
      [GC.FIELD_MODEL]: G.yupStringRequired,
      [GC.FIELD_HOURS]: G.yupNumberRequired,
      [GC.FIELD_SERIES]: G.yupStringRequired,
      [GC.FIELD_HOURS_DATE]: G.yupStringRequired,
      [GC.FIELD_COOLANT_TYPE]: G.yupStringRequired,
      [GC.FIELD_SERIAL_NUMBER]: G.yupStringRequired,
      [GC.FIELD_TEMP_RANGE_TO]: G.yupNumberRequired,
      [GC.FIELD_TEMP_RANGE_FROM]: G.yupNumberRequired,
      [GC.FIELD_TEMP_RANGE_TYPE]: G.yupStringRequired,
      [GC.FIELD_REFRIGERATION_MANUFACTURER]: G.yupStringRequired,
      [GC.FIELD_OUTPUT_YEAR]: G.yupNumberRequired
        .min(1900, G.getShouldBeFromToLocaleTxt(1900, 2100))
        .max(2100, G.getShouldBeFromToLocaleTxt(1900, 2100)),
    });

    schema = R.assoc(GC.FIELD_REFRIGERATION, refrigerationSchema, schema);
  }

  return Yup.object().shape(schema);
});
