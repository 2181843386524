import L from 'leaflet';
import * as R from 'ramda';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { Circle, Marker, CircleMarker } from 'react-leaflet';
import React, {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
// components
import Map from '../../../components/simple-map-with-markers';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import {
  getFleetTrailerListSuccess,
  getGeoFencingLocationRequest,
  getGeoFencingLocationSuccess,
} from '../../geo-fencing-location/actions';
import { VIEW_MODE, feetToMeters } from '../../geo-fencing-location/helpers';
import { makeSelectCurrentLocation } from '../../geo-fencing-location/selectors';
import ViewLocationPopup from '../../geo-fencing-location/components/view-location-popup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
// feature fleet-map
import { convertRadiusFromMetersToPixels } from '../helpers';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const markerColor = G.getTheme('colors.dark.blue');

const markerCircleRadius = 18;
const iconSize = R.multiply(markerCircleRadius, 2);

const getLocationMarker = (text: string, width: number) => L.divIcon({
  className: '',
  iconAnchor: [width / 2, markerCircleRadius],
  html: renderToString(
    <Flex
      fontSize={16}
      width={width}
      fontWeight='bold'
      height={iconSize}
      alignItems='center'
      color={markerColor}
      pointerEvents='none'
      justifyContent='center'
    >
      {text}
    </Flex>),
});

const GeoFencingLocation = memo(({
  zoom,
  viewLocation,
  geoFencingLocation,
}: Object) => (
  G.isNotNilAndNotEmpty(geoFencingLocation)
  && R.map((location: Object) => {
    const {
      guid,
      name,
      radius,
      latitude,
      longitude,
      radiusUom,
      trailerCount,
    } = location;

    const center = [latitude, longitude];

    const circleRadius = R.equals(radiusUom, GC.UOM_METER) ? radius : +feetToMeters(radius);

    const radiusInPixels = convertRadiusFromMetersToPixels(zoom, circleRadius, latitude);

    const nameTemplate = `${name} - ${trailerCount}`;
    const nameWidth = G.getStringWidth(nameTemplate, 'bold 16px arial');
    const withName = radiusInPixels > nameWidth / 2;
    const locationText = withName ? nameTemplate : trailerCount;
    const markerWidth = withName ? nameWidth : iconSize;

    return (
      <Fragment key={guid}>
        <Marker
          position={center}
          icon={getLocationMarker(locationText, markerWidth)}
        />
        { R.gte(radiusInPixels, markerCircleRadius) &&
          <Circle
            center={center}
            radius={circleRadius}
            onClick={() => viewLocation(guid)}
          />
        }
        { R.lt(radiusInPixels, markerCircleRadius) &&
          <CircleMarker
            center={center}
            radius={markerCircleRadius}
            onClick={() => viewLocation(guid)}
          />
        }
      </Fragment>
    );
  }, geoFencingLocation)
));

const FleetsMapComponent = memo(({
  locations,
  markerIcon,
  trailersActive,
  withoutClustering,
  withoutGeoFencing,
  geoFencingLocation,
  markersContainerForGM,
}: Object) => {
  const dispatch = useDispatch();

  const [mapZoom, setMapZoom] = useState(4);

  const currentLocation = useSelector(makeSelectCurrentLocation());

  const handleSetZoom = useCallback((zoom: number) => {
    setMapZoom(zoom);
  }, []);

  const closePopup = useCallback(() => {
    dispatch(getGeoFencingLocationSuccess(null));
    dispatch(getFleetTrailerListSuccess(null));

    dispatch(closeModal());
  }, []);

  const openViewLocationPopup = useCallback(() => {
    const component = (
      <ViewLocationPopup
        fromTrailer={true}
        closeModal={closePopup}
        currentLocation={currentLocation}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 'auto',
        height: 'auto',
        movable: false,
        withCloseIcon: true,
        title: G.getWindowLocale('titles:view-location', 'View Location'),
      },
    };

    dispatch(openModal(modal));
  }, [closePopup, currentLocation]);

  const viewLocation = useCallback((guid: string) => {
    dispatch(getGeoFencingLocationRequest({ guid, mode: VIEW_MODE }));
  }, []);

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(currentLocation)) {
      openViewLocationPopup();
    }
  }, [currentLocation, openViewLocationPopup]);

  const childMapComponent = R.or(withoutGeoFencing, R.not(trailersActive)) ? null : (
    <GeoFencingLocation
      zoom={mapZoom}
      viewLocation={viewLocation}
      trailersActive={trailersActive}
      withoutGeoFencing={withoutGeoFencing}
      geoFencingLocation={geoFencingLocation}
    />
  );

  return (
    <Map
      width='100%'
      mapType='fleet'
      locations={locations}
      markerIcon={markerIcon}
      height='calc(100% - 62px)'
      handleSetZoom={handleSetZoom}
      markerIconColor={darkBlueColor}
      childMapComponent={childMapComponent}
      withoutClustering={withoutClustering}
      markersContainerForGM={markersContainerForGM}
    />
  );
});

export default FleetsMapComponent;
