import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../../components/modal/actions';
import { closeLoader, openLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import {
  getConfigByGroupSaga,
  getStatusMessagesConfigSaga,
  getSequencesByTypeForConfigSaga,
  getTermsAndConditionsDocumentRequest,
  getTransportationModeConfigMappingListRequest,
} from '../../sagas';
//////////////////////////////////////////////////

function* getAccessorialsForConfigSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.accessorialsListEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAccessorialForConfigSuccess(data));
      G.setItemToWindow('assessorialList', data);
    } else {
      yield call(G.handleFailResponse, res, 'getAccessorialsForConfigSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAccessorialsForConfigSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown exception');
  }
}

function* handleCreateAccessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload) };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.accessorialsEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield put(A.createAccessorialSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateAccessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateAccessorialSaga exception');
  }
}

function* handleUpdateAccessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = {
      data: payload,
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.accessorialsEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield put(A.updateAccessorialSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateAccessorialSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateAccessorialSaga exception');
  }
}

function* handleDeleteAccessorialRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const endpoint = endpointsMap.getDeleteAccessorialEndpoint(payload);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteAccessorialSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteAccessorialRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteAccessorialRequest exception');
  }
}

function* getDistanceCalculatorConfigListSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.distanceCalculatorConfigList,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDistanceCalculatorConfigListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getDistanceCalculatorConfigListSaga exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDistanceCalculatorConfigListSaga exception');
  }
}

function* handleCreateDistanceCalculatorConfigSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const data = R.assoc(GC.BRANCH_GUID, branchGuid, payload);
    const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculatorConfig, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getDistanceCalculatorConfigListSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handlecreateDistanceCalculatorConfigSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handlecreateDistanceCalculatorConfigSaga exception');
  }
}

function* handleUpdateDistanceCalculatorConfigSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.distanceCalculatorConfig,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getDistanceCalculatorConfigListSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDistanceCalculatorConfigSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateDistanceCalculatorConfigSaga exception');
  }
}

function* handleRemoveDistanceCalculatorConfigSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.distanceCalculatorConfig,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getDistanceCalculatorConfigListSaga);
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handleRemoveDistanceCalculatorConfigSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRemoveDistanceCalculatorConfigSaga exception');
  }
}

// load custom status
function* getLoadCustomStatusListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.loadCustomStatusList,
      successAction: A.getLoadCustomStatusListSuccess,
      parentSagaName: 'getLoadCustomStatusListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getLoadCustomStatusListRequest exception');
  }
}

function* createOrUpdateLoadCustomStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.loadCustomStatus,
      successMessage: 'messages:success:200-201',
      successAction: A.createOrUpdateLoadCustomStatusSuccess,
      parentSagaName: 'createOrUpdateLoadCustomStatusRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateLoadCustomStatusRequest exception');
  }
}

function* removeLoadCustomStatusRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      payload,
      options,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.loadCustomStatus,
      successAction: A.removeLoadCustomStatusSuccess,
      parentSagaName: 'removeLoadCustomStatusRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeCarrierPickupRequesterRequest exception');
  }
}

// transportation mode grouping
function* getTransportationModeGroupingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.transportationModeGroupingList,
      successAction: A.getTransportationModeGroupingListSuccess,
      parentSagaName: 'getTransportationModeGroupingListRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTransportationModeGroupingListRequest exception');
  }
}

function* createOrUpdateTransportationModeGroupingRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      endpoint: endpointsMap.transportationModeGrouping,
      successAction: A.createOrUpdateTransportationModeGroupingSuccess,
      parentSagaName: 'createOrUpdateTransportationModeGroupingRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateTransportationModeGroupingRequest exception');
  }
}

function* removeTransportationModeGroupingRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      payload,
      options,
      method: 'delete',
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      endpoint: endpointsMap.transportationModeGrouping,
      successAction: A.removeTransportationModeGroupingSuccess,
      parentSagaName: 'removeTransportationModeGroupingRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'removeTransportationModeGroupingRequest exception');
  }
}

function* handleVisitConfigGeneralPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_GENERAL_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getConfigByGroupSaga, GC.GENERAL_CONFIG_GROUP);
    yield call(getAccessorialsForConfigSaga);
    yield call(getStatusMessagesConfigSaga);
    yield call(getLoadCustomStatusListRequest);
    yield call(getTransportationModeGroupingListRequest);
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_ROUTE });
    yield call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_BRANCH });
    yield call(getDistanceCalculatorConfigListSaga);
    yield call(getTermsAndConditionsDocumentRequest, { payload: GC.GENERAL_CONFIG_GROUP });
    yield call(
      getTransportationModeConfigMappingListRequest,
      { payload: GC.GENERAL_CONFIG_GROUP },
    );
    yield put(closeLoader());
    break;
  }
}

function* generalConfigWatcherSaga() {
  yield takeLatest(GC.VISIT_CONFIG_GENERAL_PAGE, handleVisitConfigGeneralPageSaga);
  //
  yield takeLatest(A.createAccessorialRequest, handleCreateAccessorialSaga);
  yield takeLatest(A.updateAccessorialRequest, handleUpdateAccessorialSaga);
  yield takeLatest(A.deleteAccessorialRequest, handleDeleteAccessorialRequest);
  yield takeLatest(A.getAccessorialForConfigRequest, getAccessorialsForConfigSaga);
  yield takeLatest(A.getDistanceCalculatorConfigListRequest, getDistanceCalculatorConfigListSaga);
  yield takeLatest(A.createDistanceCalculatorConfigRequest, handleCreateDistanceCalculatorConfigSaga);
  yield takeLatest(A.updateDistanceCalculatorConfigRequest, handleUpdateDistanceCalculatorConfigSaga);
  yield takeLatest(A.removeDistanceCalculatorConfigRequest, handleRemoveDistanceCalculatorConfigSaga);
  // load custom status
  yield takeLatest(A.removeLoadCustomStatusRequest, removeLoadCustomStatusRequest);
  yield takeLatest(A.getLoadCustomStatusListRequest, getLoadCustomStatusListRequest);
  yield takeLatest(A.createOrUpdateLoadCustomStatusRequest, createOrUpdateLoadCustomStatusRequest);
  // transportation mode grouping
  yield takeLatest(A.removeTransportationModeGroupingRequest, removeTransportationModeGroupingRequest);
  yield takeLatest(A.getTransportationModeGroupingListRequest, getTransportationModeGroupingListRequest);
  yield takeLatest(A.createOrUpdateTransportationModeGroupingRequest, createOrUpdateTransportationModeGroupingRequest);
}

export default generalConfigWatcherSaga;
