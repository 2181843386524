import * as R from 'ramda';
import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
// forms
import { FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { ZOrderWrapper, MainActionButton } from '../../../ui';
// feature carrier-profile
import FormGroupTitle from './form-group-title';
import { useTableProps } from '../hooks/use-table-props';
import { useFormGroupOperations, useReportFormGroupOperations } from '../hooks/use-form-group-operations';
import {
  makeSelectIsOpenedFormGroup,
  makeSelectTotalCountByGroupName,
  makeSelectPaginationByGroupName,
  makeSelectUsedReportByGroupName,
  makeSelectListLoadingByGroupName,
  makeSelectTitleSortValuesByGroupName,
  makeSelectAvailableReportsByGroupName,
  makeSelectTableTitleFiltersByGroupName,
} from '../selectors';
import {
  setReports,
  selectItem,
  setUsedReport,
  setListLoading,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  resetListAndPagination,
  setTableTitleSortValues,
  getReportItemListRequest,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} from '../actions';
//////////////////////////////////////////////////

const getMemoDeps = ({ props, groupName, operations, tablePropsMemoDeps }: Object) => {
  if (R.isEmpty(tablePropsMemoDeps)) return [groupName];

  return R.values(R.pick(tablePropsMemoDeps, R.mergeRight(props, operations)));
};

const FormGroupTable = (props: Object) => {
  const {
    p,
    group,
    margin,
    dispatch,
    expandedContainer,
    primaryObjectGuidKey,
    toggleFormGroupTableDetails,
  } = props;

  const {
    fields,
    report,
    groupName,
    endpoints,
    omitColumns,
    actionsPicker,
    rowBgFunction,
    hideIfEmptyList,
    downloadOptions,
    hideEmptyFields,
    tableInnerWidth,
    formGroupTitleArr,
    getEditPermissions,
    getRemovePermissions,
    shouldNotRenderTable,
    withArrowDown = false,
    tablePropsMemoDeps = [],
    hasFormGroupTitle = true,
    tableCallbackDataProps = [],
    additionalTableSettings = {},
  } = group;

  const isOpened = useSelector((state: Object) => makeSelectIsOpenedFormGroup(state, groupName));

  const operations = useFormGroupOperations(props);

  const {
    itemList,
    handleRemoveItem,
    getItemListRequest,
    handlePreviewDocument,
    handleDownloadDocument,
    handleCreateOrUpdateItem,
    handleToggleFormGroupTable,
  } = operations;

  const tableProps = useTableProps({
    fields,
    report,
    itemList,
    dispatch,
    groupName,
    endpoints,
    omitColumns,
    actionsPicker,
    downloadOptions,
    hideEmptyFields,
    handleRemoveItem,
    expandedContainer,
    getEditPermissions,
    primaryObjectGuidKey,
    getRemovePermissions,
    handlePreviewDocument,
    handleDownloadDocument,
    additionalTableSettings,
    handleCreateOrUpdateItem,
    memoDeps: getMemoDeps({ props, groupName, operations, tablePropsMemoDeps }),
  });

  if (R.or(
    G.isTrue(shouldNotRenderTable),
    R.and(hideIfEmptyList, R.isEmpty(itemList)),
  )) return null;

  return (
    <FormGroupWrapper p={p} m={margin} isOpened={isOpened}>
      {
        hasFormGroupTitle &&
        <FormGroupTitle
          {...props}
          {...group}
          isOpened={isOpened}
          itemList={itemList}
          withArrowDown={withArrowDown}
          getItemListRequest={getItemListRequest}
          handleAddEntity={handleCreateOrUpdateItem}
          handleToggleFormGroupTable={() => handleToggleFormGroupTable(groupName)}
          text={G.getWindowLocale(...G.ifElse(G.isNotNilAndNotEmpty(formGroupTitleArr), formGroupTitleArr, []))}
        />
      }
      {
        G.isTrue(isOpened) &&
        <Table
          {...tableProps}
          itemList={itemList}
          rowBgFunction={rowBgFunction}
          tableInnerWidth={tableInnerWidth}
          callbackData={R.pick(tableCallbackDataProps, props)}
          toggle={({ guid }: Object) => toggleFormGroupTableDetails({ guid, groupName })}
        />
      }
    </FormGroupWrapper>
  );
};

const mapStateToProps = (state: Object, { groupName }: Object) => createStructuredSelector({
  totalCount: makeSelectTotalCountByGroupName(groupName),
  pagination: makeSelectPaginationByGroupName(groupName),
  listLoading: makeSelectListLoadingByGroupName(groupName),
  selectedReport: makeSelectUsedReportByGroupName(groupName),
  reportList: makeSelectAvailableReportsByGroupName(groupName),
  titleSortValues: makeSelectTitleSortValuesByGroupName(groupName),
  tableTitleFilters: makeSelectTableTitleFiltersByGroupName(groupName),
});

const enhance = connect(
  mapStateToProps,
  {
    setReports,
    selectItem,
    setUsedReport,
    setListLoading,
    setTableTitleFilter,
    createReportRequest,
    updateReportRequest,
    resetListAndPagination,
    setTableTitleSortValues,
    getReportItemListRequest,
    changeDefaultReportRequest,
    getAvailableReportsRequest,
  },
);

const CustomTitleComponent = (props: Object) => {
  const { itemTitleArr = [], handleCreateOrUpdateItem, AdditionalReportTitleComponent } = props;

  const mainButtonProps = {
    ml: 20,
    mb: '3px',
    height: 30,
    onClick: () => handleCreateOrUpdateItem(),
  };

  if (R.isNotNil(AdditionalReportTitleComponent)) {
    return (
      <Fragment>
        <MainActionButton {...mainButtonProps}>{G.getAddTitle(itemTitleArr)}</MainActionButton>
        <AdditionalReportTitleComponent {...props} />
      </Fragment>
    );
  }

  return <MainActionButton {...mainButtonProps}>{G.getAddTitle(itemTitleArr)}</MainActionButton>;
};

export const ReportFormGroupTable = enhance((props: Object) => {
  const {
    group,
    margin,
    pagination,
    totalCount,
    reportList,
    listLoading,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    handleExportReport,
    primaryObjectGuidKey,
    filterSelectMaxMenuHeight,
    reportSelectMaxMenuHeight,
    toggleFormGroupTableDetails,
  } = props;

  const {
    fields,
    report,
    groupName,
    endpoints,
    reportType,
    omitColumns,
    filterProps,
    actionsPicker,
    downloadOptions,
    hideEmptyFields,
    tableInnerWidth,
    formGroupTitleArr,
    getEditPermissions,
    getRemovePermissions,
    withResizableColumns,
    useSearchableColumns,
    withArrowDown = false,
    handleLoadMoreEntities,
    tablePropsMemoDeps = [],
    hasFormGroupTitle = true,
    tableCallbackDataProps = [],
    additionalTableSettings = {},
  } = group;

  const isOpened = useSelector((state: Object) => makeSelectIsOpenedFormGroup(state, groupName));

  const operations = useReportFormGroupOperations(props);

  const {
    itemList,
    handleSelectItem,
    handleSetReports,
    handleRemoveItem,
    handleEditReport,
    handleSelectReport,
    getItemListRequest,
    handleSetUsedReport,
    handleTableTitleFilter,
    handleCreateOrUpdateItem,
    handleUpdateReportRequest,
    handleToggleFormGroupTable,
    handleResetListAndPagination,
    handleChangeDefaultReportRequest,
  } = operations;

  const tableProps = useTableProps({
    fields,
    report,
    itemList,
    groupName,
    endpoints,
    omitColumns,
    actionsPicker,
    downloadOptions,
    hideEmptyFields,
    handleRemoveItem,
    getEditPermissions,
    primaryObjectGuidKey,
    getRemovePermissions,
    additionalTableSettings,
    handleCreateOrUpdateItem,
    memoDeps: getMemoDeps({ props, groupName, operations, tablePropsMemoDeps }),
  });

  const selectedList = R.compose(
    R.map(G.getGuidFromObject),
    R.filter(R.prop('selected')),
  )(R.or(itemList, []));

  return (
    <FormGroupWrapper m={margin} isOpened={isOpened}>
      {
        hasFormGroupTitle &&
        <FormGroupTitle
          {...props}
          {...group}
          isOpened={isOpened}
          itemList={itemList}
          totalCount={totalCount}
          withArrowDown={withArrowDown}
          getItemListRequest={getItemListRequest}
          handleAddEntity={handleCreateOrUpdateItem}
          handleToggleFormGroupTable={() => handleToggleFormGroupTable(groupName)}
          text={G.getWindowLocale(...G.ifElse(G.isNotNilAndNotEmpty(formGroupTitleArr), formGroupTitleArr, []))}
        />
      }
      {
        isOpened &&
        <Fragment>
          <ZOrderWrapper zIndex='3'>
            <TitlePanel
              type={reportType}
              reportList={reportList}
              withoutQuickFilter={true}
              usePortalForFilters={true}
              usedReport={selectedReport}
              setReports={handleSetReports}
              hiddenRightFilterInfo={false}
              selectedReport={selectedReport}
              filterProps={R.values(filterProps)}
              setUsedReport={handleSetUsedReport}
              handleEditReport={handleEditReport}
              getItemListRequest={getItemListRequest}
              handleSelectReport={handleSelectReport}
              exportReportDataRequest={handleExportReport}
              updateReportRequest={handleUpdateReportRequest}
              reportSelectMaxMenuHeight={reportSelectMaxMenuHeight}
              filterSelectMaxMenuHeight={filterSelectMaxMenuHeight}
              noExportable={R.not(G.isFunction(handleExportReport))}
              changeDefaultReportRequest={handleChangeDefaultReportRequest}
              customTitleComponent={(
                <CustomTitleComponent
                  {...group}
                  {...props}
                  selectedList={selectedList}
                  getItemListRequest={getItemListRequest}
                  handleCreateOrUpdateItem={handleCreateOrUpdateItem}
                  handleResetListAndPagination={handleResetListAndPagination}
                />
              )}
            />
          </ZOrderWrapper>
          <ZOrderWrapper zIndex='2'>
            <Table
              {...tableProps}
              itemList={itemList}
              loading={listLoading}
              pagination={pagination}
              totalCount={totalCount}
              report={selectedReport}
              filterProps={filterProps}
              useNewTitleFilterInputs={true}
              onOptionClick={handleSelectItem}
              tableInnerWidth={tableInnerWidth}
              titleSortValues={titleSortValues}
              tableTitleFilters={tableTitleFilters}
              withResizableColumns={withResizableColumns}
              useSearchableColumns={useSearchableColumns}
              handleTableTitleFilter={handleTableTitleFilter}
              callbackData={R.pick(tableCallbackDataProps, props)}
              handleLoadMoreEntities={R.or(handleLoadMoreEntities, getItemListRequest)}
              toggle={({ guid }: Object) => toggleFormGroupTableDetails({ guid, groupName })}
            />
          </ZOrderWrapper>
        </Fragment>
      }
    </FormGroupWrapper>
  );
});

export default FormGroupTable;
