import * as R from 'ramda';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature fleet-profile
import { FormGroupTableWithSimpleCrudOperations } from './form-group-table';
import { makeSelectAssignment, makeSelectTrailersIntegrations } from '../selectors';
//////////////////////////////////////////////////

const trailerLocale = G.getWindowLocale('titles:trailer-integration', 'Trailer Integration');

const TrailersIntegrations = (props: Object) => {
  const { collapsedGroup, trailerSettings } = props;

  const trailersIntegrations = useSelector(makeSelectTrailersIntegrations);

  return (
    trailersIntegrations.map(({ guid, unitId, itemList }: Object) => (
      <FormGroupTableWithSimpleCrudOperations
        {...props}
        {...trailerSettings}
        key={guid}
        itemList={itemList}
        primaryObjectGuid={guid}
        groupName={`trailerIntegrationList.${guid}`}
        primaryObjectGuidKey={GC.FIELD_TRAILER_GUID}
        formGroupTitleArr={['_', `${trailerLocale}: ${unitId}`]}
        isOpened={R.path(['trailerIntegrationList', guid], collapsedGroup)}
      />
    ))
  );
};

const AssignmentsIntegrations = ({
  entities,
  fleetType,
  truckSettings,
  collapsedGroup,
  driverSettings,
  trailerSettings,
  driverAssignmentGuid,
  ...props
}: Object) => {
  const assignment = useSelector(makeSelectAssignment());

  if (R.and(R.isNil(assignment), R.isNotNil(driverAssignmentGuid))) return null;

  const { guid, truck, trailers, teamDriver } = R.or(assignment, {});

  if (G.isAllNilOrEmpty([truck, trailers, teamDriver])) {
    const settingsMap = {
      truck: truckSettings,
      driver: driverSettings,
      trailer: trailerSettings,
    };

    return <FormGroupTableWithSimpleCrudOperations {...props} {...R.propOr(driverSettings, fleetType, settingsMap)} />;
  }

  return (
    <Fragment>
      <FormGroupTableWithSimpleCrudOperations
        {...props}
        {...driverSettings}
        primaryObjectGuid={guid}
        primaryObjectGuidKey={GC.FIELD_DRIVER_GUID}
        formGroupTitleArr={['titles:primary-driver-integration', 'Primary Driver Integration']}
      />
      {
        G.isNotNilAndNotEmpty(teamDriver) &&
        <FormGroupTableWithSimpleCrudOperations
          {...props}
          {...driverSettings}
          groupName='teamDriverIntegrationList'
          primaryObjectGuidKey={GC.FIELD_DRIVER_GUID}
          primaryObjectGuid={G.getGuidFromObject(teamDriver)}
          itemList={R.prop('teamDriverIntegrationList', entities)}
          isOpened={R.prop('teamDriverIntegrationList', collapsedGroup)}
          formGroupTitleArr={['titles:team-driver-integration', 'Team Driver Integration']}
        />
      }
      {
        G.isNotNilAndNotEmpty(truck) &&
        <FormGroupTableWithSimpleCrudOperations
          {...props}
          {...truckSettings}
          groupName='truckIntegrationList'
          primaryObjectGuidKey={GC.FIELD_TRUCK_GUID}
          primaryObjectGuid={G.getGuidFromObject(truck)}
          itemList={R.prop('truckIntegrationList', entities)}
          isOpened={R.prop('truckIntegrationList', collapsedGroup)}
          formGroupTitleArr={['titles:truck-integration', 'Truck Integration']}
        />
      }
      <TrailersIntegrations
        {...props}
        collapsedGroup={collapsedGroup}
        trailerSettings={trailerSettings}
      />
    </Fragment>
  );
};

export default AssignmentsIntegrations;
