import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import ToggleBtn from '../../../components/toggle-btn';
import { Switcher } from '../../../components/switcher';
import ColorPicker from '../../../components/color-picker';
import { DatePickerMui } from '../../../components/datepicker-mui';
import { openModal, closeModal } from '../../../components/modal/actions';
import { QuickFilterOutsideControl3 } from '../../../components/quick-filter';
import { PlacesAutocompleteInputMui } from '../../../components/places-autocomplete-input/mui';
// features
import { commonBtnStyles } from '../../drivers-card/components/header';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncConfigs, withComponentDidUpdatePropCallback } from '../../../hocs';
// ui
import { Flex, Text, ActionButton, RelativeFlex, MainActionButton } from '../../../ui';
// feature available-driver
import { TruckTypesSelect } from './truck-types-select';
import {
  setSearchDate,
  setTruckTypes,
  setColorFilter,
  setSearchRadius,
  changeActiveList,
  setLocationValue,
  setDriverInitials,
  setSearchLocation,
  toggleTeamFilterValue,
  getSearchLocationRequest,
} from '../actions';
import {
  makeSelectActiveList,
  makeSelectSearchDate,
  makeSelectTruckTypes,
  makeSelectPageVisited,
  makeSelectSearchRadius,
  makeSelectLocationValue,
  makeSelectDriverInitials,
  makeSelectSearchLocation,
} from '../selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  searchDate: makeSelectSearchDate(state),
  activeList: makeSelectActiveList(state),
  truckTypes: makeSelectTruckTypes(state),
  pageVisited: makeSelectPageVisited(state),
  searchRadius: makeSelectSearchRadius(state),
  locationValue: makeSelectLocationValue(state),
  driverInitials: makeSelectDriverInitials(state),
  searchLocation: makeSelectSearchLocation(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    openModal,
    closeModal,
    setSearchDate,
    setTruckTypes,
    setColorFilter,
    setSearchRadius,
    changeActiveList,
    setLocationValue,
    setDriverInitials,
    setSearchLocation,
    toggleTeamFilterValue,
    getSearchLocationRequest,
  }),
  withProps({ configsNamesArray: [
    GC.UI_AVAILABLE_DRIVERS_DEFAULT_TRUCK_TYPES,
    GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_RADIUS,
    GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_LOCATION,
  ]}),
  withAsyncConfigs,
  withHandlers({
    handleChangeActiveList: ({ changeActiveList }: Object) => (type: string) => changeActiveList(type),
    handleCloseCallback: ({ setLocationValue, setSearchLocation }: Object) => () => {
      setLocationValue('');
      setSearchLocation(null);
    },
    handleChangeTruckTypes: ({ setTruckTypes}: Object) => (event: Object) => {
      const truckTypes = G.getEventTargetValue(event);

      setTruckTypes(truckTypes);
    },
    handleGetAvailableDriverListRequest: (props: Object) => () => {
      const {
        searchDate,
        truckTypes,
        searchRadius,
        driverInitials,
        searchLocation,
        getItemListRequest,
      } = props;

      const location = G.ifElse(
        G.isNilOrEmpty(searchLocation),
        null,
        {
          [GC.FIELD_LATITUDE]: G.getPropFromObject(GC.FIELD_LATITUDE, searchLocation),
          [GC.FIELD_LONGITUDE]: G.getPropFromObject(GC.FIELD_LONGITUDE, searchLocation),
        },
      );

      const data = {
        location,
        searchDate,
        truckTypes,
        driverInitials,
        radius: searchRadius,
        radiusUom: G.getMileOrKmUomByUomSystem(),
      };

      getItemListRequest(data);
    },
    handleChangeAsyncConfigs: (props: Object) => () => {
      const {
        pageVisited,
        asyncConfigs,
        setTruckTypes,
        setSearchRadius,
        getSearchLocationRequest,
      } = props;

      if (G.isFalse(pageVisited)) {
        const truckTypesFromConfig = G.getConfigValueFromStore(
          GC.UI_AVAILABLE_DRIVERS_DEFAULT_TRUCK_TYPES,
          asyncConfigs,
        );

        const radiusValueFromConfig = G.getConfigValueFromStore(
          GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_RADIUS,
          asyncConfigs,
        );

        const locationIdFromConfig = G.getConfigValueFromStore(
          GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_LOCATION,
          asyncConfigs,
        );

        if (G.isNotNilAndNotEmpty(locationIdFromConfig)) {
          getSearchLocationRequest(locationIdFromConfig);
        }

        setTruckTypes(R.or(truckTypesFromConfig, []));
        setSearchRadius(R.or(radiusValueFromConfig, ''));
      }
    },
    handleOpenCreateOrder: ({ handleSetExpandedContainerOptions }: Object) => () =>
      handleSetExpandedContainerOptions({ componentType: GC.PAGE_NEW_DO }),
  }),
  withComponentDidUpdatePropCallback({
    propName: 'asyncConfigs',
    callbackName: 'handleChangeAsyncConfigs',
  }),
  pure,
);

const SearchPanel = (props: Object) => {
  const {
    searchDate,
    searchRadius,
    setSearchDate,
    locationValue,
    driverInitials,
    setSearchRadius,
    setLocationValue,
    setSearchLocation,
    setDriverInitials,
    handleCloseCallback,
    handleGetAvailableDriverListRequest,
  } = props;

  const radiusPlaceholder = G.getDistanceUomLocale();

  return (
    <Flex mt={30}>
      <PlacesAutocompleteInputMui
        width={150}
        withCloseIcon={true}
        useOutsideValue={true}
        outsideValue={locationValue}
        resultCallback={setSearchLocation}
        setOutsideValue={setLocationValue}
        onCloseCallback={handleCloseCallback}
        inputWrapperStyles={{ mr: 20, width: 150 }}
        label={`${G.getWindowLocale('titles:search-location', 'Search Location')}*`}
      />
      <QuickFilterOutsideControl3
        delayTime='0'
        outsideValue={searchRadius}
        allowSingleCharacter={true}
        inputStyles={{ width: 110 }}
        placeholder={radiusPlaceholder}
        handleSetFilter={setSearchRadius}
        label={`${G.getWindowLocale('titles:search-radius', 'Search Radius')}*`}
      />
      <DatePickerMui
        width={100}
        m='0px 20px'
        isClearable={true}
        value={searchDate}
        inputReadOnly={true}
        withCalendarIcon={false}
        useNewMuiInputField={true}
        minDate={G.getCurrentDate()}
        label={G.getWindowLocale('titles:search-date', 'Search Date')}
        onChange={(date: Object) => G.isNilOrEmpty(date) && setSearchDate(null)}
        onAccept={(date: Object) =>
          G.isNotNilAndNotEmpty(date) && setSearchDate(G.convertInstanceToDefaultDateFormat(date))}
      />
      <TruckTypesSelect {...props} />
      <QuickFilterOutsideControl3
        delayTime='0'
        allowSingleCharacter={true}
        outsideValue={driverInitials}
        handleSetFilter={setDriverInitials}
        inputStyles={{ width: 185, maxWidth: 185 }}
        label={G.getWindowLocale('titles:search-by-driver-truck', 'Search by Driver/Truck')}
        placeholder={G.getWindowLocale('titles:driver-name-id-or-truck-unit-id', 'Driver Name/Id or Truck Unit Id')}
      />
      <ActionButton
        {...commonBtnStyles}
        ml={20}
        width={80}
        fontSize={12}
        onClick={handleGetAvailableDriverListRequest}
      >
        {G.getWindowLocale('titles:search', 'Search')}
      </ActionButton>
    </Flex>
  );
};

const switcherOptions = [
  {
    width: 100,
    value: 'availableDrivers',
    name: G.getWindowLocale('titles:available-drivers', 'Available Drivers'),
  },
  {
    width: 100,
    value: 'allDrivers',
    name: G.getWindowLocale('titles:all-drivers', 'All Drivers'),
  },
];

const Header = (props: Object) => {
  const {
    activeList,
    totalCount,
    colorFilter,
    setColorFilter,
    teamFilterValue,
    editViewBtnComponent,
    toggleTeamFilterValue,
    toggleUnassignedLoads,
    handleOpenCreateOrder,
    handleChangeActiveList,
  } = props;

  const isAvailableDriversList = R.equals(activeList, 'availableDrivers');

  const title = `${G.getWindowLocale(...G.ifElse(
    isAvailableDriversList,
    ['titles:available-drivers', 'Available Drivers'],
    ['titles:all-drivers', 'All Drivers'],
  ))} (${totalCount})`;

  return (
    <RelativeFlex
      zIndex='0'
      p='15px 25px 25px'
      flexDirection='column'
      alignItems='flex-start'
      bg={G.getTheme('colors.white')}
    >
      <Flex width='100%' justifyContent='space-between'>
        <Flex>
          <Text width={240} fontSize={20} fontWeight='bold'>{title}</Text>
          <Switcher
            ml={20}
            version={2}
            options={switcherOptions}
            onSwitch={handleChangeActiveList}
            selectedOptionIndex={G.ifElse(isAvailableDriversList, 0, 1)}
          />
          <ToggleBtn
            ml={15}
            checked={teamFilterValue}
            onToggleHandler={toggleTeamFilterValue}
            title={G.getWindowLocale('titles:show-team', 'Show Team')}
          />
          { editViewBtnComponent }
        </Flex>
        <Flex alignItems='flex-end'>
          <Flex mr={25} flexDirection='column'>
            <Text mb='3px' fontSize={11} color='darkGrey'>
              {G.getWindowLocale('titles:filter-by-color', 'Filter By Color')}
            </Text>
            <ColorPicker
              value={colorFilter}
              shouldTransformActive={true}
              changeColorCallback={setColorFilter}
              itemStyles={{ width: 25, height: 25, borderRadius: '4px' }}
              wrapperStyles={{ width: 150, justifyContent: 'space-between' }}
            />
          </Flex>
          <MainActionButton onClick={toggleUnassignedLoads}>
            {G.getWindowLocale('actions:search-loads', 'Search Loads')}
          </MainActionButton>
          <MainActionButton ml={20} onClick={handleOpenCreateOrder}>
            {G.getWindowLocale('actions:create-order', 'Create Order')}
          </MainActionButton>
        </Flex>
      </Flex>
      {G.isTrue(isAvailableDriversList) && <SearchPanel {...props} />}
    </RelativeFlex>
  );
};

export default enhance(Header);
